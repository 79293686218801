import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 80px 0;

  &.light {
    background: var(--surface-card);
  }

  h3 {
    display: inline-block;
  }

  ul {
    padding: 0;
  }
  .user-detail {
    > li {
      border-bottom: 2px solid #0cc9e6;
      padding: 20px 0;

      &:last-child {
        border-bottom: none;
      }

      h4 {
        font-size: 20px;
        font-weight: 400;
      }

      p {
        font-size: 12px;
        font-weight: 300;
        margin: 0;
        letter-spacing: 3.5px;
        word-spacing: 4px;
      }

      li {
        font-size: 14px;
        font-weight: 200;
        line-height: 1.7;
      }
    }
  }

  @media (max-width: 576px) {
  }
`;
