import { Breadcrumbs } from 'components/breadcrumbs';
import { Container } from 'components/container';
import PreLoading from 'components/pre-loading';
import { Section } from './index.styled';
import Footer from 'components/footer';
import NewsCard2 from 'components/news-card2';
import { HubspotBlog, useHubspotBlog } from 'hooks/userHooks';
import { HUB_SPOT_BLOG_TAG_IDS } from 'static/constants';
import { format } from 'date-fns';
import { DataView, DataViewPageParams } from 'primereact/dataview';
import { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'components/spinner';

const BlogListPage = () => {
  const state = useLocation().state as { tagId: string };
  const [tagId] = useState(state?.tagId || HUB_SPOT_BLOG_TAG_IDS.AMM);

  const { blogPosts, fetching } = useHubspotBlog(tagId);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [first, setFirst] = useState<number>(4 * (currentPage - 1));

  const onPageChange = ({ page, first }: DataViewPageParams) => {
    setFirst(first);
    setCurrentPage(page + 1);
    scroll.scrollTo(450);
  };

  const ItemTemplate = (blog: HubspotBlog, layout: string) => {
    if (layout === 'grid') {
      return (
        <div className="col-12">
          <NewsCard2
            image={blog.featuredImage}
            title={blog.htmlTitle}
            date={format(new Date(blog.created), 'dd MMMM yyyy')}
            tag={blog.authorName}
            text={blog.metaDescription}
            blogId={blog.id}
            tagId={tagId}
          />
        </div>
      );
    }
  };

  return (
    <>
      <Section>
        <PreLoading label="BLOGS" />
        {fetching ? (
          <Spinner />
        ) : (
          <>
            <Breadcrumbs
              className="breadcrumbs"
              label="IMPORTANT <br> BLOGS."
              text="Return here often to check out the lastest blogs."
            />
            <div className="main">
              <Container>
                <div className="grid">
                  <div className="col-12">
                    <DataView
                      value={blogPosts}
                      layout="grid"
                      itemTemplate={ItemTemplate}
                      onPage={e => onPageChange(e)}
                      first={first}
                      paginator={blogPosts?.length > 0}
                      rows={4}
                      pageLinkSize={3}
                      className="grid"
                    />
                  </div>
                </div>
              </Container>
            </div>
          </>
        )}
      </Section>
      <Footer />
    </>
  );
};
export default BlogListPage;
