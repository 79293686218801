import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  padding: 80px 0 50px;

  .heading{
    p{
      color:var(--text-color);
    }
  }

  a{
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    transition: all 0.4s;
    padding: 15px 35px;
    border: 1px solid #fff;
    color: #fff;
    background:transparent;

    &:hover {
      border: 1px solid #fff !important;
      background: #fff !important;
      color: var(--secondary-color-text) !important;
    }  
  }
  
  .side-banner {
    padding-left: 150px;
    padding-right: 50px;
    position: relative;
    z-index:1;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0;
      width: 400px;
      height: 400px;
      background-image: url(/assets/images/dots.png);
      background-repeat: repeat;
      opacity: 0.3;
      z-index: -1;
    }

    img {
      width: 100%;
      height: auto;
    }

    .stauts {
      position: absolute;
      bottom: 20px;
      left: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      .item {
        padding: 30px 20px;
        background: var(--danger-color);
        margin-right: 50px;
        position: relative;

        &:first-of-type {
          background: #fff;
          color: #000000;
        }

        h4 {
          font-size: 45px;
          font-weight: 800;
          display: table-cell;
          vertical-align: middle;
          padding-right: 20px;
        }

        h6 {
          font-size: 15px;
          display: table-cell;
          vertical-align: middle;
        }

        &:after {
          content: '';
          position: absolute;
          top: -10px;
          left: -10px;
          right: -10px;
          bottom: -10px;
          border: 1px solid var(--surface-border);
      }
    }
  }

  @media (max-width: 576px) {
  }
`;
