import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 40px 0;

  @media (min-width: 576px) {
    padding: 80px 0;
  }
  background: var(--surface-card);

  .heading {
    p {
      font-size: 18px;
      margin: 20px 0 10px;
      color: var(--primary-color);
    }
  }

  ul {
    padding-left: 22px;

    li {
      font-size: 18px;
      text-align: justify;
      // font-weight: 400;
    }
  }

  @media (max-width: 576px) {
  }
`;
