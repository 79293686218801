import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  position: relative;
  // background: var(--surface-section);
  padding: 100px 0 70px;
  overflow: hidden;

  h2 {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 25px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      margin-bottom: 20px;
    }
  }

  .contact {
    ul {
      li {
        i {
          font-size: 30px;
          margin-right: 30px;
        }
        .cont {
          h6 {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            line-height: 2;
            margin: 0;
          }
        }
      }
    }
  }

  .news {
    display: flex;
    flex-direction: column;
    // align-items: center;

    ul {
      li {
        article {
          display: flex;

          .img {
            width: 150px;
            margin-right: 15px;
            cursor: pointer;
            overflow: hidden;

            img {
              width: 100%;
              height: auto;
            }
          }

          .post {
            h3 {
              color: var(--text-color);
              font-size: 18px;
              font-weight: 300;
              line-height: 1;
              margin: 0;

              span {
                font-size: 24px;
                color: var(--text-color);
                line-height: 1;
              }
            }

            p {
              font-size: 12px;
              color: var(--primary-color);
              text-decoration: none;
            }
          }
        }

        .subscribe {
          position: relative;
          width: 80%;
          // margin: 0 auto;

          input {
            border-radius: 30px;
            width: 100%;
          }
          button {
            position: absolute;
            top: 0;
            right: -7px;
            padding: 1.571rem;
          }
        }
      }
    }
  }

  .copy-right {
    text-align:center;
     
    .logo-img {
      margin-bottom: 30px;
      width: 300px;
      cursor: pointer;
      margin-top: -15px;

      @media screen and (min-width: 960px) {
        // width: 80px;
      }
    }

    p {
      color: var(--text-color);
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 3px;
      margin-top: 30px;

      span {
        font-size: 18px;
      }

      button {
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 3px;
      }
    }
  }

  @media (max-width: 576px) {
  }
  .disclaimer {
    font-size: 12px;
  }
  .privacy {
    margin-top: 40px;
  }
`;
