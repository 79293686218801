import { Section } from './index.styled';
import { Container } from 'components/container';
import CarouselModal from 'components/modal-carousel';
import { ImgProps } from '../index';
import { useState } from 'react';

interface Props {
  small: Array<ImgProps>;
  big: ImgProps;
}
const ProjectDetails = ({ small, big }: Props) => {
  const [showCarouselModal, setCarouselModal] = useState({ show: false, index: 0 });

  return (
    <>
      <Section>
        <Container>
          <div className="grid align-items-center">
            {small &&
              small.map((item, i) => (
                <div key={i} className="col-12 md:col-3">
                  <img
                    className="cursor-pointer"
                    src={item.img}
                    onClick={() => setCarouselModal({ ...{ show: true, index: item.index } })}
                  />
                </div>
              ))}
          </div>
        </Container>
        <div className="grid align-items-center">
          <div className="col-12">
            <img
              className="big cursor-pointer"
              src={big.img}
              onClick={() => setCarouselModal({ ...{ show: true, index: big.index } })}
            />
          </div>
        </div>
      </Section>
      <CarouselModal
        items={[...(small && small), big && big]}
        show={showCarouselModal.show}
        activeIndex={showCarouselModal.index}
        onHide={() => setCarouselModal({ ...{ show: false, index: 0 } })}
      />
    </>
  );
};

export default ProjectDetails;
