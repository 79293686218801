import { Container } from 'components/container';
import { SectionHeader2 } from 'components/Section';
import parse from 'html-react-parser';
import { Image } from 'primereact/image';
import { Aside, Section } from './index.styled';
import data from './data.json';

const Card = ({ icon, title, text }) => (
  <Aside>
    <span className={icon}>
      <div className="bord"></div>
    </span>
    <div className="detail">
      <h6>{title}</h6>
      {parse(text)}
    </div>
  </Aside>
);

const Hero = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-5 p-0">
            <Image width="100%" className="block pr-3" src="/assets/images/CPT_Logos_2022_wide.png" />
          </div>
          <div className="col-12 md:col-7">
            <SectionHeader2
              label="A TRUSTED AGENT IN AN UNTRUSTED WORLD"
              title="A DIGITAL PLATFORM PROVIDER"
              text="<p>CrowdPoint is a new kind of digital platform. We push the limits of blockchain cloud technology to provide enterprise-class identity management, decentralized commerce marketplaces, and digital capital solutions for consumers and businesses of all sizes. We're not here to ruffle a few fintech feathers. We're here to crush it. Join us on our epic mission to disrupt finance and change how the world does business.</p><br><p>CrowdPoint represents a breakthrough in collective intelligence—helping communities align around shared interests and values. In a world of increasing complexity, we all need wisdom and guidance to help us access our own innate power, even if we're not sure where to start.</p>"
            />

            <div className="grid mt-5">
              <div className="col-12">
                <Card {...data[0]} />
                <Card {...data[1]} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};
export default Hero;
