import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: -250px;
    top: 225px;
    right: auto;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #059dff, #36c6f0);
    opacity: 0.25;
    filter: blur(100px);
  }
  &:after {
    content: '';
    position: absolute;
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #e80074, #c200db);
    opacity: 0.25;
    filter: blur(100px);
  }

  .breadcrumbs {
    background: transparent;
    padding-top: 230px;

    h3 {
      font-size: 80px;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .main {
    padding: 120px 0 80px;

    .blog-title {
      text-align: center;
      color: var(--text-color);
      font-weight: bold;
      font-size: 2.2rem;
    }
    img {
      border: 0;
      vertical-align: top;
      height: auto;
      max-height: none !important;
      max-width: 100%;
    }
    .blog-content {
      color: var(--text-color-secondary);

      h1 {
        color: var(--text-color);
        font-size: 32px !important;
        line-height: 1.3 !important;

        strong {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          color: var(--text-color);
        }
      }

      h2 {
        font-size: 28px;
        color: var(--text-color);
      }

      h3 {
        font-size: 24px;
        line-height: 1.3;
        font-weight: 400;
        margin: 0px;
        font-family: Oswald, sans-serif;
        padding: 0 0 10px;
        color: var(--text-color);

        strong {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
        }
      }

      h4 {
        font-size: 20px;
        font-family: Oswald, sans-serif;
        padding: 0 0 10px;
        line-height: 34px;
        font-weight: 400;
        color: var(--text-color);
      }

      h5 {
        font-size: 15px;
        font-family: Oswald, sans-serif;
        padding: 0 0 10px;
        line-height: 30px;
        font-weight: 300;
        color: var(--text-color);
      }

      p {
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        line-height: 1.5;
        padding: 0 0 10px;
        margin: 0;
      }

      img {
        border: 0;
        vertical-align: top;
        height: auto;
        max-height: auto !important;
        max-width: 100%;
      }

      strong {
        font-family: Montserrat, sans-serif;
        font-size: 20px;
        line-height: 1.5;
        color: var(--text-color);
      }

      ul {
        list-style: disc;
        margin-left: 15px !important;
        margin: 0;
        padding: 10px 0;

        li {
          padding: 2px 0 2px 15px;
          position: relative;
          margin: 0px;

          font-family: Montserrat, sans-serif;
          font-size: 18px;
          line-height: 1.5;
          // color: var(--text-color);

          &:before {
            display: none;
          }
        }
      }

      p + p {
        padding-top: 10px;
      }

      a {
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        line-height: 1.5;
        transition: all 0.4s ease-in-out;
        color: var(--text-color);
      }

      blockquote {
        margin: 20px 0;
        padding: 0 0 0 20px;
        border-left: 3px solid var(--primary-color);
        font-style: italic;
        font-weight: 700;

        p {
          padding: 0 !important;
        }
      }
    }

    .p-panel {
      border: none;
      box-shadow: none;

      .p-panel-content {
        background: transparent;
      }
    }

    .p-panel-header {
      background: transparent;
    }

    .news-card {
      margin-bottom: 10px;
      background: var(--surface-card);

      button {
        width: 100%;
        justify-content: center;

        img {
          height: 200px;
          max-width: none !important;
        }
      }

      .caption {
        padding: 0 10px 10px;

        .meta {
          margin-top: 10px;
        }

        h4 {
          font-size: 20px;
          margin: 10px 0;
        }
      }

      .button-wrapper {
        text-align: right;

        button {
          width: fit-content;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .breadcrumbs {
      padding-top: 200px;

      h3 {
        font-size: 30px;
      }
    }
  }
`;
