import { Button } from 'primereact/button';
import { CustomBtn } from 'components/button';
import { Card } from './index.styled';
import { useNavigate } from 'react-router-dom';

const NewsCard2 = ({ image, title, text = '', date, tag = '', blogId = '', tagId = '' }) => {
  const navigate = useNavigate();

  return (
    <Card className="grid">
      <div className="col-4">
        <div className="figure">
          <Button className="p-button-text p-0">
            <img src={image} alt="" />
          </Button>
        </div>
      </div>
      <div className="col-8">
        <div className="caption">
          <ul className="meta">
            <li>{date}</li>
            <li className="mx-2">/</li>
            {tag && <li className="tag">{tag}</li>}
          </ul>
          <h4>{title}</h4>
          {text && <p>{text}</p>}
          <CustomBtn
            label="Read more"
            onClick={() => {
              blogId && tagId && navigate('/blog-details', { state: { blogId, tagId } });
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default NewsCard2;
