import { Section, Item } from './index.styled';
import data from './data.json';
import parse from 'html-react-parser';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/scale-out-animation.css';
import Button from 'components/button';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-scroll';

const AutoplaySlider = withAutoplay(AwesomeSlider);

interface Props {
  img: string;
  label: string;
  text: string;
  action: { label: string; path?: string; to?: string };
}

const ItemTemplate = ({ img, label, text, action }: Props) => (
  <Item>
    <Parallax speed={5} className="grid">
      <h3>{parse(label)}</h3>
      <p>{text}</p>
      {action.path ? (
        <Button
          label={action.label}
          className="p-button-outlined light"
          onClick={() => window.open(action.path, '_blank')}
        />
      ) : (
        <Link to={action.to} smooth={true} offset={0} duration={1000}>
          <Button label={action.label} className="p-button-outlined light" />
        </Link>
      )}
    </Parallax>
  </Item>
);

const BannerSlider = () => {
  return (
    <Section>
      <AutoplaySlider
        name="slider"
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={6000}
        animation="scaleOutAnimation"
        fillParent={true}
      >
        {data.map((item, i) => (
          <div key={i} data-src={item.img}>
            <ItemTemplate {...item} />
          </div>
        ))}
      </AutoplaySlider>
    </Section>
  );
};

export default BannerSlider;
