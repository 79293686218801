import PreLoading from 'components/pre-loading';
import bannerData from './data/bannerData.json';
import Banner from 'components/banner';
import WhitepaperDownload from 'components/whitepaper-download';
import VideoWrapper from 'components/video-wrapper';
import UnitAssets from 'components/unit-assets';
import UnitInfo2 from './unit-info2';

const DigitalPlatformPage = () => {
  return (
    <>
      <PreLoading label="DIGITAL PLATFORM" />
      <Banner {...bannerData} />
      <UnitInfo2 />
      <WhitepaperDownload />
      <UnitAssets />
      <VideoWrapper />
    </>
  );
};
export default DigitalPlatformPage;
