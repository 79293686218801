import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  overflow: hidden;
  padding: 120px 0 80px;

  .heading {
    margin-bottom: 100px;

    h2 {
      font-size: 80px;
    }
  }

  .hero {
    margin-bottom: 100px;
  }

  .p-paginator {
    background: transparent;
  }

  &:before {
    content: '';
    position: absolute;
    left: -250px;
    top: 225px;
    right: auto;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #059dff, #36c6f0);
    opacity: 0.25;
    filter: blur(100px);
  }
  &:after {
    content: '';
    position: absolute;
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #e80074, #c200db);
    opacity: 0.25;
    filter: blur(100px);
  }

  @media (max-width: 576px) {
    .grid {
      gap: 30px;
    }
  }
`;
