import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   opacity: 0.6;
  //   z-index: 1;
  //   background: #080718;
  //   z-index: 4;
  // }

  .awssld__content {
    background: transparent;

    > div {
      z-index: 1;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.6;
      z-index: 1;
      background: var(--surface-ground);
    }
  }

  .awssld__bullets {
    display: none;
  }

  .crwd-font {
    font-size: 102px;
  }

  .pagination {
    position: absolute;
    bottom: 40px;
    right: 40px;
    text-align: center;
    width: auto;
    color: #fff;
    font-size: 40px;
    font-weight: 500;
    z-index: 3;

    .current {
      display: inline-block;
      font-size: 60px;
      font-weight: 700;
      position: relative;
      color: transparent;
      -webkit-text-stroke: 0.5px #fff;

      &:after {
        content: '/';
        color: #fff;
        position: relative;
        margin: 0 0 0 10px;
        font-size: 12px;
        font-weight: 100;
      }
    }
  }

  .social-wrapper {
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 8;

    .social {
      margin: 50px 5px 0px !important;

      button {
        background: rgba(255, 255, 255, 0.01);
        padding: 14px 15px;
      }
    }
  }

  @media (max-width: 576px) {
    .social-wrapper {
      left: 5vw;
      bottom: 20px;

      .social {
        button {
          padding: 10px 11px;
        }
      }
    }

    .pagination {
      position: absolute;
      bottom: 15px;
      right: 5vw;
      font-size: 30px;

      .current {
        font-size: 35px;

        &:after {
          margin: 0 0 0 10px;
          font-size: 20px;
        }
      }
    }
  }
`;

export const Item = styled.div`
  z-index: 1;
  padding: 0 25vw;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h3 {
    text-align: center;
    font-size: 65px;
    font-weight: 800;
    letter-spacing: 2px;
    color: #fff;
    transition: all 0.4s;
  }

  p {
    text-align: center;
    margin-bottom: 50px;
  }

  @media (max-width: 576px) {
    padding: 0 5vw;

    h3 {
      font-size: 35px;
      letter-spacing: 1.4px;
    }

    p {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
`;
