import styled from 'styled-components';

export const Section = styled.section`
  padding: 100px 0;
  background: var(--surface-card);

  .title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px !important;
    display: inline-block;
  }

  .items-wrapper {
  }

  @media (max-width: 576px) {
  }
`;

export const ItemWrap = styled.div`
  margin-bottom: 40px;
  align-items: center;

  &:last-child {
    margin-bottom: 0px;
  }

  .logo {
    width: 100%;
    padding: 0 20%;
  }

  .icon {
    padding: 0 20%;
    width: 100%;
  }

  h2 {
    line-height: 1.3;
    font-size: 16px;
    font-weight: 500;
    .crwd-font {
      font-size: 24px;
    }

    p {
      color: inherit;
      margin: 10px 0 0;
    }
  }

  @media (max-width: 576px) {
  }
`;
