import { Menubar as MB } from 'primereact/menubar';
import styled from 'styled-components';

export const MenuBar = styled(MB)`
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  background: transparent;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 30;
  margin: auto;
  padding: 0px 4rem;
  // box-shadow: 0px 0px 15px rgb(21 21 21 / 35%) !important;

  @media (min-width: 1400px) {
    padding: 0px 7rem;
  }

  &.fixed {
    position: fixed;
    left: 0;
    top: -80px;
    right: 0;
    z-index: 1080;
    background: var(--surface-ground);
    border-bottom-color: #333538;
    // box-shadow: 0px 0px 15px rgb(21 21 21 / 35%);
    transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
    // -webkit-transition: -webkit-transform 0.5s;
    // transition: -webkit-transform 0.5s;
    // -o-transition: transform 0.5s;
    // transition: transform 0.5s;
    // transition: transform 0.5s, -webkit-transform 0.5s;
    // -webkit-transform: translateY(80px);
    // -ms-transform: translateY(80px);
    transform: translateY(80px);
  }

  .p-menubar-start {
    img {
      width: 300px;
    }
  }

  .p-menuitem {
    &.login {
      .p-menuitem-link {
        border: 1px solid #fff;
      }
    }

    .p-menuitem-link {
      padding: 0.75rem 1.2rem !important;
      color: var(--text-color) !important;
      font-size: 12px;
      line-height: 1.5;
      font-weight: 500;
      text-transform: uppercase;
      transition: 0.25s !important;

      .p-submenu-icon,
      .p-menuitem-text {
        color: inherit !important;
      }

      &:hover {
        color: var(--primary-color) !important;
      }
    }
  }
  .p-submenu-list {
    box-shadow: none;
    min-width: 100%;

    .p-menuitem {
      .p-menuitem-link {
        letter-spacing: 0.02em;
        font-weight: 400;
        text-transform: none;
        font-size: 13px;
        transition: 0.25s !important;
        opacity: 0.8;

        .p-submenu-icon,
        .p-menuitem-text {
          color: inherit !important;
        }

        &:after {
          content: '';
          width: 0px;
          height: 1px;
          background: var(--primary-color) !important;
          position: absolute;
          left: 10px;
          top: 50%;
          transition: all 0.4s;
        }

        &:hover {
          background: transparent;
          color: var(--primary-color) !important;
          padding-left: 30px !important;

          &:after {
            width: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    // position: fixed;
    // left: 0;
    // top: 0;
    // right: 0;
    // z-index: 1080;
    // padding: 0px 1rem;
    // background: var(--surface-section);
    // border-bottom-color: #333538;
    // box-shadow: 0px 0px 15px rgb(21 21 21 / 35%);
    padding: 10px 1rem;

    .p-menubar-start {
      img {
        // height: 58px;
      }
    }
  }
`;
