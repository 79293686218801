import { useEffect, useState } from 'react';
import { gql, createClient, defaultExchanges } from 'urql';
export type Maybe<T> = T;
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  point: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};
export type HubspotBlog = {
  archived: Scalars['Boolean'];
  archivedInDashboard: Scalars['Boolean'];
  attachedStylesheets: Maybe<Scalars['String']>;
  authorName: Scalars['String'];
  blogAuthorId: Scalars['String'];
  campaign: Maybe<Scalars['String']>;
  categoryId: Scalars['Int'];
  contentGroupId: Scalars['String'];
  contentTypeCategory: Scalars['Int'];
  created: Maybe<Scalars['timestamptz']>;
  createdById: Scalars['String'];
  currentState: Scalars['String'];
  currentlyPublished: Scalars['Boolean'];
  deletedAt: Scalars['timestamptz'];
  domain: Maybe<Scalars['String']>;
  featuredImage: Scalars['String'];
  featuredImageAltText: Maybe<Scalars['String']>;
  htmlTitle: Scalars['String'];
  id: Scalars['String'];
  language: Scalars['String'];
  layoutSections: Maybe<Scalars['String']>;
  metaDescription: Scalars['String'];
  name: Scalars['String'];
  pageExpiryEnabled: Maybe<Scalars['Boolean']>;
  postBody: Scalars['String'];
  postSummary: Scalars['String'];
  publishDate: Scalars['timestamptz'];
  publishImmediately: Maybe<Scalars['Boolean']>;
  rssBody: Scalars['String'];
  rssSummary: Scalars['String'];
  slug: Scalars['String'];
  state: Scalars['String'];
  tagIds: Maybe<Scalars['Int']>;
  updated: Scalars['timestamptz'];
  updatedById: Scalars['String'];
  url: Scalars['String'];
  useFeaturedImage: Scalars['Boolean'];
  widgetContainers: Maybe<Scalars['String']>;
  widgets: Maybe<Scalars['String']>;
};

const HubSpotBlogDocument = gql`
  query HubSpotBlog($tagId: String!) {
    hubspotBlog(tagId: $tagId) {
      postSummary
      useFeaturedImage
      state
      rssBody
      authorName
      publishDate
      slug
      publishImmediately
      created
      postBody
      domain
      contentGroupId
      url
      metaDescription
      updatedById
      blogAuthorId
      currentState
      createdById
      currentlyPublished
      tagIds
      featuredImage
      widgets
      name
      language
      archivedInDashboard
      categoryId
      htmlTitle
      id
      updated
      layoutSections
      deletedAt
      widgetContainers
      featuredImageAltText
      rssSummary
      archived
      contentTypeCategory
      attachedStylesheets
    }
  }
`;

const client = createClient({
  url: 'https://user-api.crowdpoint.tech/v1/graphql',
  exchanges: [...defaultExchanges],
});

export function useHubspotBlog(tagId: string) {
  const [blogPosts, setBlogPosts] = useState<Array<HubspotBlog> | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    setFetching(true);
    client
      .query(HubSpotBlogDocument, { tagId })
      .toPromise()
      .then(result => result)
      .then(result => {
        if (result.data.hubspotBlog.error) {
          setError(result.data.hubspotBlog.error);
        } else {
          setError(undefined);
        }
        setBlogPosts(result.data.hubspotBlog);
        setFetching(false);
      })
      .catch(queryError => {
        setError(queryError);
        setFetching(false);
      });
  }, []);
  return { blogPosts, fetching, error };
}

export function useHubspotBlogById(blogId: string) {
  const [blogPost, setBlogPost] = useState<HubspotBlog | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [blogFetching, setBlogFetching] = useState<boolean>(false);

  useEffect(() => {
    setBlogFetching(true);
    client
      .query(HubSpotBlogDocument, { id: blogId })
      .toPromise()
      .then(result => result)
      .then(result => {
        if (result.data.hubspotBlog.error) {
          setError(result.data.hubspotBlog.error);
        } else {
          setError(undefined);
        }
        setBlogPost(result.data.hubspotBlog);
        setBlogFetching(false);
      })
      .catch(queryError => {
        setError(queryError);
        setBlogFetching(false);
      });
  }, []);
  return { blogPost, blogFetching, error };
}
