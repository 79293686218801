import Button from 'components/button';
import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import { Image } from 'primereact/image';
import { Section } from './index.styled';

const WhitepaperDownload = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-6">
            <SectionHeader
              label="DOWNLOAD OUR"
              title="WHITEPAPER"
              text="<p>The collapse of weaker business models and the lack of asset backed digital strategies seen in current crypto currencies is healthy for the long term growth of the emerging regulated digital asset sector. This Regulation D 506 c offer represents the entry of regulated and rule following companies like CrowdPoint.</p> <br> <p>We believe it marks the rise of the next generation Blockchains. We are confident that it is a perfect time to invest into asset backed transactions on the worlds most efficient database.</p>"
            />
            <Button
              label="Download Now !"
              onClick={() =>
                window.open(
                  'https://github.com/CrowdPoint-Technologies/whitepaper/blob/main/CrowdPoint_Living_White_Paper.pdf?raw=true',
                  '_blank',
                  'noreferrer',
                )
              }
            />
          </div>
          <div className="col-12 md:col-6">
            <Image src="/assets/images/whitepaper_download.png" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default WhitepaperDownload;
