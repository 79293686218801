import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { useEffect, useState } from 'react';
import { Item, Dialog } from './index.styled';
import { ImgProps } from 'pages/ecosystem/crwd-ecosystem';

interface PropsType {
  show: boolean;
  onHide: () => void;
  items: Array<ImgProps>;
  className?: string;
  activeIndex?: number;
}
const CarouselModal = ({ className = '', show, onHide, items, activeIndex = 0 }: PropsType) => {
  const [activeItemIndex, setActiveItemIndex] = useState(activeIndex);

  useEffect(() => {
    setActiveItemIndex(activeIndex);
  }, [activeIndex]);

  const ItemTemplate = (item: ImgProps) => {
    return (
      <Item>
        {item.link && (
          <Button
            label={item.linkLabel}
            className="p-button"
            onClick={() => {
              window.open(item.link, '_blank');
            }}
          />
        )}
        <img
          alt={item.img}
          src={item.bigImg}
          onError={e =>
            ((e.currentTarget as HTMLImageElement).src =
              'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
          }
        />
      </Item>
    );
  };

  return (
    <>
      <Dialog visible={show} onHide={onHide} draggable={false} blockScroll={true}>
        <Carousel
          value={items}
          page={activeItemIndex}
          numVisible={1}
          numScroll={1}
          className="custom-carousel"
          itemTemplate={ItemTemplate}
          indicatorsContentClassName="hidden"
        />
      </Dialog>
    </>
  );
};
export default CarouselModal;
