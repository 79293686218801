import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Controller, UseControllerProps } from 'react-hook-form';
import { InputWrapper } from './index.styled';

interface Props extends UseControllerProps {
  icon?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  children?: any;
}

const Input = ({ icon, label, placeholder, children, className = '', ...rest }: Props) => {
  return (
    <InputWrapper className={classNames('p-float-label p-input-icon-right', className && className)}>
      {icon && <i className={`pi ${icon}`} />}
      <Controller
        {...rest}
        render={({ field, fieldState }) => (
          <InputText className={className} id={field.name} {...field} placeholder={placeholder} />
        )}
      />
      {children && children}
      {label && <label>{label}</label>}
    </InputWrapper>
  );
};

export default Input;
