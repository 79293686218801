import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import parse from 'html-react-parser';
import { Aside, Section } from './index.styled';
import data from './data.json';
import { CustomBtn } from 'components/button';
import { Divider } from 'components/divider';
import { useNavigate } from 'react-router-dom';

const Card = ({ image, date, title, label, text, className, tagId }) => {
  const navigate = useNavigate();

  return (
    <Aside className={className} bg={image}>
      <span className="date">{parse(date)}</span>
      <h5>{title}</h5>
      <h4>{label}</h4>
      <h6>{text}</h6>
      <CustomBtn label="Read More" onClick={() => navigate('/blog-list', { state: { tagId } })} />
    </Aside>
  );
};

const LearnMore = ({ light = false }) => {
  return (
    <Section light={light}>
      <Divider />
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2">
            <SectionHeader
              className="text-center"
              label="WANT TO LEARN MORE?"
              title="Hear from members of our Blockchain Ecosystem:"
            />
          </div>
        </div>

        <div className="grid mt-8">
          {data.map((item, k) => (
            <div key={k} className="col-12 md:col-4">
              <Card {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};
export default LearnMore;
