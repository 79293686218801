import PreLoading from 'components/pre-loading';
import Details from './about';
import BannerSlider from './banner';
import Services from './services';
import Footer from 'components/footer';
import services1 from './services/services1.json';
import services2 from './services/services2.json';
import Clients from './clients';
import TestimonialBlock from 'components/testimonial-block';
import CallAction from 'components/call-action';

const AboutPage = () => {
  return (
    <>
      <PreLoading label="ABOUT" />
      <BannerSlider />
      <div style={{ marginTop: '100vh', position: 'relative', zIndex: '1', background: 'var(--surface-ground)' }}>
        <Details />
        <Services {...services1} />
        <Services {...services2} />
        <TestimonialBlock light />
        <Clients />
        <img className="flex" src="/assets/images/crwd_ecosystem_1.4-min.png" width="100%" />
        <CallAction />
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
