import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  position: relative;
  padding: 120px 0;

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

interface Props {
  bg?: string;
}
export const Aside = styled.aside<Props>`
  padding: 60px 40px 50px;
  position: relative;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-repeat: no-repeat;

  &.xbottom {
    bottom: 60px;
  }

  &.xtop {
    top: 60px;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #18191d;
    transition: all 0.4s;
    z-index: 0;
  }

  &:hover {
    &:after {
      opacity: 0.85;
    }
  }

  .date {
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 70px;

    i {
      font-size: 30px;
      font-style: normal;
      display: block;
    }
  }

  h5 {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 10px;
    font-size: 16px;
  }

  h4 {
    color: var(--primary-color);
    margin-left: 10px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  h6 {
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: 40px;
    font-size: 18px;
  }
`;
