import styled from 'styled-components';

export const Section = styled.section`
  padding: 60px 0;
  @media screen and (min-width: 960px) {
    padding: 120px 0;
  }
  overflow: hidden;

  &.light {
    background: var(--surface-card);
  }

  h6 {
    margin: 0;
    line-height: 1.4;
    font-size: 30px;
    font-weight: 500;

    span {
      display: inline-block;
      color: var(--primary-color);
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      font-size: 13px;
      border: 1px solid var(--primary-color);
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  p {
    color: var(--text-color);
    font-weight: 300;
    line-height: 2;
    margin: 0;
    font-size: 22px;
    text-align: justify;
    .crwd-font {
      font-size: 32px;
    }
  }

  ul {
    margin-top: 30px;
    outline: none;
    list-style: none;
    padding: 0;

    li {
      margin-top: 20px;
      font-size: 15px;
      font-weight: 300;
      padding-left: 20px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 5px;
        height: 1px;
        background: var(--primary-color);
      }
    }
  }

  @media (max-width: 576px) {
    p {
      font-size: 19px;
    }
  }
`;
