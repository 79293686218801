import { Container } from 'components/container';
import PreLoading from 'components/pre-loading';
import { Section } from './index.styled';
import Footer from 'components/footer';
import { HubspotBlog, useHubspotBlog } from 'hooks/userHooks';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { Panel } from 'primereact/panel';
import { useNavigate } from 'react-router-dom';
import NewsCard from 'components/news-card';
import { Spinner } from 'components/spinner';

const BlogDetailsPage = () => {
  const { blogId, tagId } = useLocation().state as { blogId: string; tagId: string };
  const [blog, setBlogPost] = useState<HubspotBlog>();
  const { blogPosts, fetching } = useHubspotBlog(tagId);
  const navigate = useNavigate();

  useEffect(() => {
    if (blogPosts) setBlogPost(blogPosts.filter(blog => blog.id === blogId)[0]);
  }, [blogPosts]);

  return (
    <>
      <Section>
        <PreLoading label="BLOG DETAILS" />
        {/* <Breadcrumbs className="breadcrumbs" label="BLOG DETAIL" /> */}
        {fetching ? (
          <Spinner />
        ) : (
          <div className="main">
            <Container>
              <div className="grid">
                <div className="col-12 md:col-8">
                  {blog?.htmlTitle && <h1 className="blog-title mb-3">{parse(blog?.htmlTitle)}</h1>}
                  {blog?.featuredImage && (
                    <img
                      src={blog?.featuredImage}
                      alt={blog?.htmlTitle}
                      className="block w-full p-0 mx-auto mb-3"
                      style={{
                        background: 'none',
                        objectFit: 'scale-down',
                        maxHeight: '450px',
                      }}
                    />
                  )}
                  {blog?.postBody && <span className="blog-content">{parse(blog?.postBody)}</span>}
                </div>
                <div className="col-12 md:col-4">
                  <Panel header="RELATED POSTS">
                    {blogPosts?.slice(0, 3).map(blog => (
                      <NewsCard
                        image={blog.featuredImage}
                        title={blog.htmlTitle}
                        date={format(new Date(blog.created), 'dd MMMM yyyy')}
                        tag={blog.authorName}
                        onClick={() => blog.id && setBlogPost(blogPosts.filter(b => b.id === blog.id)[0])}
                      />
                    ))}
                  </Panel>
                </div>
              </div>
            </Container>
          </div>
        )}
      </Section>
      <Footer />
    </>
  );
};
export default BlogDetailsPage;
