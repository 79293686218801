import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  // height: calc(100vh - 85px);
  background: hsl(258deg 26% 7%);
  padding: 100px 0 80px;

  .heading {
    h2 {
      font-size: 60px;
      line-height: 1;
      position: relative;
      margin-bottom: 20px;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: 10px;
        background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
      }
    }

    p {
      font-size: 24px;
    }
  }

  .p-image {
    width: 100%;
    display: block;
    margin: 0 auto 0;

    img {
      width: 100%;
    }
  }

  @media (max-width: 576px) {
  }
`;
