import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  position: relative;
  padding: 120px 0;

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

export const Aside = styled.aside`
  text-align: center;
  padding: 20px 10px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #ffffff1f;
    border-radius: 10px;
    background: #0000007a;
  }

  span {
    opacity: 0.8;
    font-size: 70px;
    margin-bottom: 40px;
  }

  h6 {
    font-size: 35px;
    font-weight: 200;
    letter-spacing: 2px;
    margin-bottom: 15px;
  }

  h5 {
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    margin: 0;
  }
`;
