import { Section, Progress, Aside } from './index.styled';
import { Container } from 'components/container';
import data from './data.json';
import { ProgressBar } from 'primereact/progressbar';
import VisibilitySensor from 'react-visibility-sensor';
import { CountUp } from 'use-count-up';
import { Image } from 'primereact/image';
import { SectionHeader2 } from 'components/Section';
import ReactTooltip from 'react-tooltip';
import { useNavigate } from 'react-router-dom';

const CustomProgressBar = ({ end = 0, label, className = '' }) => {
  return (
    <CountUp isCounting end={end}>
      {({ value, reset }) => (
        <VisibilitySensor onChange={reset}>
          <Progress value={value as number} className={className}>
            <h6>{label}</h6>
            <ProgressBar value={value as number} />
          </Progress>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

const Card = ({ index, img, title, text, path }) => {
  const navigate = useNavigate();
  return (
    <>
      <Aside
        data-tip
        data-for={'performance-card-tooltip' + index}
        className={'tooltip-card' + index}
        onClick={() => navigate(path)}
      >
        <Image src={img} />
      </Aside>
      <ReactTooltip id={'performance-card-tooltip' + index} place="right" className="custom-tooltip">
        <h6>{title}</h6>
        <h4>{text}</h4>
      </ReactTooltip>
    </>
  );
};

const Performance = ({ light = false }) => {
  return (
    <Section light={light}>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-5">
            <SectionHeader2
              className="mb-7"
              label="WHAT WE DO:"
              title="WE BUILD DIGITAL PLATFORMS."
              text="We are CrowdPoint. We built our version of a Blockhain Ecosystem. In our platform all the members extract value. Not just a few"
            />

            {data.progress.map((item, k) => (
              <CustomProgressBar key={k} end={parseInt(item.value)} label={item.title} />
            ))}
          </div>
          <div className="col-12 md:col-7">
            <div className="grid m-0">
              {data.items.map((item, i) => (
                <div key={i} className="col-12 md:col-6 p-0 pl-5">
                  <Card {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Performance;
