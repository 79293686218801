import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  overflow: hidden;
  position: relative;
  padding: 100px 0;

  .social {
    button {
      width: 8rem;
      height: 8rem;

      img {
        width: 110%;
        height: 110%;
        position: absolute;
        top: 1px;
        left: -4px;
      }
    }
  }
  @media (max-width: 576px) {
  }
`;
