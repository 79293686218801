import { Breadcrumbs } from 'components/breadcrumbs';
import { Container } from 'components/container';
import PreLoading from 'components/pre-loading';
import { Section } from './index.styled';
import Footer from 'components/footer';
import data from './blogs.json';
import NewsCard2 from 'components/news-card2';
import { Paginator } from 'primereact/paginator';
import { SectionHeader } from 'components/Section';
import Hero from './hero';

const NewsPage = () => {
  return (
    <>
      <Section>
        <PreLoading label="NEWS" />
        <Container>
          <Hero className="hero" />
          <div className="grid">
            <div className="col-6 col-offset-3 text-center">
              <SectionHeader
                title="BLOCKCHAIN ECOSYSTEM NEWS"
                text="This is the official CrowdPoint Technologies news source."
              />
            </div>
          </div>
          <div className="grid">
            {data.map((item, i) => (
              <div key={i} className="col-12">
                <NewsCard2 {...item} />
              </div>
            ))}

            <div className="text-center w-full">
              <Paginator first={0} rows={10} totalRecords={10} rowsPerPageOptions={[10, 20, 30]} />
            </div>
          </div>
        </Container>
      </Section>
      <Footer />
    </>
  );
};
export default NewsPage;
