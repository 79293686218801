import PreLoading from 'components/pre-loading';
import bannerData from './data/bannerData.json';
import introData from './data/introData.json';
import UnitPrices from './unit-prices';
import Footer from 'components/footer';
import Intro from 'components/intro';
import WhitepaperDownload from 'components/whitepaper-download';
import VideoWrapper from 'components/video-wrapper';
import Banner from 'components/banner';
import UnitInfo from 'components/unit-info';
import UnitAssets from 'components/unit-assets';
import SAFT from './saft';

const UnitPage = () => {
  return (
    <>
      <PreLoading label="UNITS" />
      <Banner {...bannerData} />
      <UnitInfo />
      {/* <img src="/assets/images/unit/safet_billet.png" alt="Safet" className="w-full" /> */}
      <SAFT />
      <UnitPrices light />
      <WhitepaperDownload />
      <UnitAssets />
      <Intro {...introData} light />
      <VideoWrapper />
      <Footer />
    </>
  );
};
export default UnitPage;
