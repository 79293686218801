import PreLoading from 'components/pre-loading';
import Banner from './banner';
import ProjectDetails from './project-details';
import JoinToday from './join-today';
import parse from 'html-react-parser';
import Intro from './intro';
import VideoWrapper from 'components/video-wrapper';
import { useState } from 'react';

export interface ImgProps {
  index: number;
  img: string;
  bigImg: string;
  link?: string;
  linkLabel?: string;
}
interface Props {
  preLoadingLabel: string;
  bannerData: {
    bg: string;
    title: string;
    label: string;
    items: Array<{ title: string; labels: Array<{ name: string; link: string }> }>;
  };
  para1: {
    index: number;
    label: string;
    text: string;
    itemList: Array<string>;
    accordionList?: Array<{ label: string; text: any; itemList?: Array<string> }>;
  };
  projectDetails: {
    small: Array<ImgProps>;
    big: ImgProps;
  };
  projectDetails2?: {
    small: Array<ImgProps>;
    big: ImgProps;
  };
  para2: {
    index: number;
    label: string;
    text: string;
    img?: any;
    accordionList?: Array<{ label: string; text: any; itemList?: Array<string> }>;
  };
  para3?: {
    index: number;
    label: string;
    text: string;
  };
  videoWrapper: { bannerImage: string; videoId: string; zoom?: number };
  joinData: { bannerImage: string; link: string; text: string };
}

const CrwdEcosystemPage = (props: Props) => {
  return (
    <>
      <PreLoading label={props.preLoadingLabel} />
      <Banner {...props.bannerData} />
      <Intro {...props.para1} />
      <ProjectDetails {...props.projectDetails} />
      <Intro {...props.para2} />
      {props.para2.img && parse(props.para2.img)}
      {props.para3 && <Intro {...props.para3} />}
      {props.projectDetails2 && <ProjectDetails {...props.projectDetails2} />}
      <VideoWrapper {...props.videoWrapper} />
      <JoinToday {...props.joinData} />
    </>
  );
};
export default CrwdEcosystemPage;
