import styled from 'styled-components';

export const Section = styled.section`
  padding: 80px 0;

  &.light {
    background: var(--surface-card);
  }

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

export const Aside = styled.aside`
  display: flex;
  margin-bottom: 20px;

  span {
    flex: none;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;
    font-size: 20px;

    .bord {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        bottom: -1px;
        right: -1px;
        border-radius: 5px;
        // background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
        background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
        z-index: -2;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: var(--surface-section);
        border-radius: 5px;
        z-index: -1;
      }
    }
  }

  .detail {
    padding-left: 15px;

    h6 {
      font-size: 18px;
      margin: 0;
      line-height: 1.4;
      font-weight: 500;
    }

    p {
      font-size: 13px;
      margin-top: 5px;
    }
  }
`;
