import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Aside, Section } from './index.styled';
import data from './data.json';
import { Divider } from 'components/divider';
import Slider, { Settings } from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import BigData from 'components/big-data';

const Card = ({ img, title, text, path }) => {
  const navigate = useNavigate();
  return (
    <Aside onClick={() => navigate(path)}>
      <Image src={img} />
      <div className="overlay">
        <h6>{title}</h6>
        <h4>{text}</h4>
      </div>
    </Aside>
  );
};

const NextArrow = props => {
  const { className, style, onClick } = props;
  return <Button icon="pi pi-chevron-right" className="p-button-rounded arrow-next" onClick={onClick} />;
  // return <div className={className} style={{ ...style, display: 'block', background: 'red' }} onClick={onClick} />;
};

const PrevArrow = props => {
  const { className, style, onClick } = props;
  return <Button icon="pi pi-chevron-left" className="p-button-rounded arrow-prev" onClick={onClick} />;
  // return <div className={className} style={{ ...style, display: 'block', background: 'green' }} onClick={onClick} />;
};

const Introduction = ({ light = false }) => {
  var settings: Settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Section light={light}>
      <Divider right />
      <BigData />
      <Container>
        <Slider {...settings} className="mt-8">
          {data.map((item, k) => (
            <Card key={k} {...item} />
          ))}
        </Slider>
      </Container>
    </Section>
  );
};
export default Introduction;
