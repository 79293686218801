import styled from 'styled-components';

export const Section = styled.section`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -250px;
    top: 225px;
    right: auto;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #059dff, #36c6f0);
    opacity: 0.25;
    filter: blur(100px);
  }
  &:after {
    content: '';
    position: absolute;
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #e80074, #c200db);
    opacity: 0.25;
    filter: blur(100px);
  }

  .breadcrumbs {
    background: transparent;
    padding-top: 230px;

    &:before,
    &:after {
      display: none;
    }
  }

  .main {
    padding: 120px 0;
    // background: var(--surface-section);

    h3 {
      font-weight: 700;
      line-height: 1.4;
      font-size: 30px;
      display: inline-block;
      margin-bottom: 50px;
    }

    h4 {
      margin-top: 60px;
      font-weight: 700;
      font-size: 30px;
    }

    textarea {
      width: 100%;
    }

    h5 {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 2px;
      margin-bottom: 10px;

      &.second {
        margin-top: 40px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 300;
      margin: 10px 0;
    }

    .social {
      margin: 50px 5px 0px !important;

      button {
        box-shadow: none;
        background: rgba(255, 255, 255, 0.01);
        padding: 14px 15px;
      }
    }
  }

  .p-float-label label {
    top: calc(50% - 11px);
  }

  .map-wrapper {
    width: 100%;
    height: 60vh;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 576px) {
    .breadcrumbs {
      padding-top: 200px;

      h3 {
        font-size: 30px;
      }
    }

    .grid {
      gap: 30px;
    }
  }
`;
