import PreLoading from 'components/pre-loading';
import { Image } from 'primereact/image';
import Approach from './approach';
import Banner from './banner';
import BigData from '../../../components/big-data';
import Ecosystem from './eco-system';
import GlobalMovement from './global-movement';

const BigDataPage = () => {
  return (
    <>
      <PreLoading label="CROWDPOINT" />
      <BigData />
      <Banner />
      <Approach />
      <Ecosystem />
      <Image src="/assets/images/crwd_ecosystem_1.4-min.png" width="100%" className="flex" />
      <GlobalMovement />
    </>
  );
};
export default BigDataPage;
