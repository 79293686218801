import Footer from 'components/footer';
import PreLoading from 'components/pre-loading';
import Testimonials from 'components/testimonials';
import Count from './count';
import Hero from './hero';
import Introduction from './introduction';
import LearnMore from './learn-more';
import Performance from './performance';
import Services from './services';

const CompanyPage = () => {
  return (
    <>
      <PreLoading label="COMPANY" />
      <Hero />
      <Services light />
      <Introduction />
      <Count light />
      <Testimonials />
      <Performance light />
      <LearnMore />
      <Footer />
    </>
  );
};
export default CompanyPage;
