import { Section } from './index.styled';
import { Container } from 'components/container';
import parse from 'html-react-parser';
import { Image } from 'primereact/image';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface Props {
  index: number;
  label: string;
  text: string;
  itemList?: Array<string>;
  bottomImg?: string;
  accordionList?: Array<{ label: string; text: any; itemList?: Array<string> }>;
}
const Intro = ({ index, label, text, itemList, accordionList, bottomImg }: Props) => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-3">
            <h6>
              <span className="titl-index">{index}</span>
              {parse(label)}
            </h6>
          </div>
          <div className="col-12 md:col-8 md:col-offset-1">
            {!accordionList && (
              <>
                <>{parse(text)}</>

                {itemList && (
                  <ul>
                    {itemList.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                )}
              </>
            )}

            {accordionList && (
              <Accordion className="ecosystem-accordion" activeIndex={0}>
                {accordionList.map((item, i) => (
                  <AccordionTab key={i} header={parse(item.label)}>
                    <p className="text-justify">{parse(item.text)}</p>
                    {item.itemList && (
                      <ul>
                        {item.itemList.map((itm, j) => (
                          <li key={j}>{itm}</li>
                        ))}
                      </ul>
                    )}
                  </AccordionTab>
                ))}
              </Accordion>
            )}
          </div>
        </div>
      </Container>
      {bottomImg && <Image className="mt-7 block" width="100%" src={bottomImg} />}
    </Section>
  );
};

export default Intro;
