import styled from 'styled-components';

export const Iframe = styled.iframe`
  width: 100%;
  border: none;
  height: calc(100vh - 95px);

  &.earn-iframe {
    min-height: 700px;
  }

  &.blogs-iframe {
    height: 3500px;
  }

  &.crowd-iframe {
    height: 8700px;
  }
`;
