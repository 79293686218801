import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 40px 0;
  &.no-padding {
    padding: 0;
  }
  @media (min-width: 576px) {
    padding: 100px 0;
  }

  &.light {
    background: var(--surface-card);
  }

  h3 {
    display: inline-block;
  }

  .title {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 35px;
  }

  .subtitle {
    margin: 0;
    line-height: 1.2;
    font-weight: 500;
    font-size: 18px;
    text-transform: initial;
    color: var(--primary-color);
  }

  .details {
    p {
      line-height: 1.3;
      margin-top: 20px;
      margin-bottom: 0;
      text-align: justify;
    }
    h3 {
      margin-top: 40px;
      line-height: 1;
    }
  }

  @media (max-width: 576px) {
  }
`;
