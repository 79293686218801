import { Container } from 'components/container';
import { Section } from './index.styled';
import { GradiantText } from 'components/text';
import parse from 'html-react-parser';
import WhitepaperDownload from 'components/whitepaper-download';

const Content = ({ label, subTitle, title, text, light = false, image = '', classNames = '' }: any) => {
  if (image.includes('whitepaper_download')) {
    return <WhitepaperDownload />;
  }
  return (
    <Section className={`${image ? 'no-padding' : light && 'light'}`}>
      {image && <img className="w-full" src={image} alt="" />}
      <Container className={classNames}>
        {label && <GradiantText className="mb-4">{label}</GradiantText>}
        {subTitle && <h4 className="subtitle mb-5">{parse(subTitle)}</h4>}
        {title && <h2 className="title mb-5">{parse(title)}</h2>}
        {text && <div className="details">{parse(text)}</div>}
      </Container>
    </Section>
  );
};

export default Content;
