import { Link, useNavigate } from 'react-router-dom';
import { MenuBar } from './index.styled';
import { MenuItem } from 'primereact/menuitem';
import { useEffect, useState } from 'react';

const Header = () => {
  const navigate = useNavigate();

  // Sticky Menu Area
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', stickyCallBak);

    return () => {
      window.removeEventListener('scroll', stickyCallBak);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const stickyCallBak = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 200 ? setIsSticky(true) : setIsSticky(false);
  };

  const items: Array<MenuItem> = [
    { label: 'Home', command: () => navigate('') },
    { label: 'Company', command: () => navigate('/company') },
    {
      label: 'About',
      command: () => navigate('/about'),
    },
    {
      label: 'Blockchain Ecosystem',
      items: [
        { label: 'crwdid', command: () => navigate('/crwdid') },
        { label: 'crwdmarket', command: () => navigate('/crwdmarket') },
        { label: 'crwdcapital', command: () => navigate('/crwdcapital') },
        { label: 'crwdfinance', command: () => navigate('/crwdfinance') },
        { label: 'crwdsystems', command: () => navigate('/crwdsystems') },
        {
          label: 'crwdworld',
          // command: () => navigate('/crwdworld'),
          url: 'https://app.crwd.world/',
        },
        { label: 'crwdunit', command: () => navigate('/crwdunit') },
      ],
    },
    {
      label: 'Discover',
      items: [
        { label: 'IMPORTANT BLOGS', command: () => navigate('/blog-list') },
        { label: 'KNOWLEDGE BASE', command: () => navigate('/kb') },
        { label: 'SUMMARY', command: () => navigate('') },
        { label: 'NEWS', command: () => navigate('/news') },
      ],
    },
    { label: 'Contact', command: () => navigate('/contact') },
    { label: 'Login', url: 'https://app.crwd.world', className: 'ml-4 login' },
  ];

  const start = (
    <Link to="">
      <img alt="logo" src="/assets/images/logo-light.png" className="mr-2" />
    </Link>
  );

  return (
    <>
      <MenuBar model={items} start={start} className={`${isSticky ? 'header fixed' : 'header'}`} />
    </>
  );
};

export default Header;
