import Button from 'components/button';
import { Container } from 'components/container';
import { openInNewTab } from 'utils/helper';
import { ParallaxBanner } from './index.styled';
import parse from 'html-react-parser';

interface Props {
  bg: string;
  title: string;
  label: string;
  className?: string;
  items: Array<{ title: string; labels: Array<{ name: string; link: string }> }>;
}
const Banner = ({ bg, title, className, label, items }: Props) => {
  return (
    <ParallaxBanner
      layers={[
        {
          image: bg,
          speed: 20,
        },
      ]}
      className={className}
    >
      <Container className="container px-4">
        <div className="grid">
          <div className="md:col-9">
            <div className="header-wrap">
              {title && <h5>{parse(title)}</h5>}
              {label && <h2>{parse(label)}</h2>}
            </div>
          </div>
        </div>
        <div className="grid mb-4">
          {items &&
            items.map((item, key) => (
              <div className="col-12 md:col-6 lg:col-3" key={key}>
                <div className="item">
                  <h6>{parse(item.title)}</h6>
                  {item.labels.map((label, k) => (
                    <Button
                      onClick={() => openInNewTab(label.link)}
                      key={k}
                      label={label.name}
                      className="p-button-link"
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </Container>
    </ParallaxBanner>
  );
};

export default Banner;
