import { Container } from 'components/container';
import { Section } from './index.styled';
import { Image } from 'primereact/image';
import { SectionHeader } from 'components/Section';

const BigData = () => {
  return (
    <Section light>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-4">
            <Image src="/assets/images/Buttonwood_logo_round.png" width="100%" />
          </div>
          <div className="col-12 md:col-8">
            <SectionHeader
              className="mb-7"
              label="THE BIG IDEA?"
              title="To interconnect commercial and capital markets on our own Layer 0 Vogon Blockchain."
              text="<span>To decentralize prosperity we had to empower people to share their data and compel others to: </span><span> 
              - Reimburse them for using it.<br>
              - Provide group discounts.<br> 
              - Share in their growth.</span"
            />
          </div>
        </div>
        <div className="states grid">
          <div className="col-12 md:col-3">
            <div className="grid align-items-center">
              <h3>11</h3>
              <p>
                ACROSS
                <br /> GICS SECTORS
              </p>
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="grid align-items-center">
              <h3>158</h3>
              <p>
                SUB INDUSTRIES <br /> POTENTIAL TOKEN INDICES{' '}
              </p>
            </div>
          </div>
          <div className="col-12 md:col-5 mail-us">
            <a href="mailto:hello@crowdpointtech.com">
              <div className="grid">
                <div className="text valign">
                  <div className="full-width">
                    <p>WANT TO LEARN MORE?</p>
                    <h6>hello@crowdpointtech.com</h6>
                  </div>
                </div>
                <div className="mail-icon">
                  <div className="icon-box">
                    <span className="icon color-font pe-7s-mail"></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default BigData;
