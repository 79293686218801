import styled from 'styled-components';
import { useState } from 'react';
import { Button } from 'primereact/button';
import { FaBehance, FaFacebookF, FaInstagram, FaLinkedinIn, FaPinterestP, FaTwitter, FaYoutube } from 'react-icons/fa';

const SocialLink = styled(Button)`
  color: #333538 !important;
  padding: 20px 21px;
  font-size: 16px;
  line-height: 15px;
  display: inline-block;
  min-width: auto;

  svg {
    color: #fff !important;
  }

  &:hover {
    color: ${({ color }) => color + ' !important'};
    background: ${({ color }) => color + ' !important'};

    svg {
      color: #fff !important;
    }
  }
`;

const Section = styled.div`
  margin: 5px !important;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

interface PropsType {
  className?: string;
  links?: Object;
}

const SocialLinks = ({
  className = '',
  links = {
    fb: '#',
    instagram: '#',
    behance: '#',
    twitter: '#',
  },
}: PropsType) => {
  const [iconList] = useState({
    fb: { icon: <FaFacebookF />, color: '#135dec' },
    twitter: { icon: <FaTwitter />, color: '#35b7e0' },
    youtube: { icon: <FaYoutube />, color: '#ff0000' },
    instagram: { icon: <FaInstagram />, color: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)' },
    linkedin: { icon: <FaLinkedinIn />, color: '#0a66c2' },
    pinterest: { icon: <FaPinterestP />, color: '#e60023' },
    behance: { icon: <FaBehance />, color: '#2c3ab6' },
  });
  return (
    <Section className={className}>
      {Object.keys(links).map((socialKey, i) => (
        <div key={i}>
          {iconList[socialKey] && (
            <SocialLink
              className="p-button-rounded p-button-outlined"
              color={iconList[socialKey].color}
              onClick={() => window.open(links[socialKey], '_blank')}
            >
              {iconList[socialKey].icon}
            </SocialLink>
          )}
        </div>
      ))}
    </Section>
  );
};

export default SocialLinks;
