import { useEffect, useState } from 'react';
import useViewport from 'utils/useViewport';
import { ContractWrap } from './index.styled';
import parse from 'html-react-parser';

interface ContactProps {
  img: string;
  title: string;
  subtitle?: string;
  text: string;
  link?: string;
}

export const ContractCard = (props: ContactProps) => {
  const { img, title, subtitle, text, link } = props;
  const { width } = useViewport();
  const isMobileWidth = width < 960;
  const [isMobile, setIsMobile] = useState(isMobileWidth);

  useEffect(() => {
    if (isMobileWidth !== isMobile) {
      setIsMobile(isMobileWidth);
    }
  }, [width, isMobile, isMobileWidth]);

  return (
    <ContractWrap>
      <div className="inner-box">
          <div className="p-button-link p-0 flex flex-column mb-4 mx-auto text-center">
            {link ? 
            <a href={link} target="_blank" rel="noreferrer" className='icon-img-box -mb-6 px-2 py-1 max-w-max mx-auto'>
              <img draggable="false" src={img} alt="" />
            </a>
            :
            <div className='icon-img-box -mb-2 px-2 py-1 max-w-max mx-auto'>
             <img draggable="false" src={img} alt="" />
            </div>
            }
            {title && (
            <p className="contract-title text-center mx-auto block">{parse(title)}</p>
            )}
            {subtitle && (
            <p className="contract-subtitle text-center mx-auto">{parse(subtitle)}</p>
            )}
          </div>
          <>{parse(text)}</>
        </div>
    </ContractWrap>
  );
};

export default ContractCard;
