import { Container } from 'components/container';
import { Section } from './index.styled';
import data from './data.json';
import ContractCard from 'components/contract';

const UnitAssets = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          {data.slice(0, 2).map((item, key) => (
            <div key={key} className="col-12 md:col-6 flex">
              <ContractCard {...item} />
            </div>
          ))}
        </div>
      </Container>

      <img src="/assets/images/vogon_grid.png" alt="Vogan" className="w-full my-7" />

      <Container>
        <div className="grid">
          {data.slice(2, 4).map((item, key) => (
            <div key={key} className="col-12 md:col-6 flex">
              <ContractCard {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default UnitAssets;
