import { Section as S } from 'components/Section';
import styled from 'styled-components';

interface Props {
  value: number;
}
export const Progress = styled.div<Props>`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0px;
  }

  h6 {
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px !important;
    line-height: 1.4;
  }

  .p-progressbar {
    height: 7px;
    overflow: visible;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #151424;
      position: absolute;
      left: ${({ value }) => `${value}%`};
      bottom: 12px;
      transition: left 0.2s ease-in-out;
    }

    &:after {
      content: ${({ value }) => `'${value}%'`};
      position: absolute;
      left: ${({ value }) => `${value - 3}%`};
      top: -45px;
      padding: 7px 15px;
      border-radius: 5px;
      background: #151424;
      font-size: 12px;
      font-weight: 600;
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value-animate {
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value {
      // background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
      background: linear-gradient(to right, #dfdfdf, #f4f4f4, #00cfe5);
    }
  }
`;

export const Section = styled(S)`
  padding: 120px 0;
  overflow: hidden;

  .heading2 {
    h3 {
      margin-left: -70px;
    }
  }

  .gyGlyH .col2 {
    padding-left: 70px;
  }

  @media (max-width: 576px) {
  }
`;
