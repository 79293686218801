import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import parse from 'html-react-parser';
import { Aside, Section } from './index.styled';
import data from './datas.json';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { CustomBtn } from 'components/button';
import { Divider } from 'components/divider';

const Card = ({ icon, title, text, className }) => (
  <Aside className={className}>
    <span className={icon}></span>
    <h6>{title}</h6>
    <p className="text-justify">{text}</p>
  </Aside>
);

const Services = ({ light = false }) => {
  return (
    <Section light={light}>
      <Divider />
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2">
            <SectionHeader
              className="text-center"
              label="ON OUR BLOCKCHAIN ECOSYSTEM"
              title="We promote financial inclusion"
            />
          </div>
        </div>

        <div className="grid mt-5">
          {data.map((item, k) => (
            <div key={k}>
              <Card {...item} />
            </div>
          ))}
        </div>

        <div className="flex align-items-center">
          <CustomBtn label="DISCOVER MORE" />
          <FaLongArrowAltRight className="ml-2" />
        </div>
      </Container>
    </Section>
  );
};
export default Services;
