import Footer from 'components/footer';
import PreLoading from 'components/pre-loading';
import Banner from './banner';
import CrwdFamily from './crwd-family';
import CrwdFamilyDetials from './crwd-family-details';
import LearnMore from './learn-more';

const HomePage = () => {
  return (
    <>
      <PreLoading label="WELCOME" />
      {/* <LearnMore /> */}
      <Banner />
      <CrwdFamily />
      <CrwdFamilyDetials />
      <Footer />
    </>
  );
};
export default HomePage;
