import { Container } from 'components/container';
import { Section, ParallaxBanner } from './index.styled';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const navigte = useNavigate();

  return (
    <Section light>
      <ParallaxBanner
        layers={[
          {
            className: 'layer1',
            children: (
              <video autoPlay muted loop>
                <source src="https://crwd.world/content/themes/default/videos/home_video.mov" />
              </video>
            ),
          },
        ]}
      >
        <Container className="container">
          <div className="grid m-0 align-items-center">
            <div className="col-12 md:col-7">
              <h3>
                Bring your <br />
                world <span className="color-font">together.</span>
              </h3>
              <p>
                crwdworld connects people, entrepreneurs, and businesses together on a single decentralized platform.
              </p>
              <Button
                label="Learn More"
                className="p-button-link light"
                onClick={() => {
                  navigte('/about');
                }}
              />
            </div>

            <div className="col-12 md:col-5 flex justify-content-end">
              <div className="login-form"></div>
            </div>
          </div>
        </Container>
      </ParallaxBanner>
    </Section>
  );
};

export default Banner;
