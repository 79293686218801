import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  position: relative;
  padding: 0 0 90px;
  margin-top: -55px;

  h1 {
    color: #fff;
    font-weight: 600;
    font-size: 50px;
    text-align: center;
  }

  .cwunit {
    text-align: center;
    padding-bottom: 5rem;

    h1 {
      font-size: 27px;
      text-align: center;
      font-weight: 500;
      padding-top: 1rem;

      button {
        font-size: 37px;
        color: var(--text-color);
        padding-bottom: 0.371rem;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

export const Aside = styled.aside`
  position: relative;
  overflow: hidden;
  margin: 0 5px;

  .p-image {
    display: block;

    img {
      width: 100%;
      height: 100px;
    }
  }
`;
