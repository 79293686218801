import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Aside, Section } from './index.styled';
import data from './data.json';
import Slider from 'react-slick';
import { Button } from 'primereact/button';

const Card = ({ img, title, text }) => (
  <Aside>
    <Image className="mt-5" src={img} />
  </Aside>
);

const CrwdFamily = ({ light = false }) => {
  var settings = {
    autoplay: false,
    dots: false,
    infinite: false,
    slidesToShow: 6,
  };

  return (
    <Section light={light}>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2">
            <div className="cwunit">
              <Button className="p-button-link" onClick={() => window.open('https://crwdunit.com', '_blank')}>
                <img src="/assets/images/cwunit.png" alt="crwdunit" />
              </Button>
              <h1>
                Click here to Discover
                <Button
                  className="crwd-font p-button-link"
                  onClick={() => window.open('https://crwdunit.com', '_blank')}
                >
                  crwd<b>units</b>
                </Button>
                today
              </h1>
            </div>
            <h1>Meet the crwd_family</h1>
          </div>
        </div>

        <Slider {...settings} className="mt-4">
          {data.map((item, k) => (
            <Card key={k} {...item} />
          ))}
        </Slider>
      </Container>
    </Section>
  );
};
export default CrwdFamily;
