import styled from 'styled-components';
import parse from 'html-react-parser';
import clsx from 'clsx';

interface Props {
  bg?: string;
}

const SectionWrap = styled.section<Props>`
  background-image: ${({ bg }) => (bg ? `url(${bg})` : '')};
  background-repeat: no-repeat;
  background-size: cover;

  &.light {
    background-color: var(--surface-card);
  }

  @media (max-width: 576px) {
  }
`;

const Heading = styled.div`
  h3 {
    font-weight: 300;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 7px;
    margin-bottom: 10px;
  }
  a {
    color: var(--white-color);
  }

  h2 {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 48px;
    background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }

  p {
    margin: 0rem;
    // text-align: justify;
  }

  @media (max-width: 576px) {
  }
`;

const Heading2 = styled.div`
  h3 {
    font-weight: 300;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: inline-block;
    // background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
    background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
    color: #000;
    padding: 7px 12px;
    border-radius: 10px;
    margin: 0 0 5px 0;
    line-height: 1.4;
  }
  a {
    color: var(--white-color);
  }

  h2 {
    margin: 0;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 55px;
  }

  p {
    margin: 0rem;
    text-align: justify;
  }

  @media (max-width: 576px) {
  }
`;

interface Props {
  bg?: string;
  light?: boolean;
  children: any;
  className?: string;
}
export const Section = ({ bg = '', light = false, children, className = '' }: Props) => {
  return (
    <SectionWrap className={clsx(className, light && 'light')} bg={bg}>
      {children}
    </SectionWrap>
  );
};

export const SectionHeader = ({ className = '', label = '', title = '', text = '' }) => {
  return (
    <Heading className={'heading ' + className}>
      {label && <h3>{parse(label)}</h3>}
      {title && <h2>{parse(title)} </h2>}
      {text && text.search(/<p>/) === -1 ? <p>{parse(text)}</p> : <>{parse(text)}</>}
    </Heading>
  );
};

export const SectionHeader2 = ({ className = '', label = '', title = '', text = '' }) => {
  return (
    <Heading2 className={'heading2 ' + className}>
      {label && <h3>{parse(label)}</h3>}
      {title && <h2>{parse(title)} </h2>}
      {text && <p>{parse(text)}</p>}
    </Heading2>
  );
};
