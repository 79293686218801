import { Container } from 'components/container';
import { SectionHeader2 } from 'components/Section';
import parse from 'html-react-parser';
import { Image } from 'primereact/image';
import { Aside, Section } from './index.styled';
import data from './data.json';

const Card = ({ icon, title, text }) => (
  <Aside>
    <span className={icon}>
      <div className="bord"></div>
    </span>
    <div className="detail">
      <h6>{title}</h6>
      {parse(text)}
    </div>
  </Aside>
);

const Hero = ({ className = '' }) => {
  return (
    <Section className={className}>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-5 p-0">
            <Image width="100%" className="img block" src="/assets/images/blog/news_page.png" />
          </div>
          <div className="col-12 md:col-7">
            <SectionHeader2
              label="OUR GLOBAL NEWS PORTAL"
              title="OPEN, HONEST, STABLE MARKETS"
              text="<p>Your single point for immutable and transparent updates</p>"
            />

            <div className="grid mt-5">
              <div className="col-12">
                <Card {...data[0]} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};
export default Hero;
