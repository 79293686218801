import { Container } from 'components/container';
import { Section } from './index.styled';
import { SectionHeader } from 'components/Section';
import { Image } from 'primereact/image';

const UnitInfo2 = () => {
  
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-6 text-center">
            <Image src="/assets/images/cwunit.png" width="100%" className="block px-5"/>
          </div>
          <div className="col-12 md:col-6">
            <SectionHeader
              className="heading-right"
              label="The <span className='crwd-font'>crwd<b>unit</b></span>"
              title="The <span className='crwd-font'>crwd<b>unit</b></span> is our cornerstone to bringing a new era to the internet and finance"
              text="<span>While traditional digital business ecosystems like Amazon and Google extract the most value and keep it. Our Vogon-powered Blockchain ecosystem shares it.</span><span>Our Vogon Utility unit is a cryptographic unit representing 0.999 grams of silver. Unique to our Blockchain Ecosystem, our <span className='crwd-font'>crwd<b>unit</b></span> allows members to perform practical actions on <span className='crwd-font'>crwd<b>world</b></span>. Its use case is simple, to establish the utility of worth with natural asset backing.</span>"
            />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default UnitInfo2;
