import { Container } from 'components/container';
import { Grid, Section } from './index.styled';
import data from './data.json';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const Card = ({ img, title, text, path, rightPosition = false }) => {
  const navigte = useNavigate();

  return (
    <Grid className="grid justify-content-center align-items-center">
      <div className={'col-f col-12 md:col-6 text-center flex-order-' + (rightPosition ? '0' : '1')}>
        <img src={img} alt={title} />
      </div>
      <div className={'col-12 md:col-5 px-5 flex-order-' + (rightPosition ? '1' : '0')}>
        <h3>{title}</h3>
        <p>{text}</p>
        <Button
          label="Learn More"
          className="p-button-link p-0"
          onClick={() => {
            navigte(path);
          }}
        />
      </div>
    </Grid>
  );
};

const CrwdFamilyDetials = () => {
  return (
    <Section light={true}>
      <Container>
        {data.map((obj, i) => (
          <Card key={i} rightPosition={i % 2 === 0} {...obj} />
        ))}
      </Container>
    </Section>
  );
};
export default CrwdFamilyDetials;
