import Footer from 'components/footer';
import { useEffect, useState } from 'react';
import { Iframe } from './index.styled';
import { useNavigate } from 'react-router-dom';
import PreLoading from 'components/pre-loading';

interface Props {
  className?: string;
  src: string;
  id: string;
}
const IFrame = ({ className = '', id, src }: Props) => {
  const navigate = useNavigate();
  const [enableFooter, setEnableFooter] = useState(false);

  let preLoadingText = {
    'quality-life': 'QUALITY OF LIFE',
    'meet-team': 'CROWD TEAM',
    'big-data': 'SIMPLICITY',
    'business-ecosystem': 'BUSINESS ECOSYSTEM',
    'blockchain-ecosystem': 'BLOCKCHAIN ECOSYSTEM',
    'our-blockchain': 'SUMMARY',
    'decenterlized-cloud': 'A NEW ERA IN CLOUD',
    'digital-platform': 'DIGITAL PLATFORM',
    'blog-details': 'BLOGS',
    kb: 'KNOWLEDGE BASE',
  };

  //console.log(src);

  useEffect(() => {
    const iframe = document.getElementById(id);

    window.addEventListener(
      'message',
      event => {
        const isBexStorefrontEvent = event.data.source === 'crwd-unit';

        if (!isBexStorefrontEvent) {
          return;
        }

        //console.log(event);

        const { type, payload } = event.data?.payload;
        if (type === 'window-size' && typeof payload.width === 'number' && typeof payload.height === 'number') {
          iframe.style.height = `${
            payload.height + (['blockchain-ecosystem', 'decenterlized-cloud'].includes(id) ? 700 : 0)
          }px`;
          setEnableFooter(true);
        } else if (type === 'btn-click') {
          navigate(payload.target);
        }
      },
      false,
    );
  }, []);

  return (
    <>
      <PreLoading label={preLoadingText[id] || 'WELCOME'} />
      <Iframe className={className} id={id} src={src} title={src} width="100%"></Iframe>
      {enableFooter && <Footer />}
    </>
  );
};

export default IFrame;
