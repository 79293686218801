import styled from 'styled-components';

export const Card = styled.div`
  margin: 0 auto;

  &:hover {
    .figure {
      img {
        opacity: 0.8;
        transform: scale(1.04);
      }
    }
  }

  .figure {
    background: var(--surface-card);
    overflow: hidden;

    button {
      border-radius: 0px;

      img {
        max-width: 100%;
        transition: all 0.3s;
        will-change: transform;
      }
    }
  }

  .caption {
    position: relative;
    padding-top: 0px;

    > * {
      position: relative;
      z-index: 1;
    }

    h4 {
      font-size: 28px;
      font-weight: 300;
      line-height: 38px;
      letter-spacing: -0.03em;
      margin: 20px 0;
    }

    .meta {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 30px;

      li {
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-size: 10px;
        font-size: 300;

        &.tag {
          color: var(--primary-color);
        }
      }
    }
  }
`;
