import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  overflow: hidden;
  position: relative;
  padding: 100px 0;

  @media (max-width: 576px) {
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  padding: 60px 40px;
  background: var(--surface-card);
  border-radius: 5px;
  height: 100%;

  .p-image {
    display: block;

    img {
      width: auto;
      height: 80px;
    }
  }

  .details {
    padding-left: 30px;
    text-align: left;

    h6 {
      line-height: 1.8;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 2;
      margin: 0;
    }
  }
`;
