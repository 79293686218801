import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  // height: calc(100vh - 85px);
  background: hsl(258deg 26% 7%);
  padding: 100px 0 80px;

  .heading {
    h2 {
      font-size: 90px;
      line-height: 1;
      position: relative;
      margin-bottom: 20px;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        height: 10px;
        background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
      }
    }
  }

  button {
    margin-top: 40px;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 5px;
    padding: 10px 20px;
    background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
  }

  .p-image {
    width: 70%;
    display: block;
    // height: calc(100vh - 150px);
    margin: -30px auto 0;

    img {
      // height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 576px) {
  }
`;
