import { Container } from 'components/container';
import { GradiantText } from 'components/text';
import data from './data.json';
import { Section, ItemWrap } from './index.styled';
import parse from 'html-react-parser';

const Item = ({ img, title, today, tomorrow }) => {
  return (
    <ItemWrap className="grid">
      <div className="col-5 ml-5 md:col-2 md:ml-0">
        <img src={img} alt="" className="logo" />
      </div>
      <div className="col-12 md:col-10">
        <div className="grid align-items-center">
          <div className="col-2 md:col-1"></div>
          <div className="col-10 md:col-11">
            <h2>{parse(title)}</h2>
          </div>
        </div>
        <div className="grid align-items-center mt-1">
          <div className="col-2 md:col-1 pb-0">
            <img src="/assets/images/roadmap/today_1.png" alt="" className="icon" />
          </div>
          <div className="col-10 md:col-11">
            <h2>{parse(today)}</h2>
          </div>
        </div>
        <div className="grid align-items-center mt-1">
          <div className="col-2 md:col-1 pb-0">
            <img src="/assets/images/roadmap/tomorrow_2.png" alt="" className="icon" />
          </div>
          <div className="col-10 md:col-11">
            <h2>{parse(tomorrow)}</h2>
          </div>
        </div>
      </div>
    </ItemWrap>
  );
};

const FutureRoadmap = () => {
  return (
    <Section>
      <Container>
        <div className="grid mb-8">
          <div className="col-12 md:col-10 md:col-offset-1 text-center">
            <GradiantText className="title">Current status and future roadmap</GradiantText>
          </div>
        </div>
        <div className="items-wrapper">
          {data.map((item, key) => (
            <Item key={key} {...item} />
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default FutureRoadmap;
