import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  citext: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ComplianceCheck = {
  __typename?: 'ComplianceCheck';
  state: Scalars['json'];
};

export type ComplianceCheckConfig = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
  companyChecks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  people?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
  peopleChecks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  requestingId: Scalars['uuid'];
  requestingType: Scalars['String'];
};

export type ContactCardAttribute = {
  __typename?: 'ContactCardAttribute';
  name: Scalars['String'];
  order: Scalars['float8'];
  type: ContactCardAttributeType;
  value: Scalars['String'];
};

export type ContactCardAttributeGroup = {
  __typename?: 'ContactCardAttributeGroup';
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  order: Scalars['float8'];
};

export type ContactCardAttributeType = {
  __typename?: 'ContactCardAttributeType';
  description?: Maybe<Scalars['String']>;
  group: ContactCardAttributeGroup;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['float8'];
  props?: Maybe<Scalars['jsonb']>;
  validation?: Maybe<Scalars['jsonb']>;
  valueType: Scalars['String'];
};

export enum ContactCardCodeStatus {
  Invalid = 'Invalid',
  None = 'None',
  Valid = 'Valid'
}

export type ContactCardInput = {
  expiringCode?: InputMaybe<Scalars['String']>;
  handle: Scalars['String'];
};

export type ContactCardOutput = {
  __typename?: 'ContactCardOutput';
  attributes: Array<ContactCardAttribute>;
  codeStatus: ContactCardCodeStatus;
  handle: Scalars['String'];
};

export type ExpiringCodeInput = {
  timeToLive?: InputMaybe<Scalars['Int']>;
};

export type ExpiringCodeOutput = {
  __typename?: 'ExpiringCodeOutput';
  expiringCode: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type IsHandleAvailable = {
  __typename?: 'IsHandleAvailable';
  isAvailable: Scalars['Boolean'];
};

export type RunUserComplianceCheckInput = {
  checkTypeNames: Array<Scalars['String']>;
  userId: Scalars['uuid'];
};

export type RunUserComplianceCheckOutput = {
  __typename?: 'RunUserComplianceCheckOutput';
  data: Scalars['jsonb'];
  isClean: Scalars['Boolean'];
  status: Scalars['String'];
  typeName: Scalars['String'];
  userId: Scalars['uuid'];
  vendorCheckId: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UnitPurchaseRequestInput = {
  formData: Scalars['jsonb'];
};

export type UnitPurchaseRequestOutput = {
  __typename?: 'UnitPurchaseRequestOutput';
  error?: Maybe<Scalars['String']>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  altitude?: Maybe<Scalars['float8']>;
  city: Scalars['String'];
  /** An object relationship */
  country: Address_Country;
  countryCode: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  postalCode: Scalars['String'];
  /** An object relationship */
  state?: Maybe<Address_State>;
  stateCode?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  user_addresses: Array<User_Address>;
  /** An aggregate relationship */
  user_addresses_aggregate: User_Address_Aggregate;
};


/** columns and relationships of "address" */
export type AddressUser_AddressesArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressUser_Addresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'address_avg_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Bool_Exp>>;
  _not?: InputMaybe<Address_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Bool_Exp>>;
  altitude?: InputMaybe<Float8_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Address_Country_Bool_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<Address_State_Bool_Exp>;
  stateCode?: InputMaybe<String_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_addresses?: InputMaybe<User_Address_Bool_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint */
  AddressesPkey = 'addresses_pkey'
}

/** columns and relationships of "address_country" */
export type Address_Country = {
  __typename?: 'address_country';
  code: Scalars['String'];
  currencyCode: Scalars['String'];
  flagEmoji: Scalars['String'];
  flagEmojiUnicode: Scalars['String'];
  iso3: Scalars['String'];
  isoNumber: Scalars['Int'];
  latitude: Scalars['float8'];
  longitude: Scalars['float8'];
  name: Scalars['String'];
  nameNative?: Maybe<Scalars['String']>;
  nameTranslations: Scalars['jsonb'];
  phoneCode: Scalars['Int'];
  region: Scalars['String'];
  secCode?: Maybe<Scalars['String']>;
  stateLabel: Scalars['String'];
  /** An array relationship */
  states: Array<Address_State>;
  /** An aggregate relationship */
  states_aggregate: Address_State_Aggregate;
  subregion: Scalars['String'];
  tld: Scalars['String'];
};


/** columns and relationships of "address_country" */
export type Address_CountryNameTranslationsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "address_country" */
export type Address_CountryStatesArgs = {
  distinct_on?: InputMaybe<Array<Address_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_State_Order_By>>;
  where?: InputMaybe<Address_State_Bool_Exp>;
};


/** columns and relationships of "address_country" */
export type Address_CountryStates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_State_Order_By>>;
  where?: InputMaybe<Address_State_Bool_Exp>;
};

/** aggregated selection of "address_country" */
export type Address_Country_Aggregate = {
  __typename?: 'address_country_aggregate';
  aggregate?: Maybe<Address_Country_Aggregate_Fields>;
  nodes: Array<Address_Country>;
};

/** aggregate fields of "address_country" */
export type Address_Country_Aggregate_Fields = {
  __typename?: 'address_country_aggregate_fields';
  avg?: Maybe<Address_Country_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Address_Country_Max_Fields>;
  min?: Maybe<Address_Country_Min_Fields>;
  stddev?: Maybe<Address_Country_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Country_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Country_Sum_Fields>;
  var_pop?: Maybe<Address_Country_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Country_Var_Samp_Fields>;
  variance?: Maybe<Address_Country_Variance_Fields>;
};


/** aggregate fields of "address_country" */
export type Address_Country_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_Country_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Address_Country_Append_Input = {
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Address_Country_Avg_Fields = {
  __typename?: 'address_country_avg_fields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "address_country". All fields are combined with a logical 'AND'. */
export type Address_Country_Bool_Exp = {
  _and?: InputMaybe<Array<Address_Country_Bool_Exp>>;
  _not?: InputMaybe<Address_Country_Bool_Exp>;
  _or?: InputMaybe<Array<Address_Country_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  currencyCode?: InputMaybe<String_Comparison_Exp>;
  flagEmoji?: InputMaybe<String_Comparison_Exp>;
  flagEmojiUnicode?: InputMaybe<String_Comparison_Exp>;
  iso3?: InputMaybe<String_Comparison_Exp>;
  isoNumber?: InputMaybe<Int_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nameNative?: InputMaybe<String_Comparison_Exp>;
  nameTranslations?: InputMaybe<Jsonb_Comparison_Exp>;
  phoneCode?: InputMaybe<Int_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  secCode?: InputMaybe<String_Comparison_Exp>;
  stateLabel?: InputMaybe<String_Comparison_Exp>;
  states?: InputMaybe<Address_State_Bool_Exp>;
  subregion?: InputMaybe<String_Comparison_Exp>;
  tld?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address_country" */
export enum Address_Country_Constraint {
  /** unique or primary key constraint */
  AddressCountryPkey = 'address_country_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Address_Country_Delete_At_Path_Input = {
  nameTranslations?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Address_Country_Delete_Elem_Input = {
  nameTranslations?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Address_Country_Delete_Key_Input = {
  nameTranslations?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "address_country" */
export type Address_Country_Inc_Input = {
  isoNumber?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  phoneCode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "address_country" */
export type Address_Country_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  flagEmoji?: InputMaybe<Scalars['String']>;
  flagEmojiUnicode?: InputMaybe<Scalars['String']>;
  iso3?: InputMaybe<Scalars['String']>;
  isoNumber?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  nameNative?: InputMaybe<Scalars['String']>;
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
  phoneCode?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  stateLabel?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Address_State_Arr_Rel_Insert_Input>;
  subregion?: InputMaybe<Scalars['String']>;
  tld?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Address_Country_Max_Fields = {
  __typename?: 'address_country_max_fields';
  code?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  flagEmoji?: Maybe<Scalars['String']>;
  flagEmojiUnicode?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  isoNumber?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  nameNative?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
  stateLabel?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  tld?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Address_Country_Min_Fields = {
  __typename?: 'address_country_min_fields';
  code?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  flagEmoji?: Maybe<Scalars['String']>;
  flagEmojiUnicode?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  isoNumber?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  nameNative?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
  stateLabel?: Maybe<Scalars['String']>;
  subregion?: Maybe<Scalars['String']>;
  tld?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "address_country" */
export type Address_Country_Mutation_Response = {
  __typename?: 'address_country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address_Country>;
};

/** input type for inserting object relation for remote table "address_country" */
export type Address_Country_Obj_Rel_Insert_Input = {
  data: Address_Country_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_Country_On_Conflict>;
};

/** on_conflict condition type for table "address_country" */
export type Address_Country_On_Conflict = {
  constraint: Address_Country_Constraint;
  update_columns?: Array<Address_Country_Update_Column>;
  where?: InputMaybe<Address_Country_Bool_Exp>;
};

/** Ordering options when selecting data from "address_country". */
export type Address_Country_Order_By = {
  code?: InputMaybe<Order_By>;
  currencyCode?: InputMaybe<Order_By>;
  flagEmoji?: InputMaybe<Order_By>;
  flagEmojiUnicode?: InputMaybe<Order_By>;
  iso3?: InputMaybe<Order_By>;
  isoNumber?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nameNative?: InputMaybe<Order_By>;
  nameTranslations?: InputMaybe<Order_By>;
  phoneCode?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  secCode?: InputMaybe<Order_By>;
  stateLabel?: InputMaybe<Order_By>;
  states_aggregate?: InputMaybe<Address_State_Aggregate_Order_By>;
  subregion?: InputMaybe<Order_By>;
  tld?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address_country */
export type Address_Country_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Address_Country_Prepend_Input = {
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "address_country" */
export enum Address_Country_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  FlagEmoji = 'flagEmoji',
  /** column name */
  FlagEmojiUnicode = 'flagEmojiUnicode',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  IsoNumber = 'isoNumber',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  NameNative = 'nameNative',
  /** column name */
  NameTranslations = 'nameTranslations',
  /** column name */
  PhoneCode = 'phoneCode',
  /** column name */
  Region = 'region',
  /** column name */
  SecCode = 'secCode',
  /** column name */
  StateLabel = 'stateLabel',
  /** column name */
  Subregion = 'subregion',
  /** column name */
  Tld = 'tld'
}

/** input type for updating data in table "address_country" */
export type Address_Country_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  flagEmoji?: InputMaybe<Scalars['String']>;
  flagEmojiUnicode?: InputMaybe<Scalars['String']>;
  iso3?: InputMaybe<Scalars['String']>;
  isoNumber?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  nameNative?: InputMaybe<Scalars['String']>;
  nameTranslations?: InputMaybe<Scalars['jsonb']>;
  phoneCode?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
  stateLabel?: InputMaybe<Scalars['String']>;
  subregion?: InputMaybe<Scalars['String']>;
  tld?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Address_Country_Stddev_Fields = {
  __typename?: 'address_country_stddev_fields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Address_Country_Stddev_Pop_Fields = {
  __typename?: 'address_country_stddev_pop_fields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Address_Country_Stddev_Samp_Fields = {
  __typename?: 'address_country_stddev_samp_fields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Address_Country_Sum_Fields = {
  __typename?: 'address_country_sum_fields';
  isoNumber?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  phoneCode?: Maybe<Scalars['Int']>;
};

/** update columns of table "address_country" */
export enum Address_Country_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  FlagEmoji = 'flagEmoji',
  /** column name */
  FlagEmojiUnicode = 'flagEmojiUnicode',
  /** column name */
  Iso3 = 'iso3',
  /** column name */
  IsoNumber = 'isoNumber',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  NameNative = 'nameNative',
  /** column name */
  NameTranslations = 'nameTranslations',
  /** column name */
  PhoneCode = 'phoneCode',
  /** column name */
  Region = 'region',
  /** column name */
  SecCode = 'secCode',
  /** column name */
  StateLabel = 'stateLabel',
  /** column name */
  Subregion = 'subregion',
  /** column name */
  Tld = 'tld'
}

/** aggregate var_pop on columns */
export type Address_Country_Var_Pop_Fields = {
  __typename?: 'address_country_var_pop_fields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Address_Country_Var_Samp_Fields = {
  __typename?: 'address_country_var_samp_fields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Address_Country_Variance_Fields = {
  __typename?: 'address_country_variance_fields';
  isoNumber?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  phoneCode?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  altitude?: InputMaybe<Scalars['float8']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  altitude?: InputMaybe<Scalars['float8']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Address_Country_Obj_Rel_Insert_Input>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Address_State_Obj_Rel_Insert_Input>;
  stateCode?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user_addresses?: InputMaybe<User_Address_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  altitude?: Maybe<Scalars['float8']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  postalCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  altitude?: Maybe<Scalars['float8']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  postalCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: InputMaybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  altitude?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Address_Country_Order_By>;
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  state?: InputMaybe<Address_State_Order_By>;
  stateCode?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_addresses_aggregate?: InputMaybe<User_Address_Aggregate_Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  Altitude = 'altitude',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  StateCode = 'stateCode',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  altitude?: InputMaybe<Scalars['float8']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  postalCode?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "address_state" */
export type Address_State = {
  __typename?: 'address_state';
  code: Scalars['String'];
  /** An object relationship */
  country: Address_Country;
  countryCode: Scalars['String'];
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name: Scalars['String'];
  secCode?: Maybe<Scalars['String']>;
};

/** aggregated selection of "address_state" */
export type Address_State_Aggregate = {
  __typename?: 'address_state_aggregate';
  aggregate?: Maybe<Address_State_Aggregate_Fields>;
  nodes: Array<Address_State>;
};

/** aggregate fields of "address_state" */
export type Address_State_Aggregate_Fields = {
  __typename?: 'address_state_aggregate_fields';
  avg?: Maybe<Address_State_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Address_State_Max_Fields>;
  min?: Maybe<Address_State_Min_Fields>;
  stddev?: Maybe<Address_State_Stddev_Fields>;
  stddev_pop?: Maybe<Address_State_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_State_Stddev_Samp_Fields>;
  sum?: Maybe<Address_State_Sum_Fields>;
  var_pop?: Maybe<Address_State_Var_Pop_Fields>;
  var_samp?: Maybe<Address_State_Var_Samp_Fields>;
  variance?: Maybe<Address_State_Variance_Fields>;
};


/** aggregate fields of "address_state" */
export type Address_State_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Address_State_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "address_state" */
export type Address_State_Aggregate_Order_By = {
  avg?: InputMaybe<Address_State_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Address_State_Max_Order_By>;
  min?: InputMaybe<Address_State_Min_Order_By>;
  stddev?: InputMaybe<Address_State_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Address_State_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Address_State_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Address_State_Sum_Order_By>;
  var_pop?: InputMaybe<Address_State_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Address_State_Var_Samp_Order_By>;
  variance?: InputMaybe<Address_State_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "address_state" */
export type Address_State_Arr_Rel_Insert_Input = {
  data: Array<Address_State_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_State_On_Conflict>;
};

/** aggregate avg on columns */
export type Address_State_Avg_Fields = {
  __typename?: 'address_state_avg_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "address_state" */
export type Address_State_Avg_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "address_state". All fields are combined with a logical 'AND'. */
export type Address_State_Bool_Exp = {
  _and?: InputMaybe<Array<Address_State_Bool_Exp>>;
  _not?: InputMaybe<Address_State_Bool_Exp>;
  _or?: InputMaybe<Array<Address_State_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Address_Country_Bool_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  secCode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address_state" */
export enum Address_State_Constraint {
  /** unique or primary key constraint */
  AddressStatePkey = 'address_state_pkey'
}

/** input type for incrementing numeric columns in table "address_state" */
export type Address_State_Inc_Input = {
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "address_state" */
export type Address_State_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Address_Country_Obj_Rel_Insert_Input>;
  countryCode?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Address_State_Max_Fields = {
  __typename?: 'address_state_max_fields';
  code?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "address_state" */
export type Address_State_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  secCode?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Address_State_Min_Fields = {
  __typename?: 'address_state_min_fields';
  code?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  secCode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "address_state" */
export type Address_State_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  secCode?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "address_state" */
export type Address_State_Mutation_Response = {
  __typename?: 'address_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address_State>;
};

/** input type for inserting object relation for remote table "address_state" */
export type Address_State_Obj_Rel_Insert_Input = {
  data: Address_State_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Address_State_On_Conflict>;
};

/** on_conflict condition type for table "address_state" */
export type Address_State_On_Conflict = {
  constraint: Address_State_Constraint;
  update_columns?: Array<Address_State_Update_Column>;
  where?: InputMaybe<Address_State_Bool_Exp>;
};

/** Ordering options when selecting data from "address_state". */
export type Address_State_Order_By = {
  code?: InputMaybe<Order_By>;
  country?: InputMaybe<Address_Country_Order_By>;
  countryCode?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  secCode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: address_state */
export type Address_State_Pk_Columns_Input = {
  code: Scalars['String'];
  countryCode: Scalars['String'];
};

/** select columns of table "address_state" */
export enum Address_State_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  SecCode = 'secCode'
}

/** input type for updating data in table "address_state" */
export type Address_State_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  name?: InputMaybe<Scalars['String']>;
  secCode?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Address_State_Stddev_Fields = {
  __typename?: 'address_state_stddev_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "address_state" */
export type Address_State_Stddev_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_State_Stddev_Pop_Fields = {
  __typename?: 'address_state_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "address_state" */
export type Address_State_Stddev_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_State_Stddev_Samp_Fields = {
  __typename?: 'address_state_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "address_state" */
export type Address_State_Stddev_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Address_State_Sum_Fields = {
  __typename?: 'address_state_sum_fields';
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "address_state" */
export type Address_State_Sum_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** update columns of table "address_state" */
export enum Address_State_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Name = 'name',
  /** column name */
  SecCode = 'secCode'
}

/** aggregate var_pop on columns */
export type Address_State_Var_Pop_Fields = {
  __typename?: 'address_state_var_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "address_state" */
export type Address_State_Var_Pop_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_State_Var_Samp_Fields = {
  __typename?: 'address_state_var_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "address_state" */
export type Address_State_Var_Samp_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_State_Variance_Fields = {
  __typename?: 'address_state_variance_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "address_state" */
export type Address_State_Variance_Order_By = {
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'address_stddev_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'address_stddev_pop_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'address_stddev_samp_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'address_sum_fields';
  altitude?: Maybe<Scalars['float8']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  Altitude = 'altitude',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  StateCode = 'stateCode',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'address_var_pop_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'address_var_samp_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'address_variance_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "app" */
export type App = {
  __typename?: 'app';
  allowedRoles: Scalars['jsonb'];
  authenticated: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};


/** columns and relationships of "app" */
export type AppAllowedRolesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "app" */
export type App_Aggregate = {
  __typename?: 'app_aggregate';
  aggregate?: Maybe<App_Aggregate_Fields>;
  nodes: Array<App>;
};

/** aggregate fields of "app" */
export type App_Aggregate_Fields = {
  __typename?: 'app_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<App_Max_Fields>;
  min?: Maybe<App_Min_Fields>;
};


/** aggregate fields of "app" */
export type App_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<App_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type App_Append_Input = {
  allowedRoles?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "app". All fields are combined with a logical 'AND'. */
export type App_Bool_Exp = {
  _and?: InputMaybe<Array<App_Bool_Exp>>;
  _not?: InputMaybe<App_Bool_Exp>;
  _or?: InputMaybe<Array<App_Bool_Exp>>;
  allowedRoles?: InputMaybe<Jsonb_Comparison_Exp>;
  authenticated?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app" */
export enum App_Constraint {
  /** unique or primary key constraint */
  AppPkey = 'app_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type App_Delete_At_Path_Input = {
  allowedRoles?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type App_Delete_Elem_Input = {
  allowedRoles?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type App_Delete_Key_Input = {
  allowedRoles?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "app" */
export type App_Insert_Input = {
  allowedRoles?: InputMaybe<Scalars['jsonb']>;
  authenticated?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Max_Fields = {
  __typename?: 'app_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type App_Min_Fields = {
  __typename?: 'app_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "app" */
export type App_Mutation_Response = {
  __typename?: 'app_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<App>;
};

/** on_conflict condition type for table "app" */
export type App_On_Conflict = {
  constraint: App_Constraint;
  update_columns?: Array<App_Update_Column>;
  where?: InputMaybe<App_Bool_Exp>;
};

/** Ordering options when selecting data from "app". */
export type App_Order_By = {
  allowedRoles?: InputMaybe<Order_By>;
  authenticated?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: app */
export type App_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type App_Prepend_Input = {
  allowedRoles?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "app" */
export enum App_Select_Column {
  /** column name */
  AllowedRoles = 'allowedRoles',
  /** column name */
  Authenticated = 'authenticated',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "app" */
export type App_Set_Input = {
  allowedRoles?: InputMaybe<Scalars['jsonb']>;
  authenticated?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "app" */
export enum App_Update_Column {
  /** column name */
  AllowedRoles = 'allowedRoles',
  /** column name */
  Authenticated = 'authenticated',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Url = 'url'
}

/** Cache for Auth and other use cases to store ephemeral values. All cache values must expire and may be deleted any time after expiration. */
export type Cache = {
  __typename?: 'cache';
  createdAt: Scalars['timestamptz'];
  expiresAt: Scalars['timestamptz'];
  key: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "cache" */
export type Cache_Aggregate = {
  __typename?: 'cache_aggregate';
  aggregate?: Maybe<Cache_Aggregate_Fields>;
  nodes: Array<Cache>;
};

/** aggregate fields of "cache" */
export type Cache_Aggregate_Fields = {
  __typename?: 'cache_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cache_Max_Fields>;
  min?: Maybe<Cache_Min_Fields>;
};


/** aggregate fields of "cache" */
export type Cache_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cache_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cache". All fields are combined with a logical 'AND'. */
export type Cache_Bool_Exp = {
  _and?: InputMaybe<Array<Cache_Bool_Exp>>;
  _not?: InputMaybe<Cache_Bool_Exp>;
  _or?: InputMaybe<Array<Cache_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cache" */
export enum Cache_Constraint {
  /** unique or primary key constraint */
  CachePkey = 'cache_pkey'
}

/** input type for inserting data into table "cache" */
export type Cache_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cache_Max_Fields = {
  __typename?: 'cache_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cache_Min_Fields = {
  __typename?: 'cache_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cache" */
export type Cache_Mutation_Response = {
  __typename?: 'cache_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cache>;
};

/** on_conflict condition type for table "cache" */
export type Cache_On_Conflict = {
  constraint: Cache_Constraint;
  update_columns?: Array<Cache_Update_Column>;
  where?: InputMaybe<Cache_Bool_Exp>;
};

/** Ordering options when selecting data from "cache". */
export type Cache_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cache */
export type Cache_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "cache" */
export enum Cache_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "cache" */
export type Cache_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cache" */
export enum Cache_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "category" */
export type Category = {
  __typename?: 'category';
  /** An array relationship */
  children: Array<Category>;
  /** An aggregate relationship */
  children_aggregate: Category_Aggregate;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  namespace: Category_Namespace_Enum;
  order?: Maybe<Scalars['float8']>;
  /** An object relationship */
  parent?: Maybe<Category>;
  parentUrlPath?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  urlKey: Scalars['String'];
  urlPath: Scalars['String'];
};


/** columns and relationships of "category" */
export type CategoryChildrenArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


/** columns and relationships of "category" */
export type CategoryChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};

/** aggregated selection of "category" */
export type Category_Aggregate = {
  __typename?: 'category_aggregate';
  aggregate?: Maybe<Category_Aggregate_Fields>;
  nodes: Array<Category>;
};

/** aggregate fields of "category" */
export type Category_Aggregate_Fields = {
  __typename?: 'category_aggregate_fields';
  avg?: Maybe<Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Category_Max_Fields>;
  min?: Maybe<Category_Min_Fields>;
  stddev?: Maybe<Category_Stddev_Fields>;
  stddev_pop?: Maybe<Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Category_Stddev_Samp_Fields>;
  sum?: Maybe<Category_Sum_Fields>;
  var_pop?: Maybe<Category_Var_Pop_Fields>;
  var_samp?: Maybe<Category_Var_Samp_Fields>;
  variance?: Maybe<Category_Variance_Fields>;
};


/** aggregate fields of "category" */
export type Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category" */
export type Category_Aggregate_Order_By = {
  avg?: InputMaybe<Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Category_Max_Order_By>;
  min?: InputMaybe<Category_Min_Order_By>;
  stddev?: InputMaybe<Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Category_Sum_Order_By>;
  var_pop?: InputMaybe<Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "category" */
export type Category_Arr_Rel_Insert_Input = {
  data: Array<Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Category_Avg_Fields = {
  __typename?: 'category_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "category" */
export type Category_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "category". All fields are combined with a logical 'AND'. */
export type Category_Bool_Exp = {
  _and?: InputMaybe<Array<Category_Bool_Exp>>;
  _not?: InputMaybe<Category_Bool_Exp>;
  _or?: InputMaybe<Array<Category_Bool_Exp>>;
  children?: InputMaybe<Category_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  namespace?: InputMaybe<Category_Namespace_Enum_Comparison_Exp>;
  order?: InputMaybe<Float8_Comparison_Exp>;
  parent?: InputMaybe<Category_Bool_Exp>;
  parentUrlPath?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  urlKey?: InputMaybe<String_Comparison_Exp>;
  urlPath?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "category" */
export enum Category_Constraint {
  /** unique or primary key constraint */
  CategoryIdentifierKey = 'category_identifier_key',
  /** unique or primary key constraint */
  CategoryPkey = 'category_pkey'
}

/** input type for incrementing numeric columns in table "category" */
export type Category_Inc_Input = {
  order?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "category" */
export type Category_Insert_Input = {
  children?: InputMaybe<Category_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  namespace?: InputMaybe<Category_Namespace_Enum>;
  order?: InputMaybe<Scalars['float8']>;
  parent?: InputMaybe<Category_Obj_Rel_Insert_Input>;
  parentUrlPath?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  urlKey?: InputMaybe<Scalars['String']>;
  urlPath?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Category_Max_Fields = {
  __typename?: 'category_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  parentUrlPath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  urlKey?: Maybe<Scalars['String']>;
  urlPath?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "category" */
export type Category_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parentUrlPath?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  urlKey?: InputMaybe<Order_By>;
  urlPath?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Category_Min_Fields = {
  __typename?: 'category_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  parentUrlPath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  urlKey?: Maybe<Scalars['String']>;
  urlPath?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "category" */
export type Category_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parentUrlPath?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  urlKey?: InputMaybe<Order_By>;
  urlPath?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "category" */
export type Category_Mutation_Response = {
  __typename?: 'category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category>;
};

/** columns and relationships of "category_namespace" */
export type Category_Namespace = {
  __typename?: 'category_namespace';
  namespace: Scalars['String'];
};

/** aggregated selection of "category_namespace" */
export type Category_Namespace_Aggregate = {
  __typename?: 'category_namespace_aggregate';
  aggregate?: Maybe<Category_Namespace_Aggregate_Fields>;
  nodes: Array<Category_Namespace>;
};

/** aggregate fields of "category_namespace" */
export type Category_Namespace_Aggregate_Fields = {
  __typename?: 'category_namespace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Namespace_Max_Fields>;
  min?: Maybe<Category_Namespace_Min_Fields>;
};


/** aggregate fields of "category_namespace" */
export type Category_Namespace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Category_Namespace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "category_namespace". All fields are combined with a logical 'AND'. */
export type Category_Namespace_Bool_Exp = {
  _and?: InputMaybe<Array<Category_Namespace_Bool_Exp>>;
  _not?: InputMaybe<Category_Namespace_Bool_Exp>;
  _or?: InputMaybe<Array<Category_Namespace_Bool_Exp>>;
  namespace?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_namespace" */
export enum Category_Namespace_Constraint {
  /** unique or primary key constraint */
  CategoryNamespacePkey = 'category_namespace_pkey'
}

export enum Category_Namespace_Enum {
  Gics = 'GICS',
  Shopping = 'shopping'
}

/** Boolean expression to compare columns of type "category_namespace_enum". All fields are combined with logical 'AND'. */
export type Category_Namespace_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Category_Namespace_Enum>;
  _in?: InputMaybe<Array<Category_Namespace_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Category_Namespace_Enum>;
  _nin?: InputMaybe<Array<Category_Namespace_Enum>>;
};

/** input type for inserting data into table "category_namespace" */
export type Category_Namespace_Insert_Input = {
  namespace?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Category_Namespace_Max_Fields = {
  __typename?: 'category_namespace_max_fields';
  namespace?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Namespace_Min_Fields = {
  __typename?: 'category_namespace_min_fields';
  namespace?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "category_namespace" */
export type Category_Namespace_Mutation_Response = {
  __typename?: 'category_namespace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Namespace>;
};

/** on_conflict condition type for table "category_namespace" */
export type Category_Namespace_On_Conflict = {
  constraint: Category_Namespace_Constraint;
  update_columns?: Array<Category_Namespace_Update_Column>;
  where?: InputMaybe<Category_Namespace_Bool_Exp>;
};

/** Ordering options when selecting data from "category_namespace". */
export type Category_Namespace_Order_By = {
  namespace?: InputMaybe<Order_By>;
};

/** primary key columns input for table: category_namespace */
export type Category_Namespace_Pk_Columns_Input = {
  namespace: Scalars['String'];
};

/** select columns of table "category_namespace" */
export enum Category_Namespace_Select_Column {
  /** column name */
  Namespace = 'namespace'
}

/** input type for updating data in table "category_namespace" */
export type Category_Namespace_Set_Input = {
  namespace?: InputMaybe<Scalars['String']>;
};

/** update columns of table "category_namespace" */
export enum Category_Namespace_Update_Column {
  /** column name */
  Namespace = 'namespace'
}

/** input type for inserting object relation for remote table "category" */
export type Category_Obj_Rel_Insert_Input = {
  data: Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Category_On_Conflict>;
};

/** on_conflict condition type for table "category" */
export type Category_On_Conflict = {
  constraint: Category_Constraint;
  update_columns?: Array<Category_Update_Column>;
  where?: InputMaybe<Category_Bool_Exp>;
};

/** Ordering options when selecting data from "category". */
export type Category_Order_By = {
  children_aggregate?: InputMaybe<Category_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent?: InputMaybe<Category_Order_By>;
  parentUrlPath?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  urlKey?: InputMaybe<Order_By>;
  urlPath?: InputMaybe<Order_By>;
};

/** primary key columns input for table: category */
export type Category_Pk_Columns_Input = {
  urlPath: Scalars['String'];
};

/** select columns of table "category" */
export enum Category_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Order = 'order',
  /** column name */
  ParentUrlPath = 'parentUrlPath',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UrlKey = 'urlKey',
  /** column name */
  UrlPath = 'urlPath'
}

/** input type for updating data in table "category" */
export type Category_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  identifier?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  namespace?: InputMaybe<Category_Namespace_Enum>;
  order?: InputMaybe<Scalars['float8']>;
  parentUrlPath?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  urlKey?: InputMaybe<Scalars['String']>;
  urlPath?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Category_Stddev_Fields = {
  __typename?: 'category_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "category" */
export type Category_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Category_Stddev_Pop_Fields = {
  __typename?: 'category_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "category" */
export type Category_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Category_Stddev_Samp_Fields = {
  __typename?: 'category_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "category" */
export type Category_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Category_Sum_Fields = {
  __typename?: 'category_sum_fields';
  order?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "category" */
export type Category_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "category" */
export enum Category_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Image = 'image',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Order = 'order',
  /** column name */
  ParentUrlPath = 'parentUrlPath',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UrlKey = 'urlKey',
  /** column name */
  UrlPath = 'urlPath'
}

/** aggregate var_pop on columns */
export type Category_Var_Pop_Fields = {
  __typename?: 'category_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "category" */
export type Category_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Category_Var_Samp_Fields = {
  __typename?: 'category_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "category" */
export type Category_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Category_Variance_Fields = {
  __typename?: 'category_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "category" */
export type Category_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  /** An array relationship */
  attributes: Array<Company_Attribute>;
  /** An aggregate relationship */
  attributes_aggregate: Company_Attribute_Aggregate;
  /** An array relationship */
  companyAddresses: Array<Company_Address>;
  /** An aggregate relationship */
  companyAddresses_aggregate: Company_Address_Aggregate;
  /** An array relationship */
  companyCategories: Array<Company_Category>;
  /** An aggregate relationship */
  companyCategories_aggregate: Company_Category_Aggregate;
  /** An array relationship */
  companyFormResponses: Array<Company_Form_Response>;
  /** An aggregate relationship */
  companyFormResponses_aggregate: Company_Form_Response_Aggregate;
  createdAt: Scalars['timestamptz'];
  displayName?: Maybe<Scalars['String']>;
  /** An array relationship */
  emails: Array<Company_Email>;
  /** An aggregate relationship */
  emails_aggregate: Company_Email_Aggregate;
  /** An array relationship */
  formResponses: Array<Form_Response>;
  /** An aggregate relationship */
  formResponses_aggregate: Form_Response_Aggregate;
  id: Scalars['uuid'];
  legalName: Scalars['String'];
  /** An object relationship */
  logoImage?: Maybe<File>;
  logoImageId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  phoneNumbers: Array<Company_Phone_Number>;
  /** An aggregate relationship */
  phoneNumbers_aggregate: Company_Phone_Number_Aggregate;
  updatedAt: Scalars['timestamptz'];
  websiteUrl?: Maybe<Scalars['String']>;
};


/** columns and relationships of "company" */
export type CompanyAttributesArgs = {
  distinct_on?: InputMaybe<Array<Company_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Attribute_Order_By>>;
  where?: InputMaybe<Company_Attribute_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyAttributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Attribute_Order_By>>;
  where?: InputMaybe<Company_Attribute_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompanyAddressesArgs = {
  distinct_on?: InputMaybe<Array<Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Address_Order_By>>;
  where?: InputMaybe<Company_Address_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompanyAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Address_Order_By>>;
  where?: InputMaybe<Company_Address_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompanyCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Company_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Category_Order_By>>;
  where?: InputMaybe<Company_Category_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompanyCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Category_Order_By>>;
  where?: InputMaybe<Company_Category_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompanyFormResponsesArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyCompanyFormResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyEmailsArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Order_By>>;
  where?: InputMaybe<Company_Email_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Order_By>>;
  where?: InputMaybe<Company_Email_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyFormResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyFormResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyPhoneNumbersArgs = {
  distinct_on?: InputMaybe<Array<Company_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Phone_Number_Order_By>>;
  where?: InputMaybe<Company_Phone_Number_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyPhoneNumbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Phone_Number_Order_By>>;
  where?: InputMaybe<Company_Phone_Number_Bool_Exp>;
};

/** columns and relationships of "company_address" */
export type Company_Address = {
  __typename?: 'company_address';
  /** An object relationship */
  address: Address;
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  isPrimary: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "company_address" */
export type Company_Address_Aggregate = {
  __typename?: 'company_address_aggregate';
  aggregate?: Maybe<Company_Address_Aggregate_Fields>;
  nodes: Array<Company_Address>;
};

/** aggregate fields of "company_address" */
export type Company_Address_Aggregate_Fields = {
  __typename?: 'company_address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Address_Max_Fields>;
  min?: Maybe<Company_Address_Min_Fields>;
};


/** aggregate fields of "company_address" */
export type Company_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_address" */
export type Company_Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Address_Max_Order_By>;
  min?: InputMaybe<Company_Address_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_address" */
export type Company_Address_Arr_Rel_Insert_Input = {
  data: Array<Company_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Address_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_address". All fields are combined with a logical 'AND'. */
export type Company_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Address_Bool_Exp>>;
  _not?: InputMaybe<Company_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Address_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  addressId?: InputMaybe<Uuid_Comparison_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isPrimary?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_address" */
export enum Company_Address_Constraint {
  /** unique or primary key constraint */
  CompanyAddressPkey = 'company_address_pkey'
}

/** input type for inserting data into table "company_address" */
export type Company_Address_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  addressId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Address_Max_Fields = {
  __typename?: 'company_address_max_fields';
  addressId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "company_address" */
export type Company_Address_Max_Order_By = {
  addressId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Address_Min_Fields = {
  __typename?: 'company_address_min_fields';
  addressId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "company_address" */
export type Company_Address_Min_Order_By = {
  addressId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_address" */
export type Company_Address_Mutation_Response = {
  __typename?: 'company_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Address>;
};

/** on_conflict condition type for table "company_address" */
export type Company_Address_On_Conflict = {
  constraint: Company_Address_Constraint;
  update_columns?: Array<Company_Address_Update_Column>;
  where?: InputMaybe<Company_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "company_address". */
export type Company_Address_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  addressId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  isPrimary?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_address */
export type Company_Address_Pk_Columns_Input = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};

/** select columns of table "company_address" */
export enum Company_Address_Select_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "company_address" */
export type Company_Address_Set_Input = {
  addressId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "company_address" */
export enum Company_Address_Update_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
};


/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "company_attribute" */
export type Company_Attribute = {
  __typename?: 'company_attribute';
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  entityName: Entity_Enum;
  name: Scalars['String'];
  /** An object relationship */
  type?: Maybe<Entity_Attribute_Type>;
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "company_attribute" */
export type Company_Attribute_Aggregate = {
  __typename?: 'company_attribute_aggregate';
  aggregate?: Maybe<Company_Attribute_Aggregate_Fields>;
  nodes: Array<Company_Attribute>;
};

/** aggregate fields of "company_attribute" */
export type Company_Attribute_Aggregate_Fields = {
  __typename?: 'company_attribute_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Attribute_Max_Fields>;
  min?: Maybe<Company_Attribute_Min_Fields>;
};


/** aggregate fields of "company_attribute" */
export type Company_Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_attribute" */
export type Company_Attribute_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Attribute_Max_Order_By>;
  min?: InputMaybe<Company_Attribute_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_attribute" */
export type Company_Attribute_Arr_Rel_Insert_Input = {
  data: Array<Company_Attribute_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Attribute_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_attribute". All fields are combined with a logical 'AND'. */
export type Company_Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Attribute_Bool_Exp>>;
  _not?: InputMaybe<Company_Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Attribute_Bool_Exp>>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  entityName?: InputMaybe<Entity_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_attribute" */
export enum Company_Attribute_Constraint {
  /** unique or primary key constraint */
  CompanyAttributePkey = 'company_attribute_pkey'
}

/** input type for inserting data into table "company_attribute" */
export type Company_Attribute_Insert_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityName?: InputMaybe<Entity_Enum>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Entity_Attribute_Type_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Attribute_Max_Fields = {
  __typename?: 'company_attribute_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "company_attribute" */
export type Company_Attribute_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Attribute_Min_Fields = {
  __typename?: 'company_attribute_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "company_attribute" */
export type Company_Attribute_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_attribute" */
export type Company_Attribute_Mutation_Response = {
  __typename?: 'company_attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Attribute>;
};

/** on_conflict condition type for table "company_attribute" */
export type Company_Attribute_On_Conflict = {
  constraint: Company_Attribute_Constraint;
  update_columns?: Array<Company_Attribute_Update_Column>;
  where?: InputMaybe<Company_Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "company_attribute". */
export type Company_Attribute_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  entityName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Entity_Attribute_Type_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_attribute */
export type Company_Attribute_Pk_Columns_Input = {
  companyId: Scalars['uuid'];
  name: Scalars['String'];
};

/** select columns of table "company_attribute" */
export enum Company_Attribute_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "company_attribute" */
export type Company_Attribute_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityName?: InputMaybe<Entity_Enum>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "company_attribute" */
export enum Company_Attribute_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  attributes?: InputMaybe<Company_Attribute_Bool_Exp>;
  companyAddresses?: InputMaybe<Company_Address_Bool_Exp>;
  companyCategories?: InputMaybe<Company_Category_Bool_Exp>;
  companyFormResponses?: InputMaybe<Company_Form_Response_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  emails?: InputMaybe<Company_Email_Bool_Exp>;
  formResponses?: InputMaybe<Form_Response_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  legalName?: InputMaybe<String_Comparison_Exp>;
  logoImage?: InputMaybe<File_Bool_Exp>;
  logoImageId?: InputMaybe<Uuid_Comparison_Exp>;
  phoneNumbers?: InputMaybe<Company_Phone_Number_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  websiteUrl?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "company_category" */
export type Company_Category = {
  __typename?: 'company_category';
  /** An object relationship */
  category: Category;
  categoryUrlPath: Scalars['String'];
  companyId: Scalars['uuid'];
};

/** aggregated selection of "company_category" */
export type Company_Category_Aggregate = {
  __typename?: 'company_category_aggregate';
  aggregate?: Maybe<Company_Category_Aggregate_Fields>;
  nodes: Array<Company_Category>;
};

/** aggregate fields of "company_category" */
export type Company_Category_Aggregate_Fields = {
  __typename?: 'company_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Category_Max_Fields>;
  min?: Maybe<Company_Category_Min_Fields>;
};


/** aggregate fields of "company_category" */
export type Company_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_category" */
export type Company_Category_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Category_Max_Order_By>;
  min?: InputMaybe<Company_Category_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_category" */
export type Company_Category_Arr_Rel_Insert_Input = {
  data: Array<Company_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Category_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_category". All fields are combined with a logical 'AND'. */
export type Company_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Category_Bool_Exp>>;
  _not?: InputMaybe<Company_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Category_Bool_Exp>>;
  category?: InputMaybe<Category_Bool_Exp>;
  categoryUrlPath?: InputMaybe<String_Comparison_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_category" */
export enum Company_Category_Constraint {
  /** unique or primary key constraint */
  CompanyCategoryPkey = 'company_category_pkey'
}

/** input type for inserting data into table "company_category" */
export type Company_Category_Insert_Input = {
  category?: InputMaybe<Category_Obj_Rel_Insert_Input>;
  categoryUrlPath?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Company_Category_Max_Fields = {
  __typename?: 'company_category_max_fields';
  categoryUrlPath?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "company_category" */
export type Company_Category_Max_Order_By = {
  categoryUrlPath?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Category_Min_Fields = {
  __typename?: 'company_category_min_fields';
  categoryUrlPath?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "company_category" */
export type Company_Category_Min_Order_By = {
  categoryUrlPath?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_category" */
export type Company_Category_Mutation_Response = {
  __typename?: 'company_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Category>;
};

/** on_conflict condition type for table "company_category" */
export type Company_Category_On_Conflict = {
  constraint: Company_Category_Constraint;
  update_columns?: Array<Company_Category_Update_Column>;
  where?: InputMaybe<Company_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "company_category". */
export type Company_Category_Order_By = {
  category?: InputMaybe<Category_Order_By>;
  categoryUrlPath?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_category */
export type Company_Category_Pk_Columns_Input = {
  categoryUrlPath: Scalars['String'];
  companyId: Scalars['uuid'];
};

/** select columns of table "company_category" */
export enum Company_Category_Select_Column {
  /** column name */
  CategoryUrlPath = 'categoryUrlPath',
  /** column name */
  CompanyId = 'companyId'
}

/** input type for updating data in table "company_category" */
export type Company_Category_Set_Input = {
  categoryUrlPath?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "company_category" */
export enum Company_Category_Update_Column {
  /** column name */
  CategoryUrlPath = 'categoryUrlPath',
  /** column name */
  CompanyId = 'companyId'
}

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint */
  CompaniesPkey = 'companies_pkey'
}

/** columns and relationships of "company_email" */
export type Company_Email = {
  __typename?: 'company_email';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  label: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "company_email" */
export type Company_Email_Aggregate = {
  __typename?: 'company_email_aggregate';
  aggregate?: Maybe<Company_Email_Aggregate_Fields>;
  nodes: Array<Company_Email>;
};

/** aggregate fields of "company_email" */
export type Company_Email_Aggregate_Fields = {
  __typename?: 'company_email_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Email_Max_Fields>;
  min?: Maybe<Company_Email_Min_Fields>;
};


/** aggregate fields of "company_email" */
export type Company_Email_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Email_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_email" */
export type Company_Email_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Email_Max_Order_By>;
  min?: InputMaybe<Company_Email_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_email" */
export type Company_Email_Arr_Rel_Insert_Input = {
  data: Array<Company_Email_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Email_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_email". All fields are combined with a logical 'AND'. */
export type Company_Email_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Email_Bool_Exp>>;
  _not?: InputMaybe<Company_Email_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Email_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_email" */
export enum Company_Email_Constraint {
  /** unique or primary key constraint */
  CompanyEmailEmailKey = 'company_email_email_key',
  /** unique or primary key constraint */
  CompanyEmailPkey = 'company_email_pkey'
}

/** input type for inserting data into table "company_email" */
export type Company_Email_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Email_Max_Fields = {
  __typename?: 'company_email_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "company_email" */
export type Company_Email_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Email_Min_Fields = {
  __typename?: 'company_email_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "company_email" */
export type Company_Email_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_email" */
export type Company_Email_Mutation_Response = {
  __typename?: 'company_email_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Email>;
};

/** on_conflict condition type for table "company_email" */
export type Company_Email_On_Conflict = {
  constraint: Company_Email_Constraint;
  update_columns?: Array<Company_Email_Update_Column>;
  where?: InputMaybe<Company_Email_Bool_Exp>;
};

/** Ordering options when selecting data from "company_email". */
export type Company_Email_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_email */
export type Company_Email_Pk_Columns_Input = {
  companyId: Scalars['uuid'];
  email: Scalars['String'];
};

/** select columns of table "company_email" */
export enum Company_Email_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "company_email" */
export type Company_Email_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "company_email" */
export enum Company_Email_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "company_form_response" */
export type Company_Form_Response = {
  __typename?: 'company_form_response';
  companyId: Scalars['uuid'];
  /** An object relationship */
  formResponse: Form_Response;
  formResponseId: Scalars['uuid'];
};

/** aggregated selection of "company_form_response" */
export type Company_Form_Response_Aggregate = {
  __typename?: 'company_form_response_aggregate';
  aggregate?: Maybe<Company_Form_Response_Aggregate_Fields>;
  nodes: Array<Company_Form_Response>;
};

/** aggregate fields of "company_form_response" */
export type Company_Form_Response_Aggregate_Fields = {
  __typename?: 'company_form_response_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Form_Response_Max_Fields>;
  min?: Maybe<Company_Form_Response_Min_Fields>;
};


/** aggregate fields of "company_form_response" */
export type Company_Form_Response_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_form_response" */
export type Company_Form_Response_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Form_Response_Max_Order_By>;
  min?: InputMaybe<Company_Form_Response_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_form_response" */
export type Company_Form_Response_Arr_Rel_Insert_Input = {
  data: Array<Company_Form_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Form_Response_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_form_response". All fields are combined with a logical 'AND'. */
export type Company_Form_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Form_Response_Bool_Exp>>;
  _not?: InputMaybe<Company_Form_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Form_Response_Bool_Exp>>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  formResponse?: InputMaybe<Form_Response_Bool_Exp>;
  formResponseId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_form_response" */
export enum Company_Form_Response_Constraint {
  /** unique or primary key constraint */
  CompanyFormResponsePkey = 'company_form_response_pkey'
}

/** input type for inserting data into table "company_form_response" */
export type Company_Form_Response_Insert_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  formResponse?: InputMaybe<Form_Response_Obj_Rel_Insert_Input>;
  formResponseId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Company_Form_Response_Max_Fields = {
  __typename?: 'company_form_response_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  formResponseId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "company_form_response" */
export type Company_Form_Response_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  formResponseId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Form_Response_Min_Fields = {
  __typename?: 'company_form_response_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  formResponseId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "company_form_response" */
export type Company_Form_Response_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  formResponseId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_form_response" */
export type Company_Form_Response_Mutation_Response = {
  __typename?: 'company_form_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Form_Response>;
};

/** on_conflict condition type for table "company_form_response" */
export type Company_Form_Response_On_Conflict = {
  constraint: Company_Form_Response_Constraint;
  update_columns?: Array<Company_Form_Response_Update_Column>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "company_form_response". */
export type Company_Form_Response_Order_By = {
  companyId?: InputMaybe<Order_By>;
  formResponse?: InputMaybe<Form_Response_Order_By>;
  formResponseId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_form_response */
export type Company_Form_Response_Pk_Columns_Input = {
  companyId: Scalars['uuid'];
  formResponseId: Scalars['uuid'];
};

/** select columns of table "company_form_response" */
export enum Company_Form_Response_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FormResponseId = 'formResponseId'
}

/** input type for updating data in table "company_form_response" */
export type Company_Form_Response_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  formResponseId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "company_form_response" */
export enum Company_Form_Response_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  FormResponseId = 'formResponseId'
}

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  attributes?: InputMaybe<Company_Attribute_Arr_Rel_Insert_Input>;
  companyAddresses?: InputMaybe<Company_Address_Arr_Rel_Insert_Input>;
  companyCategories?: InputMaybe<Company_Category_Arr_Rel_Insert_Input>;
  companyFormResponses?: InputMaybe<Company_Form_Response_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Company_Email_Arr_Rel_Insert_Input>;
  formResponses?: InputMaybe<Form_Response_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  legalName?: InputMaybe<Scalars['String']>;
  logoImage?: InputMaybe<File_Obj_Rel_Insert_Input>;
  logoImageId?: InputMaybe<Scalars['uuid']>;
  phoneNumbers?: InputMaybe<Company_Phone_Number_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  legalName?: Maybe<Scalars['String']>;
  logoImageId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  legalName?: Maybe<Scalars['String']>;
  logoImageId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  attributes_aggregate?: InputMaybe<Company_Attribute_Aggregate_Order_By>;
  companyAddresses_aggregate?: InputMaybe<Company_Address_Aggregate_Order_By>;
  companyCategories_aggregate?: InputMaybe<Company_Category_Aggregate_Order_By>;
  companyFormResponses_aggregate?: InputMaybe<Company_Form_Response_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  emails_aggregate?: InputMaybe<Company_Email_Aggregate_Order_By>;
  formResponses_aggregate?: InputMaybe<Form_Response_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  legalName?: InputMaybe<Order_By>;
  logoImage?: InputMaybe<File_Order_By>;
  logoImageId?: InputMaybe<Order_By>;
  phoneNumbers_aggregate?: InputMaybe<Company_Phone_Number_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  websiteUrl?: InputMaybe<Order_By>;
};

/** columns and relationships of "company_phone_number" */
export type Company_Phone_Number = {
  __typename?: 'company_phone_number';
  /** An object relationship */
  company: Company;
  companyId: Scalars['uuid'];
  countryCode: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  label?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "company_phone_number" */
export type Company_Phone_Number_Aggregate = {
  __typename?: 'company_phone_number_aggregate';
  aggregate?: Maybe<Company_Phone_Number_Aggregate_Fields>;
  nodes: Array<Company_Phone_Number>;
};

/** aggregate fields of "company_phone_number" */
export type Company_Phone_Number_Aggregate_Fields = {
  __typename?: 'company_phone_number_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Phone_Number_Max_Fields>;
  min?: Maybe<Company_Phone_Number_Min_Fields>;
};


/** aggregate fields of "company_phone_number" */
export type Company_Phone_Number_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Phone_Number_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_phone_number" */
export type Company_Phone_Number_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Phone_Number_Max_Order_By>;
  min?: InputMaybe<Company_Phone_Number_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_phone_number" */
export type Company_Phone_Number_Arr_Rel_Insert_Input = {
  data: Array<Company_Phone_Number_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_Phone_Number_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_phone_number". All fields are combined with a logical 'AND'. */
export type Company_Phone_Number_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Phone_Number_Bool_Exp>>;
  _not?: InputMaybe<Company_Phone_Number_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Phone_Number_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_phone_number" */
export enum Company_Phone_Number_Constraint {
  /** unique or primary key constraint */
  CompanyPhoneNumberPkey = 'company_phone_number_pkey'
}

/** input type for inserting data into table "company_phone_number" */
export type Company_Phone_Number_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['uuid']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  label?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Phone_Number_Max_Fields = {
  __typename?: 'company_phone_number_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "company_phone_number" */
export type Company_Phone_Number_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Phone_Number_Min_Fields = {
  __typename?: 'company_phone_number_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "company_phone_number" */
export type Company_Phone_Number_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company_phone_number" */
export type Company_Phone_Number_Mutation_Response = {
  __typename?: 'company_phone_number_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Phone_Number>;
};

/** on_conflict condition type for table "company_phone_number" */
export type Company_Phone_Number_On_Conflict = {
  constraint: Company_Phone_Number_Constraint;
  update_columns?: Array<Company_Phone_Number_Update_Column>;
  where?: InputMaybe<Company_Phone_Number_Bool_Exp>;
};

/** Ordering options when selecting data from "company_phone_number". */
export type Company_Phone_Number_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_phone_number */
export type Company_Phone_Number_Pk_Columns_Input = {
  companyId: Scalars['uuid'];
  number: Scalars['String'];
};

/** select columns of table "company_phone_number" */
export enum Company_Phone_Number_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Label = 'label',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "company_phone_number" */
export type Company_Phone_Number_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  label?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "company_phone_number" */
export enum Company_Phone_Number_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Label = 'label',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  LegalName = 'legalName',
  /** column name */
  LogoImageId = 'logoImageId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteUrl = 'websiteUrl'
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  displayName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  legalName?: InputMaybe<Scalars['String']>;
  logoImageId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  LegalName = 'legalName',
  /** column name */
  LogoImageId = 'logoImageId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteUrl = 'websiteUrl'
}

/** columns and relationships of "company_user" */
export type Company_User = {
  __typename?: 'company_user';
  companyId: Scalars['uuid'];
  /** An array relationship */
  complianceChecks: Array<User_Compliance_Check>;
  /** An aggregate relationship */
  complianceChecks_aggregate: User_Compliance_Check_Aggregate;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};


/** columns and relationships of "company_user" */
export type Company_UserComplianceChecksArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};


/** columns and relationships of "company_user" */
export type Company_UserComplianceChecks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};

/** aggregated selection of "company_user" */
export type Company_User_Aggregate = {
  __typename?: 'company_user_aggregate';
  aggregate?: Maybe<Company_User_Aggregate_Fields>;
  nodes: Array<Company_User>;
};

/** aggregate fields of "company_user" */
export type Company_User_Aggregate_Fields = {
  __typename?: 'company_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_User_Max_Fields>;
  min?: Maybe<Company_User_Min_Fields>;
};


/** aggregate fields of "company_user" */
export type Company_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "company_user". All fields are combined with a logical 'AND'. */
export type Company_User_Bool_Exp = {
  _and?: InputMaybe<Array<Company_User_Bool_Exp>>;
  _not?: InputMaybe<Company_User_Bool_Exp>;
  _or?: InputMaybe<Array<Company_User_Bool_Exp>>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  complianceChecks?: InputMaybe<User_Compliance_Check_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  middleName?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_user" */
export enum Company_User_Constraint {
  /** unique or primary key constraint */
  CompanyUserPkey = 'company_user_pkey'
}

/** input type for inserting data into table "company_user" */
export type Company_User_Insert_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  complianceChecks?: InputMaybe<User_Compliance_Check_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Company_User_Max_Fields = {
  __typename?: 'company_user_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Company_User_Min_Fields = {
  __typename?: 'company_user_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "company_user" */
export type Company_User_Mutation_Response = {
  __typename?: 'company_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_User>;
};

/** on_conflict condition type for table "company_user" */
export type Company_User_On_Conflict = {
  constraint: Company_User_Constraint;
  update_columns?: Array<Company_User_Update_Column>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};

/** Ordering options when selecting data from "company_user". */
export type Company_User_Order_By = {
  companyId?: InputMaybe<Order_By>;
  complianceChecks_aggregate?: InputMaybe<User_Compliance_Check_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: company_user */
export type Company_User_Pk_Columns_Input = {
  companyId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "company_user" */
export enum Company_User_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "company_user" */
export type Company_User_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "company_user" */
export enum Company_User_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "compliance_account" */
export type Compliance_Account = {
  __typename?: 'compliance_account';
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  dashboards: Array<Compliance_Dashboard>;
  /** An aggregate relationship */
  dashboards_aggregate: Compliance_Dashboard_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Compliance_Account_User>;
  /** An aggregate relationship */
  users_aggregate: Compliance_Account_User_Aggregate;
};


/** columns and relationships of "compliance_account" */
export type Compliance_AccountDashboardsArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
};


/** columns and relationships of "compliance_account" */
export type Compliance_AccountDashboards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
};


/** columns and relationships of "compliance_account" */
export type Compliance_AccountUsersArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_User_Order_By>>;
  where?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};


/** columns and relationships of "compliance_account" */
export type Compliance_AccountUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_User_Order_By>>;
  where?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};

/** aggregated selection of "compliance_account" */
export type Compliance_Account_Aggregate = {
  __typename?: 'compliance_account_aggregate';
  aggregate?: Maybe<Compliance_Account_Aggregate_Fields>;
  nodes: Array<Compliance_Account>;
};

/** aggregate fields of "compliance_account" */
export type Compliance_Account_Aggregate_Fields = {
  __typename?: 'compliance_account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Account_Max_Fields>;
  min?: Maybe<Compliance_Account_Min_Fields>;
};


/** aggregate fields of "compliance_account" */
export type Compliance_Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "compliance_account". All fields are combined with a logical 'AND'. */
export type Compliance_Account_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Account_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Account_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Account_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dashboards?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};

/** unique or primary key constraints on table "compliance_account" */
export enum Compliance_Account_Constraint {
  /** unique or primary key constraint */
  ReveriAccountPkey = 'reveri_account_pkey'
}

/** input type for inserting data into table "compliance_account" */
export type Compliance_Account_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboards?: InputMaybe<Compliance_Dashboard_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<Compliance_Account_User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Compliance_Account_Max_Fields = {
  __typename?: 'compliance_account_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Compliance_Account_Min_Fields = {
  __typename?: 'compliance_account_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "compliance_account" */
export type Compliance_Account_Mutation_Response = {
  __typename?: 'compliance_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Account>;
};

/** on_conflict condition type for table "compliance_account" */
export type Compliance_Account_On_Conflict = {
  constraint: Compliance_Account_Constraint;
  update_columns?: Array<Compliance_Account_Update_Column>;
  where?: InputMaybe<Compliance_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_account". */
export type Compliance_Account_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboards_aggregate?: InputMaybe<Compliance_Dashboard_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Compliance_Account_User_Aggregate_Order_By>;
};

/** primary key columns input for table: compliance_account */
export type Compliance_Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compliance_account" */
export enum Compliance_Account_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_account" */
export type Compliance_Account_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compliance_account" */
export enum Compliance_Account_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "compliance_account_user" */
export type Compliance_Account_User = {
  __typename?: 'compliance_account_user';
  accountId: Scalars['uuid'];
  allDashboards: Scalars['Boolean'];
  createdAt: Scalars['timestamptz'];
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userDashboards: Array<Compliance_Dashboard_User>;
  /** An aggregate relationship */
  userDashboards_aggregate: Compliance_Dashboard_User_Aggregate;
  userId: Scalars['uuid'];
};


/** columns and relationships of "compliance_account_user" */
export type Compliance_Account_UserUserDashboardsArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};


/** columns and relationships of "compliance_account_user" */
export type Compliance_Account_UserUserDashboards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};

/** aggregated selection of "compliance_account_user" */
export type Compliance_Account_User_Aggregate = {
  __typename?: 'compliance_account_user_aggregate';
  aggregate?: Maybe<Compliance_Account_User_Aggregate_Fields>;
  nodes: Array<Compliance_Account_User>;
};

/** aggregate fields of "compliance_account_user" */
export type Compliance_Account_User_Aggregate_Fields = {
  __typename?: 'compliance_account_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Account_User_Max_Fields>;
  min?: Maybe<Compliance_Account_User_Min_Fields>;
};


/** aggregate fields of "compliance_account_user" */
export type Compliance_Account_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Account_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_account_user" */
export type Compliance_Account_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Account_User_Max_Order_By>;
  min?: InputMaybe<Compliance_Account_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_account_user" */
export type Compliance_Account_User_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Account_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Account_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compliance_account_user". All fields are combined with a logical 'AND'. */
export type Compliance_Account_User_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Account_User_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Account_User_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Account_User_Bool_Exp>>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  allDashboards?: InputMaybe<Boolean_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userDashboards?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_account_user" */
export enum Compliance_Account_User_Constraint {
  /** unique or primary key constraint */
  ComplianceAccountUserPkey = 'compliance_account_user_pkey'
}

/** input type for inserting data into table "compliance_account_user" */
export type Compliance_Account_User_Insert_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  allDashboards?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userDashboards?: InputMaybe<Compliance_Dashboard_User_Arr_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Compliance_Account_User_Max_Fields = {
  __typename?: 'compliance_account_user_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "compliance_account_user" */
export type Compliance_Account_User_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Account_User_Min_Fields = {
  __typename?: 'compliance_account_user_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "compliance_account_user" */
export type Compliance_Account_User_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_account_user" */
export type Compliance_Account_User_Mutation_Response = {
  __typename?: 'compliance_account_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Account_User>;
};

/** on_conflict condition type for table "compliance_account_user" */
export type Compliance_Account_User_On_Conflict = {
  constraint: Compliance_Account_User_Constraint;
  update_columns?: Array<Compliance_Account_User_Update_Column>;
  where?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_account_user". */
export type Compliance_Account_User_Order_By = {
  accountId?: InputMaybe<Order_By>;
  allDashboards?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userDashboards_aggregate?: InputMaybe<Compliance_Dashboard_User_Aggregate_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_account_user */
export type Compliance_Account_User_Pk_Columns_Input = {
  accountId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "compliance_account_user" */
export enum Compliance_Account_User_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AllDashboards = 'allDashboards',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "compliance_account_user" */
export type Compliance_Account_User_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  allDashboards?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "compliance_account_user" */
export enum Compliance_Account_User_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  AllDashboards = 'allDashboards',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** columns and relationships of "compliance_check" */
export type Compliance_Check = {
  __typename?: 'compliance_check';
  createdAt: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  entityId: Scalars['uuid'];
  /** compliance_entity_person, compliance_entity_company, user, or company */
  entityName: Scalars['String'];
  id: Scalars['uuid'];
  isClean: Scalars['Boolean'];
  /** compliance_account, company or user */
  ownerEntityName: Scalars['String'];
  ownerId: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  type: Compliance_Check_Type;
  typeName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "compliance_check" */
export type Compliance_CheckDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "compliance_check" */
export type Compliance_Check_Aggregate = {
  __typename?: 'compliance_check_aggregate';
  aggregate?: Maybe<Compliance_Check_Aggregate_Fields>;
  nodes: Array<Compliance_Check>;
};

/** aggregate fields of "compliance_check" */
export type Compliance_Check_Aggregate_Fields = {
  __typename?: 'compliance_check_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Check_Max_Fields>;
  min?: Maybe<Compliance_Check_Min_Fields>;
};


/** aggregate fields of "compliance_check" */
export type Compliance_Check_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Check_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Compliance_Check_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "compliance_check". All fields are combined with a logical 'AND'. */
export type Compliance_Check_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Check_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Check_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Check_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  entityId?: InputMaybe<Uuid_Comparison_Exp>;
  entityName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isClean?: InputMaybe<Boolean_Comparison_Exp>;
  ownerEntityName?: InputMaybe<String_Comparison_Exp>;
  ownerId?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
  typeName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_check" */
export enum Compliance_Check_Constraint {
  /** unique or primary key constraint */
  ComplianceCheckPkey = 'compliance_check_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Compliance_Check_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Compliance_Check_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Compliance_Check_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "compliance_check" */
export type Compliance_Check_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['uuid']>;
  /** compliance_entity_person, compliance_entity_company, user, or company */
  entityName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isClean?: InputMaybe<Scalars['Boolean']>;
  /** compliance_account, company or user */
  ownerEntityName?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Compliance_Check_Type_Obj_Rel_Insert_Input>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compliance_Check_Max_Fields = {
  __typename?: 'compliance_check_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  entityId?: Maybe<Scalars['uuid']>;
  /** compliance_entity_person, compliance_entity_company, user, or company */
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** compliance_account, company or user */
  ownerEntityName?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Compliance_Check_Min_Fields = {
  __typename?: 'compliance_check_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  entityId?: Maybe<Scalars['uuid']>;
  /** compliance_entity_person, compliance_entity_company, user, or company */
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** compliance_account, company or user */
  ownerEntityName?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "compliance_check" */
export type Compliance_Check_Mutation_Response = {
  __typename?: 'compliance_check_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Check>;
};

/** on_conflict condition type for table "compliance_check" */
export type Compliance_Check_On_Conflict = {
  constraint: Compliance_Check_Constraint;
  update_columns?: Array<Compliance_Check_Update_Column>;
  where?: InputMaybe<Compliance_Check_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_check". */
export type Compliance_Check_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  entityName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isClean?: InputMaybe<Order_By>;
  ownerEntityName?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Compliance_Check_Type_Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_check */
export type Compliance_Check_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Compliance_Check_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "compliance_check" */
export enum Compliance_Check_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Id = 'id',
  /** column name */
  IsClean = 'isClean',
  /** column name */
  OwnerEntityName = 'ownerEntityName',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Status = 'status',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_check" */
export type Compliance_Check_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  entityId?: InputMaybe<Scalars['uuid']>;
  /** compliance_entity_person, compliance_entity_company, user, or company */
  entityName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isClean?: InputMaybe<Scalars['Boolean']>;
  /** compliance_account, company or user */
  ownerEntityName?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "compliance_check_type" */
export type Compliance_Check_Type = {
  __typename?: 'compliance_check_type';
  createdAt: Scalars['timestamptz'];
  isCompanyCheck: Scalars['Boolean'];
  isPersonCheck: Scalars['Boolean'];
  label: Scalars['String'];
  meta?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "compliance_check_type" */
export type Compliance_Check_TypeMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "compliance_check_type" */
export type Compliance_Check_Type_Aggregate = {
  __typename?: 'compliance_check_type_aggregate';
  aggregate?: Maybe<Compliance_Check_Type_Aggregate_Fields>;
  nodes: Array<Compliance_Check_Type>;
};

/** aggregate fields of "compliance_check_type" */
export type Compliance_Check_Type_Aggregate_Fields = {
  __typename?: 'compliance_check_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Check_Type_Max_Fields>;
  min?: Maybe<Compliance_Check_Type_Min_Fields>;
};


/** aggregate fields of "compliance_check_type" */
export type Compliance_Check_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Check_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Compliance_Check_Type_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "compliance_check_type". All fields are combined with a logical 'AND'. */
export type Compliance_Check_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Check_Type_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Check_Type_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isCompanyCheck?: InputMaybe<Boolean_Comparison_Exp>;
  isPersonCheck?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_check_type" */
export enum Compliance_Check_Type_Constraint {
  /** unique or primary key constraint */
  UserComplianceCheckTypePkey = 'user_compliance_check_type_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Compliance_Check_Type_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Compliance_Check_Type_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Compliance_Check_Type_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "compliance_check_type" */
export type Compliance_Check_Type_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isCompanyCheck?: InputMaybe<Scalars['Boolean']>;
  isPersonCheck?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compliance_Check_Type_Max_Fields = {
  __typename?: 'compliance_check_type_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Compliance_Check_Type_Min_Fields = {
  __typename?: 'compliance_check_type_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "compliance_check_type" */
export type Compliance_Check_Type_Mutation_Response = {
  __typename?: 'compliance_check_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Check_Type>;
};

/** input type for inserting object relation for remote table "compliance_check_type" */
export type Compliance_Check_Type_Obj_Rel_Insert_Input = {
  data: Compliance_Check_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Check_Type_On_Conflict>;
};

/** on_conflict condition type for table "compliance_check_type" */
export type Compliance_Check_Type_On_Conflict = {
  constraint: Compliance_Check_Type_Constraint;
  update_columns?: Array<Compliance_Check_Type_Update_Column>;
  where?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_check_type". */
export type Compliance_Check_Type_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  isCompanyCheck?: InputMaybe<Order_By>;
  isPersonCheck?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_check_type */
export type Compliance_Check_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Compliance_Check_Type_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "compliance_check_type" */
export enum Compliance_Check_Type_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsCompanyCheck = 'isCompanyCheck',
  /** column name */
  IsPersonCheck = 'isPersonCheck',
  /** column name */
  Label = 'label',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_check_type" */
export type Compliance_Check_Type_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isCompanyCheck?: InputMaybe<Scalars['Boolean']>;
  isPersonCheck?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compliance_check_type" */
export enum Compliance_Check_Type_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsCompanyCheck = 'isCompanyCheck',
  /** column name */
  IsPersonCheck = 'isPersonCheck',
  /** column name */
  Label = 'label',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** update columns of table "compliance_check" */
export enum Compliance_Check_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Id = 'id',
  /** column name */
  IsClean = 'isClean',
  /** column name */
  OwnerEntityName = 'ownerEntityName',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  Status = 'status',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "compliance_dashboard" */
export type Compliance_Dashboard = {
  __typename?: 'compliance_dashboard';
  accountId: Scalars['uuid'];
  /** An array relationship */
  checks: Array<Compliance_Dashboard_Check_Type>;
  /** An aggregate relationship */
  checks_aggregate: Compliance_Dashboard_Check_Type_Aggregate;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  paymentAmount: Scalars['float8'];
  paymentType: Scalars['String'];
  /** An array relationship */
  people: Array<Compliance_Entity_Person>;
  /** An aggregate relationship */
  people_aggregate: Compliance_Entity_Person_Aggregate;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Compliance_Dashboard_User>;
  /** An aggregate relationship */
  users_aggregate: Compliance_Dashboard_User_Aggregate;
};


/** columns and relationships of "compliance_dashboard" */
export type Compliance_DashboardChecksArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
};


/** columns and relationships of "compliance_dashboard" */
export type Compliance_DashboardChecks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
};


/** columns and relationships of "compliance_dashboard" */
export type Compliance_DashboardPeopleArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
};


/** columns and relationships of "compliance_dashboard" */
export type Compliance_DashboardPeople_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
};


/** columns and relationships of "compliance_dashboard" */
export type Compliance_DashboardUsersArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};


/** columns and relationships of "compliance_dashboard" */
export type Compliance_DashboardUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};

/** aggregated selection of "compliance_dashboard" */
export type Compliance_Dashboard_Aggregate = {
  __typename?: 'compliance_dashboard_aggregate';
  aggregate?: Maybe<Compliance_Dashboard_Aggregate_Fields>;
  nodes: Array<Compliance_Dashboard>;
};

/** aggregate fields of "compliance_dashboard" */
export type Compliance_Dashboard_Aggregate_Fields = {
  __typename?: 'compliance_dashboard_aggregate_fields';
  avg?: Maybe<Compliance_Dashboard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Compliance_Dashboard_Max_Fields>;
  min?: Maybe<Compliance_Dashboard_Min_Fields>;
  stddev?: Maybe<Compliance_Dashboard_Stddev_Fields>;
  stddev_pop?: Maybe<Compliance_Dashboard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Compliance_Dashboard_Stddev_Samp_Fields>;
  sum?: Maybe<Compliance_Dashboard_Sum_Fields>;
  var_pop?: Maybe<Compliance_Dashboard_Var_Pop_Fields>;
  var_samp?: Maybe<Compliance_Dashboard_Var_Samp_Fields>;
  variance?: Maybe<Compliance_Dashboard_Variance_Fields>;
};


/** aggregate fields of "compliance_dashboard" */
export type Compliance_Dashboard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Dashboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_dashboard" */
export type Compliance_Dashboard_Aggregate_Order_By = {
  avg?: InputMaybe<Compliance_Dashboard_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Dashboard_Max_Order_By>;
  min?: InputMaybe<Compliance_Dashboard_Min_Order_By>;
  stddev?: InputMaybe<Compliance_Dashboard_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Compliance_Dashboard_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Compliance_Dashboard_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Compliance_Dashboard_Sum_Order_By>;
  var_pop?: InputMaybe<Compliance_Dashboard_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Compliance_Dashboard_Var_Samp_Order_By>;
  variance?: InputMaybe<Compliance_Dashboard_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_dashboard" */
export type Compliance_Dashboard_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Dashboard_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Dashboard_On_Conflict>;
};

/** aggregate avg on columns */
export type Compliance_Dashboard_Avg_Fields = {
  __typename?: 'compliance_dashboard_avg_fields';
  paymentAmount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Avg_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "compliance_dashboard". All fields are combined with a logical 'AND'. */
export type Compliance_Dashboard_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Dashboard_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Dashboard_Bool_Exp>>;
  accountId?: InputMaybe<Uuid_Comparison_Exp>;
  checks?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  paymentAmount?: InputMaybe<Float8_Comparison_Exp>;
  paymentType?: InputMaybe<String_Comparison_Exp>;
  people?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};

/** columns and relationships of "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type = {
  __typename?: 'compliance_dashboard_check_type';
  createdAt: Scalars['timestamptz'];
  dashboardId: Scalars['uuid'];
  frequency: Scalars['String'];
  id: Scalars['uuid'];
  seats: Scalars['Int'];
  /** An object relationship */
  type: Compliance_Check_Type;
  typeName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Aggregate = {
  __typename?: 'compliance_dashboard_check_type_aggregate';
  aggregate?: Maybe<Compliance_Dashboard_Check_Type_Aggregate_Fields>;
  nodes: Array<Compliance_Dashboard_Check_Type>;
};

/** aggregate fields of "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Aggregate_Fields = {
  __typename?: 'compliance_dashboard_check_type_aggregate_fields';
  avg?: Maybe<Compliance_Dashboard_Check_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Compliance_Dashboard_Check_Type_Max_Fields>;
  min?: Maybe<Compliance_Dashboard_Check_Type_Min_Fields>;
  stddev?: Maybe<Compliance_Dashboard_Check_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Compliance_Dashboard_Check_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Compliance_Dashboard_Check_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Compliance_Dashboard_Check_Type_Sum_Fields>;
  var_pop?: Maybe<Compliance_Dashboard_Check_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Compliance_Dashboard_Check_Type_Var_Samp_Fields>;
  variance?: Maybe<Compliance_Dashboard_Check_Type_Variance_Fields>;
};


/** aggregate fields of "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Aggregate_Order_By = {
  avg?: InputMaybe<Compliance_Dashboard_Check_Type_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Dashboard_Check_Type_Max_Order_By>;
  min?: InputMaybe<Compliance_Dashboard_Check_Type_Min_Order_By>;
  stddev?: InputMaybe<Compliance_Dashboard_Check_Type_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Compliance_Dashboard_Check_Type_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Compliance_Dashboard_Check_Type_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Compliance_Dashboard_Check_Type_Sum_Order_By>;
  var_pop?: InputMaybe<Compliance_Dashboard_Check_Type_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Compliance_Dashboard_Check_Type_Var_Samp_Order_By>;
  variance?: InputMaybe<Compliance_Dashboard_Check_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Dashboard_Check_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Dashboard_Check_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Compliance_Dashboard_Check_Type_Avg_Fields = {
  __typename?: 'compliance_dashboard_check_type_avg_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Avg_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "compliance_dashboard_check_type". All fields are combined with a logical 'AND'. */
export type Compliance_Dashboard_Check_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dashboardId?: InputMaybe<Uuid_Comparison_Exp>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  seats?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
  typeName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_dashboard_check_type" */
export enum Compliance_Dashboard_Check_Type_Constraint {
  /** unique or primary key constraint */
  ComplianceDashboardCheckPkey = 'compliance_dashboard_check_pkey'
}

/** input type for incrementing numeric columns in table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Inc_Input = {
  seats?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  seats?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Compliance_Check_Type_Obj_Rel_Insert_Input>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compliance_Dashboard_Check_Type_Max_Fields = {
  __typename?: 'compliance_dashboard_check_type_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  seats?: Maybe<Scalars['Int']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seats?: InputMaybe<Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Dashboard_Check_Type_Min_Fields = {
  __typename?: 'compliance_dashboard_check_type_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  seats?: Maybe<Scalars['Int']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seats?: InputMaybe<Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Mutation_Response = {
  __typename?: 'compliance_dashboard_check_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Dashboard_Check_Type>;
};

/** on_conflict condition type for table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_On_Conflict = {
  constraint: Compliance_Dashboard_Check_Type_Constraint;
  update_columns?: Array<Compliance_Dashboard_Check_Type_Update_Column>;
  where?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_dashboard_check_type". */
export type Compliance_Dashboard_Check_Type_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  seats?: InputMaybe<Order_By>;
  type?: InputMaybe<Compliance_Check_Type_Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_dashboard_check_type */
export type Compliance_Dashboard_Check_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compliance_dashboard_check_type" */
export enum Compliance_Dashboard_Check_Type_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Seats = 'seats',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  frequency?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  seats?: InputMaybe<Scalars['Int']>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Compliance_Dashboard_Check_Type_Stddev_Fields = {
  __typename?: 'compliance_dashboard_check_type_stddev_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Stddev_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Compliance_Dashboard_Check_Type_Stddev_Pop_Fields = {
  __typename?: 'compliance_dashboard_check_type_stddev_pop_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Stddev_Pop_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Compliance_Dashboard_Check_Type_Stddev_Samp_Fields = {
  __typename?: 'compliance_dashboard_check_type_stddev_samp_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Stddev_Samp_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Compliance_Dashboard_Check_Type_Sum_Fields = {
  __typename?: 'compliance_dashboard_check_type_sum_fields';
  seats?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Sum_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** update columns of table "compliance_dashboard_check_type" */
export enum Compliance_Dashboard_Check_Type_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  Seats = 'seats',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Compliance_Dashboard_Check_Type_Var_Pop_Fields = {
  __typename?: 'compliance_dashboard_check_type_var_pop_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Var_Pop_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Compliance_Dashboard_Check_Type_Var_Samp_Fields = {
  __typename?: 'compliance_dashboard_check_type_var_samp_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Var_Samp_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Compliance_Dashboard_Check_Type_Variance_Fields = {
  __typename?: 'compliance_dashboard_check_type_variance_fields';
  seats?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "compliance_dashboard_check_type" */
export type Compliance_Dashboard_Check_Type_Variance_Order_By = {
  seats?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "compliance_dashboard" */
export enum Compliance_Dashboard_Constraint {
  /** unique or primary key constraint */
  ComplianceDashboardPkey = 'compliance_dashboard_pkey'
}

/** input type for incrementing numeric columns in table "compliance_dashboard" */
export type Compliance_Dashboard_Inc_Input = {
  paymentAmount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "compliance_dashboard" */
export type Compliance_Dashboard_Insert_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  checks?: InputMaybe<Compliance_Dashboard_Check_Type_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  paymentAmount?: InputMaybe<Scalars['float8']>;
  paymentType?: InputMaybe<Scalars['String']>;
  people?: InputMaybe<Compliance_Entity_Person_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<Compliance_Dashboard_User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Compliance_Dashboard_Max_Fields = {
  __typename?: 'compliance_dashboard_max_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['float8']>;
  paymentType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Max_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paymentAmount?: InputMaybe<Order_By>;
  paymentType?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Dashboard_Min_Fields = {
  __typename?: 'compliance_dashboard_min_fields';
  accountId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['float8']>;
  paymentType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Min_Order_By = {
  accountId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paymentAmount?: InputMaybe<Order_By>;
  paymentType?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_dashboard" */
export type Compliance_Dashboard_Mutation_Response = {
  __typename?: 'compliance_dashboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Dashboard>;
};

/** input type for inserting object relation for remote table "compliance_dashboard" */
export type Compliance_Dashboard_Obj_Rel_Insert_Input = {
  data: Compliance_Dashboard_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Dashboard_On_Conflict>;
};

/** on_conflict condition type for table "compliance_dashboard" */
export type Compliance_Dashboard_On_Conflict = {
  constraint: Compliance_Dashboard_Constraint;
  update_columns?: Array<Compliance_Dashboard_Update_Column>;
  where?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_dashboard". */
export type Compliance_Dashboard_Order_By = {
  accountId?: InputMaybe<Order_By>;
  checks_aggregate?: InputMaybe<Compliance_Dashboard_Check_Type_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  paymentAmount?: InputMaybe<Order_By>;
  paymentType?: InputMaybe<Order_By>;
  people_aggregate?: InputMaybe<Compliance_Entity_Person_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Compliance_Dashboard_User_Aggregate_Order_By>;
};

/** primary key columns input for table: compliance_dashboard */
export type Compliance_Dashboard_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compliance_dashboard" */
export enum Compliance_Dashboard_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentAmount = 'paymentAmount',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_dashboard" */
export type Compliance_Dashboard_Set_Input = {
  accountId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  paymentAmount?: InputMaybe<Scalars['float8']>;
  paymentType?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Compliance_Dashboard_Stddev_Fields = {
  __typename?: 'compliance_dashboard_stddev_fields';
  paymentAmount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Stddev_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Compliance_Dashboard_Stddev_Pop_Fields = {
  __typename?: 'compliance_dashboard_stddev_pop_fields';
  paymentAmount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Stddev_Pop_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Compliance_Dashboard_Stddev_Samp_Fields = {
  __typename?: 'compliance_dashboard_stddev_samp_fields';
  paymentAmount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Stddev_Samp_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Compliance_Dashboard_Sum_Fields = {
  __typename?: 'compliance_dashboard_sum_fields';
  paymentAmount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Sum_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** update columns of table "compliance_dashboard" */
export enum Compliance_Dashboard_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentAmount = 'paymentAmount',
  /** column name */
  PaymentType = 'paymentType',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "compliance_dashboard_user" */
export type Compliance_Dashboard_User = {
  __typename?: 'compliance_dashboard_user';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  dashboard: Compliance_Dashboard;
  dashboardId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Aggregate = {
  __typename?: 'compliance_dashboard_user_aggregate';
  aggregate?: Maybe<Compliance_Dashboard_User_Aggregate_Fields>;
  nodes: Array<Compliance_Dashboard_User>;
};

/** aggregate fields of "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Aggregate_Fields = {
  __typename?: 'compliance_dashboard_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Dashboard_User_Max_Fields>;
  min?: Maybe<Compliance_Dashboard_User_Min_Fields>;
};


/** aggregate fields of "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Dashboard_User_Max_Order_By>;
  min?: InputMaybe<Compliance_Dashboard_User_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Dashboard_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Dashboard_User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compliance_dashboard_user". All fields are combined with a logical 'AND'. */
export type Compliance_Dashboard_User_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Dashboard_User_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Dashboard_User_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dashboard?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
  dashboardId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_dashboard_user" */
export enum Compliance_Dashboard_User_Constraint {
  /** unique or primary key constraint */
  ComplianceDashboardUserPkey = 'compliance_dashboard_user_pkey'
}

/** input type for inserting data into table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboard?: InputMaybe<Compliance_Dashboard_Obj_Rel_Insert_Input>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Compliance_Dashboard_User_Max_Fields = {
  __typename?: 'compliance_dashboard_user_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Dashboard_User_Min_Fields = {
  __typename?: 'compliance_dashboard_user_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Mutation_Response = {
  __typename?: 'compliance_dashboard_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Dashboard_User>;
};

/** on_conflict condition type for table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_On_Conflict = {
  constraint: Compliance_Dashboard_User_Constraint;
  update_columns?: Array<Compliance_Dashboard_User_Update_Column>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_dashboard_user". */
export type Compliance_Dashboard_User_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboard?: InputMaybe<Compliance_Dashboard_Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_dashboard_user */
export type Compliance_Dashboard_User_Pk_Columns_Input = {
  dashboardId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "compliance_dashboard_user" */
export enum Compliance_Dashboard_User_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "compliance_dashboard_user" */
export type Compliance_Dashboard_User_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "compliance_dashboard_user" */
export enum Compliance_Dashboard_User_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Compliance_Dashboard_Var_Pop_Fields = {
  __typename?: 'compliance_dashboard_var_pop_fields';
  paymentAmount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Var_Pop_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Compliance_Dashboard_Var_Samp_Fields = {
  __typename?: 'compliance_dashboard_var_samp_fields';
  paymentAmount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Var_Samp_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Compliance_Dashboard_Variance_Fields = {
  __typename?: 'compliance_dashboard_variance_fields';
  paymentAmount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "compliance_dashboard" */
export type Compliance_Dashboard_Variance_Order_By = {
  paymentAmount?: InputMaybe<Order_By>;
};

/** columns and relationships of "compliance_entity_company" */
export type Compliance_Entity_Company = {
  __typename?: 'compliance_entity_company';
  /** An array relationship */
  companyAddresses: Array<Compliance_Entity_Company_Address>;
  /** An aggregate relationship */
  companyAddresses_aggregate: Compliance_Entity_Company_Address_Aggregate;
  createdAt: Scalars['timestamptz'];
  dashboardId: Scalars['uuid'];
  id: Scalars['uuid'];
  legalName: Scalars['String'];
  nationalIdType: Scalars['String'];
  nationalIdValue: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "compliance_entity_company" */
export type Compliance_Entity_CompanyCompanyAddressesArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
};


/** columns and relationships of "compliance_entity_company" */
export type Compliance_Entity_CompanyCompanyAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
};

/** columns and relationships of "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address = {
  __typename?: 'compliance_entity_company_address';
  /** An object relationship */
  address: Address;
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Aggregate = {
  __typename?: 'compliance_entity_company_address_aggregate';
  aggregate?: Maybe<Compliance_Entity_Company_Address_Aggregate_Fields>;
  nodes: Array<Compliance_Entity_Company_Address>;
};

/** aggregate fields of "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Aggregate_Fields = {
  __typename?: 'compliance_entity_company_address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Entity_Company_Address_Max_Fields>;
  min?: Maybe<Compliance_Entity_Company_Address_Min_Fields>;
};


/** aggregate fields of "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Entity_Company_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Entity_Company_Address_Max_Order_By>;
  min?: InputMaybe<Compliance_Entity_Company_Address_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Entity_Company_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Entity_Company_Address_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compliance_entity_company_address". All fields are combined with a logical 'AND'. */
export type Compliance_Entity_Company_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Entity_Company_Address_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Entity_Company_Address_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  addressId?: InputMaybe<Uuid_Comparison_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_entity_company_address" */
export enum Compliance_Entity_Company_Address_Constraint {
  /** unique or primary key constraint */
  ComplianceEntityCompanyAddressPkey = 'compliance_entity_company_address_pkey'
}

/** input type for inserting data into table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  addressId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compliance_Entity_Company_Address_Max_Fields = {
  __typename?: 'compliance_entity_company_address_max_fields';
  addressId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Max_Order_By = {
  addressId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Entity_Company_Address_Min_Fields = {
  __typename?: 'compliance_entity_company_address_min_fields';
  addressId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Min_Order_By = {
  addressId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Mutation_Response = {
  __typename?: 'compliance_entity_company_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Entity_Company_Address>;
};

/** on_conflict condition type for table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_On_Conflict = {
  constraint: Compliance_Entity_Company_Address_Constraint;
  update_columns?: Array<Compliance_Entity_Company_Address_Update_Column>;
  where?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_entity_company_address". */
export type Compliance_Entity_Company_Address_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  addressId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_entity_company_address */
export type Compliance_Entity_Company_Address_Pk_Columns_Input = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};

/** select columns of table "compliance_entity_company_address" */
export enum Compliance_Entity_Company_Address_Select_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_entity_company_address" */
export type Compliance_Entity_Company_Address_Set_Input = {
  addressId?: InputMaybe<Scalars['uuid']>;
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compliance_entity_company_address" */
export enum Compliance_Entity_Company_Address_Update_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregated selection of "compliance_entity_company" */
export type Compliance_Entity_Company_Aggregate = {
  __typename?: 'compliance_entity_company_aggregate';
  aggregate?: Maybe<Compliance_Entity_Company_Aggregate_Fields>;
  nodes: Array<Compliance_Entity_Company>;
};

/** aggregate fields of "compliance_entity_company" */
export type Compliance_Entity_Company_Aggregate_Fields = {
  __typename?: 'compliance_entity_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Entity_Company_Max_Fields>;
  min?: Maybe<Compliance_Entity_Company_Min_Fields>;
};


/** aggregate fields of "compliance_entity_company" */
export type Compliance_Entity_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Entity_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "compliance_entity_company". All fields are combined with a logical 'AND'. */
export type Compliance_Entity_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Entity_Company_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Entity_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Entity_Company_Bool_Exp>>;
  companyAddresses?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dashboardId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  legalName?: InputMaybe<String_Comparison_Exp>;
  nationalIdType?: InputMaybe<String_Comparison_Exp>;
  nationalIdValue?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_entity_company" */
export enum Compliance_Entity_Company_Constraint {
  /** unique or primary key constraint */
  ComplianceEntityCompanyPkey = 'compliance_entity_company_pkey'
}

/** input type for inserting data into table "compliance_entity_company" */
export type Compliance_Entity_Company_Insert_Input = {
  companyAddresses?: InputMaybe<Compliance_Entity_Company_Address_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  legalName?: InputMaybe<Scalars['String']>;
  nationalIdType?: InputMaybe<Scalars['String']>;
  nationalIdValue?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compliance_Entity_Company_Max_Fields = {
  __typename?: 'compliance_entity_company_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  legalName?: Maybe<Scalars['String']>;
  nationalIdType?: Maybe<Scalars['String']>;
  nationalIdValue?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Compliance_Entity_Company_Min_Fields = {
  __typename?: 'compliance_entity_company_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  legalName?: Maybe<Scalars['String']>;
  nationalIdType?: Maybe<Scalars['String']>;
  nationalIdValue?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "compliance_entity_company" */
export type Compliance_Entity_Company_Mutation_Response = {
  __typename?: 'compliance_entity_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Entity_Company>;
};

/** on_conflict condition type for table "compliance_entity_company" */
export type Compliance_Entity_Company_On_Conflict = {
  constraint: Compliance_Entity_Company_Constraint;
  update_columns?: Array<Compliance_Entity_Company_Update_Column>;
  where?: InputMaybe<Compliance_Entity_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_entity_company". */
export type Compliance_Entity_Company_Order_By = {
  companyAddresses_aggregate?: InputMaybe<Compliance_Entity_Company_Address_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  legalName?: InputMaybe<Order_By>;
  nationalIdType?: InputMaybe<Order_By>;
  nationalIdValue?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_entity_company */
export type Compliance_Entity_Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compliance_entity_company" */
export enum Compliance_Entity_Company_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  Id = 'id',
  /** column name */
  LegalName = 'legalName',
  /** column name */
  NationalIdType = 'nationalIdType',
  /** column name */
  NationalIdValue = 'nationalIdValue',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_entity_company" */
export type Compliance_Entity_Company_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  legalName?: InputMaybe<Scalars['String']>;
  nationalIdType?: InputMaybe<Scalars['String']>;
  nationalIdValue?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compliance_entity_company" */
export enum Compliance_Entity_Company_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  Id = 'id',
  /** column name */
  LegalName = 'legalName',
  /** column name */
  NationalIdType = 'nationalIdType',
  /** column name */
  NationalIdValue = 'nationalIdValue',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "compliance_entity_person" */
export type Compliance_Entity_Person = {
  __typename?: 'compliance_entity_person';
  /** An array relationship */
  addresses: Array<Compliance_Entity_Person_Address>;
  /** An aggregate relationship */
  addresses_aggregate: Compliance_Entity_Person_Address_Aggregate;
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  dashboard: Compliance_Dashboard;
  dashboardId: Scalars['uuid'];
  dateOfBirth: Scalars['timestamptz'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
  /** An array relationship */
  nationalIds: Array<Compliance_Entity_Person_National_Id>;
  /** An aggregate relationship */
  nationalIds_aggregate: Compliance_Entity_Person_National_Id_Aggregate;
  phoneNumber?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "compliance_entity_person" */
export type Compliance_Entity_PersonAddressesArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
};


/** columns and relationships of "compliance_entity_person" */
export type Compliance_Entity_PersonAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
};


/** columns and relationships of "compliance_entity_person" */
export type Compliance_Entity_PersonNationalIdsArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
};


/** columns and relationships of "compliance_entity_person" */
export type Compliance_Entity_PersonNationalIds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
};

/** columns and relationships of "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address = {
  __typename?: 'compliance_entity_person_address';
  /** An object relationship */
  address: Address;
  addressId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  personId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Aggregate = {
  __typename?: 'compliance_entity_person_address_aggregate';
  aggregate?: Maybe<Compliance_Entity_Person_Address_Aggregate_Fields>;
  nodes: Array<Compliance_Entity_Person_Address>;
};

/** aggregate fields of "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Aggregate_Fields = {
  __typename?: 'compliance_entity_person_address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Entity_Person_Address_Max_Fields>;
  min?: Maybe<Compliance_Entity_Person_Address_Min_Fields>;
};


/** aggregate fields of "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Entity_Person_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Entity_Person_Address_Max_Order_By>;
  min?: InputMaybe<Compliance_Entity_Person_Address_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Entity_Person_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Entity_Person_Address_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compliance_entity_person_address". All fields are combined with a logical 'AND'. */
export type Compliance_Entity_Person_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Entity_Person_Address_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Entity_Person_Address_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  addressId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  personId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_entity_person_address" */
export enum Compliance_Entity_Person_Address_Constraint {
  /** unique or primary key constraint */
  ComplianceEntityPersonAddressPkey = 'compliance_entity_person_address_pkey'
}

/** input type for inserting data into table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  addressId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  personId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compliance_Entity_Person_Address_Max_Fields = {
  __typename?: 'compliance_entity_person_address_max_fields';
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  personId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Max_Order_By = {
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Entity_Person_Address_Min_Fields = {
  __typename?: 'compliance_entity_person_address_min_fields';
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  personId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Min_Order_By = {
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Mutation_Response = {
  __typename?: 'compliance_entity_person_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Entity_Person_Address>;
};

/** on_conflict condition type for table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_On_Conflict = {
  constraint: Compliance_Entity_Person_Address_Constraint;
  update_columns?: Array<Compliance_Entity_Person_Address_Update_Column>;
  where?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_entity_person_address". */
export type Compliance_Entity_Person_Address_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_entity_person_address */
export type Compliance_Entity_Person_Address_Pk_Columns_Input = {
  addressId: Scalars['uuid'];
  personId: Scalars['uuid'];
};

/** select columns of table "compliance_entity_person_address" */
export enum Compliance_Entity_Person_Address_Select_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PersonId = 'personId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_entity_person_address" */
export type Compliance_Entity_Person_Address_Set_Input = {
  addressId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  personId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compliance_entity_person_address" */
export enum Compliance_Entity_Person_Address_Update_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  PersonId = 'personId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregated selection of "compliance_entity_person" */
export type Compliance_Entity_Person_Aggregate = {
  __typename?: 'compliance_entity_person_aggregate';
  aggregate?: Maybe<Compliance_Entity_Person_Aggregate_Fields>;
  nodes: Array<Compliance_Entity_Person>;
};

/** aggregate fields of "compliance_entity_person" */
export type Compliance_Entity_Person_Aggregate_Fields = {
  __typename?: 'compliance_entity_person_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Entity_Person_Max_Fields>;
  min?: Maybe<Compliance_Entity_Person_Min_Fields>;
};


/** aggregate fields of "compliance_entity_person" */
export type Compliance_Entity_Person_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Entity_Person_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_entity_person" */
export type Compliance_Entity_Person_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Entity_Person_Max_Order_By>;
  min?: InputMaybe<Compliance_Entity_Person_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_entity_person" */
export type Compliance_Entity_Person_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Entity_Person_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Entity_Person_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compliance_entity_person". All fields are combined with a logical 'AND'. */
export type Compliance_Entity_Person_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Entity_Person_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Entity_Person_Bool_Exp>>;
  addresses?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  dashboard?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
  dashboardId?: InputMaybe<Uuid_Comparison_Exp>;
  dateOfBirth?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  middleName?: InputMaybe<String_Comparison_Exp>;
  nationalIds?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_entity_person" */
export enum Compliance_Entity_Person_Constraint {
  /** unique or primary key constraint */
  ComplianceEntityPersonPkey = 'compliance_entity_person_pkey'
}

/** input type for inserting data into table "compliance_entity_person" */
export type Compliance_Entity_Person_Insert_Input = {
  addresses?: InputMaybe<Compliance_Entity_Person_Address_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboard?: InputMaybe<Compliance_Dashboard_Obj_Rel_Insert_Input>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  dateOfBirth?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  nationalIds?: InputMaybe<Compliance_Entity_Person_National_Id_Arr_Rel_Insert_Input>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Compliance_Entity_Person_Max_Fields = {
  __typename?: 'compliance_entity_person_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "compliance_entity_person" */
export type Compliance_Entity_Person_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Entity_Person_Min_Fields = {
  __typename?: 'compliance_entity_person_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dashboardId?: Maybe<Scalars['uuid']>;
  dateOfBirth?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "compliance_entity_person" */
export type Compliance_Entity_Person_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_entity_person" */
export type Compliance_Entity_Person_Mutation_Response = {
  __typename?: 'compliance_entity_person_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Entity_Person>;
};

/** columns and relationships of "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id = {
  __typename?: 'compliance_entity_person_national_id';
  createdAt: Scalars['timestamptz'];
  expiresAt?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  personId: Scalars['uuid'];
  /** An object relationship */
  type: National_Id_Type;
  typeName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Aggregate = {
  __typename?: 'compliance_entity_person_national_id_aggregate';
  aggregate?: Maybe<Compliance_Entity_Person_National_Id_Aggregate_Fields>;
  nodes: Array<Compliance_Entity_Person_National_Id>;
};

/** aggregate fields of "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Aggregate_Fields = {
  __typename?: 'compliance_entity_person_national_id_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compliance_Entity_Person_National_Id_Max_Fields>;
  min?: Maybe<Compliance_Entity_Person_National_Id_Min_Fields>;
};


/** aggregate fields of "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Compliance_Entity_Person_National_Id_Max_Order_By>;
  min?: InputMaybe<Compliance_Entity_Person_National_Id_Min_Order_By>;
};

/** input type for inserting array relation for remote table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Arr_Rel_Insert_Input = {
  data: Array<Compliance_Entity_Person_National_Id_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Compliance_Entity_Person_National_Id_On_Conflict>;
};

/** Boolean expression to filter rows from the table "compliance_entity_person_national_id". All fields are combined with a logical 'AND'. */
export type Compliance_Entity_Person_National_Id_Bool_Exp = {
  _and?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Bool_Exp>>;
  _not?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
  _or?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fileId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  personId?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<National_Id_Type_Bool_Exp>;
  typeName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "compliance_entity_person_national_id" */
export enum Compliance_Entity_Person_National_Id_Constraint {
  /** unique or primary key constraint */
  ComplianceEntityPersonNationalIdPkey = 'compliance_entity_person_national_id_pkey'
}

/** input type for inserting data into table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  personId?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<National_Id_Type_Obj_Rel_Insert_Input>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Compliance_Entity_Person_National_Id_Max_Fields = {
  __typename?: 'compliance_entity_person_national_id_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  personId?: Maybe<Scalars['uuid']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Compliance_Entity_Person_National_Id_Min_Fields = {
  __typename?: 'compliance_entity_person_national_id_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  personId?: Maybe<Scalars['uuid']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Mutation_Response = {
  __typename?: 'compliance_entity_person_national_id_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compliance_Entity_Person_National_Id>;
};

/** on_conflict condition type for table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_On_Conflict = {
  constraint: Compliance_Entity_Person_National_Id_Constraint;
  update_columns?: Array<Compliance_Entity_Person_National_Id_Update_Column>;
  where?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_entity_person_national_id". */
export type Compliance_Entity_Person_National_Id_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  personId?: InputMaybe<Order_By>;
  type?: InputMaybe<National_Id_Type_Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_entity_person_national_id */
export type Compliance_Entity_Person_National_Id_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compliance_entity_person_national_id" */
export enum Compliance_Entity_Person_National_Id_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'personId',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "compliance_entity_person_national_id" */
export type Compliance_Entity_Person_National_Id_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  personId?: InputMaybe<Scalars['uuid']>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "compliance_entity_person_national_id" */
export enum Compliance_Entity_Person_National_Id_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  PersonId = 'personId',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** on_conflict condition type for table "compliance_entity_person" */
export type Compliance_Entity_Person_On_Conflict = {
  constraint: Compliance_Entity_Person_Constraint;
  update_columns?: Array<Compliance_Entity_Person_Update_Column>;
  where?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
};

/** Ordering options when selecting data from "compliance_entity_person". */
export type Compliance_Entity_Person_Order_By = {
  addresses_aggregate?: InputMaybe<Compliance_Entity_Person_Address_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dashboard?: InputMaybe<Compliance_Dashboard_Order_By>;
  dashboardId?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  middleName?: InputMaybe<Order_By>;
  nationalIds_aggregate?: InputMaybe<Compliance_Entity_Person_National_Id_Aggregate_Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compliance_entity_person */
export type Compliance_Entity_Person_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "compliance_entity_person" */
export enum Compliance_Entity_Person_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "compliance_entity_person" */
export type Compliance_Entity_Person_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  dashboardId?: InputMaybe<Scalars['uuid']>;
  dateOfBirth?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "compliance_entity_person" */
export enum Compliance_Entity_Person_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DashboardId = 'dashboardId',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  MiddleName = 'middleName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "entity" */
export type Entity = {
  __typename?: 'entity';
  /** An array relationship */
  groups: Array<Entity_Attribute_Group>;
  /** An aggregate relationship */
  groups_aggregate: Entity_Attribute_Group_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  types: Array<Entity_Attribute_Type>;
  /** An aggregate relationship */
  types_aggregate: Entity_Attribute_Type_Aggregate;
};


/** columns and relationships of "entity" */
export type EntityGroupsArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Group_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
};


/** columns and relationships of "entity" */
export type EntityGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Group_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
};


/** columns and relationships of "entity" */
export type EntityTypesArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};


/** columns and relationships of "entity" */
export type EntityTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};

/** aggregated selection of "entity" */
export type Entity_Aggregate = {
  __typename?: 'entity_aggregate';
  aggregate?: Maybe<Entity_Aggregate_Fields>;
  nodes: Array<Entity>;
};

/** aggregate fields of "entity" */
export type Entity_Aggregate_Fields = {
  __typename?: 'entity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Entity_Max_Fields>;
  min?: Maybe<Entity_Min_Fields>;
};


/** aggregate fields of "entity" */
export type Entity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "entity_attribute_group" */
export type Entity_Attribute_Group = {
  __typename?: 'entity_attribute_group';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  entityName: Entity_Enum;
  label?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  order: Scalars['float8'];
  /** An array relationship */
  types: Array<Entity_Attribute_Type>;
  /** An aggregate relationship */
  types_aggregate: Entity_Attribute_Type_Aggregate;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "entity_attribute_group" */
export type Entity_Attribute_GroupMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "entity_attribute_group" */
export type Entity_Attribute_GroupTypesArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};


/** columns and relationships of "entity_attribute_group" */
export type Entity_Attribute_GroupTypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};

/** aggregated selection of "entity_attribute_group" */
export type Entity_Attribute_Group_Aggregate = {
  __typename?: 'entity_attribute_group_aggregate';
  aggregate?: Maybe<Entity_Attribute_Group_Aggregate_Fields>;
  nodes: Array<Entity_Attribute_Group>;
};

/** aggregate fields of "entity_attribute_group" */
export type Entity_Attribute_Group_Aggregate_Fields = {
  __typename?: 'entity_attribute_group_aggregate_fields';
  avg?: Maybe<Entity_Attribute_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Entity_Attribute_Group_Max_Fields>;
  min?: Maybe<Entity_Attribute_Group_Min_Fields>;
  stddev?: Maybe<Entity_Attribute_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Entity_Attribute_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Entity_Attribute_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Entity_Attribute_Group_Sum_Fields>;
  var_pop?: Maybe<Entity_Attribute_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Entity_Attribute_Group_Var_Samp_Fields>;
  variance?: Maybe<Entity_Attribute_Group_Variance_Fields>;
};


/** aggregate fields of "entity_attribute_group" */
export type Entity_Attribute_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Attribute_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "entity_attribute_group" */
export type Entity_Attribute_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Entity_Attribute_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Entity_Attribute_Group_Max_Order_By>;
  min?: InputMaybe<Entity_Attribute_Group_Min_Order_By>;
  stddev?: InputMaybe<Entity_Attribute_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Entity_Attribute_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Entity_Attribute_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Entity_Attribute_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Entity_Attribute_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Entity_Attribute_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Entity_Attribute_Group_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Entity_Attribute_Group_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "entity_attribute_group" */
export type Entity_Attribute_Group_Arr_Rel_Insert_Input = {
  data: Array<Entity_Attribute_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Attribute_Group_On_Conflict>;
};

/** aggregate avg on columns */
export type Entity_Attribute_Group_Avg_Fields = {
  __typename?: 'entity_attribute_group_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "entity_attribute_group". All fields are combined with a logical 'AND'. */
export type Entity_Attribute_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Attribute_Group_Bool_Exp>>;
  _not?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Attribute_Group_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  entityName?: InputMaybe<Entity_Enum_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Float8_Comparison_Exp>;
  types?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_attribute_group" */
export enum Entity_Attribute_Group_Constraint {
  /** unique or primary key constraint */
  EntityAttributeGroupPkey = 'entity_attribute_group_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Entity_Attribute_Group_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Entity_Attribute_Group_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Entity_Attribute_Group_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "entity_attribute_group" */
export type Entity_Attribute_Group_Inc_Input = {
  order?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "entity_attribute_group" */
export type Entity_Attribute_Group_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Entity_Enum>;
  label?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  types?: InputMaybe<Entity_Attribute_Type_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Entity_Attribute_Group_Max_Fields = {
  __typename?: 'entity_attribute_group_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Entity_Attribute_Group_Min_Fields = {
  __typename?: 'entity_attribute_group_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "entity_attribute_group" */
export type Entity_Attribute_Group_Mutation_Response = {
  __typename?: 'entity_attribute_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Attribute_Group>;
};

/** input type for inserting object relation for remote table "entity_attribute_group" */
export type Entity_Attribute_Group_Obj_Rel_Insert_Input = {
  data: Entity_Attribute_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Attribute_Group_On_Conflict>;
};

/** on_conflict condition type for table "entity_attribute_group" */
export type Entity_Attribute_Group_On_Conflict = {
  constraint: Entity_Attribute_Group_Constraint;
  update_columns?: Array<Entity_Attribute_Group_Update_Column>;
  where?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_attribute_group". */
export type Entity_Attribute_Group_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  entityName?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  types_aggregate?: InputMaybe<Entity_Attribute_Type_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_attribute_group */
export type Entity_Attribute_Group_Pk_Columns_Input = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Entity_Attribute_Group_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "entity_attribute_group" */
export enum Entity_Attribute_Group_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Label = 'label',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "entity_attribute_group" */
export type Entity_Attribute_Group_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Entity_Enum>;
  label?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Entity_Attribute_Group_Stddev_Fields = {
  __typename?: 'entity_attribute_group_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Entity_Attribute_Group_Stddev_Pop_Fields = {
  __typename?: 'entity_attribute_group_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Entity_Attribute_Group_Stddev_Samp_Fields = {
  __typename?: 'entity_attribute_group_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Entity_Attribute_Group_Sum_Fields = {
  __typename?: 'entity_attribute_group_sum_fields';
  order?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "entity_attribute_group" */
export enum Entity_Attribute_Group_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Label = 'label',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Entity_Attribute_Group_Var_Pop_Fields = {
  __typename?: 'entity_attribute_group_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Entity_Attribute_Group_Var_Samp_Fields = {
  __typename?: 'entity_attribute_group_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Entity_Attribute_Group_Variance_Fields = {
  __typename?: 'entity_attribute_group_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "entity_attribute_group" */
export type Entity_Attribute_Group_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "entity_attribute_type" */
export type Entity_Attribute_Type = {
  __typename?: 'entity_attribute_type';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  entityName: Entity_Enum;
  /** An object relationship */
  group?: Maybe<Entity_Attribute_Group>;
  groupName: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['float8'];
  props?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  validation?: Maybe<Scalars['jsonb']>;
  valueType: Value_Type_Enum;
};


/** columns and relationships of "entity_attribute_type" */
export type Entity_Attribute_TypePropsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "entity_attribute_type" */
export type Entity_Attribute_TypeValidationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "entity_attribute_type" */
export type Entity_Attribute_Type_Aggregate = {
  __typename?: 'entity_attribute_type_aggregate';
  aggregate?: Maybe<Entity_Attribute_Type_Aggregate_Fields>;
  nodes: Array<Entity_Attribute_Type>;
};

/** aggregate fields of "entity_attribute_type" */
export type Entity_Attribute_Type_Aggregate_Fields = {
  __typename?: 'entity_attribute_type_aggregate_fields';
  avg?: Maybe<Entity_Attribute_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Entity_Attribute_Type_Max_Fields>;
  min?: Maybe<Entity_Attribute_Type_Min_Fields>;
  stddev?: Maybe<Entity_Attribute_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Entity_Attribute_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Entity_Attribute_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Entity_Attribute_Type_Sum_Fields>;
  var_pop?: Maybe<Entity_Attribute_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Entity_Attribute_Type_Var_Samp_Fields>;
  variance?: Maybe<Entity_Attribute_Type_Variance_Fields>;
};


/** aggregate fields of "entity_attribute_type" */
export type Entity_Attribute_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "entity_attribute_type" */
export type Entity_Attribute_Type_Aggregate_Order_By = {
  avg?: InputMaybe<Entity_Attribute_Type_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Entity_Attribute_Type_Max_Order_By>;
  min?: InputMaybe<Entity_Attribute_Type_Min_Order_By>;
  stddev?: InputMaybe<Entity_Attribute_Type_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Entity_Attribute_Type_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Entity_Attribute_Type_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Entity_Attribute_Type_Sum_Order_By>;
  var_pop?: InputMaybe<Entity_Attribute_Type_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Entity_Attribute_Type_Var_Samp_Order_By>;
  variance?: InputMaybe<Entity_Attribute_Type_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Entity_Attribute_Type_Append_Input = {
  props?: InputMaybe<Scalars['jsonb']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "entity_attribute_type" */
export type Entity_Attribute_Type_Arr_Rel_Insert_Input = {
  data: Array<Entity_Attribute_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Attribute_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Entity_Attribute_Type_Avg_Fields = {
  __typename?: 'entity_attribute_type_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "entity_attribute_type". All fields are combined with a logical 'AND'. */
export type Entity_Attribute_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Attribute_Type_Bool_Exp>>;
  _not?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Attribute_Type_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  entityName?: InputMaybe<Entity_Enum_Comparison_Exp>;
  group?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
  groupName?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Float8_Comparison_Exp>;
  props?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validation?: InputMaybe<Jsonb_Comparison_Exp>;
  valueType?: InputMaybe<Value_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "entity_attribute_type" */
export enum Entity_Attribute_Type_Constraint {
  /** unique or primary key constraint */
  EntityAttributeTypePkey = 'entity_attribute_type_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Entity_Attribute_Type_Delete_At_Path_Input = {
  props?: InputMaybe<Array<Scalars['String']>>;
  validation?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Entity_Attribute_Type_Delete_Elem_Input = {
  props?: InputMaybe<Scalars['Int']>;
  validation?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Entity_Attribute_Type_Delete_Key_Input = {
  props?: InputMaybe<Scalars['String']>;
  validation?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "entity_attribute_type" */
export type Entity_Attribute_Type_Inc_Input = {
  order?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "entity_attribute_type" */
export type Entity_Attribute_Type_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Entity_Enum>;
  group?: InputMaybe<Entity_Attribute_Group_Obj_Rel_Insert_Input>;
  groupName?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  props?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validation?: InputMaybe<Scalars['jsonb']>;
  valueType?: InputMaybe<Value_Type_Enum>;
};

/** aggregate max on columns */
export type Entity_Attribute_Type_Max_Fields = {
  __typename?: 'entity_attribute_type_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  groupName?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Entity_Attribute_Type_Min_Fields = {
  __typename?: 'entity_attribute_type_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  groupName?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "entity_attribute_type" */
export type Entity_Attribute_Type_Mutation_Response = {
  __typename?: 'entity_attribute_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity_Attribute_Type>;
};

/** input type for inserting object relation for remote table "entity_attribute_type" */
export type Entity_Attribute_Type_Obj_Rel_Insert_Input = {
  data: Entity_Attribute_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Entity_Attribute_Type_On_Conflict>;
};

/** on_conflict condition type for table "entity_attribute_type" */
export type Entity_Attribute_Type_On_Conflict = {
  constraint: Entity_Attribute_Type_Constraint;
  update_columns?: Array<Entity_Attribute_Type_Update_Column>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "entity_attribute_type". */
export type Entity_Attribute_Type_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  entityName?: InputMaybe<Order_By>;
  group?: InputMaybe<Entity_Attribute_Group_Order_By>;
  groupName?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  props?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validation?: InputMaybe<Order_By>;
  valueType?: InputMaybe<Order_By>;
};

/** primary key columns input for table: entity_attribute_type */
export type Entity_Attribute_Type_Pk_Columns_Input = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Entity_Attribute_Type_Prepend_Input = {
  props?: InputMaybe<Scalars['jsonb']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "entity_attribute_type" */
export enum Entity_Attribute_Type_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  GroupName = 'groupName',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Props = 'props',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validation = 'validation',
  /** column name */
  ValueType = 'valueType'
}

/** input type for updating data in table "entity_attribute_type" */
export type Entity_Attribute_Type_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  entityName?: InputMaybe<Entity_Enum>;
  groupName?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  props?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validation?: InputMaybe<Scalars['jsonb']>;
  valueType?: InputMaybe<Value_Type_Enum>;
};

/** aggregate stddev on columns */
export type Entity_Attribute_Type_Stddev_Fields = {
  __typename?: 'entity_attribute_type_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Entity_Attribute_Type_Stddev_Pop_Fields = {
  __typename?: 'entity_attribute_type_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Entity_Attribute_Type_Stddev_Samp_Fields = {
  __typename?: 'entity_attribute_type_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Entity_Attribute_Type_Sum_Fields = {
  __typename?: 'entity_attribute_type_sum_fields';
  order?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "entity_attribute_type" */
export enum Entity_Attribute_Type_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  GroupName = 'groupName',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Props = 'props',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validation = 'validation',
  /** column name */
  ValueType = 'valueType'
}

/** aggregate var_pop on columns */
export type Entity_Attribute_Type_Var_Pop_Fields = {
  __typename?: 'entity_attribute_type_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Entity_Attribute_Type_Var_Samp_Fields = {
  __typename?: 'entity_attribute_type_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Entity_Attribute_Type_Variance_Fields = {
  __typename?: 'entity_attribute_type_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "entity_attribute_type" */
export type Entity_Attribute_Type_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "entity". All fields are combined with a logical 'AND'. */
export type Entity_Bool_Exp = {
  _and?: InputMaybe<Array<Entity_Bool_Exp>>;
  _not?: InputMaybe<Entity_Bool_Exp>;
  _or?: InputMaybe<Array<Entity_Bool_Exp>>;
  groups?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  types?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "entity" */
export enum Entity_Constraint {
  /** unique or primary key constraint */
  EntityPkey = 'entity_pkey'
}

export enum Entity_Enum {
  Company = 'company',
  StoreProduct = 'store_product',
  User = 'user'
}

/** Boolean expression to compare columns of type "entity_enum". All fields are combined with logical 'AND'. */
export type Entity_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Entity_Enum>;
  _in?: InputMaybe<Array<Entity_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Entity_Enum>;
  _nin?: InputMaybe<Array<Entity_Enum>>;
};

/** input type for inserting data into table "entity" */
export type Entity_Insert_Input = {
  groups?: InputMaybe<Entity_Attribute_Group_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Entity_Attribute_Type_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Entity_Max_Fields = {
  __typename?: 'entity_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Entity_Min_Fields = {
  __typename?: 'entity_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "entity" */
export type Entity_Mutation_Response = {
  __typename?: 'entity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Entity>;
};

/** on_conflict condition type for table "entity" */
export type Entity_On_Conflict = {
  constraint: Entity_Constraint;
  update_columns?: Array<Entity_Update_Column>;
  where?: InputMaybe<Entity_Bool_Exp>;
};

/** Ordering options when selecting data from "entity". */
export type Entity_Order_By = {
  groups_aggregate?: InputMaybe<Entity_Attribute_Group_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  types_aggregate?: InputMaybe<Entity_Attribute_Type_Aggregate_Order_By>;
};

/** primary key columns input for table: entity */
export type Entity_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "entity" */
export enum Entity_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "entity" */
export type Entity_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "entity" */
export enum Entity_Update_Column {
  /** column name */
  Name = 'name'
}

/** columns and relationships of "file" */
export type File = {
  __typename?: 'file';
  contentLength: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  domain?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  id: Scalars['uuid'];
  isPublic: Scalars['Boolean'];
  meta?: Maybe<Scalars['jsonb']>;
  mimeType: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** A computed field, executes function "file_url" */
  url?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "file" */
export type FileMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "file" */
export type File_Aggregate = {
  __typename?: 'file_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
};

/** aggregate fields of "file" */
export type File_Aggregate_Fields = {
  __typename?: 'file_aggregate_fields';
  avg?: Maybe<File_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
  stddev?: Maybe<File_Stddev_Fields>;
  stddev_pop?: Maybe<File_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Stddev_Samp_Fields>;
  sum?: Maybe<File_Sum_Fields>;
  var_pop?: Maybe<File_Var_Pop_Fields>;
  var_samp?: Maybe<File_Var_Samp_Fields>;
  variance?: Maybe<File_Variance_Fields>;
};


/** aggregate fields of "file" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type File_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type File_Avg_Fields = {
  __typename?: 'file_avg_fields';
  contentLength?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "file". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  contentLength?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  fileName?: InputMaybe<String_Comparison_Exp>;
  fileType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isPublic?: InputMaybe<Boolean_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "file" */
export enum File_Constraint {
  /** unique or primary key constraint */
  FilePkey = 'file_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type File_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type File_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type File_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "file" */
export type File_Inc_Input = {
  contentLength?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "file" */
export type File_Insert_Input = {
  contentLength?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'file_max_fields';
  contentLength?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'file_min_fields';
  contentLength?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "file" */
export type File_Mutation_Response = {
  __typename?: 'file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "file" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** on_conflict condition type for table "file" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** Ordering options when selecting data from "file". */
export type File_Order_By = {
  contentLength?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  fileName?: InputMaybe<Order_By>;
  fileType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isPublic?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file */
export type File_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type File_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "file" */
export enum File_Select_Column {
  /** column name */
  ContentLength = 'contentLength',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  FileName = 'fileName',
  /** column name */
  FileType = 'fileType',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Meta = 'meta',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "file" */
export type File_Set_Input = {
  contentLength?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  mimeType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type File_Stddev_Fields = {
  __typename?: 'file_stddev_fields';
  contentLength?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type File_Stddev_Pop_Fields = {
  __typename?: 'file_stddev_pop_fields';
  contentLength?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type File_Stddev_Samp_Fields = {
  __typename?: 'file_stddev_samp_fields';
  contentLength?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type File_Sum_Fields = {
  __typename?: 'file_sum_fields';
  contentLength?: Maybe<Scalars['Int']>;
};

/** update columns of table "file" */
export enum File_Update_Column {
  /** column name */
  ContentLength = 'contentLength',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Domain = 'domain',
  /** column name */
  FileName = 'fileName',
  /** column name */
  FileType = 'fileType',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  Meta = 'meta',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type File_Var_Pop_Fields = {
  __typename?: 'file_var_pop_fields';
  contentLength?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type File_Var_Samp_Fields = {
  __typename?: 'file_var_samp_fields';
  contentLength?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type File_Variance_Fields = {
  __typename?: 'file_variance_fields';
  contentLength?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "form" */
export type Form = {
  __typename?: 'form';
  /** An array relationship */
  children: Array<Form_Group>;
  /** An aggregate relationship */
  children_aggregate: Form_Group_Aggregate;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  formItems: Array<Form_Item>;
  /** An aggregate relationship */
  formItems_aggregate: Form_Item_Aggregate;
  /** An array relationship */
  formResponses: Array<Form_Response>;
  /** An aggregate relationship */
  formResponses_aggregate: Form_Response_Aggregate;
  id: Scalars['uuid'];
  meta?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  namespace: Form_Namespace_Enum;
  /** An array relationship */
  parents: Array<Form_Group>;
  /** An aggregate relationship */
  parents_aggregate: Form_Group_Aggregate;
  style?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "form" */
export type FormChildrenArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormFormItemsArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Order_By>>;
  where?: InputMaybe<Form_Item_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormFormItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Order_By>>;
  where?: InputMaybe<Form_Item_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormFormResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormFormResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "form" */
export type FormParentsArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};


/** columns and relationships of "form" */
export type FormParents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};

/** aggregated selection of "form" */
export type Form_Aggregate = {
  __typename?: 'form_aggregate';
  aggregate?: Maybe<Form_Aggregate_Fields>;
  nodes: Array<Form>;
};

/** aggregate fields of "form" */
export type Form_Aggregate_Fields = {
  __typename?: 'form_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Form_Max_Fields>;
  min?: Maybe<Form_Min_Fields>;
};


/** aggregate fields of "form" */
export type Form_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "form". All fields are combined with a logical 'AND'. */
export type Form_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Bool_Exp>>;
  _not?: InputMaybe<Form_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Bool_Exp>>;
  children?: InputMaybe<Form_Group_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  formItems?: InputMaybe<Form_Item_Bool_Exp>;
  formResponses?: InputMaybe<Form_Response_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  namespace?: InputMaybe<Form_Namespace_Enum_Comparison_Exp>;
  parents?: InputMaybe<Form_Group_Bool_Exp>;
  style?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "form" */
export enum Form_Constraint {
  /** unique or primary key constraint */
  FormPkey = 'form_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "form_group" */
export type Form_Group = {
  __typename?: 'form_group';
  /** An object relationship */
  child: Form;
  childId: Scalars['uuid'];
  isMultiSubmit: Scalars['Boolean'];
  order: Scalars['float8'];
  /** An object relationship */
  parent: Form;
  parentId: Scalars['uuid'];
};

/** aggregated selection of "form_group" */
export type Form_Group_Aggregate = {
  __typename?: 'form_group_aggregate';
  aggregate?: Maybe<Form_Group_Aggregate_Fields>;
  nodes: Array<Form_Group>;
};

/** aggregate fields of "form_group" */
export type Form_Group_Aggregate_Fields = {
  __typename?: 'form_group_aggregate_fields';
  avg?: Maybe<Form_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Form_Group_Max_Fields>;
  min?: Maybe<Form_Group_Min_Fields>;
  stddev?: Maybe<Form_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Form_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Form_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Form_Group_Sum_Fields>;
  var_pop?: Maybe<Form_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Form_Group_Var_Samp_Fields>;
  variance?: Maybe<Form_Group_Variance_Fields>;
};


/** aggregate fields of "form_group" */
export type Form_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_group" */
export type Form_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Form_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Group_Max_Order_By>;
  min?: InputMaybe<Form_Group_Min_Order_By>;
  stddev?: InputMaybe<Form_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Form_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Form_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Form_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Form_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Form_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Form_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "form_group" */
export type Form_Group_Arr_Rel_Insert_Input = {
  data: Array<Form_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Group_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Group_Avg_Fields = {
  __typename?: 'form_group_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_group" */
export type Form_Group_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_group". All fields are combined with a logical 'AND'. */
export type Form_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Group_Bool_Exp>>;
  _not?: InputMaybe<Form_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Group_Bool_Exp>>;
  child?: InputMaybe<Form_Bool_Exp>;
  childId?: InputMaybe<Uuid_Comparison_Exp>;
  isMultiSubmit?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Float8_Comparison_Exp>;
  parent?: InputMaybe<Form_Bool_Exp>;
  parentId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_group" */
export enum Form_Group_Constraint {
  /** unique or primary key constraint */
  FormGroupPkey = 'form_group_pkey'
}

/** input type for incrementing numeric columns in table "form_group" */
export type Form_Group_Inc_Input = {
  order?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "form_group" */
export type Form_Group_Insert_Input = {
  child?: InputMaybe<Form_Obj_Rel_Insert_Input>;
  childId?: InputMaybe<Scalars['uuid']>;
  isMultiSubmit?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['float8']>;
  parent?: InputMaybe<Form_Obj_Rel_Insert_Input>;
  parentId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Form_Group_Max_Fields = {
  __typename?: 'form_group_max_fields';
  childId?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['float8']>;
  parentId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "form_group" */
export type Form_Group_Max_Order_By = {
  childId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Group_Min_Fields = {
  __typename?: 'form_group_min_fields';
  childId?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['float8']>;
  parentId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "form_group" */
export type Form_Group_Min_Order_By = {
  childId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "form_group" */
export type Form_Group_Mutation_Response = {
  __typename?: 'form_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Group>;
};

/** on_conflict condition type for table "form_group" */
export type Form_Group_On_Conflict = {
  constraint: Form_Group_Constraint;
  update_columns?: Array<Form_Group_Update_Column>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "form_group". */
export type Form_Group_Order_By = {
  child?: InputMaybe<Form_Order_By>;
  childId?: InputMaybe<Order_By>;
  isMultiSubmit?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  parent?: InputMaybe<Form_Order_By>;
  parentId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_group */
export type Form_Group_Pk_Columns_Input = {
  childId: Scalars['uuid'];
  parentId: Scalars['uuid'];
};

/** select columns of table "form_group" */
export enum Form_Group_Select_Column {
  /** column name */
  ChildId = 'childId',
  /** column name */
  IsMultiSubmit = 'isMultiSubmit',
  /** column name */
  Order = 'order',
  /** column name */
  ParentId = 'parentId'
}

/** input type for updating data in table "form_group" */
export type Form_Group_Set_Input = {
  childId?: InputMaybe<Scalars['uuid']>;
  isMultiSubmit?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['float8']>;
  parentId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Form_Group_Stddev_Fields = {
  __typename?: 'form_group_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_group" */
export type Form_Group_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Group_Stddev_Pop_Fields = {
  __typename?: 'form_group_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_group" */
export type Form_Group_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Group_Stddev_Samp_Fields = {
  __typename?: 'form_group_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_group" */
export type Form_Group_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Form_Group_Sum_Fields = {
  __typename?: 'form_group_sum_fields';
  order?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "form_group" */
export type Form_Group_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "form_group" */
export enum Form_Group_Update_Column {
  /** column name */
  ChildId = 'childId',
  /** column name */
  IsMultiSubmit = 'isMultiSubmit',
  /** column name */
  Order = 'order',
  /** column name */
  ParentId = 'parentId'
}

/** aggregate var_pop on columns */
export type Form_Group_Var_Pop_Fields = {
  __typename?: 'form_group_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_group" */
export type Form_Group_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Group_Var_Samp_Fields = {
  __typename?: 'form_group_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_group" */
export type Form_Group_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Group_Variance_Fields = {
  __typename?: 'form_group_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_group" */
export type Form_Group_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** input type for inserting data into table "form" */
export type Form_Insert_Input = {
  children?: InputMaybe<Form_Group_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  formItems?: InputMaybe<Form_Item_Arr_Rel_Insert_Input>;
  formResponses?: InputMaybe<Form_Response_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  namespace?: InputMaybe<Form_Namespace_Enum>;
  parents?: InputMaybe<Form_Group_Arr_Rel_Insert_Input>;
  style?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "form_item" */
export type Form_Item = {
  __typename?: 'form_item';
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  form: Form;
  formId: Scalars['uuid'];
  /** An array relationship */
  itemResponses: Array<Form_Item_Response>;
  /** An aggregate relationship */
  itemResponses_aggregate: Form_Item_Response_Aggregate;
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['float8']>;
  props?: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  validation?: Maybe<Scalars['jsonb']>;
  valueType: Value_Type_Enum;
  /** An object relationship */
  valueTypeByValueType: Value_Type;
};


/** columns and relationships of "form_item" */
export type Form_ItemItemResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};


/** columns and relationships of "form_item" */
export type Form_ItemItemResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};


/** columns and relationships of "form_item" */
export type Form_ItemPropsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "form_item" */
export type Form_ItemValidationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "form_item" */
export type Form_Item_Aggregate = {
  __typename?: 'form_item_aggregate';
  aggregate?: Maybe<Form_Item_Aggregate_Fields>;
  nodes: Array<Form_Item>;
};

/** aggregate fields of "form_item" */
export type Form_Item_Aggregate_Fields = {
  __typename?: 'form_item_aggregate_fields';
  avg?: Maybe<Form_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Form_Item_Max_Fields>;
  min?: Maybe<Form_Item_Min_Fields>;
  stddev?: Maybe<Form_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Form_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Form_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Form_Item_Sum_Fields>;
  var_pop?: Maybe<Form_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Form_Item_Var_Samp_Fields>;
  variance?: Maybe<Form_Item_Variance_Fields>;
};


/** aggregate fields of "form_item" */
export type Form_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_item" */
export type Form_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Form_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Item_Max_Order_By>;
  min?: InputMaybe<Form_Item_Min_Order_By>;
  stddev?: InputMaybe<Form_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Form_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Form_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Form_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Form_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Form_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Form_Item_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Item_Append_Input = {
  props?: InputMaybe<Scalars['jsonb']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "form_item" */
export type Form_Item_Arr_Rel_Insert_Input = {
  data: Array<Form_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Form_Item_Avg_Fields = {
  __typename?: 'form_item_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "form_item" */
export type Form_Item_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "form_item". All fields are combined with a logical 'AND'. */
export type Form_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Item_Bool_Exp>>;
  _not?: InputMaybe<Form_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Item_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  form?: InputMaybe<Form_Bool_Exp>;
  formId?: InputMaybe<Uuid_Comparison_Exp>;
  itemResponses?: InputMaybe<Form_Item_Response_Bool_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Float8_Comparison_Exp>;
  props?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  validation?: InputMaybe<Jsonb_Comparison_Exp>;
  valueType?: InputMaybe<Value_Type_Enum_Comparison_Exp>;
  valueTypeByValueType?: InputMaybe<Value_Type_Bool_Exp>;
};

/** unique or primary key constraints on table "form_item" */
export enum Form_Item_Constraint {
  /** unique or primary key constraint */
  FormItemFormIdNameKey = 'form_item_form_id_name_key',
  /** unique or primary key constraint */
  FormItemPkey = 'form_item_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Item_Delete_At_Path_Input = {
  props?: InputMaybe<Array<Scalars['String']>>;
  validation?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Item_Delete_Elem_Input = {
  props?: InputMaybe<Scalars['Int']>;
  validation?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Item_Delete_Key_Input = {
  props?: InputMaybe<Scalars['String']>;
  validation?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "form_item" */
export type Form_Item_Inc_Input = {
  order?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "form_item" */
export type Form_Item_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<Form_Obj_Rel_Insert_Input>;
  formId?: InputMaybe<Scalars['uuid']>;
  itemResponses?: InputMaybe<Form_Item_Response_Arr_Rel_Insert_Input>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  props?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validation?: InputMaybe<Scalars['jsonb']>;
  valueType?: InputMaybe<Value_Type_Enum>;
  valueTypeByValueType?: InputMaybe<Value_Type_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Form_Item_Max_Fields = {
  __typename?: 'form_item_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "form_item" */
export type Form_Item_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  formId?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Item_Min_Fields = {
  __typename?: 'form_item_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  formId?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "form_item" */
export type Form_Item_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  formId?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "form_item" */
export type Form_Item_Mutation_Response = {
  __typename?: 'form_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Item>;
};

/** input type for inserting object relation for remote table "form_item" */
export type Form_Item_Obj_Rel_Insert_Input = {
  data: Form_Item_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Item_On_Conflict>;
};

/** on_conflict condition type for table "form_item" */
export type Form_Item_On_Conflict = {
  constraint: Form_Item_Constraint;
  update_columns?: Array<Form_Item_Update_Column>;
  where?: InputMaybe<Form_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "form_item". */
export type Form_Item_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  form?: InputMaybe<Form_Order_By>;
  formId?: InputMaybe<Order_By>;
  itemResponses_aggregate?: InputMaybe<Form_Item_Response_Aggregate_Order_By>;
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  props?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  validation?: InputMaybe<Order_By>;
  valueType?: InputMaybe<Order_By>;
  valueTypeByValueType?: InputMaybe<Value_Type_Order_By>;
};

/** primary key columns input for table: form_item */
export type Form_Item_Pk_Columns_Input = {
  formId: Scalars['uuid'];
  name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Item_Prepend_Input = {
  props?: InputMaybe<Scalars['jsonb']>;
  validation?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "form_item_response" */
export type Form_Item_Response = {
  __typename?: 'form_item_response';
  createdAt: Scalars['timestamptz'];
  formId: Scalars['uuid'];
  /** An object relationship */
  formItem?: Maybe<Form_Item>;
  formItemName: Scalars['String'];
  /** An object relationship */
  formResponse: Form_Response;
  formResponseId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "form_item_response" */
export type Form_Item_Response_Aggregate = {
  __typename?: 'form_item_response_aggregate';
  aggregate?: Maybe<Form_Item_Response_Aggregate_Fields>;
  nodes: Array<Form_Item_Response>;
};

/** aggregate fields of "form_item_response" */
export type Form_Item_Response_Aggregate_Fields = {
  __typename?: 'form_item_response_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Form_Item_Response_Max_Fields>;
  min?: Maybe<Form_Item_Response_Min_Fields>;
};


/** aggregate fields of "form_item_response" */
export type Form_Item_Response_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_item_response" */
export type Form_Item_Response_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Item_Response_Max_Order_By>;
  min?: InputMaybe<Form_Item_Response_Min_Order_By>;
};

/** input type for inserting array relation for remote table "form_item_response" */
export type Form_Item_Response_Arr_Rel_Insert_Input = {
  data: Array<Form_Item_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Item_Response_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form_item_response". All fields are combined with a logical 'AND'. */
export type Form_Item_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Item_Response_Bool_Exp>>;
  _not?: InputMaybe<Form_Item_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Item_Response_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  formId?: InputMaybe<Uuid_Comparison_Exp>;
  formItem?: InputMaybe<Form_Item_Bool_Exp>;
  formItemName?: InputMaybe<String_Comparison_Exp>;
  formResponse?: InputMaybe<Form_Response_Bool_Exp>;
  formResponseId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_item_response" */
export enum Form_Item_Response_Constraint {
  /** unique or primary key constraint */
  FormItemResponsePkey = 'form_item_response_pkey'
}

/** input type for inserting data into table "form_item_response" */
export type Form_Item_Response_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formId?: InputMaybe<Scalars['uuid']>;
  formItem?: InputMaybe<Form_Item_Obj_Rel_Insert_Input>;
  formItemName?: InputMaybe<Scalars['String']>;
  formResponse?: InputMaybe<Form_Response_Obj_Rel_Insert_Input>;
  formResponseId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Item_Response_Max_Fields = {
  __typename?: 'form_item_response_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  formId?: Maybe<Scalars['uuid']>;
  formItemName?: Maybe<Scalars['String']>;
  formResponseId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "form_item_response" */
export type Form_Item_Response_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  formId?: InputMaybe<Order_By>;
  formItemName?: InputMaybe<Order_By>;
  formResponseId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Item_Response_Min_Fields = {
  __typename?: 'form_item_response_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  formId?: Maybe<Scalars['uuid']>;
  formItemName?: Maybe<Scalars['String']>;
  formResponseId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "form_item_response" */
export type Form_Item_Response_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  formId?: InputMaybe<Order_By>;
  formItemName?: InputMaybe<Order_By>;
  formResponseId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "form_item_response" */
export type Form_Item_Response_Mutation_Response = {
  __typename?: 'form_item_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Item_Response>;
};

/** on_conflict condition type for table "form_item_response" */
export type Form_Item_Response_On_Conflict = {
  constraint: Form_Item_Response_Constraint;
  update_columns?: Array<Form_Item_Response_Update_Column>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "form_item_response". */
export type Form_Item_Response_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  formId?: InputMaybe<Order_By>;
  formItem?: InputMaybe<Form_Item_Order_By>;
  formItemName?: InputMaybe<Order_By>;
  formResponse?: InputMaybe<Form_Response_Order_By>;
  formResponseId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_item_response */
export type Form_Item_Response_Pk_Columns_Input = {
  formItemName: Scalars['String'];
  formResponseId: Scalars['uuid'];
};

/** select columns of table "form_item_response" */
export enum Form_Item_Response_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormId = 'formId',
  /** column name */
  FormItemName = 'formItemName',
  /** column name */
  FormResponseId = 'formResponseId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "form_item_response" */
export type Form_Item_Response_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  formId?: InputMaybe<Scalars['uuid']>;
  formItemName?: InputMaybe<Scalars['String']>;
  formResponseId?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "form_item_response" */
export enum Form_Item_Response_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormId = 'formId',
  /** column name */
  FormItemName = 'formItemName',
  /** column name */
  FormResponseId = 'formResponseId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** select columns of table "form_item" */
export enum Form_Item_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  FormId = 'formId',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Props = 'props',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validation = 'validation',
  /** column name */
  ValueType = 'valueType'
}

/** input type for updating data in table "form_item" */
export type Form_Item_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  formId?: InputMaybe<Scalars['uuid']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  props?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  validation?: InputMaybe<Scalars['jsonb']>;
  valueType?: InputMaybe<Value_Type_Enum>;
};

/** aggregate stddev on columns */
export type Form_Item_Stddev_Fields = {
  __typename?: 'form_item_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "form_item" */
export type Form_Item_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Form_Item_Stddev_Pop_Fields = {
  __typename?: 'form_item_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "form_item" */
export type Form_Item_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Form_Item_Stddev_Samp_Fields = {
  __typename?: 'form_item_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "form_item" */
export type Form_Item_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Form_Item_Sum_Fields = {
  __typename?: 'form_item_sum_fields';
  order?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "form_item" */
export type Form_Item_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "form_item" */
export enum Form_Item_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  FormId = 'formId',
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Props = 'props',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Validation = 'validation',
  /** column name */
  ValueType = 'valueType'
}

/** aggregate var_pop on columns */
export type Form_Item_Var_Pop_Fields = {
  __typename?: 'form_item_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "form_item" */
export type Form_Item_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Form_Item_Var_Samp_Fields = {
  __typename?: 'form_item_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "form_item" */
export type Form_Item_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Form_Item_Variance_Fields = {
  __typename?: 'form_item_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "form_item" */
export type Form_Item_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Form_Max_Fields = {
  __typename?: 'form_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Form_Min_Fields = {
  __typename?: 'form_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "form" */
export type Form_Mutation_Response = {
  __typename?: 'form_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form>;
};

/** columns and relationships of "form_namespace" */
export type Form_Namespace = {
  __typename?: 'form_namespace';
  namespace: Scalars['String'];
};

/** aggregated selection of "form_namespace" */
export type Form_Namespace_Aggregate = {
  __typename?: 'form_namespace_aggregate';
  aggregate?: Maybe<Form_Namespace_Aggregate_Fields>;
  nodes: Array<Form_Namespace>;
};

/** aggregate fields of "form_namespace" */
export type Form_Namespace_Aggregate_Fields = {
  __typename?: 'form_namespace_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Form_Namespace_Max_Fields>;
  min?: Maybe<Form_Namespace_Min_Fields>;
};


/** aggregate fields of "form_namespace" */
export type Form_Namespace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Namespace_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "form_namespace". All fields are combined with a logical 'AND'. */
export type Form_Namespace_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Namespace_Bool_Exp>>;
  _not?: InputMaybe<Form_Namespace_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Namespace_Bool_Exp>>;
  namespace?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_namespace" */
export enum Form_Namespace_Constraint {
  /** unique or primary key constraint */
  FormNamespacePkey = 'form_namespace_pkey'
}

export enum Form_Namespace_Enum {
  Company = 'company',
  Lead = 'lead',
  User = 'user'
}

/** Boolean expression to compare columns of type "form_namespace_enum". All fields are combined with logical 'AND'. */
export type Form_Namespace_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Form_Namespace_Enum>;
  _in?: InputMaybe<Array<Form_Namespace_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Form_Namespace_Enum>;
  _nin?: InputMaybe<Array<Form_Namespace_Enum>>;
};

/** input type for inserting data into table "form_namespace" */
export type Form_Namespace_Insert_Input = {
  namespace?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Form_Namespace_Max_Fields = {
  __typename?: 'form_namespace_max_fields';
  namespace?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Form_Namespace_Min_Fields = {
  __typename?: 'form_namespace_min_fields';
  namespace?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "form_namespace" */
export type Form_Namespace_Mutation_Response = {
  __typename?: 'form_namespace_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Namespace>;
};

/** on_conflict condition type for table "form_namespace" */
export type Form_Namespace_On_Conflict = {
  constraint: Form_Namespace_Constraint;
  update_columns?: Array<Form_Namespace_Update_Column>;
  where?: InputMaybe<Form_Namespace_Bool_Exp>;
};

/** Ordering options when selecting data from "form_namespace". */
export type Form_Namespace_Order_By = {
  namespace?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_namespace */
export type Form_Namespace_Pk_Columns_Input = {
  namespace: Scalars['String'];
};

/** select columns of table "form_namespace" */
export enum Form_Namespace_Select_Column {
  /** column name */
  Namespace = 'namespace'
}

/** input type for updating data in table "form_namespace" */
export type Form_Namespace_Set_Input = {
  namespace?: InputMaybe<Scalars['String']>;
};

/** update columns of table "form_namespace" */
export enum Form_Namespace_Update_Column {
  /** column name */
  Namespace = 'namespace'
}

/** input type for inserting object relation for remote table "form" */
export type Form_Obj_Rel_Insert_Input = {
  data: Form_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_On_Conflict>;
};

/** on_conflict condition type for table "form" */
export type Form_On_Conflict = {
  constraint: Form_Constraint;
  update_columns?: Array<Form_Update_Column>;
  where?: InputMaybe<Form_Bool_Exp>;
};

/** Ordering options when selecting data from "form". */
export type Form_Order_By = {
  children_aggregate?: InputMaybe<Form_Group_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  formItems_aggregate?: InputMaybe<Form_Item_Aggregate_Order_By>;
  formResponses_aggregate?: InputMaybe<Form_Response_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
  parents_aggregate?: InputMaybe<Form_Group_Aggregate_Order_By>;
  style?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form */
export type Form_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "form_response" */
export type Form_Response = {
  __typename?: 'form_response';
  /** An array relationship */
  companyFormResponses: Array<Company_Form_Response>;
  /** An aggregate relationship */
  companyFormResponses_aggregate: Company_Form_Response_Aggregate;
  createdAt: Scalars['timestamptz'];
  entityId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  form: Form;
  formId: Scalars['uuid'];
  /** An array relationship */
  formItemResponses: Array<Form_Item_Response>;
  /** An aggregate relationship */
  formItemResponses_aggregate: Form_Item_Response_Aggregate;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "form_response" */
export type Form_ResponseCompanyFormResponsesArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


/** columns and relationships of "form_response" */
export type Form_ResponseCompanyFormResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


/** columns and relationships of "form_response" */
export type Form_ResponseFormItemResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};


/** columns and relationships of "form_response" */
export type Form_ResponseFormItemResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};

/** aggregated selection of "form_response" */
export type Form_Response_Aggregate = {
  __typename?: 'form_response_aggregate';
  aggregate?: Maybe<Form_Response_Aggregate_Fields>;
  nodes: Array<Form_Response>;
};

/** aggregate fields of "form_response" */
export type Form_Response_Aggregate_Fields = {
  __typename?: 'form_response_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Form_Response_Max_Fields>;
  min?: Maybe<Form_Response_Min_Fields>;
};


/** aggregate fields of "form_response" */
export type Form_Response_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "form_response" */
export type Form_Response_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Form_Response_Max_Order_By>;
  min?: InputMaybe<Form_Response_Min_Order_By>;
};

/** input type for inserting array relation for remote table "form_response" */
export type Form_Response_Arr_Rel_Insert_Input = {
  data: Array<Form_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Response_On_Conflict>;
};

/** Boolean expression to filter rows from the table "form_response". All fields are combined with a logical 'AND'. */
export type Form_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Response_Bool_Exp>>;
  _not?: InputMaybe<Form_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Form_Response_Bool_Exp>>;
  companyFormResponses?: InputMaybe<Company_Form_Response_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  entityId?: InputMaybe<Uuid_Comparison_Exp>;
  form?: InputMaybe<Form_Bool_Exp>;
  formId?: InputMaybe<Uuid_Comparison_Exp>;
  formItemResponses?: InputMaybe<Form_Item_Response_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "form_response" */
export enum Form_Response_Constraint {
  /** unique or primary key constraint */
  FormResponsePkey = 'form_response_pkey'
}

/** input type for inserting data into table "form_response" */
export type Form_Response_Insert_Input = {
  companyFormResponses?: InputMaybe<Company_Form_Response_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityId?: InputMaybe<Scalars['uuid']>;
  form?: InputMaybe<Form_Obj_Rel_Insert_Input>;
  formId?: InputMaybe<Scalars['uuid']>;
  formItemResponses?: InputMaybe<Form_Item_Response_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Form_Response_Max_Fields = {
  __typename?: 'form_response_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  entityId?: Maybe<Scalars['uuid']>;
  formId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "form_response" */
export type Form_Response_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  formId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Form_Response_Min_Fields = {
  __typename?: 'form_response_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  entityId?: Maybe<Scalars['uuid']>;
  formId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "form_response" */
export type Form_Response_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  formId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "form_response" */
export type Form_Response_Mutation_Response = {
  __typename?: 'form_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Form_Response>;
};

/** input type for inserting object relation for remote table "form_response" */
export type Form_Response_Obj_Rel_Insert_Input = {
  data: Form_Response_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Response_On_Conflict>;
};

/** on_conflict condition type for table "form_response" */
export type Form_Response_On_Conflict = {
  constraint: Form_Response_Constraint;
  update_columns?: Array<Form_Response_Update_Column>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "form_response". */
export type Form_Response_Order_By = {
  companyFormResponses_aggregate?: InputMaybe<Company_Form_Response_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  entityId?: InputMaybe<Order_By>;
  form?: InputMaybe<Form_Order_By>;
  formId?: InputMaybe<Order_By>;
  formItemResponses_aggregate?: InputMaybe<Form_Item_Response_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: form_response */
export type Form_Response_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "form_response" */
export enum Form_Response_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "form_response" */
export type Form_Response_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityId?: InputMaybe<Scalars['uuid']>;
  formId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "form_response" */
export enum Form_Response_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityId = 'entityId',
  /** column name */
  FormId = 'formId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select columns of table "form" */
export enum Form_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Style = 'style',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "form" */
export type Form_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  namespace?: InputMaybe<Form_Namespace_Enum>;
  style?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "form" */
export enum Form_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Style = 'style',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "job" */
export type Job = {
  __typename?: 'job';
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  meta: Scalars['jsonb'];
  name: Scalars['String'];
  /** An array relationship */
  runs: Array<Job_Run>;
  /** An aggregate relationship */
  runs_aggregate: Job_Run_Aggregate;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "job" */
export type JobMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "job" */
export type JobRunsArgs = {
  distinct_on?: InputMaybe<Array<Job_Run_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Run_Order_By>>;
  where?: InputMaybe<Job_Run_Bool_Exp>;
};


/** columns and relationships of "job" */
export type JobRuns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Run_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Run_Order_By>>;
  where?: InputMaybe<Job_Run_Bool_Exp>;
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Job_Max_Fields>;
  min?: Maybe<Job_Min_Fields>;
};


/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Job_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Bool_Exp>>;
  _not?: InputMaybe<Job_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  runs?: InputMaybe<Job_Run_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "job" */
export enum Job_Constraint {
  /** unique or primary key constraint */
  JobPkey = 'job_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Job_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Job_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Job_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "job" */
export type Job_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  runs?: InputMaybe<Job_Run_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "job" */
export type Job_Mutation_Response = {
  __typename?: 'job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job>;
};

/** on_conflict condition type for table "job" */
export type Job_On_Conflict = {
  constraint: Job_Constraint;
  update_columns?: Array<Job_Update_Column>;
  where?: InputMaybe<Job_Bool_Exp>;
};

/** Ordering options when selecting data from "job". */
export type Job_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  runs_aggregate?: InputMaybe<Job_Run_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job */
export type Job_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Job_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "job_run" */
export type Job_Run = {
  __typename?: 'job_run';
  createdAt: Scalars['timestamptz'];
  cursor?: Maybe<Scalars['String']>;
  data: Scalars['jsonb'];
  executionTimeSeconds: Scalars['float8'];
  jobName: Scalars['String'];
  status: Scalars['String'];
};


/** columns and relationships of "job_run" */
export type Job_RunDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "job_run" */
export type Job_Run_Aggregate = {
  __typename?: 'job_run_aggregate';
  aggregate?: Maybe<Job_Run_Aggregate_Fields>;
  nodes: Array<Job_Run>;
};

/** aggregate fields of "job_run" */
export type Job_Run_Aggregate_Fields = {
  __typename?: 'job_run_aggregate_fields';
  avg?: Maybe<Job_Run_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Job_Run_Max_Fields>;
  min?: Maybe<Job_Run_Min_Fields>;
  stddev?: Maybe<Job_Run_Stddev_Fields>;
  stddev_pop?: Maybe<Job_Run_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Job_Run_Stddev_Samp_Fields>;
  sum?: Maybe<Job_Run_Sum_Fields>;
  var_pop?: Maybe<Job_Run_Var_Pop_Fields>;
  var_samp?: Maybe<Job_Run_Var_Samp_Fields>;
  variance?: Maybe<Job_Run_Variance_Fields>;
};


/** aggregate fields of "job_run" */
export type Job_Run_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Job_Run_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job_run" */
export type Job_Run_Aggregate_Order_By = {
  avg?: InputMaybe<Job_Run_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Job_Run_Max_Order_By>;
  min?: InputMaybe<Job_Run_Min_Order_By>;
  stddev?: InputMaybe<Job_Run_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Job_Run_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Job_Run_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Job_Run_Sum_Order_By>;
  var_pop?: InputMaybe<Job_Run_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Job_Run_Var_Samp_Order_By>;
  variance?: InputMaybe<Job_Run_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Job_Run_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "job_run" */
export type Job_Run_Arr_Rel_Insert_Input = {
  data: Array<Job_Run_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Job_Run_On_Conflict>;
};

/** aggregate avg on columns */
export type Job_Run_Avg_Fields = {
  __typename?: 'job_run_avg_fields';
  executionTimeSeconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "job_run" */
export type Job_Run_Avg_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "job_run". All fields are combined with a logical 'AND'. */
export type Job_Run_Bool_Exp = {
  _and?: InputMaybe<Array<Job_Run_Bool_Exp>>;
  _not?: InputMaybe<Job_Run_Bool_Exp>;
  _or?: InputMaybe<Array<Job_Run_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  cursor?: InputMaybe<String_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  executionTimeSeconds?: InputMaybe<Float8_Comparison_Exp>;
  jobName?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job_run" */
export enum Job_Run_Constraint {
  /** unique or primary key constraint */
  JobRunPkey = 'job_run_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Job_Run_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Job_Run_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Job_Run_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "job_run" */
export type Job_Run_Inc_Input = {
  executionTimeSeconds?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "job_run" */
export type Job_Run_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  cursor?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['jsonb']>;
  executionTimeSeconds?: InputMaybe<Scalars['float8']>;
  jobName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Run_Max_Fields = {
  __typename?: 'job_run_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  cursor?: Maybe<Scalars['String']>;
  executionTimeSeconds?: Maybe<Scalars['float8']>;
  jobName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job_run" */
export type Job_Run_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cursor?: InputMaybe<Order_By>;
  executionTimeSeconds?: InputMaybe<Order_By>;
  jobName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Run_Min_Fields = {
  __typename?: 'job_run_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  cursor?: Maybe<Scalars['String']>;
  executionTimeSeconds?: Maybe<Scalars['float8']>;
  jobName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job_run" */
export type Job_Run_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cursor?: InputMaybe<Order_By>;
  executionTimeSeconds?: InputMaybe<Order_By>;
  jobName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "job_run" */
export type Job_Run_Mutation_Response = {
  __typename?: 'job_run_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Job_Run>;
};

/** on_conflict condition type for table "job_run" */
export type Job_Run_On_Conflict = {
  constraint: Job_Run_Constraint;
  update_columns?: Array<Job_Run_Update_Column>;
  where?: InputMaybe<Job_Run_Bool_Exp>;
};

/** Ordering options when selecting data from "job_run". */
export type Job_Run_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cursor?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  executionTimeSeconds?: InputMaybe<Order_By>;
  jobName?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: job_run */
export type Job_Run_Pk_Columns_Input = {
  createdAt: Scalars['timestamptz'];
  jobName: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Job_Run_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "job_run" */
export enum Job_Run_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cursor = 'cursor',
  /** column name */
  Data = 'data',
  /** column name */
  ExecutionTimeSeconds = 'executionTimeSeconds',
  /** column name */
  JobName = 'jobName',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "job_run" */
export type Job_Run_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  cursor?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['jsonb']>;
  executionTimeSeconds?: InputMaybe<Scalars['float8']>;
  jobName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Job_Run_Stddev_Fields = {
  __typename?: 'job_run_stddev_fields';
  executionTimeSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "job_run" */
export type Job_Run_Stddev_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Job_Run_Stddev_Pop_Fields = {
  __typename?: 'job_run_stddev_pop_fields';
  executionTimeSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "job_run" */
export type Job_Run_Stddev_Pop_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Job_Run_Stddev_Samp_Fields = {
  __typename?: 'job_run_stddev_samp_fields';
  executionTimeSeconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "job_run" */
export type Job_Run_Stddev_Samp_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Job_Run_Sum_Fields = {
  __typename?: 'job_run_sum_fields';
  executionTimeSeconds?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "job_run" */
export type Job_Run_Sum_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** update columns of table "job_run" */
export enum Job_Run_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cursor = 'cursor',
  /** column name */
  Data = 'data',
  /** column name */
  ExecutionTimeSeconds = 'executionTimeSeconds',
  /** column name */
  JobName = 'jobName',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Job_Run_Var_Pop_Fields = {
  __typename?: 'job_run_var_pop_fields';
  executionTimeSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "job_run" */
export type Job_Run_Var_Pop_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Job_Run_Var_Samp_Fields = {
  __typename?: 'job_run_var_samp_fields';
  executionTimeSeconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "job_run" */
export type Job_Run_Var_Samp_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Job_Run_Variance_Fields = {
  __typename?: 'job_run_variance_fields';
  executionTimeSeconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "job_run" */
export type Job_Run_Variance_Order_By = {
  executionTimeSeconds?: InputMaybe<Order_By>;
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "job" */
export type Job_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "job" */
export enum Job_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Meta = 'meta',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "log" */
export type Log = {
  __typename?: 'log';
  createdAt: Scalars['timestamptz'];
  details: Scalars['jsonb'];
  id: Scalars['uuid'];
  level?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  timestamp: Scalars['timestamptz'];
  trace: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "log" */
export type LogDetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "log" */
export type Log_Aggregate = {
  __typename?: 'log_aggregate';
  aggregate?: Maybe<Log_Aggregate_Fields>;
  nodes: Array<Log>;
};

/** aggregate fields of "log" */
export type Log_Aggregate_Fields = {
  __typename?: 'log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Log_Max_Fields>;
  min?: Maybe<Log_Min_Fields>;
};


/** aggregate fields of "log" */
export type Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Log_Append_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "log". All fields are combined with a logical 'AND'. */
export type Log_Bool_Exp = {
  _and?: InputMaybe<Array<Log_Bool_Exp>>;
  _not?: InputMaybe<Log_Bool_Exp>;
  _or?: InputMaybe<Array<Log_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  trace?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "log" */
export enum Log_Constraint {
  /** unique or primary key constraint */
  LogPkey = 'log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Log_Delete_At_Path_Input = {
  details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Log_Delete_Elem_Input = {
  details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Log_Delete_Key_Input = {
  details?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "log" */
export type Log_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trace?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Log_Max_Fields = {
  __typename?: 'log_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  trace?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Log_Min_Fields = {
  __typename?: 'log_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  trace?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "log" */
export type Log_Mutation_Response = {
  __typename?: 'log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Log>;
};

/** on_conflict condition type for table "log" */
export type Log_On_Conflict = {
  constraint: Log_Constraint;
  update_columns?: Array<Log_Update_Column>;
  where?: InputMaybe<Log_Bool_Exp>;
};

/** Ordering options when selecting data from "log". */
export type Log_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  trace?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: log */
export type Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Log_Prepend_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "log" */
export enum Log_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  Service = 'service',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Trace = 'trace',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "log" */
export type Log_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  level?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  trace?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "log" */
export enum Log_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  Service = 'service',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Trace = 'trace',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createExpiringContactCardCode?: Maybe<ExpiringCodeOutput>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "address_country" */
  delete_address_country?: Maybe<Address_Country_Mutation_Response>;
  /** delete single row from the table: "address_country" */
  delete_address_country_by_pk?: Maybe<Address_Country>;
  /** delete data from the table: "address_state" */
  delete_address_state?: Maybe<Address_State_Mutation_Response>;
  /** delete single row from the table: "address_state" */
  delete_address_state_by_pk?: Maybe<Address_State>;
  /** delete data from the table: "app" */
  delete_app?: Maybe<App_Mutation_Response>;
  /** delete single row from the table: "app" */
  delete_app_by_pk?: Maybe<App>;
  /** delete data from the table: "cache" */
  delete_cache?: Maybe<Cache_Mutation_Response>;
  /** delete single row from the table: "cache" */
  delete_cache_by_pk?: Maybe<Cache>;
  /** delete data from the table: "category" */
  delete_category?: Maybe<Category_Mutation_Response>;
  /** delete single row from the table: "category" */
  delete_category_by_pk?: Maybe<Category>;
  /** delete data from the table: "category_namespace" */
  delete_category_namespace?: Maybe<Category_Namespace_Mutation_Response>;
  /** delete single row from the table: "category_namespace" */
  delete_category_namespace_by_pk?: Maybe<Category_Namespace>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete data from the table: "company_address" */
  delete_company_address?: Maybe<Company_Address_Mutation_Response>;
  /** delete single row from the table: "company_address" */
  delete_company_address_by_pk?: Maybe<Company_Address>;
  /** delete data from the table: "company_attribute" */
  delete_company_attribute?: Maybe<Company_Attribute_Mutation_Response>;
  /** delete single row from the table: "company_attribute" */
  delete_company_attribute_by_pk?: Maybe<Company_Attribute>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "company_category" */
  delete_company_category?: Maybe<Company_Category_Mutation_Response>;
  /** delete single row from the table: "company_category" */
  delete_company_category_by_pk?: Maybe<Company_Category>;
  /** delete data from the table: "company_email" */
  delete_company_email?: Maybe<Company_Email_Mutation_Response>;
  /** delete single row from the table: "company_email" */
  delete_company_email_by_pk?: Maybe<Company_Email>;
  /** delete data from the table: "company_form_response" */
  delete_company_form_response?: Maybe<Company_Form_Response_Mutation_Response>;
  /** delete single row from the table: "company_form_response" */
  delete_company_form_response_by_pk?: Maybe<Company_Form_Response>;
  /** delete data from the table: "company_phone_number" */
  delete_company_phone_number?: Maybe<Company_Phone_Number_Mutation_Response>;
  /** delete single row from the table: "company_phone_number" */
  delete_company_phone_number_by_pk?: Maybe<Company_Phone_Number>;
  /** delete data from the table: "company_user" */
  delete_company_user?: Maybe<Company_User_Mutation_Response>;
  /** delete single row from the table: "company_user" */
  delete_company_user_by_pk?: Maybe<Company_User>;
  /** delete data from the table: "compliance_account" */
  delete_compliance_account?: Maybe<Compliance_Account_Mutation_Response>;
  /** delete single row from the table: "compliance_account" */
  delete_compliance_account_by_pk?: Maybe<Compliance_Account>;
  /** delete data from the table: "compliance_account_user" */
  delete_compliance_account_user?: Maybe<Compliance_Account_User_Mutation_Response>;
  /** delete single row from the table: "compliance_account_user" */
  delete_compliance_account_user_by_pk?: Maybe<Compliance_Account_User>;
  /** delete data from the table: "compliance_check" */
  delete_compliance_check?: Maybe<Compliance_Check_Mutation_Response>;
  /** delete single row from the table: "compliance_check" */
  delete_compliance_check_by_pk?: Maybe<Compliance_Check>;
  /** delete data from the table: "compliance_check_type" */
  delete_compliance_check_type?: Maybe<Compliance_Check_Type_Mutation_Response>;
  /** delete single row from the table: "compliance_check_type" */
  delete_compliance_check_type_by_pk?: Maybe<Compliance_Check_Type>;
  /** delete data from the table: "compliance_dashboard" */
  delete_compliance_dashboard?: Maybe<Compliance_Dashboard_Mutation_Response>;
  /** delete single row from the table: "compliance_dashboard" */
  delete_compliance_dashboard_by_pk?: Maybe<Compliance_Dashboard>;
  /** delete data from the table: "compliance_dashboard_check_type" */
  delete_compliance_dashboard_check_type?: Maybe<Compliance_Dashboard_Check_Type_Mutation_Response>;
  /** delete single row from the table: "compliance_dashboard_check_type" */
  delete_compliance_dashboard_check_type_by_pk?: Maybe<Compliance_Dashboard_Check_Type>;
  /** delete data from the table: "compliance_dashboard_user" */
  delete_compliance_dashboard_user?: Maybe<Compliance_Dashboard_User_Mutation_Response>;
  /** delete single row from the table: "compliance_dashboard_user" */
  delete_compliance_dashboard_user_by_pk?: Maybe<Compliance_Dashboard_User>;
  /** delete data from the table: "compliance_entity_company" */
  delete_compliance_entity_company?: Maybe<Compliance_Entity_Company_Mutation_Response>;
  /** delete data from the table: "compliance_entity_company_address" */
  delete_compliance_entity_company_address?: Maybe<Compliance_Entity_Company_Address_Mutation_Response>;
  /** delete single row from the table: "compliance_entity_company_address" */
  delete_compliance_entity_company_address_by_pk?: Maybe<Compliance_Entity_Company_Address>;
  /** delete single row from the table: "compliance_entity_company" */
  delete_compliance_entity_company_by_pk?: Maybe<Compliance_Entity_Company>;
  /** delete data from the table: "compliance_entity_person" */
  delete_compliance_entity_person?: Maybe<Compliance_Entity_Person_Mutation_Response>;
  /** delete data from the table: "compliance_entity_person_address" */
  delete_compliance_entity_person_address?: Maybe<Compliance_Entity_Person_Address_Mutation_Response>;
  /** delete single row from the table: "compliance_entity_person_address" */
  delete_compliance_entity_person_address_by_pk?: Maybe<Compliance_Entity_Person_Address>;
  /** delete single row from the table: "compliance_entity_person" */
  delete_compliance_entity_person_by_pk?: Maybe<Compliance_Entity_Person>;
  /** delete data from the table: "compliance_entity_person_national_id" */
  delete_compliance_entity_person_national_id?: Maybe<Compliance_Entity_Person_National_Id_Mutation_Response>;
  /** delete single row from the table: "compliance_entity_person_national_id" */
  delete_compliance_entity_person_national_id_by_pk?: Maybe<Compliance_Entity_Person_National_Id>;
  /** delete data from the table: "entity" */
  delete_entity?: Maybe<Entity_Mutation_Response>;
  /** delete data from the table: "entity_attribute_group" */
  delete_entity_attribute_group?: Maybe<Entity_Attribute_Group_Mutation_Response>;
  /** delete single row from the table: "entity_attribute_group" */
  delete_entity_attribute_group_by_pk?: Maybe<Entity_Attribute_Group>;
  /** delete data from the table: "entity_attribute_type" */
  delete_entity_attribute_type?: Maybe<Entity_Attribute_Type_Mutation_Response>;
  /** delete single row from the table: "entity_attribute_type" */
  delete_entity_attribute_type_by_pk?: Maybe<Entity_Attribute_Type>;
  /** delete single row from the table: "entity" */
  delete_entity_by_pk?: Maybe<Entity>;
  /** delete data from the table: "file" */
  delete_file?: Maybe<File_Mutation_Response>;
  /** delete single row from the table: "file" */
  delete_file_by_pk?: Maybe<File>;
  /** delete data from the table: "form" */
  delete_form?: Maybe<Form_Mutation_Response>;
  /** delete single row from the table: "form" */
  delete_form_by_pk?: Maybe<Form>;
  /** delete data from the table: "form_group" */
  delete_form_group?: Maybe<Form_Group_Mutation_Response>;
  /** delete single row from the table: "form_group" */
  delete_form_group_by_pk?: Maybe<Form_Group>;
  /** delete data from the table: "form_item" */
  delete_form_item?: Maybe<Form_Item_Mutation_Response>;
  /** delete single row from the table: "form_item" */
  delete_form_item_by_pk?: Maybe<Form_Item>;
  /** delete data from the table: "form_item_response" */
  delete_form_item_response?: Maybe<Form_Item_Response_Mutation_Response>;
  /** delete single row from the table: "form_item_response" */
  delete_form_item_response_by_pk?: Maybe<Form_Item_Response>;
  /** delete data from the table: "form_namespace" */
  delete_form_namespace?: Maybe<Form_Namespace_Mutation_Response>;
  /** delete single row from the table: "form_namespace" */
  delete_form_namespace_by_pk?: Maybe<Form_Namespace>;
  /** delete data from the table: "form_response" */
  delete_form_response?: Maybe<Form_Response_Mutation_Response>;
  /** delete single row from the table: "form_response" */
  delete_form_response_by_pk?: Maybe<Form_Response>;
  /** delete data from the table: "job" */
  delete_job?: Maybe<Job_Mutation_Response>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<Job>;
  /** delete data from the table: "job_run" */
  delete_job_run?: Maybe<Job_Run_Mutation_Response>;
  /** delete single row from the table: "job_run" */
  delete_job_run_by_pk?: Maybe<Job_Run>;
  /** delete data from the table: "log" */
  delete_log?: Maybe<Log_Mutation_Response>;
  /** delete single row from the table: "log" */
  delete_log_by_pk?: Maybe<Log>;
  /** delete data from the table: "national_id_type" */
  delete_national_id_type?: Maybe<National_Id_Type_Mutation_Response>;
  /** delete single row from the table: "national_id_type" */
  delete_national_id_type_by_pk?: Maybe<National_Id_Type>;
  /** delete data from the table: "public_configuration" */
  delete_public_configuration?: Maybe<Public_Configuration_Mutation_Response>;
  /** delete single row from the table: "public_configuration" */
  delete_public_configuration_by_pk?: Maybe<Public_Configuration>;
  /** delete data from the table: "public_configuration_value" */
  delete_public_configuration_value?: Maybe<Public_Configuration_Value_Mutation_Response>;
  /** delete single row from the table: "public_configuration_value" */
  delete_public_configuration_value_by_pk?: Maybe<Public_Configuration_Value>;
  /** delete data from the table: "store_order" */
  delete_store_order?: Maybe<Store_Order_Mutation_Response>;
  /** delete data from the table: "store_order_address" */
  delete_store_order_address?: Maybe<Store_Order_Address_Mutation_Response>;
  /** delete single row from the table: "store_order_address" */
  delete_store_order_address_by_pk?: Maybe<Store_Order_Address>;
  /** delete data from the table: "store_order_address_type" */
  delete_store_order_address_type?: Maybe<Store_Order_Address_Type_Mutation_Response>;
  /** delete single row from the table: "store_order_address_type" */
  delete_store_order_address_type_by_pk?: Maybe<Store_Order_Address_Type>;
  /** delete single row from the table: "store_order" */
  delete_store_order_by_pk?: Maybe<Store_Order>;
  /** delete data from the table: "store_order_item" */
  delete_store_order_item?: Maybe<Store_Order_Item_Mutation_Response>;
  /** delete single row from the table: "store_order_item" */
  delete_store_order_item_by_pk?: Maybe<Store_Order_Item>;
  /** delete data from the table: "store_product" */
  delete_store_product?: Maybe<Store_Product_Mutation_Response>;
  /** delete data from the table: "store_product_attribute" */
  delete_store_product_attribute?: Maybe<Store_Product_Attribute_Mutation_Response>;
  /** delete single row from the table: "store_product_attribute" */
  delete_store_product_attribute_by_pk?: Maybe<Store_Product_Attribute>;
  /** delete single row from the table: "store_product" */
  delete_store_product_by_pk?: Maybe<Store_Product>;
  /** delete data from the table: "store_product_category" */
  delete_store_product_category?: Maybe<Store_Product_Category_Mutation_Response>;
  /** delete single row from the table: "store_product_category" */
  delete_store_product_category_by_pk?: Maybe<Store_Product_Category>;
  /** delete data from the table: "store_product_link" */
  delete_store_product_link?: Maybe<Store_Product_Link_Mutation_Response>;
  /** delete single row from the table: "store_product_link" */
  delete_store_product_link_by_pk?: Maybe<Store_Product_Link>;
  /** delete data from the table: "store_product_link_type" */
  delete_store_product_link_type?: Maybe<Store_Product_Link_Type_Mutation_Response>;
  /** delete single row from the table: "store_product_link_type" */
  delete_store_product_link_type_by_pk?: Maybe<Store_Product_Link_Type>;
  /** delete data from the table: "store_product_media" */
  delete_store_product_media?: Maybe<Store_Product_Media_Mutation_Response>;
  /** delete single row from the table: "store_product_media" */
  delete_store_product_media_by_pk?: Maybe<Store_Product_Media>;
  /** delete data from the table: "subscription_interval" */
  delete_subscription_interval?: Maybe<Subscription_Interval_Mutation_Response>;
  /** delete single row from the table: "subscription_interval" */
  delete_subscription_interval_by_pk?: Maybe<Subscription_Interval>;
  /** delete data from the table: "tracking_event" */
  delete_tracking_event?: Maybe<Tracking_Event_Mutation_Response>;
  /** delete single row from the table: "tracking_event" */
  delete_tracking_event_by_pk?: Maybe<Tracking_Event>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "user_address" */
  delete_user_address?: Maybe<User_Address_Mutation_Response>;
  /** delete single row from the table: "user_address" */
  delete_user_address_by_pk?: Maybe<User_Address>;
  /** delete data from the table: "user_attribute" */
  delete_user_attribute?: Maybe<User_Attribute_Mutation_Response>;
  /** delete single row from the table: "user_attribute" */
  delete_user_attribute_by_pk?: Maybe<User_Attribute>;
  /** delete data from the table: "user_auth" */
  delete_user_auth?: Maybe<User_Auth_Mutation_Response>;
  /** delete single row from the table: "user_auth" */
  delete_user_auth_by_pk?: Maybe<User_Auth>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_compliance_check" */
  delete_user_compliance_check?: Maybe<User_Compliance_Check_Mutation_Response>;
  /** delete single row from the table: "user_compliance_check" */
  delete_user_compliance_check_by_pk?: Maybe<User_Compliance_Check>;
  /** delete data from the table: "user_compliance_check_status" */
  delete_user_compliance_check_status?: Maybe<User_Compliance_Check_Status_Mutation_Response>;
  /** delete single row from the table: "user_compliance_check_status" */
  delete_user_compliance_check_status_by_pk?: Maybe<User_Compliance_Check_Status>;
  /** delete data from the table: "user_email" */
  delete_user_email?: Maybe<User_Email_Mutation_Response>;
  /** delete single row from the table: "user_email" */
  delete_user_email_by_pk?: Maybe<User_Email>;
  /** delete data from the table: "user_national_id" */
  delete_user_national_id?: Maybe<User_National_Id_Mutation_Response>;
  /** delete single row from the table: "user_national_id" */
  delete_user_national_id_by_pk?: Maybe<User_National_Id>;
  /** delete data from the table: "user_phone_number" */
  delete_user_phone_number?: Maybe<User_Phone_Number_Mutation_Response>;
  /** delete single row from the table: "user_phone_number" */
  delete_user_phone_number_by_pk?: Maybe<User_Phone_Number>;
  /** delete data from the table: "value_type" */
  delete_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** delete single row from the table: "value_type" */
  delete_value_type_by_pk?: Maybe<Value_Type>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert data into the table: "address_country" */
  insert_address_country?: Maybe<Address_Country_Mutation_Response>;
  /** insert a single row into the table: "address_country" */
  insert_address_country_one?: Maybe<Address_Country>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "address_state" */
  insert_address_state?: Maybe<Address_State_Mutation_Response>;
  /** insert a single row into the table: "address_state" */
  insert_address_state_one?: Maybe<Address_State>;
  /** insert data into the table: "app" */
  insert_app?: Maybe<App_Mutation_Response>;
  /** insert a single row into the table: "app" */
  insert_app_one?: Maybe<App>;
  /** insert data into the table: "cache" */
  insert_cache?: Maybe<Cache_Mutation_Response>;
  /** insert a single row into the table: "cache" */
  insert_cache_one?: Maybe<Cache>;
  /** insert data into the table: "category" */
  insert_category?: Maybe<Category_Mutation_Response>;
  /** insert data into the table: "category_namespace" */
  insert_category_namespace?: Maybe<Category_Namespace_Mutation_Response>;
  /** insert a single row into the table: "category_namespace" */
  insert_category_namespace_one?: Maybe<Category_Namespace>;
  /** insert a single row into the table: "category" */
  insert_category_one?: Maybe<Category>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert data into the table: "company_address" */
  insert_company_address?: Maybe<Company_Address_Mutation_Response>;
  /** insert a single row into the table: "company_address" */
  insert_company_address_one?: Maybe<Company_Address>;
  /** insert data into the table: "company_attribute" */
  insert_company_attribute?: Maybe<Company_Attribute_Mutation_Response>;
  /** insert a single row into the table: "company_attribute" */
  insert_company_attribute_one?: Maybe<Company_Attribute>;
  /** insert data into the table: "company_category" */
  insert_company_category?: Maybe<Company_Category_Mutation_Response>;
  /** insert a single row into the table: "company_category" */
  insert_company_category_one?: Maybe<Company_Category>;
  /** insert data into the table: "company_email" */
  insert_company_email?: Maybe<Company_Email_Mutation_Response>;
  /** insert a single row into the table: "company_email" */
  insert_company_email_one?: Maybe<Company_Email>;
  /** insert data into the table: "company_form_response" */
  insert_company_form_response?: Maybe<Company_Form_Response_Mutation_Response>;
  /** insert a single row into the table: "company_form_response" */
  insert_company_form_response_one?: Maybe<Company_Form_Response>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "company_phone_number" */
  insert_company_phone_number?: Maybe<Company_Phone_Number_Mutation_Response>;
  /** insert a single row into the table: "company_phone_number" */
  insert_company_phone_number_one?: Maybe<Company_Phone_Number>;
  /** insert data into the table: "company_user" */
  insert_company_user?: Maybe<Company_User_Mutation_Response>;
  /** insert a single row into the table: "company_user" */
  insert_company_user_one?: Maybe<Company_User>;
  /** insert data into the table: "compliance_account" */
  insert_compliance_account?: Maybe<Compliance_Account_Mutation_Response>;
  /** insert a single row into the table: "compliance_account" */
  insert_compliance_account_one?: Maybe<Compliance_Account>;
  /** insert data into the table: "compliance_account_user" */
  insert_compliance_account_user?: Maybe<Compliance_Account_User_Mutation_Response>;
  /** insert a single row into the table: "compliance_account_user" */
  insert_compliance_account_user_one?: Maybe<Compliance_Account_User>;
  /** insert data into the table: "compliance_check" */
  insert_compliance_check?: Maybe<Compliance_Check_Mutation_Response>;
  /** insert a single row into the table: "compliance_check" */
  insert_compliance_check_one?: Maybe<Compliance_Check>;
  /** insert data into the table: "compliance_check_type" */
  insert_compliance_check_type?: Maybe<Compliance_Check_Type_Mutation_Response>;
  /** insert a single row into the table: "compliance_check_type" */
  insert_compliance_check_type_one?: Maybe<Compliance_Check_Type>;
  /** insert data into the table: "compliance_dashboard" */
  insert_compliance_dashboard?: Maybe<Compliance_Dashboard_Mutation_Response>;
  /** insert data into the table: "compliance_dashboard_check_type" */
  insert_compliance_dashboard_check_type?: Maybe<Compliance_Dashboard_Check_Type_Mutation_Response>;
  /** insert a single row into the table: "compliance_dashboard_check_type" */
  insert_compliance_dashboard_check_type_one?: Maybe<Compliance_Dashboard_Check_Type>;
  /** insert a single row into the table: "compliance_dashboard" */
  insert_compliance_dashboard_one?: Maybe<Compliance_Dashboard>;
  /** insert data into the table: "compliance_dashboard_user" */
  insert_compliance_dashboard_user?: Maybe<Compliance_Dashboard_User_Mutation_Response>;
  /** insert a single row into the table: "compliance_dashboard_user" */
  insert_compliance_dashboard_user_one?: Maybe<Compliance_Dashboard_User>;
  /** insert data into the table: "compliance_entity_company" */
  insert_compliance_entity_company?: Maybe<Compliance_Entity_Company_Mutation_Response>;
  /** insert data into the table: "compliance_entity_company_address" */
  insert_compliance_entity_company_address?: Maybe<Compliance_Entity_Company_Address_Mutation_Response>;
  /** insert a single row into the table: "compliance_entity_company_address" */
  insert_compliance_entity_company_address_one?: Maybe<Compliance_Entity_Company_Address>;
  /** insert a single row into the table: "compliance_entity_company" */
  insert_compliance_entity_company_one?: Maybe<Compliance_Entity_Company>;
  /** insert data into the table: "compliance_entity_person" */
  insert_compliance_entity_person?: Maybe<Compliance_Entity_Person_Mutation_Response>;
  /** insert data into the table: "compliance_entity_person_address" */
  insert_compliance_entity_person_address?: Maybe<Compliance_Entity_Person_Address_Mutation_Response>;
  /** insert a single row into the table: "compliance_entity_person_address" */
  insert_compliance_entity_person_address_one?: Maybe<Compliance_Entity_Person_Address>;
  /** insert data into the table: "compliance_entity_person_national_id" */
  insert_compliance_entity_person_national_id?: Maybe<Compliance_Entity_Person_National_Id_Mutation_Response>;
  /** insert a single row into the table: "compliance_entity_person_national_id" */
  insert_compliance_entity_person_national_id_one?: Maybe<Compliance_Entity_Person_National_Id>;
  /** insert a single row into the table: "compliance_entity_person" */
  insert_compliance_entity_person_one?: Maybe<Compliance_Entity_Person>;
  /** insert data into the table: "entity" */
  insert_entity?: Maybe<Entity_Mutation_Response>;
  /** insert data into the table: "entity_attribute_group" */
  insert_entity_attribute_group?: Maybe<Entity_Attribute_Group_Mutation_Response>;
  /** insert a single row into the table: "entity_attribute_group" */
  insert_entity_attribute_group_one?: Maybe<Entity_Attribute_Group>;
  /** insert data into the table: "entity_attribute_type" */
  insert_entity_attribute_type?: Maybe<Entity_Attribute_Type_Mutation_Response>;
  /** insert a single row into the table: "entity_attribute_type" */
  insert_entity_attribute_type_one?: Maybe<Entity_Attribute_Type>;
  /** insert a single row into the table: "entity" */
  insert_entity_one?: Maybe<Entity>;
  /** insert data into the table: "file" */
  insert_file?: Maybe<File_Mutation_Response>;
  /** insert a single row into the table: "file" */
  insert_file_one?: Maybe<File>;
  /** insert data into the table: "form" */
  insert_form?: Maybe<Form_Mutation_Response>;
  /** insert data into the table: "form_group" */
  insert_form_group?: Maybe<Form_Group_Mutation_Response>;
  /** insert a single row into the table: "form_group" */
  insert_form_group_one?: Maybe<Form_Group>;
  /** insert data into the table: "form_item" */
  insert_form_item?: Maybe<Form_Item_Mutation_Response>;
  /** insert a single row into the table: "form_item" */
  insert_form_item_one?: Maybe<Form_Item>;
  /** insert data into the table: "form_item_response" */
  insert_form_item_response?: Maybe<Form_Item_Response_Mutation_Response>;
  /** insert a single row into the table: "form_item_response" */
  insert_form_item_response_one?: Maybe<Form_Item_Response>;
  /** insert data into the table: "form_namespace" */
  insert_form_namespace?: Maybe<Form_Namespace_Mutation_Response>;
  /** insert a single row into the table: "form_namespace" */
  insert_form_namespace_one?: Maybe<Form_Namespace>;
  /** insert a single row into the table: "form" */
  insert_form_one?: Maybe<Form>;
  /** insert data into the table: "form_response" */
  insert_form_response?: Maybe<Form_Response_Mutation_Response>;
  /** insert a single row into the table: "form_response" */
  insert_form_response_one?: Maybe<Form_Response>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<Job_Mutation_Response>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<Job>;
  /** insert data into the table: "job_run" */
  insert_job_run?: Maybe<Job_Run_Mutation_Response>;
  /** insert a single row into the table: "job_run" */
  insert_job_run_one?: Maybe<Job_Run>;
  /** insert data into the table: "log" */
  insert_log?: Maybe<Log_Mutation_Response>;
  /** insert a single row into the table: "log" */
  insert_log_one?: Maybe<Log>;
  /** insert data into the table: "national_id_type" */
  insert_national_id_type?: Maybe<National_Id_Type_Mutation_Response>;
  /** insert a single row into the table: "national_id_type" */
  insert_national_id_type_one?: Maybe<National_Id_Type>;
  /** insert data into the table: "public_configuration" */
  insert_public_configuration?: Maybe<Public_Configuration_Mutation_Response>;
  /** insert a single row into the table: "public_configuration" */
  insert_public_configuration_one?: Maybe<Public_Configuration>;
  /** insert data into the table: "public_configuration_value" */
  insert_public_configuration_value?: Maybe<Public_Configuration_Value_Mutation_Response>;
  /** insert a single row into the table: "public_configuration_value" */
  insert_public_configuration_value_one?: Maybe<Public_Configuration_Value>;
  /** insert data into the table: "store_order" */
  insert_store_order?: Maybe<Store_Order_Mutation_Response>;
  /** insert data into the table: "store_order_address" */
  insert_store_order_address?: Maybe<Store_Order_Address_Mutation_Response>;
  /** insert a single row into the table: "store_order_address" */
  insert_store_order_address_one?: Maybe<Store_Order_Address>;
  /** insert data into the table: "store_order_address_type" */
  insert_store_order_address_type?: Maybe<Store_Order_Address_Type_Mutation_Response>;
  /** insert a single row into the table: "store_order_address_type" */
  insert_store_order_address_type_one?: Maybe<Store_Order_Address_Type>;
  /** insert data into the table: "store_order_item" */
  insert_store_order_item?: Maybe<Store_Order_Item_Mutation_Response>;
  /** insert a single row into the table: "store_order_item" */
  insert_store_order_item_one?: Maybe<Store_Order_Item>;
  /** insert a single row into the table: "store_order" */
  insert_store_order_one?: Maybe<Store_Order>;
  /** insert data into the table: "store_product" */
  insert_store_product?: Maybe<Store_Product_Mutation_Response>;
  /** insert data into the table: "store_product_attribute" */
  insert_store_product_attribute?: Maybe<Store_Product_Attribute_Mutation_Response>;
  /** insert a single row into the table: "store_product_attribute" */
  insert_store_product_attribute_one?: Maybe<Store_Product_Attribute>;
  /** insert data into the table: "store_product_category" */
  insert_store_product_category?: Maybe<Store_Product_Category_Mutation_Response>;
  /** insert a single row into the table: "store_product_category" */
  insert_store_product_category_one?: Maybe<Store_Product_Category>;
  /** insert data into the table: "store_product_link" */
  insert_store_product_link?: Maybe<Store_Product_Link_Mutation_Response>;
  /** insert a single row into the table: "store_product_link" */
  insert_store_product_link_one?: Maybe<Store_Product_Link>;
  /** insert data into the table: "store_product_link_type" */
  insert_store_product_link_type?: Maybe<Store_Product_Link_Type_Mutation_Response>;
  /** insert a single row into the table: "store_product_link_type" */
  insert_store_product_link_type_one?: Maybe<Store_Product_Link_Type>;
  /** insert data into the table: "store_product_media" */
  insert_store_product_media?: Maybe<Store_Product_Media_Mutation_Response>;
  /** insert a single row into the table: "store_product_media" */
  insert_store_product_media_one?: Maybe<Store_Product_Media>;
  /** insert a single row into the table: "store_product" */
  insert_store_product_one?: Maybe<Store_Product>;
  /** insert data into the table: "subscription_interval" */
  insert_subscription_interval?: Maybe<Subscription_Interval_Mutation_Response>;
  /** insert a single row into the table: "subscription_interval" */
  insert_subscription_interval_one?: Maybe<Subscription_Interval>;
  /** insert data into the table: "tracking_event" */
  insert_tracking_event?: Maybe<Tracking_Event_Mutation_Response>;
  /** insert a single row into the table: "tracking_event" */
  insert_tracking_event_one?: Maybe<Tracking_Event>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_address" */
  insert_user_address?: Maybe<User_Address_Mutation_Response>;
  /** insert a single row into the table: "user_address" */
  insert_user_address_one?: Maybe<User_Address>;
  /** insert data into the table: "user_attribute" */
  insert_user_attribute?: Maybe<User_Attribute_Mutation_Response>;
  /** insert a single row into the table: "user_attribute" */
  insert_user_attribute_one?: Maybe<User_Attribute>;
  /** insert data into the table: "user_auth" */
  insert_user_auth?: Maybe<User_Auth_Mutation_Response>;
  /** insert a single row into the table: "user_auth" */
  insert_user_auth_one?: Maybe<User_Auth>;
  /** insert data into the table: "user_compliance_check" */
  insert_user_compliance_check?: Maybe<User_Compliance_Check_Mutation_Response>;
  /** insert a single row into the table: "user_compliance_check" */
  insert_user_compliance_check_one?: Maybe<User_Compliance_Check>;
  /** insert data into the table: "user_compliance_check_status" */
  insert_user_compliance_check_status?: Maybe<User_Compliance_Check_Status_Mutation_Response>;
  /** insert a single row into the table: "user_compliance_check_status" */
  insert_user_compliance_check_status_one?: Maybe<User_Compliance_Check_Status>;
  /** insert data into the table: "user_email" */
  insert_user_email?: Maybe<User_Email_Mutation_Response>;
  /** insert a single row into the table: "user_email" */
  insert_user_email_one?: Maybe<User_Email>;
  /** insert data into the table: "user_national_id" */
  insert_user_national_id?: Maybe<User_National_Id_Mutation_Response>;
  /** insert a single row into the table: "user_national_id" */
  insert_user_national_id_one?: Maybe<User_National_Id>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_phone_number" */
  insert_user_phone_number?: Maybe<User_Phone_Number_Mutation_Response>;
  /** insert a single row into the table: "user_phone_number" */
  insert_user_phone_number_one?: Maybe<User_Phone_Number>;
  /** insert data into the table: "value_type" */
  insert_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** insert a single row into the table: "value_type" */
  insert_value_type_one?: Maybe<Value_Type>;
  runUserComplianceChecks: Array<RunUserComplianceCheckOutput>;
  /** Starts AML Check for given product and user data */
  startAml?: Maybe<ComplianceCheck>;
  /** unitPurchaseRequest */
  unitPurchaseRequest?: Maybe<UnitPurchaseRequestOutput>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update data of the table: "address_country" */
  update_address_country?: Maybe<Address_Country_Mutation_Response>;
  /** update single row of the table: "address_country" */
  update_address_country_by_pk?: Maybe<Address_Country>;
  /** update data of the table: "address_state" */
  update_address_state?: Maybe<Address_State_Mutation_Response>;
  /** update single row of the table: "address_state" */
  update_address_state_by_pk?: Maybe<Address_State>;
  /** update data of the table: "app" */
  update_app?: Maybe<App_Mutation_Response>;
  /** update single row of the table: "app" */
  update_app_by_pk?: Maybe<App>;
  /** update data of the table: "cache" */
  update_cache?: Maybe<Cache_Mutation_Response>;
  /** update single row of the table: "cache" */
  update_cache_by_pk?: Maybe<Cache>;
  /** update data of the table: "category" */
  update_category?: Maybe<Category_Mutation_Response>;
  /** update single row of the table: "category" */
  update_category_by_pk?: Maybe<Category>;
  /** update data of the table: "category_namespace" */
  update_category_namespace?: Maybe<Category_Namespace_Mutation_Response>;
  /** update single row of the table: "category_namespace" */
  update_category_namespace_by_pk?: Maybe<Category_Namespace>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update data of the table: "company_address" */
  update_company_address?: Maybe<Company_Address_Mutation_Response>;
  /** update single row of the table: "company_address" */
  update_company_address_by_pk?: Maybe<Company_Address>;
  /** update data of the table: "company_attribute" */
  update_company_attribute?: Maybe<Company_Attribute_Mutation_Response>;
  /** update single row of the table: "company_attribute" */
  update_company_attribute_by_pk?: Maybe<Company_Attribute>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update data of the table: "company_category" */
  update_company_category?: Maybe<Company_Category_Mutation_Response>;
  /** update single row of the table: "company_category" */
  update_company_category_by_pk?: Maybe<Company_Category>;
  /** update data of the table: "company_email" */
  update_company_email?: Maybe<Company_Email_Mutation_Response>;
  /** update single row of the table: "company_email" */
  update_company_email_by_pk?: Maybe<Company_Email>;
  /** update data of the table: "company_form_response" */
  update_company_form_response?: Maybe<Company_Form_Response_Mutation_Response>;
  /** update single row of the table: "company_form_response" */
  update_company_form_response_by_pk?: Maybe<Company_Form_Response>;
  /** update data of the table: "company_phone_number" */
  update_company_phone_number?: Maybe<Company_Phone_Number_Mutation_Response>;
  /** update single row of the table: "company_phone_number" */
  update_company_phone_number_by_pk?: Maybe<Company_Phone_Number>;
  /** update data of the table: "company_user" */
  update_company_user?: Maybe<Company_User_Mutation_Response>;
  /** update single row of the table: "company_user" */
  update_company_user_by_pk?: Maybe<Company_User>;
  /** update data of the table: "compliance_account" */
  update_compliance_account?: Maybe<Compliance_Account_Mutation_Response>;
  /** update single row of the table: "compliance_account" */
  update_compliance_account_by_pk?: Maybe<Compliance_Account>;
  /** update data of the table: "compliance_account_user" */
  update_compliance_account_user?: Maybe<Compliance_Account_User_Mutation_Response>;
  /** update single row of the table: "compliance_account_user" */
  update_compliance_account_user_by_pk?: Maybe<Compliance_Account_User>;
  /** update data of the table: "compliance_check" */
  update_compliance_check?: Maybe<Compliance_Check_Mutation_Response>;
  /** update single row of the table: "compliance_check" */
  update_compliance_check_by_pk?: Maybe<Compliance_Check>;
  /** update data of the table: "compliance_check_type" */
  update_compliance_check_type?: Maybe<Compliance_Check_Type_Mutation_Response>;
  /** update single row of the table: "compliance_check_type" */
  update_compliance_check_type_by_pk?: Maybe<Compliance_Check_Type>;
  /** update data of the table: "compliance_dashboard" */
  update_compliance_dashboard?: Maybe<Compliance_Dashboard_Mutation_Response>;
  /** update single row of the table: "compliance_dashboard" */
  update_compliance_dashboard_by_pk?: Maybe<Compliance_Dashboard>;
  /** update data of the table: "compliance_dashboard_check_type" */
  update_compliance_dashboard_check_type?: Maybe<Compliance_Dashboard_Check_Type_Mutation_Response>;
  /** update single row of the table: "compliance_dashboard_check_type" */
  update_compliance_dashboard_check_type_by_pk?: Maybe<Compliance_Dashboard_Check_Type>;
  /** update data of the table: "compliance_dashboard_user" */
  update_compliance_dashboard_user?: Maybe<Compliance_Dashboard_User_Mutation_Response>;
  /** update single row of the table: "compliance_dashboard_user" */
  update_compliance_dashboard_user_by_pk?: Maybe<Compliance_Dashboard_User>;
  /** update data of the table: "compliance_entity_company" */
  update_compliance_entity_company?: Maybe<Compliance_Entity_Company_Mutation_Response>;
  /** update data of the table: "compliance_entity_company_address" */
  update_compliance_entity_company_address?: Maybe<Compliance_Entity_Company_Address_Mutation_Response>;
  /** update single row of the table: "compliance_entity_company_address" */
  update_compliance_entity_company_address_by_pk?: Maybe<Compliance_Entity_Company_Address>;
  /** update single row of the table: "compliance_entity_company" */
  update_compliance_entity_company_by_pk?: Maybe<Compliance_Entity_Company>;
  /** update data of the table: "compliance_entity_person" */
  update_compliance_entity_person?: Maybe<Compliance_Entity_Person_Mutation_Response>;
  /** update data of the table: "compliance_entity_person_address" */
  update_compliance_entity_person_address?: Maybe<Compliance_Entity_Person_Address_Mutation_Response>;
  /** update single row of the table: "compliance_entity_person_address" */
  update_compliance_entity_person_address_by_pk?: Maybe<Compliance_Entity_Person_Address>;
  /** update single row of the table: "compliance_entity_person" */
  update_compliance_entity_person_by_pk?: Maybe<Compliance_Entity_Person>;
  /** update data of the table: "compliance_entity_person_national_id" */
  update_compliance_entity_person_national_id?: Maybe<Compliance_Entity_Person_National_Id_Mutation_Response>;
  /** update single row of the table: "compliance_entity_person_national_id" */
  update_compliance_entity_person_national_id_by_pk?: Maybe<Compliance_Entity_Person_National_Id>;
  /** update data of the table: "entity" */
  update_entity?: Maybe<Entity_Mutation_Response>;
  /** update data of the table: "entity_attribute_group" */
  update_entity_attribute_group?: Maybe<Entity_Attribute_Group_Mutation_Response>;
  /** update single row of the table: "entity_attribute_group" */
  update_entity_attribute_group_by_pk?: Maybe<Entity_Attribute_Group>;
  /** update data of the table: "entity_attribute_type" */
  update_entity_attribute_type?: Maybe<Entity_Attribute_Type_Mutation_Response>;
  /** update single row of the table: "entity_attribute_type" */
  update_entity_attribute_type_by_pk?: Maybe<Entity_Attribute_Type>;
  /** update single row of the table: "entity" */
  update_entity_by_pk?: Maybe<Entity>;
  /** update data of the table: "file" */
  update_file?: Maybe<File_Mutation_Response>;
  /** update single row of the table: "file" */
  update_file_by_pk?: Maybe<File>;
  /** update data of the table: "form" */
  update_form?: Maybe<Form_Mutation_Response>;
  /** update single row of the table: "form" */
  update_form_by_pk?: Maybe<Form>;
  /** update data of the table: "form_group" */
  update_form_group?: Maybe<Form_Group_Mutation_Response>;
  /** update single row of the table: "form_group" */
  update_form_group_by_pk?: Maybe<Form_Group>;
  /** update data of the table: "form_item" */
  update_form_item?: Maybe<Form_Item_Mutation_Response>;
  /** update single row of the table: "form_item" */
  update_form_item_by_pk?: Maybe<Form_Item>;
  /** update data of the table: "form_item_response" */
  update_form_item_response?: Maybe<Form_Item_Response_Mutation_Response>;
  /** update single row of the table: "form_item_response" */
  update_form_item_response_by_pk?: Maybe<Form_Item_Response>;
  /** update data of the table: "form_namespace" */
  update_form_namespace?: Maybe<Form_Namespace_Mutation_Response>;
  /** update single row of the table: "form_namespace" */
  update_form_namespace_by_pk?: Maybe<Form_Namespace>;
  /** update data of the table: "form_response" */
  update_form_response?: Maybe<Form_Response_Mutation_Response>;
  /** update single row of the table: "form_response" */
  update_form_response_by_pk?: Maybe<Form_Response>;
  /** update data of the table: "job" */
  update_job?: Maybe<Job_Mutation_Response>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<Job>;
  /** update data of the table: "job_run" */
  update_job_run?: Maybe<Job_Run_Mutation_Response>;
  /** update single row of the table: "job_run" */
  update_job_run_by_pk?: Maybe<Job_Run>;
  /** update data of the table: "log" */
  update_log?: Maybe<Log_Mutation_Response>;
  /** update single row of the table: "log" */
  update_log_by_pk?: Maybe<Log>;
  /** update data of the table: "national_id_type" */
  update_national_id_type?: Maybe<National_Id_Type_Mutation_Response>;
  /** update single row of the table: "national_id_type" */
  update_national_id_type_by_pk?: Maybe<National_Id_Type>;
  /** update data of the table: "public_configuration" */
  update_public_configuration?: Maybe<Public_Configuration_Mutation_Response>;
  /** update single row of the table: "public_configuration" */
  update_public_configuration_by_pk?: Maybe<Public_Configuration>;
  /** update data of the table: "public_configuration_value" */
  update_public_configuration_value?: Maybe<Public_Configuration_Value_Mutation_Response>;
  /** update single row of the table: "public_configuration_value" */
  update_public_configuration_value_by_pk?: Maybe<Public_Configuration_Value>;
  /** update data of the table: "store_order" */
  update_store_order?: Maybe<Store_Order_Mutation_Response>;
  /** update data of the table: "store_order_address" */
  update_store_order_address?: Maybe<Store_Order_Address_Mutation_Response>;
  /** update single row of the table: "store_order_address" */
  update_store_order_address_by_pk?: Maybe<Store_Order_Address>;
  /** update data of the table: "store_order_address_type" */
  update_store_order_address_type?: Maybe<Store_Order_Address_Type_Mutation_Response>;
  /** update single row of the table: "store_order_address_type" */
  update_store_order_address_type_by_pk?: Maybe<Store_Order_Address_Type>;
  /** update single row of the table: "store_order" */
  update_store_order_by_pk?: Maybe<Store_Order>;
  /** update data of the table: "store_order_item" */
  update_store_order_item?: Maybe<Store_Order_Item_Mutation_Response>;
  /** update single row of the table: "store_order_item" */
  update_store_order_item_by_pk?: Maybe<Store_Order_Item>;
  /** update data of the table: "store_product" */
  update_store_product?: Maybe<Store_Product_Mutation_Response>;
  /** update data of the table: "store_product_attribute" */
  update_store_product_attribute?: Maybe<Store_Product_Attribute_Mutation_Response>;
  /** update single row of the table: "store_product_attribute" */
  update_store_product_attribute_by_pk?: Maybe<Store_Product_Attribute>;
  /** update single row of the table: "store_product" */
  update_store_product_by_pk?: Maybe<Store_Product>;
  /** update data of the table: "store_product_category" */
  update_store_product_category?: Maybe<Store_Product_Category_Mutation_Response>;
  /** update single row of the table: "store_product_category" */
  update_store_product_category_by_pk?: Maybe<Store_Product_Category>;
  /** update data of the table: "store_product_link" */
  update_store_product_link?: Maybe<Store_Product_Link_Mutation_Response>;
  /** update single row of the table: "store_product_link" */
  update_store_product_link_by_pk?: Maybe<Store_Product_Link>;
  /** update data of the table: "store_product_link_type" */
  update_store_product_link_type?: Maybe<Store_Product_Link_Type_Mutation_Response>;
  /** update single row of the table: "store_product_link_type" */
  update_store_product_link_type_by_pk?: Maybe<Store_Product_Link_Type>;
  /** update data of the table: "store_product_media" */
  update_store_product_media?: Maybe<Store_Product_Media_Mutation_Response>;
  /** update single row of the table: "store_product_media" */
  update_store_product_media_by_pk?: Maybe<Store_Product_Media>;
  /** update data of the table: "subscription_interval" */
  update_subscription_interval?: Maybe<Subscription_Interval_Mutation_Response>;
  /** update single row of the table: "subscription_interval" */
  update_subscription_interval_by_pk?: Maybe<Subscription_Interval>;
  /** update data of the table: "tracking_event" */
  update_tracking_event?: Maybe<Tracking_Event_Mutation_Response>;
  /** update single row of the table: "tracking_event" */
  update_tracking_event_by_pk?: Maybe<Tracking_Event>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "user_address" */
  update_user_address?: Maybe<User_Address_Mutation_Response>;
  /** update single row of the table: "user_address" */
  update_user_address_by_pk?: Maybe<User_Address>;
  /** update data of the table: "user_attribute" */
  update_user_attribute?: Maybe<User_Attribute_Mutation_Response>;
  /** update single row of the table: "user_attribute" */
  update_user_attribute_by_pk?: Maybe<User_Attribute>;
  /** update data of the table: "user_auth" */
  update_user_auth?: Maybe<User_Auth_Mutation_Response>;
  /** update single row of the table: "user_auth" */
  update_user_auth_by_pk?: Maybe<User_Auth>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_compliance_check" */
  update_user_compliance_check?: Maybe<User_Compliance_Check_Mutation_Response>;
  /** update single row of the table: "user_compliance_check" */
  update_user_compliance_check_by_pk?: Maybe<User_Compliance_Check>;
  /** update data of the table: "user_compliance_check_status" */
  update_user_compliance_check_status?: Maybe<User_Compliance_Check_Status_Mutation_Response>;
  /** update single row of the table: "user_compliance_check_status" */
  update_user_compliance_check_status_by_pk?: Maybe<User_Compliance_Check_Status>;
  /** update data of the table: "user_email" */
  update_user_email?: Maybe<User_Email_Mutation_Response>;
  /** update single row of the table: "user_email" */
  update_user_email_by_pk?: Maybe<User_Email>;
  /** update data of the table: "user_national_id" */
  update_user_national_id?: Maybe<User_National_Id_Mutation_Response>;
  /** update single row of the table: "user_national_id" */
  update_user_national_id_by_pk?: Maybe<User_National_Id>;
  /** update data of the table: "user_phone_number" */
  update_user_phone_number?: Maybe<User_Phone_Number_Mutation_Response>;
  /** update single row of the table: "user_phone_number" */
  update_user_phone_number_by_pk?: Maybe<User_Phone_Number>;
  /** update data of the table: "value_type" */
  update_value_type?: Maybe<Value_Type_Mutation_Response>;
  /** update single row of the table: "value_type" */
  update_value_type_by_pk?: Maybe<Value_Type>;
};


/** mutation root */
export type Mutation_RootCreateExpiringContactCardCodeArgs = {
  input?: InputMaybe<ExpiringCodeInput>;
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Address_CountryArgs = {
  where: Address_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_Country_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Address_StateArgs = {
  where: Address_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_State_By_PkArgs = {
  code: Scalars['String'];
  countryCode: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AppArgs = {
  where: App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CacheArgs = {
  where: Cache_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cache_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CategoryArgs = {
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_By_PkArgs = {
  urlPath: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Category_NamespaceArgs = {
  where: Category_Namespace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Namespace_By_PkArgs = {
  namespace: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_AddressArgs = {
  where: Company_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Company_AttributeArgs = {
  where: Company_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Attribute_By_PkArgs = {
  companyId: Scalars['uuid'];
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Company_CategoryArgs = {
  where: Company_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Category_By_PkArgs = {
  categoryUrlPath: Scalars['String'];
  companyId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Company_EmailArgs = {
  where: Company_Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Email_By_PkArgs = {
  companyId: Scalars['uuid'];
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Form_ResponseArgs = {
  where: Company_Form_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Form_Response_By_PkArgs = {
  companyId: Scalars['uuid'];
  formResponseId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Phone_NumberArgs = {
  where: Company_Phone_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Phone_Number_By_PkArgs = {
  companyId: Scalars['uuid'];
  number: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Company_UserArgs = {
  where: Company_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_User_By_PkArgs = {
  companyId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_AccountArgs = {
  where: Compliance_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Account_UserArgs = {
  where: Compliance_Account_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Account_User_By_PkArgs = {
  accountId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_CheckArgs = {
  where: Compliance_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Check_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Check_TypeArgs = {
  where: Compliance_Check_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Check_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_DashboardArgs = {
  where: Compliance_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Dashboard_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Dashboard_Check_TypeArgs = {
  where: Compliance_Dashboard_Check_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Dashboard_Check_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Dashboard_UserArgs = {
  where: Compliance_Dashboard_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Dashboard_User_By_PkArgs = {
  dashboardId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_CompanyArgs = {
  where: Compliance_Entity_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Company_AddressArgs = {
  where: Compliance_Entity_Company_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Company_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_PersonArgs = {
  where: Compliance_Entity_Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Person_AddressArgs = {
  where: Compliance_Entity_Person_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Person_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  personId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Person_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Person_National_IdArgs = {
  where: Compliance_Entity_Person_National_Id_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Compliance_Entity_Person_National_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EntityArgs = {
  where: Entity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entity_Attribute_GroupArgs = {
  where: Entity_Attribute_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entity_Attribute_Group_By_PkArgs = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Entity_Attribute_TypeArgs = {
  where: Entity_Attribute_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Entity_Attribute_Type_By_PkArgs = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Entity_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FormArgs = {
  where: Form_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_GroupArgs = {
  where: Form_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Group_By_PkArgs = {
  childId: Scalars['uuid'];
  parentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_ItemArgs = {
  where: Form_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Item_By_PkArgs = {
  formId: Scalars['uuid'];
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_Item_ResponseArgs = {
  where: Form_Item_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Item_Response_By_PkArgs = {
  formItemName: Scalars['String'];
  formResponseId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Form_NamespaceArgs = {
  where: Form_Namespace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Namespace_By_PkArgs = {
  namespace: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Form_ResponseArgs = {
  where: Form_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Form_Response_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_JobArgs = {
  where: Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Job_RunArgs = {
  where: Job_Run_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Job_Run_By_PkArgs = {
  createdAt: Scalars['timestamptz'];
  jobName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_LogArgs = {
  where: Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_National_Id_TypeArgs = {
  where: National_Id_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_National_Id_Type_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Public_ConfigurationArgs = {
  where: Public_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Public_Configuration_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Public_Configuration_ValueArgs = {
  where: Public_Configuration_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Public_Configuration_Value_By_PkArgs = {
  configurationName: Scalars['String'];
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_OrderArgs = {
  where: Store_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Order_AddressArgs = {
  where: Store_Order_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Order_Address_By_PkArgs = {
  addressType: Store_Order_Address_Type_Enum;
  orderId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Order_Address_TypeArgs = {
  where: Store_Order_Address_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Order_Address_Type_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Order_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Order_ItemArgs = {
  where: Store_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Order_Item_By_PkArgs = {
  orderId: Scalars['String'];
  productSku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_ProductArgs = {
  where: Store_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_AttributeArgs = {
  where: Store_Product_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_Attribute_By_PkArgs = {
  name: Scalars['String'];
  sku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_By_PkArgs = {
  sku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_CategoryArgs = {
  where: Store_Product_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_Category_By_PkArgs = {
  sku: Scalars['String'];
  urlPath: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_LinkArgs = {
  where: Store_Product_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_Link_By_PkArgs = {
  linkType: Store_Product_Link_Type_Enum;
  linkedSku: Scalars['String'];
  sku: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_Link_TypeArgs = {
  where: Store_Product_Link_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_Link_Type_By_PkArgs = {
  link_type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_MediaArgs = {
  where: Store_Product_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Store_Product_Media_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_IntervalArgs = {
  where: Subscription_Interval_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_Interval_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Tracking_EventArgs = {
  where: Tracking_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tracking_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_AddressArgs = {
  where: User_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_AttributeArgs = {
  where: User_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Attribute_By_PkArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_AuthArgs = {
  where: User_Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Auth_By_PkArgs = {
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Compliance_CheckArgs = {
  where: User_Compliance_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Compliance_Check_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Compliance_Check_StatusArgs = {
  where: User_Compliance_Check_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Compliance_Check_Status_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_User_EmailArgs = {
  where: User_Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Email_By_PkArgs = {
  email: Scalars['String'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_National_IdArgs = {
  where: User_National_Id_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_National_Id_By_PkArgs = {
  type_name: Scalars['String'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Phone_NumberArgs = {
  where: User_Phone_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Phone_Number_By_PkArgs = {
  number: Scalars['String'];
  userId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Value_TypeArgs = {
  where: Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Value_Type_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_CountryArgs = {
  objects: Array<Address_Country_Insert_Input>;
  on_conflict?: InputMaybe<Address_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_Country_OneArgs = {
  object: Address_Country_Insert_Input;
  on_conflict?: InputMaybe<Address_Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: InputMaybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_StateArgs = {
  objects: Array<Address_State_Insert_Input>;
  on_conflict?: InputMaybe<Address_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_State_OneArgs = {
  object: Address_State_Insert_Input;
  on_conflict?: InputMaybe<Address_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AppArgs = {
  objects: Array<App_Insert_Input>;
  on_conflict?: InputMaybe<App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_OneArgs = {
  object: App_Insert_Input;
  on_conflict?: InputMaybe<App_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CacheArgs = {
  objects: Array<Cache_Insert_Input>;
  on_conflict?: InputMaybe<Cache_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cache_OneArgs = {
  object: Cache_Insert_Input;
  on_conflict?: InputMaybe<Cache_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoryArgs = {
  objects: Array<Category_Insert_Input>;
  on_conflict?: InputMaybe<Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_NamespaceArgs = {
  objects: Array<Category_Namespace_Insert_Input>;
  on_conflict?: InputMaybe<Category_Namespace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Namespace_OneArgs = {
  object: Category_Namespace_Insert_Input;
  on_conflict?: InputMaybe<Category_Namespace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_OneArgs = {
  object: Category_Insert_Input;
  on_conflict?: InputMaybe<Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_AddressArgs = {
  objects: Array<Company_Address_Insert_Input>;
  on_conflict?: InputMaybe<Company_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Address_OneArgs = {
  object: Company_Address_Insert_Input;
  on_conflict?: InputMaybe<Company_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_AttributeArgs = {
  objects: Array<Company_Attribute_Insert_Input>;
  on_conflict?: InputMaybe<Company_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Attribute_OneArgs = {
  object: Company_Attribute_Insert_Input;
  on_conflict?: InputMaybe<Company_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_CategoryArgs = {
  objects: Array<Company_Category_Insert_Input>;
  on_conflict?: InputMaybe<Company_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Category_OneArgs = {
  object: Company_Category_Insert_Input;
  on_conflict?: InputMaybe<Company_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_EmailArgs = {
  objects: Array<Company_Email_Insert_Input>;
  on_conflict?: InputMaybe<Company_Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Email_OneArgs = {
  object: Company_Email_Insert_Input;
  on_conflict?: InputMaybe<Company_Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Form_ResponseArgs = {
  objects: Array<Company_Form_Response_Insert_Input>;
  on_conflict?: InputMaybe<Company_Form_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Form_Response_OneArgs = {
  object: Company_Form_Response_Insert_Input;
  on_conflict?: InputMaybe<Company_Form_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Phone_NumberArgs = {
  objects: Array<Company_Phone_Number_Insert_Input>;
  on_conflict?: InputMaybe<Company_Phone_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Phone_Number_OneArgs = {
  object: Company_Phone_Number_Insert_Input;
  on_conflict?: InputMaybe<Company_Phone_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_UserArgs = {
  objects: Array<Company_User_Insert_Input>;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_User_OneArgs = {
  object: Company_User_Insert_Input;
  on_conflict?: InputMaybe<Company_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_AccountArgs = {
  objects: Array<Compliance_Account_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Account_OneArgs = {
  object: Compliance_Account_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Account_UserArgs = {
  objects: Array<Compliance_Account_User_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Account_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Account_User_OneArgs = {
  object: Compliance_Account_User_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Account_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_CheckArgs = {
  objects: Array<Compliance_Check_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Check_OneArgs = {
  object: Compliance_Check_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Check_TypeArgs = {
  objects: Array<Compliance_Check_Type_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Check_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Check_Type_OneArgs = {
  object: Compliance_Check_Type_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Check_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_DashboardArgs = {
  objects: Array<Compliance_Dashboard_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Dashboard_Check_TypeArgs = {
  objects: Array<Compliance_Dashboard_Check_Type_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Dashboard_Check_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Dashboard_Check_Type_OneArgs = {
  object: Compliance_Dashboard_Check_Type_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Dashboard_Check_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Dashboard_OneArgs = {
  object: Compliance_Dashboard_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Dashboard_UserArgs = {
  objects: Array<Compliance_Dashboard_User_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Dashboard_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Dashboard_User_OneArgs = {
  object: Compliance_Dashboard_User_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Dashboard_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_CompanyArgs = {
  objects: Array<Compliance_Entity_Company_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Entity_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Company_AddressArgs = {
  objects: Array<Compliance_Entity_Company_Address_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Entity_Company_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Company_Address_OneArgs = {
  object: Compliance_Entity_Company_Address_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Entity_Company_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Company_OneArgs = {
  object: Compliance_Entity_Company_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Entity_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_PersonArgs = {
  objects: Array<Compliance_Entity_Person_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Entity_Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Person_AddressArgs = {
  objects: Array<Compliance_Entity_Person_Address_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Entity_Person_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Person_Address_OneArgs = {
  object: Compliance_Entity_Person_Address_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Entity_Person_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Person_National_IdArgs = {
  objects: Array<Compliance_Entity_Person_National_Id_Insert_Input>;
  on_conflict?: InputMaybe<Compliance_Entity_Person_National_Id_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Person_National_Id_OneArgs = {
  object: Compliance_Entity_Person_National_Id_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Entity_Person_National_Id_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Compliance_Entity_Person_OneArgs = {
  object: Compliance_Entity_Person_Insert_Input;
  on_conflict?: InputMaybe<Compliance_Entity_Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EntityArgs = {
  objects: Array<Entity_Insert_Input>;
  on_conflict?: InputMaybe<Entity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_Attribute_GroupArgs = {
  objects: Array<Entity_Attribute_Group_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Attribute_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_Attribute_Group_OneArgs = {
  object: Entity_Attribute_Group_Insert_Input;
  on_conflict?: InputMaybe<Entity_Attribute_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_Attribute_TypeArgs = {
  objects: Array<Entity_Attribute_Type_Insert_Input>;
  on_conflict?: InputMaybe<Entity_Attribute_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_Attribute_Type_OneArgs = {
  object: Entity_Attribute_Type_Insert_Input;
  on_conflict?: InputMaybe<Entity_Attribute_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Entity_OneArgs = {
  object: Entity_Insert_Input;
  on_conflict?: InputMaybe<Entity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FormArgs = {
  objects: Array<Form_Insert_Input>;
  on_conflict?: InputMaybe<Form_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_GroupArgs = {
  objects: Array<Form_Group_Insert_Input>;
  on_conflict?: InputMaybe<Form_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Group_OneArgs = {
  object: Form_Group_Insert_Input;
  on_conflict?: InputMaybe<Form_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_ItemArgs = {
  objects: Array<Form_Item_Insert_Input>;
  on_conflict?: InputMaybe<Form_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Item_OneArgs = {
  object: Form_Item_Insert_Input;
  on_conflict?: InputMaybe<Form_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Item_ResponseArgs = {
  objects: Array<Form_Item_Response_Insert_Input>;
  on_conflict?: InputMaybe<Form_Item_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Item_Response_OneArgs = {
  object: Form_Item_Response_Insert_Input;
  on_conflict?: InputMaybe<Form_Item_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_NamespaceArgs = {
  objects: Array<Form_Namespace_Insert_Input>;
  on_conflict?: InputMaybe<Form_Namespace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Namespace_OneArgs = {
  object: Form_Namespace_Insert_Input;
  on_conflict?: InputMaybe<Form_Namespace_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_OneArgs = {
  object: Form_Insert_Input;
  on_conflict?: InputMaybe<Form_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_ResponseArgs = {
  objects: Array<Form_Response_Insert_Input>;
  on_conflict?: InputMaybe<Form_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Form_Response_OneArgs = {
  object: Form_Response_Insert_Input;
  on_conflict?: InputMaybe<Form_Response_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_JobArgs = {
  objects: Array<Job_Insert_Input>;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_OneArgs = {
  object: Job_Insert_Input;
  on_conflict?: InputMaybe<Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_RunArgs = {
  objects: Array<Job_Run_Insert_Input>;
  on_conflict?: InputMaybe<Job_Run_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Job_Run_OneArgs = {
  object: Job_Run_Insert_Input;
  on_conflict?: InputMaybe<Job_Run_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LogArgs = {
  objects: Array<Log_Insert_Input>;
  on_conflict?: InputMaybe<Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Log_OneArgs = {
  object: Log_Insert_Input;
  on_conflict?: InputMaybe<Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_National_Id_TypeArgs = {
  objects: Array<National_Id_Type_Insert_Input>;
  on_conflict?: InputMaybe<National_Id_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_National_Id_Type_OneArgs = {
  object: National_Id_Type_Insert_Input;
  on_conflict?: InputMaybe<National_Id_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_ConfigurationArgs = {
  objects: Array<Public_Configuration_Insert_Input>;
  on_conflict?: InputMaybe<Public_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Configuration_OneArgs = {
  object: Public_Configuration_Insert_Input;
  on_conflict?: InputMaybe<Public_Configuration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Configuration_ValueArgs = {
  objects: Array<Public_Configuration_Value_Insert_Input>;
  on_conflict?: InputMaybe<Public_Configuration_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Public_Configuration_Value_OneArgs = {
  object: Public_Configuration_Value_Insert_Input;
  on_conflict?: InputMaybe<Public_Configuration_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_OrderArgs = {
  objects: Array<Store_Order_Insert_Input>;
  on_conflict?: InputMaybe<Store_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Order_AddressArgs = {
  objects: Array<Store_Order_Address_Insert_Input>;
  on_conflict?: InputMaybe<Store_Order_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Order_Address_OneArgs = {
  object: Store_Order_Address_Insert_Input;
  on_conflict?: InputMaybe<Store_Order_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Order_Address_TypeArgs = {
  objects: Array<Store_Order_Address_Type_Insert_Input>;
  on_conflict?: InputMaybe<Store_Order_Address_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Order_Address_Type_OneArgs = {
  object: Store_Order_Address_Type_Insert_Input;
  on_conflict?: InputMaybe<Store_Order_Address_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Order_ItemArgs = {
  objects: Array<Store_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Store_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Order_Item_OneArgs = {
  object: Store_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Store_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Order_OneArgs = {
  object: Store_Order_Insert_Input;
  on_conflict?: InputMaybe<Store_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_ProductArgs = {
  objects: Array<Store_Product_Insert_Input>;
  on_conflict?: InputMaybe<Store_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_AttributeArgs = {
  objects: Array<Store_Product_Attribute_Insert_Input>;
  on_conflict?: InputMaybe<Store_Product_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_Attribute_OneArgs = {
  object: Store_Product_Attribute_Insert_Input;
  on_conflict?: InputMaybe<Store_Product_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_CategoryArgs = {
  objects: Array<Store_Product_Category_Insert_Input>;
  on_conflict?: InputMaybe<Store_Product_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_Category_OneArgs = {
  object: Store_Product_Category_Insert_Input;
  on_conflict?: InputMaybe<Store_Product_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_LinkArgs = {
  objects: Array<Store_Product_Link_Insert_Input>;
  on_conflict?: InputMaybe<Store_Product_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_Link_OneArgs = {
  object: Store_Product_Link_Insert_Input;
  on_conflict?: InputMaybe<Store_Product_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_Link_TypeArgs = {
  objects: Array<Store_Product_Link_Type_Insert_Input>;
  on_conflict?: InputMaybe<Store_Product_Link_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_Link_Type_OneArgs = {
  object: Store_Product_Link_Type_Insert_Input;
  on_conflict?: InputMaybe<Store_Product_Link_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_MediaArgs = {
  objects: Array<Store_Product_Media_Insert_Input>;
  on_conflict?: InputMaybe<Store_Product_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_Media_OneArgs = {
  object: Store_Product_Media_Insert_Input;
  on_conflict?: InputMaybe<Store_Product_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Store_Product_OneArgs = {
  object: Store_Product_Insert_Input;
  on_conflict?: InputMaybe<Store_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_IntervalArgs = {
  objects: Array<Subscription_Interval_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Interval_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_Interval_OneArgs = {
  object: Subscription_Interval_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Interval_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_EventArgs = {
  objects: Array<Tracking_Event_Insert_Input>;
  on_conflict?: InputMaybe<Tracking_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tracking_Event_OneArgs = {
  object: Tracking_Event_Insert_Input;
  on_conflict?: InputMaybe<Tracking_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AddressArgs = {
  objects: Array<User_Address_Insert_Input>;
  on_conflict?: InputMaybe<User_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Address_OneArgs = {
  object: User_Address_Insert_Input;
  on_conflict?: InputMaybe<User_Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AttributeArgs = {
  objects: Array<User_Attribute_Insert_Input>;
  on_conflict?: InputMaybe<User_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Attribute_OneArgs = {
  object: User_Attribute_Insert_Input;
  on_conflict?: InputMaybe<User_Attribute_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_AuthArgs = {
  objects: Array<User_Auth_Insert_Input>;
  on_conflict?: InputMaybe<User_Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Auth_OneArgs = {
  object: User_Auth_Insert_Input;
  on_conflict?: InputMaybe<User_Auth_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Compliance_CheckArgs = {
  objects: Array<User_Compliance_Check_Insert_Input>;
  on_conflict?: InputMaybe<User_Compliance_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Compliance_Check_OneArgs = {
  object: User_Compliance_Check_Insert_Input;
  on_conflict?: InputMaybe<User_Compliance_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Compliance_Check_StatusArgs = {
  objects: Array<User_Compliance_Check_Status_Insert_Input>;
  on_conflict?: InputMaybe<User_Compliance_Check_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Compliance_Check_Status_OneArgs = {
  object: User_Compliance_Check_Status_Insert_Input;
  on_conflict?: InputMaybe<User_Compliance_Check_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_EmailArgs = {
  objects: Array<User_Email_Insert_Input>;
  on_conflict?: InputMaybe<User_Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Email_OneArgs = {
  object: User_Email_Insert_Input;
  on_conflict?: InputMaybe<User_Email_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_National_IdArgs = {
  objects: Array<User_National_Id_Insert_Input>;
  on_conflict?: InputMaybe<User_National_Id_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_National_Id_OneArgs = {
  object: User_National_Id_Insert_Input;
  on_conflict?: InputMaybe<User_National_Id_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Phone_NumberArgs = {
  objects: Array<User_Phone_Number_Insert_Input>;
  on_conflict?: InputMaybe<User_Phone_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Phone_Number_OneArgs = {
  object: User_Phone_Number_Insert_Input;
  on_conflict?: InputMaybe<User_Phone_Number_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Value_TypeArgs = {
  objects: Array<Value_Type_Insert_Input>;
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Value_Type_OneArgs = {
  object: Value_Type_Insert_Input;
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRunUserComplianceChecksArgs = {
  input: RunUserComplianceCheckInput;
};


/** mutation root */
export type Mutation_RootStartAmlArgs = {
  input: ComplianceCheckConfig;
};


/** mutation root */
export type Mutation_RootUnitPurchaseRequestArgs = {
  input?: InputMaybe<UnitPurchaseRequestInput>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: InputMaybe<Address_Inc_Input>;
  _set?: InputMaybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_CountryArgs = {
  _append?: InputMaybe<Address_Country_Append_Input>;
  _delete_at_path?: InputMaybe<Address_Country_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Address_Country_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Address_Country_Delete_Key_Input>;
  _inc?: InputMaybe<Address_Country_Inc_Input>;
  _prepend?: InputMaybe<Address_Country_Prepend_Input>;
  _set?: InputMaybe<Address_Country_Set_Input>;
  where: Address_Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_Country_By_PkArgs = {
  _append?: InputMaybe<Address_Country_Append_Input>;
  _delete_at_path?: InputMaybe<Address_Country_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Address_Country_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Address_Country_Delete_Key_Input>;
  _inc?: InputMaybe<Address_Country_Inc_Input>;
  _prepend?: InputMaybe<Address_Country_Prepend_Input>;
  _set?: InputMaybe<Address_Country_Set_Input>;
  pk_columns: Address_Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_StateArgs = {
  _inc?: InputMaybe<Address_State_Inc_Input>;
  _set?: InputMaybe<Address_State_Set_Input>;
  where: Address_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_State_By_PkArgs = {
  _inc?: InputMaybe<Address_State_Inc_Input>;
  _set?: InputMaybe<Address_State_Set_Input>;
  pk_columns: Address_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AppArgs = {
  _append?: InputMaybe<App_Append_Input>;
  _delete_at_path?: InputMaybe<App_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Prepend_Input>;
  _set?: InputMaybe<App_Set_Input>;
  where: App_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_By_PkArgs = {
  _append?: InputMaybe<App_Append_Input>;
  _delete_at_path?: InputMaybe<App_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<App_Delete_Elem_Input>;
  _delete_key?: InputMaybe<App_Delete_Key_Input>;
  _prepend?: InputMaybe<App_Prepend_Input>;
  _set?: InputMaybe<App_Set_Input>;
  pk_columns: App_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CacheArgs = {
  _set?: InputMaybe<Cache_Set_Input>;
  where: Cache_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cache_By_PkArgs = {
  _set?: InputMaybe<Cache_Set_Input>;
  pk_columns: Cache_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CategoryArgs = {
  _inc?: InputMaybe<Category_Inc_Input>;
  _set?: InputMaybe<Category_Set_Input>;
  where: Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_By_PkArgs = {
  _inc?: InputMaybe<Category_Inc_Input>;
  _set?: InputMaybe<Category_Set_Input>;
  pk_columns: Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_NamespaceArgs = {
  _set?: InputMaybe<Category_Namespace_Set_Input>;
  where: Category_Namespace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Namespace_By_PkArgs = {
  _set?: InputMaybe<Category_Namespace_Set_Input>;
  pk_columns: Category_Namespace_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_AddressArgs = {
  _set?: InputMaybe<Company_Address_Set_Input>;
  where: Company_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Address_By_PkArgs = {
  _set?: InputMaybe<Company_Address_Set_Input>;
  pk_columns: Company_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_AttributeArgs = {
  _set?: InputMaybe<Company_Attribute_Set_Input>;
  where: Company_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Attribute_By_PkArgs = {
  _set?: InputMaybe<Company_Attribute_Set_Input>;
  pk_columns: Company_Attribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_CategoryArgs = {
  _set?: InputMaybe<Company_Category_Set_Input>;
  where: Company_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Category_By_PkArgs = {
  _set?: InputMaybe<Company_Category_Set_Input>;
  pk_columns: Company_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_EmailArgs = {
  _set?: InputMaybe<Company_Email_Set_Input>;
  where: Company_Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Email_By_PkArgs = {
  _set?: InputMaybe<Company_Email_Set_Input>;
  pk_columns: Company_Email_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Form_ResponseArgs = {
  _set?: InputMaybe<Company_Form_Response_Set_Input>;
  where: Company_Form_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Form_Response_By_PkArgs = {
  _set?: InputMaybe<Company_Form_Response_Set_Input>;
  pk_columns: Company_Form_Response_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Phone_NumberArgs = {
  _set?: InputMaybe<Company_Phone_Number_Set_Input>;
  where: Company_Phone_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Phone_Number_By_PkArgs = {
  _set?: InputMaybe<Company_Phone_Number_Set_Input>;
  pk_columns: Company_Phone_Number_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_UserArgs = {
  _set?: InputMaybe<Company_User_Set_Input>;
  where: Company_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_User_By_PkArgs = {
  _set?: InputMaybe<Company_User_Set_Input>;
  pk_columns: Company_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_AccountArgs = {
  _set?: InputMaybe<Compliance_Account_Set_Input>;
  where: Compliance_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Account_By_PkArgs = {
  _set?: InputMaybe<Compliance_Account_Set_Input>;
  pk_columns: Compliance_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Account_UserArgs = {
  _set?: InputMaybe<Compliance_Account_User_Set_Input>;
  where: Compliance_Account_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Account_User_By_PkArgs = {
  _set?: InputMaybe<Compliance_Account_User_Set_Input>;
  pk_columns: Compliance_Account_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_CheckArgs = {
  _append?: InputMaybe<Compliance_Check_Append_Input>;
  _delete_at_path?: InputMaybe<Compliance_Check_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compliance_Check_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compliance_Check_Delete_Key_Input>;
  _prepend?: InputMaybe<Compliance_Check_Prepend_Input>;
  _set?: InputMaybe<Compliance_Check_Set_Input>;
  where: Compliance_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Check_By_PkArgs = {
  _append?: InputMaybe<Compliance_Check_Append_Input>;
  _delete_at_path?: InputMaybe<Compliance_Check_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compliance_Check_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compliance_Check_Delete_Key_Input>;
  _prepend?: InputMaybe<Compliance_Check_Prepend_Input>;
  _set?: InputMaybe<Compliance_Check_Set_Input>;
  pk_columns: Compliance_Check_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Check_TypeArgs = {
  _append?: InputMaybe<Compliance_Check_Type_Append_Input>;
  _delete_at_path?: InputMaybe<Compliance_Check_Type_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compliance_Check_Type_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compliance_Check_Type_Delete_Key_Input>;
  _prepend?: InputMaybe<Compliance_Check_Type_Prepend_Input>;
  _set?: InputMaybe<Compliance_Check_Type_Set_Input>;
  where: Compliance_Check_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Check_Type_By_PkArgs = {
  _append?: InputMaybe<Compliance_Check_Type_Append_Input>;
  _delete_at_path?: InputMaybe<Compliance_Check_Type_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compliance_Check_Type_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compliance_Check_Type_Delete_Key_Input>;
  _prepend?: InputMaybe<Compliance_Check_Type_Prepend_Input>;
  _set?: InputMaybe<Compliance_Check_Type_Set_Input>;
  pk_columns: Compliance_Check_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_DashboardArgs = {
  _inc?: InputMaybe<Compliance_Dashboard_Inc_Input>;
  _set?: InputMaybe<Compliance_Dashboard_Set_Input>;
  where: Compliance_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Dashboard_By_PkArgs = {
  _inc?: InputMaybe<Compliance_Dashboard_Inc_Input>;
  _set?: InputMaybe<Compliance_Dashboard_Set_Input>;
  pk_columns: Compliance_Dashboard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Dashboard_Check_TypeArgs = {
  _inc?: InputMaybe<Compliance_Dashboard_Check_Type_Inc_Input>;
  _set?: InputMaybe<Compliance_Dashboard_Check_Type_Set_Input>;
  where: Compliance_Dashboard_Check_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Dashboard_Check_Type_By_PkArgs = {
  _inc?: InputMaybe<Compliance_Dashboard_Check_Type_Inc_Input>;
  _set?: InputMaybe<Compliance_Dashboard_Check_Type_Set_Input>;
  pk_columns: Compliance_Dashboard_Check_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Dashboard_UserArgs = {
  _set?: InputMaybe<Compliance_Dashboard_User_Set_Input>;
  where: Compliance_Dashboard_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Dashboard_User_By_PkArgs = {
  _set?: InputMaybe<Compliance_Dashboard_User_Set_Input>;
  pk_columns: Compliance_Dashboard_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_CompanyArgs = {
  _set?: InputMaybe<Compliance_Entity_Company_Set_Input>;
  where: Compliance_Entity_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Company_AddressArgs = {
  _set?: InputMaybe<Compliance_Entity_Company_Address_Set_Input>;
  where: Compliance_Entity_Company_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Company_Address_By_PkArgs = {
  _set?: InputMaybe<Compliance_Entity_Company_Address_Set_Input>;
  pk_columns: Compliance_Entity_Company_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Company_By_PkArgs = {
  _set?: InputMaybe<Compliance_Entity_Company_Set_Input>;
  pk_columns: Compliance_Entity_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_PersonArgs = {
  _set?: InputMaybe<Compliance_Entity_Person_Set_Input>;
  where: Compliance_Entity_Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Person_AddressArgs = {
  _set?: InputMaybe<Compliance_Entity_Person_Address_Set_Input>;
  where: Compliance_Entity_Person_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Person_Address_By_PkArgs = {
  _set?: InputMaybe<Compliance_Entity_Person_Address_Set_Input>;
  pk_columns: Compliance_Entity_Person_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Person_By_PkArgs = {
  _set?: InputMaybe<Compliance_Entity_Person_Set_Input>;
  pk_columns: Compliance_Entity_Person_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Person_National_IdArgs = {
  _set?: InputMaybe<Compliance_Entity_Person_National_Id_Set_Input>;
  where: Compliance_Entity_Person_National_Id_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Compliance_Entity_Person_National_Id_By_PkArgs = {
  _set?: InputMaybe<Compliance_Entity_Person_National_Id_Set_Input>;
  pk_columns: Compliance_Entity_Person_National_Id_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EntityArgs = {
  _set?: InputMaybe<Entity_Set_Input>;
  where: Entity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_Attribute_GroupArgs = {
  _append?: InputMaybe<Entity_Attribute_Group_Append_Input>;
  _delete_at_path?: InputMaybe<Entity_Attribute_Group_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Entity_Attribute_Group_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Entity_Attribute_Group_Delete_Key_Input>;
  _inc?: InputMaybe<Entity_Attribute_Group_Inc_Input>;
  _prepend?: InputMaybe<Entity_Attribute_Group_Prepend_Input>;
  _set?: InputMaybe<Entity_Attribute_Group_Set_Input>;
  where: Entity_Attribute_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_Attribute_Group_By_PkArgs = {
  _append?: InputMaybe<Entity_Attribute_Group_Append_Input>;
  _delete_at_path?: InputMaybe<Entity_Attribute_Group_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Entity_Attribute_Group_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Entity_Attribute_Group_Delete_Key_Input>;
  _inc?: InputMaybe<Entity_Attribute_Group_Inc_Input>;
  _prepend?: InputMaybe<Entity_Attribute_Group_Prepend_Input>;
  _set?: InputMaybe<Entity_Attribute_Group_Set_Input>;
  pk_columns: Entity_Attribute_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_Attribute_TypeArgs = {
  _append?: InputMaybe<Entity_Attribute_Type_Append_Input>;
  _delete_at_path?: InputMaybe<Entity_Attribute_Type_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Entity_Attribute_Type_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Entity_Attribute_Type_Delete_Key_Input>;
  _inc?: InputMaybe<Entity_Attribute_Type_Inc_Input>;
  _prepend?: InputMaybe<Entity_Attribute_Type_Prepend_Input>;
  _set?: InputMaybe<Entity_Attribute_Type_Set_Input>;
  where: Entity_Attribute_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_Attribute_Type_By_PkArgs = {
  _append?: InputMaybe<Entity_Attribute_Type_Append_Input>;
  _delete_at_path?: InputMaybe<Entity_Attribute_Type_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Entity_Attribute_Type_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Entity_Attribute_Type_Delete_Key_Input>;
  _inc?: InputMaybe<Entity_Attribute_Type_Inc_Input>;
  _prepend?: InputMaybe<Entity_Attribute_Type_Prepend_Input>;
  _set?: InputMaybe<Entity_Attribute_Type_Set_Input>;
  pk_columns: Entity_Attribute_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Entity_By_PkArgs = {
  _set?: InputMaybe<Entity_Set_Input>;
  pk_columns: Entity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FileArgs = {
  _append?: InputMaybe<File_Append_Input>;
  _delete_at_path?: InputMaybe<File_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<File_Delete_Elem_Input>;
  _delete_key?: InputMaybe<File_Delete_Key_Input>;
  _inc?: InputMaybe<File_Inc_Input>;
  _prepend?: InputMaybe<File_Prepend_Input>;
  _set?: InputMaybe<File_Set_Input>;
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_By_PkArgs = {
  _append?: InputMaybe<File_Append_Input>;
  _delete_at_path?: InputMaybe<File_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<File_Delete_Elem_Input>;
  _delete_key?: InputMaybe<File_Delete_Key_Input>;
  _inc?: InputMaybe<File_Inc_Input>;
  _prepend?: InputMaybe<File_Prepend_Input>;
  _set?: InputMaybe<File_Set_Input>;
  pk_columns: File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FormArgs = {
  _append?: InputMaybe<Form_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Delete_Key_Input>;
  _prepend?: InputMaybe<Form_Prepend_Input>;
  _set?: InputMaybe<Form_Set_Input>;
  where: Form_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_By_PkArgs = {
  _append?: InputMaybe<Form_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Delete_Key_Input>;
  _prepend?: InputMaybe<Form_Prepend_Input>;
  _set?: InputMaybe<Form_Set_Input>;
  pk_columns: Form_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_GroupArgs = {
  _inc?: InputMaybe<Form_Group_Inc_Input>;
  _set?: InputMaybe<Form_Group_Set_Input>;
  where: Form_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Group_By_PkArgs = {
  _inc?: InputMaybe<Form_Group_Inc_Input>;
  _set?: InputMaybe<Form_Group_Set_Input>;
  pk_columns: Form_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_ItemArgs = {
  _append?: InputMaybe<Form_Item_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Item_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Item_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Item_Delete_Key_Input>;
  _inc?: InputMaybe<Form_Item_Inc_Input>;
  _prepend?: InputMaybe<Form_Item_Prepend_Input>;
  _set?: InputMaybe<Form_Item_Set_Input>;
  where: Form_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Item_By_PkArgs = {
  _append?: InputMaybe<Form_Item_Append_Input>;
  _delete_at_path?: InputMaybe<Form_Item_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Form_Item_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Form_Item_Delete_Key_Input>;
  _inc?: InputMaybe<Form_Item_Inc_Input>;
  _prepend?: InputMaybe<Form_Item_Prepend_Input>;
  _set?: InputMaybe<Form_Item_Set_Input>;
  pk_columns: Form_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Item_ResponseArgs = {
  _set?: InputMaybe<Form_Item_Response_Set_Input>;
  where: Form_Item_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Item_Response_By_PkArgs = {
  _set?: InputMaybe<Form_Item_Response_Set_Input>;
  pk_columns: Form_Item_Response_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_NamespaceArgs = {
  _set?: InputMaybe<Form_Namespace_Set_Input>;
  where: Form_Namespace_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Namespace_By_PkArgs = {
  _set?: InputMaybe<Form_Namespace_Set_Input>;
  pk_columns: Form_Namespace_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Form_ResponseArgs = {
  _set?: InputMaybe<Form_Response_Set_Input>;
  where: Form_Response_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Form_Response_By_PkArgs = {
  _set?: InputMaybe<Form_Response_Set_Input>;
  pk_columns: Form_Response_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_JobArgs = {
  _append?: InputMaybe<Job_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Delete_Key_Input>;
  _prepend?: InputMaybe<Job_Prepend_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  where: Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_By_PkArgs = {
  _append?: InputMaybe<Job_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Delete_Key_Input>;
  _prepend?: InputMaybe<Job_Prepend_Input>;
  _set?: InputMaybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Job_RunArgs = {
  _append?: InputMaybe<Job_Run_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Run_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Run_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Run_Delete_Key_Input>;
  _inc?: InputMaybe<Job_Run_Inc_Input>;
  _prepend?: InputMaybe<Job_Run_Prepend_Input>;
  _set?: InputMaybe<Job_Run_Set_Input>;
  where: Job_Run_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Job_Run_By_PkArgs = {
  _append?: InputMaybe<Job_Run_Append_Input>;
  _delete_at_path?: InputMaybe<Job_Run_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Job_Run_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Job_Run_Delete_Key_Input>;
  _inc?: InputMaybe<Job_Run_Inc_Input>;
  _prepend?: InputMaybe<Job_Run_Prepend_Input>;
  _set?: InputMaybe<Job_Run_Set_Input>;
  pk_columns: Job_Run_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LogArgs = {
  _append?: InputMaybe<Log_Append_Input>;
  _delete_at_path?: InputMaybe<Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Log_Prepend_Input>;
  _set?: InputMaybe<Log_Set_Input>;
  where: Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Log_By_PkArgs = {
  _append?: InputMaybe<Log_Append_Input>;
  _delete_at_path?: InputMaybe<Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Log_Prepend_Input>;
  _set?: InputMaybe<Log_Set_Input>;
  pk_columns: Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_National_Id_TypeArgs = {
  _set?: InputMaybe<National_Id_Type_Set_Input>;
  where: National_Id_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_National_Id_Type_By_PkArgs = {
  _set?: InputMaybe<National_Id_Type_Set_Input>;
  pk_columns: National_Id_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Public_ConfigurationArgs = {
  _set?: InputMaybe<Public_Configuration_Set_Input>;
  where: Public_Configuration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Configuration_By_PkArgs = {
  _set?: InputMaybe<Public_Configuration_Set_Input>;
  pk_columns: Public_Configuration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Configuration_ValueArgs = {
  _set?: InputMaybe<Public_Configuration_Value_Set_Input>;
  where: Public_Configuration_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Public_Configuration_Value_By_PkArgs = {
  _set?: InputMaybe<Public_Configuration_Value_Set_Input>;
  pk_columns: Public_Configuration_Value_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_OrderArgs = {
  _inc?: InputMaybe<Store_Order_Inc_Input>;
  _set?: InputMaybe<Store_Order_Set_Input>;
  where: Store_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Order_AddressArgs = {
  _inc?: InputMaybe<Store_Order_Address_Inc_Input>;
  _set?: InputMaybe<Store_Order_Address_Set_Input>;
  where: Store_Order_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Order_Address_By_PkArgs = {
  _inc?: InputMaybe<Store_Order_Address_Inc_Input>;
  _set?: InputMaybe<Store_Order_Address_Set_Input>;
  pk_columns: Store_Order_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Order_Address_TypeArgs = {
  _set?: InputMaybe<Store_Order_Address_Type_Set_Input>;
  where: Store_Order_Address_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Order_Address_Type_By_PkArgs = {
  _set?: InputMaybe<Store_Order_Address_Type_Set_Input>;
  pk_columns: Store_Order_Address_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Order_By_PkArgs = {
  _inc?: InputMaybe<Store_Order_Inc_Input>;
  _set?: InputMaybe<Store_Order_Set_Input>;
  pk_columns: Store_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Order_ItemArgs = {
  _inc?: InputMaybe<Store_Order_Item_Inc_Input>;
  _set?: InputMaybe<Store_Order_Item_Set_Input>;
  where: Store_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Order_Item_By_PkArgs = {
  _inc?: InputMaybe<Store_Order_Item_Inc_Input>;
  _set?: InputMaybe<Store_Order_Item_Set_Input>;
  pk_columns: Store_Order_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_ProductArgs = {
  _inc?: InputMaybe<Store_Product_Inc_Input>;
  _set?: InputMaybe<Store_Product_Set_Input>;
  where: Store_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_AttributeArgs = {
  _set?: InputMaybe<Store_Product_Attribute_Set_Input>;
  where: Store_Product_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_Attribute_By_PkArgs = {
  _set?: InputMaybe<Store_Product_Attribute_Set_Input>;
  pk_columns: Store_Product_Attribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_By_PkArgs = {
  _inc?: InputMaybe<Store_Product_Inc_Input>;
  _set?: InputMaybe<Store_Product_Set_Input>;
  pk_columns: Store_Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_CategoryArgs = {
  _set?: InputMaybe<Store_Product_Category_Set_Input>;
  where: Store_Product_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_Category_By_PkArgs = {
  _set?: InputMaybe<Store_Product_Category_Set_Input>;
  pk_columns: Store_Product_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_LinkArgs = {
  _set?: InputMaybe<Store_Product_Link_Set_Input>;
  where: Store_Product_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_Link_By_PkArgs = {
  _set?: InputMaybe<Store_Product_Link_Set_Input>;
  pk_columns: Store_Product_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_Link_TypeArgs = {
  _set?: InputMaybe<Store_Product_Link_Type_Set_Input>;
  where: Store_Product_Link_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_Link_Type_By_PkArgs = {
  _set?: InputMaybe<Store_Product_Link_Type_Set_Input>;
  pk_columns: Store_Product_Link_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_MediaArgs = {
  _inc?: InputMaybe<Store_Product_Media_Inc_Input>;
  _set?: InputMaybe<Store_Product_Media_Set_Input>;
  where: Store_Product_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Store_Product_Media_By_PkArgs = {
  _inc?: InputMaybe<Store_Product_Media_Inc_Input>;
  _set?: InputMaybe<Store_Product_Media_Set_Input>;
  pk_columns: Store_Product_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_IntervalArgs = {
  _set?: InputMaybe<Subscription_Interval_Set_Input>;
  where: Subscription_Interval_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_Interval_By_PkArgs = {
  _set?: InputMaybe<Subscription_Interval_Set_Input>;
  pk_columns: Subscription_Interval_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_EventArgs = {
  _append?: InputMaybe<Tracking_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Tracking_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tracking_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tracking_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Tracking_Event_Prepend_Input>;
  _set?: InputMaybe<Tracking_Event_Set_Input>;
  where: Tracking_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tracking_Event_By_PkArgs = {
  _append?: InputMaybe<Tracking_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Tracking_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tracking_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tracking_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Tracking_Event_Prepend_Input>;
  _set?: InputMaybe<Tracking_Event_Set_Input>;
  pk_columns: Tracking_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_AddressArgs = {
  _set?: InputMaybe<User_Address_Set_Input>;
  where: User_Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Address_By_PkArgs = {
  _set?: InputMaybe<User_Address_Set_Input>;
  pk_columns: User_Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_AttributeArgs = {
  _inc?: InputMaybe<User_Attribute_Inc_Input>;
  _set?: InputMaybe<User_Attribute_Set_Input>;
  where: User_Attribute_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Attribute_By_PkArgs = {
  _inc?: InputMaybe<User_Attribute_Inc_Input>;
  _set?: InputMaybe<User_Attribute_Set_Input>;
  pk_columns: User_Attribute_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_AuthArgs = {
  _set?: InputMaybe<User_Auth_Set_Input>;
  where: User_Auth_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Auth_By_PkArgs = {
  _set?: InputMaybe<User_Auth_Set_Input>;
  pk_columns: User_Auth_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Compliance_CheckArgs = {
  _append?: InputMaybe<User_Compliance_Check_Append_Input>;
  _delete_at_path?: InputMaybe<User_Compliance_Check_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Compliance_Check_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Compliance_Check_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Compliance_Check_Prepend_Input>;
  _set?: InputMaybe<User_Compliance_Check_Set_Input>;
  where: User_Compliance_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Compliance_Check_By_PkArgs = {
  _append?: InputMaybe<User_Compliance_Check_Append_Input>;
  _delete_at_path?: InputMaybe<User_Compliance_Check_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Compliance_Check_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Compliance_Check_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Compliance_Check_Prepend_Input>;
  _set?: InputMaybe<User_Compliance_Check_Set_Input>;
  pk_columns: User_Compliance_Check_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Compliance_Check_StatusArgs = {
  _set?: InputMaybe<User_Compliance_Check_Status_Set_Input>;
  where: User_Compliance_Check_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Compliance_Check_Status_By_PkArgs = {
  _set?: InputMaybe<User_Compliance_Check_Status_Set_Input>;
  pk_columns: User_Compliance_Check_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_EmailArgs = {
  _set?: InputMaybe<User_Email_Set_Input>;
  where: User_Email_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Email_By_PkArgs = {
  _set?: InputMaybe<User_Email_Set_Input>;
  pk_columns: User_Email_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_National_IdArgs = {
  _set?: InputMaybe<User_National_Id_Set_Input>;
  where: User_National_Id_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_National_Id_By_PkArgs = {
  _set?: InputMaybe<User_National_Id_Set_Input>;
  pk_columns: User_National_Id_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Phone_NumberArgs = {
  _set?: InputMaybe<User_Phone_Number_Set_Input>;
  where: User_Phone_Number_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Phone_Number_By_PkArgs = {
  _set?: InputMaybe<User_Phone_Number_Set_Input>;
  pk_columns: User_Phone_Number_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Value_TypeArgs = {
  _set?: InputMaybe<Value_Type_Set_Input>;
  where: Value_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Value_Type_By_PkArgs = {
  _set?: InputMaybe<Value_Type_Set_Input>;
  pk_columns: Value_Type_Pk_Columns_Input;
};

/** columns and relationships of "national_id_type" */
export type National_Id_Type = {
  __typename?: 'national_id_type';
  label: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "national_id_type" */
export type National_Id_Type_Aggregate = {
  __typename?: 'national_id_type_aggregate';
  aggregate?: Maybe<National_Id_Type_Aggregate_Fields>;
  nodes: Array<National_Id_Type>;
};

/** aggregate fields of "national_id_type" */
export type National_Id_Type_Aggregate_Fields = {
  __typename?: 'national_id_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<National_Id_Type_Max_Fields>;
  min?: Maybe<National_Id_Type_Min_Fields>;
};


/** aggregate fields of "national_id_type" */
export type National_Id_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<National_Id_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "national_id_type". All fields are combined with a logical 'AND'. */
export type National_Id_Type_Bool_Exp = {
  _and?: InputMaybe<Array<National_Id_Type_Bool_Exp>>;
  _not?: InputMaybe<National_Id_Type_Bool_Exp>;
  _or?: InputMaybe<Array<National_Id_Type_Bool_Exp>>;
  label?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "national_id_type" */
export enum National_Id_Type_Constraint {
  /** unique or primary key constraint */
  NationalIdTypePkey = 'national_id_type_pkey'
}

/** input type for inserting data into table "national_id_type" */
export type National_Id_Type_Insert_Input = {
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type National_Id_Type_Max_Fields = {
  __typename?: 'national_id_type_max_fields';
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type National_Id_Type_Min_Fields = {
  __typename?: 'national_id_type_min_fields';
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "national_id_type" */
export type National_Id_Type_Mutation_Response = {
  __typename?: 'national_id_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<National_Id_Type>;
};

/** input type for inserting object relation for remote table "national_id_type" */
export type National_Id_Type_Obj_Rel_Insert_Input = {
  data: National_Id_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<National_Id_Type_On_Conflict>;
};

/** on_conflict condition type for table "national_id_type" */
export type National_Id_Type_On_Conflict = {
  constraint: National_Id_Type_Constraint;
  update_columns?: Array<National_Id_Type_Update_Column>;
  where?: InputMaybe<National_Id_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "national_id_type". */
export type National_Id_Type_Order_By = {
  label?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: national_id_type */
export type National_Id_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "national_id_type" */
export enum National_Id_Type_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "national_id_type" */
export type National_Id_Type_Set_Input = {
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "national_id_type" */
export enum National_Id_Type_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Name = 'name'
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "public_configuration" */
export type Public_Configuration = {
  __typename?: 'public_configuration';
  createdAt: Scalars['timestamptz'];
  name: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "public_configuration" */
export type Public_Configuration_Aggregate = {
  __typename?: 'public_configuration_aggregate';
  aggregate?: Maybe<Public_Configuration_Aggregate_Fields>;
  nodes: Array<Public_Configuration>;
};

/** aggregate fields of "public_configuration" */
export type Public_Configuration_Aggregate_Fields = {
  __typename?: 'public_configuration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Public_Configuration_Max_Fields>;
  min?: Maybe<Public_Configuration_Min_Fields>;
};


/** aggregate fields of "public_configuration" */
export type Public_Configuration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Public_Configuration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "public_configuration". All fields are combined with a logical 'AND'. */
export type Public_Configuration_Bool_Exp = {
  _and?: InputMaybe<Array<Public_Configuration_Bool_Exp>>;
  _not?: InputMaybe<Public_Configuration_Bool_Exp>;
  _or?: InputMaybe<Array<Public_Configuration_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "public_configuration" */
export enum Public_Configuration_Constraint {
  /** unique or primary key constraint */
  PublicConfigurationsPkey = 'public_configurations_pkey'
}

/** input type for inserting data into table "public_configuration" */
export type Public_Configuration_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Public_Configuration_Max_Fields = {
  __typename?: 'public_configuration_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Public_Configuration_Min_Fields = {
  __typename?: 'public_configuration_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "public_configuration" */
export type Public_Configuration_Mutation_Response = {
  __typename?: 'public_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Public_Configuration>;
};

/** on_conflict condition type for table "public_configuration" */
export type Public_Configuration_On_Conflict = {
  constraint: Public_Configuration_Constraint;
  update_columns?: Array<Public_Configuration_Update_Column>;
  where?: InputMaybe<Public_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "public_configuration". */
export type Public_Configuration_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: public_configuration */
export type Public_Configuration_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "public_configuration" */
export enum Public_Configuration_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "public_configuration" */
export type Public_Configuration_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "public_configuration" */
export enum Public_Configuration_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "public_configuration_value" */
export type Public_Configuration_Value = {
  __typename?: 'public_configuration_value';
  configurationName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  name: Scalars['String'];
  type: Value_Type_Enum;
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "public_configuration_value" */
export type Public_Configuration_Value_Aggregate = {
  __typename?: 'public_configuration_value_aggregate';
  aggregate?: Maybe<Public_Configuration_Value_Aggregate_Fields>;
  nodes: Array<Public_Configuration_Value>;
};

/** aggregate fields of "public_configuration_value" */
export type Public_Configuration_Value_Aggregate_Fields = {
  __typename?: 'public_configuration_value_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Public_Configuration_Value_Max_Fields>;
  min?: Maybe<Public_Configuration_Value_Min_Fields>;
};


/** aggregate fields of "public_configuration_value" */
export type Public_Configuration_Value_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Public_Configuration_Value_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "public_configuration_value". All fields are combined with a logical 'AND'. */
export type Public_Configuration_Value_Bool_Exp = {
  _and?: InputMaybe<Array<Public_Configuration_Value_Bool_Exp>>;
  _not?: InputMaybe<Public_Configuration_Value_Bool_Exp>;
  _or?: InputMaybe<Array<Public_Configuration_Value_Bool_Exp>>;
  configurationName?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Value_Type_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "public_configuration_value" */
export enum Public_Configuration_Value_Constraint {
  /** unique or primary key constraint */
  PublicConfigurationValuesPkey = 'public_configuration_values_pkey'
}

/** input type for inserting data into table "public_configuration_value" */
export type Public_Configuration_Value_Insert_Input = {
  configurationName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Value_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Public_Configuration_Value_Max_Fields = {
  __typename?: 'public_configuration_value_max_fields';
  configurationName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Public_Configuration_Value_Min_Fields = {
  __typename?: 'public_configuration_value_min_fields';
  configurationName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "public_configuration_value" */
export type Public_Configuration_Value_Mutation_Response = {
  __typename?: 'public_configuration_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Public_Configuration_Value>;
};

/** on_conflict condition type for table "public_configuration_value" */
export type Public_Configuration_Value_On_Conflict = {
  constraint: Public_Configuration_Value_Constraint;
  update_columns?: Array<Public_Configuration_Value_Update_Column>;
  where?: InputMaybe<Public_Configuration_Value_Bool_Exp>;
};

/** Ordering options when selecting data from "public_configuration_value". */
export type Public_Configuration_Value_Order_By = {
  configurationName?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: public_configuration_value */
export type Public_Configuration_Value_Pk_Columns_Input = {
  configurationName: Scalars['String'];
  name: Scalars['String'];
};

/** select columns of table "public_configuration_value" */
export enum Public_Configuration_Value_Select_Column {
  /** column name */
  ConfigurationName = 'configurationName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "public_configuration_value" */
export type Public_Configuration_Value_Set_Input = {
  configurationName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Value_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "public_configuration_value" */
export enum Public_Configuration_Value_Update_Column {
  /** column name */
  ConfigurationName = 'configurationName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "address_country" */
  address_country: Array<Address_Country>;
  /** fetch aggregated fields from the table: "address_country" */
  address_country_aggregate: Address_Country_Aggregate;
  /** fetch data from the table: "address_country" using primary key columns */
  address_country_by_pk?: Maybe<Address_Country>;
  /** fetch data from the table: "address_state" */
  address_state: Array<Address_State>;
  /** fetch aggregated fields from the table: "address_state" */
  address_state_aggregate: Address_State_Aggregate;
  /** fetch data from the table: "address_state" using primary key columns */
  address_state_by_pk?: Maybe<Address_State>;
  /** fetch data from the table: "app" */
  app: Array<App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App>;
  /** fetch data from the table: "cache" */
  cache: Array<Cache>;
  /** fetch aggregated fields from the table: "cache" */
  cache_aggregate: Cache_Aggregate;
  /** fetch data from the table: "cache" using primary key columns */
  cache_by_pk?: Maybe<Cache>;
  /** fetch data from the table: "category" */
  category: Array<Category>;
  /** fetch aggregated fields from the table: "category" */
  category_aggregate: Category_Aggregate;
  /** fetch data from the table: "category" using primary key columns */
  category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "category_namespace" */
  category_namespace: Array<Category_Namespace>;
  /** fetch aggregated fields from the table: "category_namespace" */
  category_namespace_aggregate: Category_Namespace_Aggregate;
  /** fetch data from the table: "category_namespace" using primary key columns */
  category_namespace_by_pk?: Maybe<Category_Namespace>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "company_address" */
  company_address: Array<Company_Address>;
  /** fetch aggregated fields from the table: "company_address" */
  company_address_aggregate: Company_Address_Aggregate;
  /** fetch data from the table: "company_address" using primary key columns */
  company_address_by_pk?: Maybe<Company_Address>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company_attribute" */
  company_attribute: Array<Company_Attribute>;
  /** fetch aggregated fields from the table: "company_attribute" */
  company_attribute_aggregate: Company_Attribute_Aggregate;
  /** fetch data from the table: "company_attribute" using primary key columns */
  company_attribute_by_pk?: Maybe<Company_Attribute>;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_category" */
  company_category: Array<Company_Category>;
  /** fetch aggregated fields from the table: "company_category" */
  company_category_aggregate: Company_Category_Aggregate;
  /** fetch data from the table: "company_category" using primary key columns */
  company_category_by_pk?: Maybe<Company_Category>;
  /** fetch data from the table: "company_email" */
  company_email: Array<Company_Email>;
  /** fetch aggregated fields from the table: "company_email" */
  company_email_aggregate: Company_Email_Aggregate;
  /** fetch data from the table: "company_email" using primary key columns */
  company_email_by_pk?: Maybe<Company_Email>;
  /** fetch data from the table: "company_form_response" */
  company_form_response: Array<Company_Form_Response>;
  /** fetch aggregated fields from the table: "company_form_response" */
  company_form_response_aggregate: Company_Form_Response_Aggregate;
  /** fetch data from the table: "company_form_response" using primary key columns */
  company_form_response_by_pk?: Maybe<Company_Form_Response>;
  /** fetch data from the table: "company_phone_number" */
  company_phone_number: Array<Company_Phone_Number>;
  /** fetch aggregated fields from the table: "company_phone_number" */
  company_phone_number_aggregate: Company_Phone_Number_Aggregate;
  /** fetch data from the table: "company_phone_number" using primary key columns */
  company_phone_number_by_pk?: Maybe<Company_Phone_Number>;
  /** fetch data from the table: "company_user" */
  company_user: Array<Company_User>;
  /** fetch aggregated fields from the table: "company_user" */
  company_user_aggregate: Company_User_Aggregate;
  /** fetch data from the table: "company_user" using primary key columns */
  company_user_by_pk?: Maybe<Company_User>;
  /** fetch data from the table: "compliance_account" */
  compliance_account: Array<Compliance_Account>;
  /** fetch aggregated fields from the table: "compliance_account" */
  compliance_account_aggregate: Compliance_Account_Aggregate;
  /** fetch data from the table: "compliance_account" using primary key columns */
  compliance_account_by_pk?: Maybe<Compliance_Account>;
  /** fetch data from the table: "compliance_account_user" */
  compliance_account_user: Array<Compliance_Account_User>;
  /** fetch aggregated fields from the table: "compliance_account_user" */
  compliance_account_user_aggregate: Compliance_Account_User_Aggregate;
  /** fetch data from the table: "compliance_account_user" using primary key columns */
  compliance_account_user_by_pk?: Maybe<Compliance_Account_User>;
  /** fetch data from the table: "compliance_check" */
  compliance_check: Array<Compliance_Check>;
  /** fetch aggregated fields from the table: "compliance_check" */
  compliance_check_aggregate: Compliance_Check_Aggregate;
  /** fetch data from the table: "compliance_check" using primary key columns */
  compliance_check_by_pk?: Maybe<Compliance_Check>;
  /** fetch data from the table: "compliance_check_type" */
  compliance_check_type: Array<Compliance_Check_Type>;
  /** fetch aggregated fields from the table: "compliance_check_type" */
  compliance_check_type_aggregate: Compliance_Check_Type_Aggregate;
  /** fetch data from the table: "compliance_check_type" using primary key columns */
  compliance_check_type_by_pk?: Maybe<Compliance_Check_Type>;
  /** fetch data from the table: "compliance_dashboard" */
  compliance_dashboard: Array<Compliance_Dashboard>;
  /** fetch aggregated fields from the table: "compliance_dashboard" */
  compliance_dashboard_aggregate: Compliance_Dashboard_Aggregate;
  /** fetch data from the table: "compliance_dashboard" using primary key columns */
  compliance_dashboard_by_pk?: Maybe<Compliance_Dashboard>;
  /** fetch data from the table: "compliance_dashboard_check_type" */
  compliance_dashboard_check_type: Array<Compliance_Dashboard_Check_Type>;
  /** fetch aggregated fields from the table: "compliance_dashboard_check_type" */
  compliance_dashboard_check_type_aggregate: Compliance_Dashboard_Check_Type_Aggregate;
  /** fetch data from the table: "compliance_dashboard_check_type" using primary key columns */
  compliance_dashboard_check_type_by_pk?: Maybe<Compliance_Dashboard_Check_Type>;
  /** fetch data from the table: "compliance_dashboard_user" */
  compliance_dashboard_user: Array<Compliance_Dashboard_User>;
  /** fetch aggregated fields from the table: "compliance_dashboard_user" */
  compliance_dashboard_user_aggregate: Compliance_Dashboard_User_Aggregate;
  /** fetch data from the table: "compliance_dashboard_user" using primary key columns */
  compliance_dashboard_user_by_pk?: Maybe<Compliance_Dashboard_User>;
  /** fetch data from the table: "compliance_entity_company" */
  compliance_entity_company: Array<Compliance_Entity_Company>;
  /** fetch data from the table: "compliance_entity_company_address" */
  compliance_entity_company_address: Array<Compliance_Entity_Company_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_company_address" */
  compliance_entity_company_address_aggregate: Compliance_Entity_Company_Address_Aggregate;
  /** fetch data from the table: "compliance_entity_company_address" using primary key columns */
  compliance_entity_company_address_by_pk?: Maybe<Compliance_Entity_Company_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_company" */
  compliance_entity_company_aggregate: Compliance_Entity_Company_Aggregate;
  /** fetch data from the table: "compliance_entity_company" using primary key columns */
  compliance_entity_company_by_pk?: Maybe<Compliance_Entity_Company>;
  /** fetch data from the table: "compliance_entity_person" */
  compliance_entity_person: Array<Compliance_Entity_Person>;
  /** fetch data from the table: "compliance_entity_person_address" */
  compliance_entity_person_address: Array<Compliance_Entity_Person_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_person_address" */
  compliance_entity_person_address_aggregate: Compliance_Entity_Person_Address_Aggregate;
  /** fetch data from the table: "compliance_entity_person_address" using primary key columns */
  compliance_entity_person_address_by_pk?: Maybe<Compliance_Entity_Person_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_person" */
  compliance_entity_person_aggregate: Compliance_Entity_Person_Aggregate;
  /** fetch data from the table: "compliance_entity_person" using primary key columns */
  compliance_entity_person_by_pk?: Maybe<Compliance_Entity_Person>;
  /** fetch data from the table: "compliance_entity_person_national_id" */
  compliance_entity_person_national_id: Array<Compliance_Entity_Person_National_Id>;
  /** fetch aggregated fields from the table: "compliance_entity_person_national_id" */
  compliance_entity_person_national_id_aggregate: Compliance_Entity_Person_National_Id_Aggregate;
  /** fetch data from the table: "compliance_entity_person_national_id" using primary key columns */
  compliance_entity_person_national_id_by_pk?: Maybe<Compliance_Entity_Person_National_Id>;
  contactCard?: Maybe<ContactCardOutput>;
  /** fetch data from the table: "entity" */
  entity: Array<Entity>;
  /** fetch aggregated fields from the table: "entity" */
  entity_aggregate: Entity_Aggregate;
  /** fetch data from the table: "entity_attribute_group" */
  entity_attribute_group: Array<Entity_Attribute_Group>;
  /** fetch aggregated fields from the table: "entity_attribute_group" */
  entity_attribute_group_aggregate: Entity_Attribute_Group_Aggregate;
  /** fetch data from the table: "entity_attribute_group" using primary key columns */
  entity_attribute_group_by_pk?: Maybe<Entity_Attribute_Group>;
  /** fetch data from the table: "entity_attribute_type" */
  entity_attribute_type: Array<Entity_Attribute_Type>;
  /** fetch aggregated fields from the table: "entity_attribute_type" */
  entity_attribute_type_aggregate: Entity_Attribute_Type_Aggregate;
  /** fetch data from the table: "entity_attribute_type" using primary key columns */
  entity_attribute_type_by_pk?: Maybe<Entity_Attribute_Type>;
  /** fetch data from the table: "entity" using primary key columns */
  entity_by_pk?: Maybe<Entity>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "form" */
  form: Array<Form>;
  /** fetch aggregated fields from the table: "form" */
  form_aggregate: Form_Aggregate;
  /** fetch data from the table: "form" using primary key columns */
  form_by_pk?: Maybe<Form>;
  /** fetch data from the table: "form_group" */
  form_group: Array<Form_Group>;
  /** fetch aggregated fields from the table: "form_group" */
  form_group_aggregate: Form_Group_Aggregate;
  /** fetch data from the table: "form_group" using primary key columns */
  form_group_by_pk?: Maybe<Form_Group>;
  /** fetch data from the table: "form_item" */
  form_item: Array<Form_Item>;
  /** fetch aggregated fields from the table: "form_item" */
  form_item_aggregate: Form_Item_Aggregate;
  /** fetch data from the table: "form_item" using primary key columns */
  form_item_by_pk?: Maybe<Form_Item>;
  /** fetch data from the table: "form_item_response" */
  form_item_response: Array<Form_Item_Response>;
  /** fetch aggregated fields from the table: "form_item_response" */
  form_item_response_aggregate: Form_Item_Response_Aggregate;
  /** fetch data from the table: "form_item_response" using primary key columns */
  form_item_response_by_pk?: Maybe<Form_Item_Response>;
  /** fetch data from the table: "form_namespace" */
  form_namespace: Array<Form_Namespace>;
  /** fetch aggregated fields from the table: "form_namespace" */
  form_namespace_aggregate: Form_Namespace_Aggregate;
  /** fetch data from the table: "form_namespace" using primary key columns */
  form_namespace_by_pk?: Maybe<Form_Namespace>;
  /** fetch data from the table: "form_response" */
  form_response: Array<Form_Response>;
  /** fetch aggregated fields from the table: "form_response" */
  form_response_aggregate: Form_Response_Aggregate;
  /** fetch data from the table: "form_response" using primary key columns */
  form_response_by_pk?: Maybe<Form_Response>;
  isHandleAvailable?: Maybe<IsHandleAvailable>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "job_run" */
  job_run: Array<Job_Run>;
  /** fetch aggregated fields from the table: "job_run" */
  job_run_aggregate: Job_Run_Aggregate;
  /** fetch data from the table: "job_run" using primary key columns */
  job_run_by_pk?: Maybe<Job_Run>;
  /** fetch data from the table: "log" */
  log: Array<Log>;
  /** fetch aggregated fields from the table: "log" */
  log_aggregate: Log_Aggregate;
  /** fetch data from the table: "log" using primary key columns */
  log_by_pk?: Maybe<Log>;
  /** fetch data from the table: "national_id_type" */
  national_id_type: Array<National_Id_Type>;
  /** fetch aggregated fields from the table: "national_id_type" */
  national_id_type_aggregate: National_Id_Type_Aggregate;
  /** fetch data from the table: "national_id_type" using primary key columns */
  national_id_type_by_pk?: Maybe<National_Id_Type>;
  /** fetch data from the table: "public_configuration" */
  public_configuration: Array<Public_Configuration>;
  /** fetch aggregated fields from the table: "public_configuration" */
  public_configuration_aggregate: Public_Configuration_Aggregate;
  /** fetch data from the table: "public_configuration" using primary key columns */
  public_configuration_by_pk?: Maybe<Public_Configuration>;
  /** fetch data from the table: "public_configuration_value" */
  public_configuration_value: Array<Public_Configuration_Value>;
  /** fetch aggregated fields from the table: "public_configuration_value" */
  public_configuration_value_aggregate: Public_Configuration_Value_Aggregate;
  /** fetch data from the table: "public_configuration_value" using primary key columns */
  public_configuration_value_by_pk?: Maybe<Public_Configuration_Value>;
  /** fetch data from the table: "store_order" */
  store_order: Array<Store_Order>;
  /** fetch data from the table: "store_order_address" */
  store_order_address: Array<Store_Order_Address>;
  /** fetch aggregated fields from the table: "store_order_address" */
  store_order_address_aggregate: Store_Order_Address_Aggregate;
  /** fetch data from the table: "store_order_address" using primary key columns */
  store_order_address_by_pk?: Maybe<Store_Order_Address>;
  /** fetch data from the table: "store_order_address_type" */
  store_order_address_type: Array<Store_Order_Address_Type>;
  /** fetch aggregated fields from the table: "store_order_address_type" */
  store_order_address_type_aggregate: Store_Order_Address_Type_Aggregate;
  /** fetch data from the table: "store_order_address_type" using primary key columns */
  store_order_address_type_by_pk?: Maybe<Store_Order_Address_Type>;
  /** fetch aggregated fields from the table: "store_order" */
  store_order_aggregate: Store_Order_Aggregate;
  /** fetch data from the table: "store_order" using primary key columns */
  store_order_by_pk?: Maybe<Store_Order>;
  /** fetch data from the table: "store_order_item" */
  store_order_item: Array<Store_Order_Item>;
  /** fetch aggregated fields from the table: "store_order_item" */
  store_order_item_aggregate: Store_Order_Item_Aggregate;
  /** fetch data from the table: "store_order_item" using primary key columns */
  store_order_item_by_pk?: Maybe<Store_Order_Item>;
  /** fetch data from the table: "store_product" */
  store_product: Array<Store_Product>;
  /** fetch aggregated fields from the table: "store_product" */
  store_product_aggregate: Store_Product_Aggregate;
  /** fetch data from the table: "store_product_attribute" */
  store_product_attribute: Array<Store_Product_Attribute>;
  /** fetch aggregated fields from the table: "store_product_attribute" */
  store_product_attribute_aggregate: Store_Product_Attribute_Aggregate;
  /** fetch data from the table: "store_product_attribute" using primary key columns */
  store_product_attribute_by_pk?: Maybe<Store_Product_Attribute>;
  /** fetch data from the table: "store_product" using primary key columns */
  store_product_by_pk?: Maybe<Store_Product>;
  /** fetch data from the table: "store_product_category" */
  store_product_category: Array<Store_Product_Category>;
  /** fetch aggregated fields from the table: "store_product_category" */
  store_product_category_aggregate: Store_Product_Category_Aggregate;
  /** fetch data from the table: "store_product_category" using primary key columns */
  store_product_category_by_pk?: Maybe<Store_Product_Category>;
  /** fetch data from the table: "store_product_link" */
  store_product_link: Array<Store_Product_Link>;
  /** fetch aggregated fields from the table: "store_product_link" */
  store_product_link_aggregate: Store_Product_Link_Aggregate;
  /** fetch data from the table: "store_product_link" using primary key columns */
  store_product_link_by_pk?: Maybe<Store_Product_Link>;
  /** fetch data from the table: "store_product_link_type" */
  store_product_link_type: Array<Store_Product_Link_Type>;
  /** fetch aggregated fields from the table: "store_product_link_type" */
  store_product_link_type_aggregate: Store_Product_Link_Type_Aggregate;
  /** fetch data from the table: "store_product_link_type" using primary key columns */
  store_product_link_type_by_pk?: Maybe<Store_Product_Link_Type>;
  /** fetch data from the table: "store_product_media" */
  store_product_media: Array<Store_Product_Media>;
  /** fetch aggregated fields from the table: "store_product_media" */
  store_product_media_aggregate: Store_Product_Media_Aggregate;
  /** fetch data from the table: "store_product_media" using primary key columns */
  store_product_media_by_pk?: Maybe<Store_Product_Media>;
  /** fetch data from the table: "subscription_interval" */
  subscription_interval: Array<Subscription_Interval>;
  /** fetch aggregated fields from the table: "subscription_interval" */
  subscription_interval_aggregate: Subscription_Interval_Aggregate;
  /** fetch data from the table: "subscription_interval" using primary key columns */
  subscription_interval_by_pk?: Maybe<Subscription_Interval>;
  /** fetch data from the table: "tracking_event" */
  tracking_event: Array<Tracking_Event>;
  /** fetch aggregated fields from the table: "tracking_event" */
  tracking_event_aggregate: Tracking_Event_Aggregate;
  /** fetch data from the table: "tracking_event" using primary key columns */
  tracking_event_by_pk?: Maybe<Tracking_Event>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user_address" */
  user_address: Array<User_Address>;
  /** fetch aggregated fields from the table: "user_address" */
  user_address_aggregate: User_Address_Aggregate;
  /** fetch data from the table: "user_address" using primary key columns */
  user_address_by_pk?: Maybe<User_Address>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user_attribute" */
  user_attribute: Array<User_Attribute>;
  /** fetch aggregated fields from the table: "user_attribute" */
  user_attribute_aggregate: User_Attribute_Aggregate;
  /** fetch data from the table: "user_attribute" using primary key columns */
  user_attribute_by_pk?: Maybe<User_Attribute>;
  /** fetch data from the table: "user_auth" */
  user_auth: Array<User_Auth>;
  /** fetch aggregated fields from the table: "user_auth" */
  user_auth_aggregate: User_Auth_Aggregate;
  /** fetch data from the table: "user_auth" using primary key columns */
  user_auth_by_pk?: Maybe<User_Auth>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_compliance_check" */
  user_compliance_check: Array<User_Compliance_Check>;
  /** fetch aggregated fields from the table: "user_compliance_check" */
  user_compliance_check_aggregate: User_Compliance_Check_Aggregate;
  /** fetch data from the table: "user_compliance_check" using primary key columns */
  user_compliance_check_by_pk?: Maybe<User_Compliance_Check>;
  /** fetch data from the table: "user_compliance_check_status" */
  user_compliance_check_status: Array<User_Compliance_Check_Status>;
  /** fetch aggregated fields from the table: "user_compliance_check_status" */
  user_compliance_check_status_aggregate: User_Compliance_Check_Status_Aggregate;
  /** fetch data from the table: "user_compliance_check_status" using primary key columns */
  user_compliance_check_status_by_pk?: Maybe<User_Compliance_Check_Status>;
  /** fetch data from the table: "user_email" */
  user_email: Array<User_Email>;
  /** fetch aggregated fields from the table: "user_email" */
  user_email_aggregate: User_Email_Aggregate;
  /** fetch data from the table: "user_email" using primary key columns */
  user_email_by_pk?: Maybe<User_Email>;
  /** fetch data from the table: "user_national_id" */
  user_national_id: Array<User_National_Id>;
  /** fetch aggregated fields from the table: "user_national_id" */
  user_national_id_aggregate: User_National_Id_Aggregate;
  /** fetch data from the table: "user_national_id" using primary key columns */
  user_national_id_by_pk?: Maybe<User_National_Id>;
  /** fetch data from the table: "user_phone_number" */
  user_phone_number: Array<User_Phone_Number>;
  /** fetch aggregated fields from the table: "user_phone_number" */
  user_phone_number_aggregate: User_Phone_Number_Aggregate;
  /** fetch data from the table: "user_phone_number" using primary key columns */
  user_phone_number_by_pk?: Maybe<User_Phone_Number>;
  /** fetch data from the table: "value_type" */
  value_type: Array<Value_Type>;
  /** fetch aggregated fields from the table: "value_type" */
  value_type_aggregate: Value_Type_Aggregate;
  /** fetch data from the table: "value_type" using primary key columns */
  value_type_by_pk?: Maybe<Value_Type>;
};


export type Query_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAddress_CountryArgs = {
  distinct_on?: InputMaybe<Array<Address_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Country_Order_By>>;
  where?: InputMaybe<Address_Country_Bool_Exp>;
};


export type Query_RootAddress_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Country_Order_By>>;
  where?: InputMaybe<Address_Country_Bool_Exp>;
};


export type Query_RootAddress_Country_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootAddress_StateArgs = {
  distinct_on?: InputMaybe<Array<Address_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_State_Order_By>>;
  where?: InputMaybe<Address_State_Bool_Exp>;
};


export type Query_RootAddress_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_State_Order_By>>;
  where?: InputMaybe<Address_State_Bool_Exp>;
};


export type Query_RootAddress_State_By_PkArgs = {
  code: Scalars['String'];
  countryCode: Scalars['String'];
};


export type Query_RootAppArgs = {
  distinct_on?: InputMaybe<Array<App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Order_By>>;
  where?: InputMaybe<App_Bool_Exp>;
};


export type Query_RootApp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Order_By>>;
  where?: InputMaybe<App_Bool_Exp>;
};


export type Query_RootApp_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCacheArgs = {
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  where?: InputMaybe<Cache_Bool_Exp>;
};


export type Query_RootCache_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  where?: InputMaybe<Cache_Bool_Exp>;
};


export type Query_RootCache_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootCategoryArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Query_RootCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Query_RootCategory_By_PkArgs = {
  urlPath: Scalars['String'];
};


export type Query_RootCategory_NamespaceArgs = {
  distinct_on?: InputMaybe<Array<Category_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Namespace_Order_By>>;
  where?: InputMaybe<Category_Namespace_Bool_Exp>;
};


export type Query_RootCategory_Namespace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Category_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Namespace_Order_By>>;
  where?: InputMaybe<Category_Namespace_Bool_Exp>;
};


export type Query_RootCategory_Namespace_By_PkArgs = {
  namespace: Scalars['String'];
};


export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_AddressArgs = {
  distinct_on?: InputMaybe<Array<Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Address_Order_By>>;
  where?: InputMaybe<Company_Address_Bool_Exp>;
};


export type Query_RootCompany_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Address_Order_By>>;
  where?: InputMaybe<Company_Address_Bool_Exp>;
};


export type Query_RootCompany_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};


export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Company_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Attribute_Order_By>>;
  where?: InputMaybe<Company_Attribute_Bool_Exp>;
};


export type Query_RootCompany_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Attribute_Order_By>>;
  where?: InputMaybe<Company_Attribute_Bool_Exp>;
};


export type Query_RootCompany_Attribute_By_PkArgs = {
  companyId: Scalars['uuid'];
  name: Scalars['String'];
};


export type Query_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompany_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Company_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Category_Order_By>>;
  where?: InputMaybe<Company_Category_Bool_Exp>;
};


export type Query_RootCompany_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Category_Order_By>>;
  where?: InputMaybe<Company_Category_Bool_Exp>;
};


export type Query_RootCompany_Category_By_PkArgs = {
  categoryUrlPath: Scalars['String'];
  companyId: Scalars['uuid'];
};


export type Query_RootCompany_EmailArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Order_By>>;
  where?: InputMaybe<Company_Email_Bool_Exp>;
};


export type Query_RootCompany_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Order_By>>;
  where?: InputMaybe<Company_Email_Bool_Exp>;
};


export type Query_RootCompany_Email_By_PkArgs = {
  companyId: Scalars['uuid'];
  email: Scalars['String'];
};


export type Query_RootCompany_Form_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


export type Query_RootCompany_Form_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


export type Query_RootCompany_Form_Response_By_PkArgs = {
  companyId: Scalars['uuid'];
  formResponseId: Scalars['uuid'];
};


export type Query_RootCompany_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<Company_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Phone_Number_Order_By>>;
  where?: InputMaybe<Company_Phone_Number_Bool_Exp>;
};


export type Query_RootCompany_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Phone_Number_Order_By>>;
  where?: InputMaybe<Company_Phone_Number_Bool_Exp>;
};


export type Query_RootCompany_Phone_Number_By_PkArgs = {
  companyId: Scalars['uuid'];
  number: Scalars['String'];
};


export type Query_RootCompany_UserArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Query_RootCompany_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Query_RootCompany_User_By_PkArgs = {
  companyId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Query_RootCompliance_AccountArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_Order_By>>;
  where?: InputMaybe<Compliance_Account_Bool_Exp>;
};


export type Query_RootCompliance_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_Order_By>>;
  where?: InputMaybe<Compliance_Account_Bool_Exp>;
};


export type Query_RootCompliance_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompliance_Account_UserArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_User_Order_By>>;
  where?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};


export type Query_RootCompliance_Account_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_User_Order_By>>;
  where?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};


export type Query_RootCompliance_Account_User_By_PkArgs = {
  accountId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Query_RootCompliance_CheckArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Order_By>>;
  where?: InputMaybe<Compliance_Check_Bool_Exp>;
};


export type Query_RootCompliance_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Order_By>>;
  where?: InputMaybe<Compliance_Check_Bool_Exp>;
};


export type Query_RootCompliance_Check_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompliance_Check_TypeArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
};


export type Query_RootCompliance_Check_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
};


export type Query_RootCompliance_Check_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootCompliance_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
};


export type Query_RootCompliance_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
};


export type Query_RootCompliance_Dashboard_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompliance_Dashboard_Check_TypeArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
};


export type Query_RootCompliance_Dashboard_Check_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
};


export type Query_RootCompliance_Dashboard_Check_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompliance_Dashboard_UserArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};


export type Query_RootCompliance_Dashboard_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};


export type Query_RootCompliance_Dashboard_User_By_PkArgs = {
  dashboardId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Query_RootCompliance_Entity_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Company_AddressArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Company_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Company_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};


export type Query_RootCompliance_Entity_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompliance_Entity_PersonArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Person_AddressArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Person_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Person_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  personId: Scalars['uuid'];
};


export type Query_RootCompliance_Entity_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Person_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompliance_Entity_Person_National_IdArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Person_National_Id_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
};


export type Query_RootCompliance_Entity_Person_National_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootContactCardArgs = {
  input: ContactCardInput;
};


export type Query_RootEntityArgs = {
  distinct_on?: InputMaybe<Array<Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Order_By>>;
  where?: InputMaybe<Entity_Bool_Exp>;
};


export type Query_RootEntity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Order_By>>;
  where?: InputMaybe<Entity_Bool_Exp>;
};


export type Query_RootEntity_Attribute_GroupArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Group_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
};


export type Query_RootEntity_Attribute_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Group_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
};


export type Query_RootEntity_Attribute_Group_By_PkArgs = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};


export type Query_RootEntity_Attribute_TypeArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};


export type Query_RootEntity_Attribute_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};


export type Query_RootEntity_Attribute_Type_By_PkArgs = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};


export type Query_RootEntity_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFormArgs = {
  distinct_on?: InputMaybe<Array<Form_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Order_By>>;
  where?: InputMaybe<Form_Bool_Exp>;
};


export type Query_RootForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Order_By>>;
  where?: InputMaybe<Form_Bool_Exp>;
};


export type Query_RootForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootForm_GroupArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};


export type Query_RootForm_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};


export type Query_RootForm_Group_By_PkArgs = {
  childId: Scalars['uuid'];
  parentId: Scalars['uuid'];
};


export type Query_RootForm_ItemArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Order_By>>;
  where?: InputMaybe<Form_Item_Bool_Exp>;
};


export type Query_RootForm_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Order_By>>;
  where?: InputMaybe<Form_Item_Bool_Exp>;
};


export type Query_RootForm_Item_By_PkArgs = {
  formId: Scalars['uuid'];
  name: Scalars['String'];
};


export type Query_RootForm_Item_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};


export type Query_RootForm_Item_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};


export type Query_RootForm_Item_Response_By_PkArgs = {
  formItemName: Scalars['String'];
  formResponseId: Scalars['uuid'];
};


export type Query_RootForm_NamespaceArgs = {
  distinct_on?: InputMaybe<Array<Form_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Namespace_Order_By>>;
  where?: InputMaybe<Form_Namespace_Bool_Exp>;
};


export type Query_RootForm_Namespace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Namespace_Order_By>>;
  where?: InputMaybe<Form_Namespace_Bool_Exp>;
};


export type Query_RootForm_Namespace_By_PkArgs = {
  namespace: Scalars['String'];
};


export type Query_RootForm_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


export type Query_RootForm_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


export type Query_RootForm_Response_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIsHandleAvailableArgs = {
  handle: Scalars['citext'];
};


export type Query_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Query_RootJob_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootJob_RunArgs = {
  distinct_on?: InputMaybe<Array<Job_Run_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Run_Order_By>>;
  where?: InputMaybe<Job_Run_Bool_Exp>;
};


export type Query_RootJob_Run_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Run_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Run_Order_By>>;
  where?: InputMaybe<Job_Run_Bool_Exp>;
};


export type Query_RootJob_Run_By_PkArgs = {
  createdAt: Scalars['timestamptz'];
  jobName: Scalars['String'];
};


export type Query_RootLogArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};


export type Query_RootLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};


export type Query_RootLog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNational_Id_TypeArgs = {
  distinct_on?: InputMaybe<Array<National_Id_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Id_Type_Order_By>>;
  where?: InputMaybe<National_Id_Type_Bool_Exp>;
};


export type Query_RootNational_Id_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<National_Id_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Id_Type_Order_By>>;
  where?: InputMaybe<National_Id_Type_Bool_Exp>;
};


export type Query_RootNational_Id_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootPublic_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Order_By>>;
  where?: InputMaybe<Public_Configuration_Bool_Exp>;
};


export type Query_RootPublic_Configuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Order_By>>;
  where?: InputMaybe<Public_Configuration_Bool_Exp>;
};


export type Query_RootPublic_Configuration_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootPublic_Configuration_ValueArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Value_Order_By>>;
  where?: InputMaybe<Public_Configuration_Value_Bool_Exp>;
};


export type Query_RootPublic_Configuration_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Value_Order_By>>;
  where?: InputMaybe<Public_Configuration_Value_Bool_Exp>;
};


export type Query_RootPublic_Configuration_Value_By_PkArgs = {
  configurationName: Scalars['String'];
  name: Scalars['String'];
};


export type Query_RootStore_OrderArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Order_By>>;
  where?: InputMaybe<Store_Order_Bool_Exp>;
};


export type Query_RootStore_Order_AddressArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Bool_Exp>;
};


export type Query_RootStore_Order_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Bool_Exp>;
};


export type Query_RootStore_Order_Address_By_PkArgs = {
  addressType: Store_Order_Address_Type_Enum;
  orderId: Scalars['String'];
};


export type Query_RootStore_Order_Address_TypeArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Type_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Type_Bool_Exp>;
};


export type Query_RootStore_Order_Address_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Type_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Type_Bool_Exp>;
};


export type Query_RootStore_Order_Address_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Query_RootStore_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Order_By>>;
  where?: InputMaybe<Store_Order_Bool_Exp>;
};


export type Query_RootStore_Order_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootStore_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Item_Order_By>>;
  where?: InputMaybe<Store_Order_Item_Bool_Exp>;
};


export type Query_RootStore_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Item_Order_By>>;
  where?: InputMaybe<Store_Order_Item_Bool_Exp>;
};


export type Query_RootStore_Order_Item_By_PkArgs = {
  orderId: Scalars['String'];
  productSku: Scalars['String'];
};


export type Query_RootStore_ProductArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Order_By>>;
  where?: InputMaybe<Store_Product_Bool_Exp>;
};


export type Query_RootStore_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Order_By>>;
  where?: InputMaybe<Store_Product_Bool_Exp>;
};


export type Query_RootStore_Product_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Attribute_Order_By>>;
  where?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
};


export type Query_RootStore_Product_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Attribute_Order_By>>;
  where?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
};


export type Query_RootStore_Product_Attribute_By_PkArgs = {
  name: Scalars['String'];
  sku: Scalars['String'];
};


export type Query_RootStore_Product_By_PkArgs = {
  sku: Scalars['String'];
};


export type Query_RootStore_Product_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Category_Order_By>>;
  where?: InputMaybe<Store_Product_Category_Bool_Exp>;
};


export type Query_RootStore_Product_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Category_Order_By>>;
  where?: InputMaybe<Store_Product_Category_Bool_Exp>;
};


export type Query_RootStore_Product_Category_By_PkArgs = {
  sku: Scalars['String'];
  urlPath: Scalars['String'];
};


export type Query_RootStore_Product_LinkArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Bool_Exp>;
};


export type Query_RootStore_Product_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Bool_Exp>;
};


export type Query_RootStore_Product_Link_By_PkArgs = {
  linkType: Store_Product_Link_Type_Enum;
  linkedSku: Scalars['String'];
  sku: Scalars['String'];
};


export type Query_RootStore_Product_Link_TypeArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Type_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Type_Bool_Exp>;
};


export type Query_RootStore_Product_Link_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Type_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Type_Bool_Exp>;
};


export type Query_RootStore_Product_Link_Type_By_PkArgs = {
  link_type: Scalars['String'];
};


export type Query_RootStore_Product_MediaArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Media_Order_By>>;
  where?: InputMaybe<Store_Product_Media_Bool_Exp>;
};


export type Query_RootStore_Product_Media_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Media_Order_By>>;
  where?: InputMaybe<Store_Product_Media_Bool_Exp>;
};


export type Query_RootStore_Product_Media_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSubscription_IntervalArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Interval_Order_By>>;
  where?: InputMaybe<Subscription_Interval_Bool_Exp>;
};


export type Query_RootSubscription_Interval_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Interval_Order_By>>;
  where?: InputMaybe<Subscription_Interval_Bool_Exp>;
};


export type Query_RootSubscription_Interval_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootTracking_EventArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Event_Order_By>>;
  where?: InputMaybe<Tracking_Event_Bool_Exp>;
};


export type Query_RootTracking_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Event_Order_By>>;
  where?: InputMaybe<Tracking_Event_Bool_Exp>;
};


export type Query_RootTracking_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AddressArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};


export type Query_RootUser_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};


export type Query_RootUser_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AttributeArgs = {
  distinct_on?: InputMaybe<Array<User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Attribute_Order_By>>;
  where?: InputMaybe<User_Attribute_Bool_Exp>;
};


export type Query_RootUser_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Attribute_Order_By>>;
  where?: InputMaybe<User_Attribute_Bool_Exp>;
};


export type Query_RootUser_Attribute_By_PkArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
  userId: Scalars['uuid'];
};


export type Query_RootUser_AuthArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Query_RootUser_Auth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Query_RootUser_Auth_By_PkArgs = {
  userId: Scalars['uuid'];
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Compliance_CheckArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};


export type Query_RootUser_Compliance_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};


export type Query_RootUser_Compliance_Check_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Compliance_Check_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Status_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Status_Bool_Exp>;
};


export type Query_RootUser_Compliance_Check_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Status_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Status_Bool_Exp>;
};


export type Query_RootUser_Compliance_Check_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootUser_EmailArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Order_By>>;
  where?: InputMaybe<User_Email_Bool_Exp>;
};


export type Query_RootUser_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Order_By>>;
  where?: InputMaybe<User_Email_Bool_Exp>;
};


export type Query_RootUser_Email_By_PkArgs = {
  email: Scalars['String'];
  userId: Scalars['uuid'];
};


export type Query_RootUser_National_IdArgs = {
  distinct_on?: InputMaybe<Array<User_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_National_Id_Order_By>>;
  where?: InputMaybe<User_National_Id_Bool_Exp>;
};


export type Query_RootUser_National_Id_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_National_Id_Order_By>>;
  where?: InputMaybe<User_National_Id_Bool_Exp>;
};


export type Query_RootUser_National_Id_By_PkArgs = {
  type_name: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Query_RootUser_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<User_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Phone_Number_Order_By>>;
  where?: InputMaybe<User_Phone_Number_Bool_Exp>;
};


export type Query_RootUser_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Phone_Number_Order_By>>;
  where?: InputMaybe<User_Phone_Number_Bool_Exp>;
};


export type Query_RootUser_Phone_Number_By_PkArgs = {
  number: Scalars['String'];
  userId: Scalars['uuid'];
};


export type Query_RootValue_TypeArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Query_RootValue_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Query_RootValue_Type_By_PkArgs = {
  type: Scalars['String'];
};

/** columns and relationships of "store_order" */
export type Store_Order = {
  __typename?: 'store_order';
  createdAt: Scalars['timestamptz'];
  currencyCode: Scalars['String'];
  customerEmail: Scalars['String'];
  customerFirstName: Scalars['String'];
  customerLastName: Scalars['String'];
  emailSent: Scalars['Boolean'];
  id: Scalars['String'];
  shippingAmount: Scalars['numeric'];
  status: Scalars['String'];
  subtotal: Scalars['numeric'];
  taxAmount: Scalars['numeric'];
  total: Scalars['numeric'];
  updatedAt: Scalars['timestamptz'];
  userId?: Maybe<Scalars['uuid']>;
};

/** Billing and shipping addresses history */
export type Store_Order_Address = {
  __typename?: 'store_order_address';
  addressType: Store_Order_Address_Type_Enum;
  altitude?: Maybe<Scalars['float8']>;
  city: Scalars['String'];
  countryCode: Scalars['String'];
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  orderId: Scalars['String'];
  postalCode: Scalars['String'];
  stateCode?: Maybe<Scalars['String']>;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregated selection of "store_order_address" */
export type Store_Order_Address_Aggregate = {
  __typename?: 'store_order_address_aggregate';
  aggregate?: Maybe<Store_Order_Address_Aggregate_Fields>;
  nodes: Array<Store_Order_Address>;
};

/** aggregate fields of "store_order_address" */
export type Store_Order_Address_Aggregate_Fields = {
  __typename?: 'store_order_address_aggregate_fields';
  avg?: Maybe<Store_Order_Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Order_Address_Max_Fields>;
  min?: Maybe<Store_Order_Address_Min_Fields>;
  stddev?: Maybe<Store_Order_Address_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Order_Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Order_Address_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Order_Address_Sum_Fields>;
  var_pop?: Maybe<Store_Order_Address_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Order_Address_Var_Samp_Fields>;
  variance?: Maybe<Store_Order_Address_Variance_Fields>;
};


/** aggregate fields of "store_order_address" */
export type Store_Order_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Order_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Order_Address_Avg_Fields = {
  __typename?: 'store_order_address_avg_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_order_address". All fields are combined with a logical 'AND'. */
export type Store_Order_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Order_Address_Bool_Exp>>;
  _not?: InputMaybe<Store_Order_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Order_Address_Bool_Exp>>;
  addressType?: InputMaybe<Store_Order_Address_Type_Enum_Comparison_Exp>;
  altitude?: InputMaybe<Float8_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  orderId?: InputMaybe<String_Comparison_Exp>;
  postalCode?: InputMaybe<String_Comparison_Exp>;
  stateCode?: InputMaybe<String_Comparison_Exp>;
  street1?: InputMaybe<String_Comparison_Exp>;
  street2?: InputMaybe<String_Comparison_Exp>;
  telephone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_order_address" */
export enum Store_Order_Address_Constraint {
  /** unique or primary key constraint */
  StoreOrderAddressPkey = 'store_order_address_pkey'
}

/** input type for incrementing numeric columns in table "store_order_address" */
export type Store_Order_Address_Inc_Input = {
  altitude?: InputMaybe<Scalars['float8']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "store_order_address" */
export type Store_Order_Address_Insert_Input = {
  addressType?: InputMaybe<Store_Order_Address_Type_Enum>;
  altitude?: InputMaybe<Scalars['float8']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  orderId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Order_Address_Max_Fields = {
  __typename?: 'store_order_address_max_fields';
  altitude?: Maybe<Scalars['float8']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  orderId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Order_Address_Min_Fields = {
  __typename?: 'store_order_address_min_fields';
  altitude?: Maybe<Scalars['float8']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  orderId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_order_address" */
export type Store_Order_Address_Mutation_Response = {
  __typename?: 'store_order_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Order_Address>;
};

/** on_conflict condition type for table "store_order_address" */
export type Store_Order_Address_On_Conflict = {
  constraint: Store_Order_Address_Constraint;
  update_columns?: Array<Store_Order_Address_Update_Column>;
  where?: InputMaybe<Store_Order_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "store_order_address". */
export type Store_Order_Address_Order_By = {
  addressType?: InputMaybe<Order_By>;
  altitude?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  countryCode?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  postalCode?: InputMaybe<Order_By>;
  stateCode?: InputMaybe<Order_By>;
  street1?: InputMaybe<Order_By>;
  street2?: InputMaybe<Order_By>;
  telephone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_order_address */
export type Store_Order_Address_Pk_Columns_Input = {
  addressType: Store_Order_Address_Type_Enum;
  orderId: Scalars['String'];
};

/** select columns of table "store_order_address" */
export enum Store_Order_Address_Select_Column {
  /** column name */
  AddressType = 'addressType',
  /** column name */
  Altitude = 'altitude',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  StateCode = 'stateCode',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  Telephone = 'telephone'
}

/** input type for updating data in table "store_order_address" */
export type Store_Order_Address_Set_Input = {
  addressType?: InputMaybe<Store_Order_Address_Type_Enum>;
  altitude?: InputMaybe<Scalars['float8']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  orderId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  stateCode?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  telephone?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Store_Order_Address_Stddev_Fields = {
  __typename?: 'store_order_address_stddev_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Order_Address_Stddev_Pop_Fields = {
  __typename?: 'store_order_address_stddev_pop_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Order_Address_Stddev_Samp_Fields = {
  __typename?: 'store_order_address_stddev_samp_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Store_Order_Address_Sum_Fields = {
  __typename?: 'store_order_address_sum_fields';
  altitude?: Maybe<Scalars['float8']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};

/** columns and relationships of "store_order_address_type" */
export type Store_Order_Address_Type = {
  __typename?: 'store_order_address_type';
  type: Scalars['String'];
};

/** aggregated selection of "store_order_address_type" */
export type Store_Order_Address_Type_Aggregate = {
  __typename?: 'store_order_address_type_aggregate';
  aggregate?: Maybe<Store_Order_Address_Type_Aggregate_Fields>;
  nodes: Array<Store_Order_Address_Type>;
};

/** aggregate fields of "store_order_address_type" */
export type Store_Order_Address_Type_Aggregate_Fields = {
  __typename?: 'store_order_address_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Order_Address_Type_Max_Fields>;
  min?: Maybe<Store_Order_Address_Type_Min_Fields>;
};


/** aggregate fields of "store_order_address_type" */
export type Store_Order_Address_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Order_Address_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_order_address_type". All fields are combined with a logical 'AND'. */
export type Store_Order_Address_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Order_Address_Type_Bool_Exp>>;
  _not?: InputMaybe<Store_Order_Address_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Order_Address_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_order_address_type" */
export enum Store_Order_Address_Type_Constraint {
  /** unique or primary key constraint */
  StoreOrderAddressTypePkey = 'store_order_address_type_pkey'
}

export enum Store_Order_Address_Type_Enum {
  Billing = 'billing',
  Shipping = 'shipping'
}

/** Boolean expression to compare columns of type "store_order_address_type_enum". All fields are combined with logical 'AND'. */
export type Store_Order_Address_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Store_Order_Address_Type_Enum>;
  _in?: InputMaybe<Array<Store_Order_Address_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Store_Order_Address_Type_Enum>;
  _nin?: InputMaybe<Array<Store_Order_Address_Type_Enum>>;
};

/** input type for inserting data into table "store_order_address_type" */
export type Store_Order_Address_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Order_Address_Type_Max_Fields = {
  __typename?: 'store_order_address_type_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Order_Address_Type_Min_Fields = {
  __typename?: 'store_order_address_type_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_order_address_type" */
export type Store_Order_Address_Type_Mutation_Response = {
  __typename?: 'store_order_address_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Order_Address_Type>;
};

/** on_conflict condition type for table "store_order_address_type" */
export type Store_Order_Address_Type_On_Conflict = {
  constraint: Store_Order_Address_Type_Constraint;
  update_columns?: Array<Store_Order_Address_Type_Update_Column>;
  where?: InputMaybe<Store_Order_Address_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "store_order_address_type". */
export type Store_Order_Address_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_order_address_type */
export type Store_Order_Address_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "store_order_address_type" */
export enum Store_Order_Address_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "store_order_address_type" */
export type Store_Order_Address_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "store_order_address_type" */
export enum Store_Order_Address_Type_Update_Column {
  /** column name */
  Type = 'type'
}

/** update columns of table "store_order_address" */
export enum Store_Order_Address_Update_Column {
  /** column name */
  AddressType = 'addressType',
  /** column name */
  Altitude = 'altitude',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  PostalCode = 'postalCode',
  /** column name */
  StateCode = 'stateCode',
  /** column name */
  Street1 = 'street1',
  /** column name */
  Street2 = 'street2',
  /** column name */
  Telephone = 'telephone'
}

/** aggregate var_pop on columns */
export type Store_Order_Address_Var_Pop_Fields = {
  __typename?: 'store_order_address_var_pop_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Order_Address_Var_Samp_Fields = {
  __typename?: 'store_order_address_var_samp_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Order_Address_Variance_Fields = {
  __typename?: 'store_order_address_variance_fields';
  altitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "store_order" */
export type Store_Order_Aggregate = {
  __typename?: 'store_order_aggregate';
  aggregate?: Maybe<Store_Order_Aggregate_Fields>;
  nodes: Array<Store_Order>;
};

/** aggregate fields of "store_order" */
export type Store_Order_Aggregate_Fields = {
  __typename?: 'store_order_aggregate_fields';
  avg?: Maybe<Store_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Order_Max_Fields>;
  min?: Maybe<Store_Order_Min_Fields>;
  stddev?: Maybe<Store_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Order_Sum_Fields>;
  var_pop?: Maybe<Store_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Order_Var_Samp_Fields>;
  variance?: Maybe<Store_Order_Variance_Fields>;
};


/** aggregate fields of "store_order" */
export type Store_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Order_Avg_Fields = {
  __typename?: 'store_order_avg_fields';
  shippingAmount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_order". All fields are combined with a logical 'AND'. */
export type Store_Order_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Order_Bool_Exp>>;
  _not?: InputMaybe<Store_Order_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Order_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currencyCode?: InputMaybe<String_Comparison_Exp>;
  customerEmail?: InputMaybe<String_Comparison_Exp>;
  customerFirstName?: InputMaybe<String_Comparison_Exp>;
  customerLastName?: InputMaybe<String_Comparison_Exp>;
  emailSent?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  shippingAmount?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subtotal?: InputMaybe<Numeric_Comparison_Exp>;
  taxAmount?: InputMaybe<Numeric_Comparison_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_order" */
export enum Store_Order_Constraint {
  /** unique or primary key constraint */
  StoreOrderPkey = 'store_order_pkey'
}

/** input type for incrementing numeric columns in table "store_order" */
export type Store_Order_Inc_Input = {
  shippingAmount?: InputMaybe<Scalars['numeric']>;
  subtotal?: InputMaybe<Scalars['numeric']>;
  taxAmount?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "store_order" */
export type Store_Order_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  customerEmail?: InputMaybe<Scalars['String']>;
  customerFirstName?: InputMaybe<Scalars['String']>;
  customerLastName?: InputMaybe<Scalars['String']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  shippingAmount?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  subtotal?: InputMaybe<Scalars['numeric']>;
  taxAmount?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "store_order_item" */
export type Store_Order_Item = {
  __typename?: 'store_order_item';
  createdAt: Scalars['timestamptz'];
  orderId: Scalars['String'];
  price: Scalars['numeric'];
  productSku: Scalars['String'];
  quantity: Scalars['Int'];
  taxAmount: Scalars['numeric'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "store_order_item" */
export type Store_Order_Item_Aggregate = {
  __typename?: 'store_order_item_aggregate';
  aggregate?: Maybe<Store_Order_Item_Aggregate_Fields>;
  nodes: Array<Store_Order_Item>;
};

/** aggregate fields of "store_order_item" */
export type Store_Order_Item_Aggregate_Fields = {
  __typename?: 'store_order_item_aggregate_fields';
  avg?: Maybe<Store_Order_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Order_Item_Max_Fields>;
  min?: Maybe<Store_Order_Item_Min_Fields>;
  stddev?: Maybe<Store_Order_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Order_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Order_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Order_Item_Sum_Fields>;
  var_pop?: Maybe<Store_Order_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Order_Item_Var_Samp_Fields>;
  variance?: Maybe<Store_Order_Item_Variance_Fields>;
};


/** aggregate fields of "store_order_item" */
export type Store_Order_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Order_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Store_Order_Item_Avg_Fields = {
  __typename?: 'store_order_item_avg_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_order_item". All fields are combined with a logical 'AND'. */
export type Store_Order_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Order_Item_Bool_Exp>>;
  _not?: InputMaybe<Store_Order_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Order_Item_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  orderId?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  productSku?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  taxAmount?: InputMaybe<Numeric_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_order_item" */
export enum Store_Order_Item_Constraint {
  /** unique or primary key constraint */
  StoreOrderItemPkey = 'store_order_item_pkey'
}

/** input type for incrementing numeric columns in table "store_order_item" */
export type Store_Order_Item_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxAmount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "store_order_item" */
export type Store_Order_Item_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  orderId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  productSku?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxAmount?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_Order_Item_Max_Fields = {
  __typename?: 'store_order_item_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  orderId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  productSku?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Store_Order_Item_Min_Fields = {
  __typename?: 'store_order_item_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  orderId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  productSku?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "store_order_item" */
export type Store_Order_Item_Mutation_Response = {
  __typename?: 'store_order_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Order_Item>;
};

/** on_conflict condition type for table "store_order_item" */
export type Store_Order_Item_On_Conflict = {
  constraint: Store_Order_Item_Constraint;
  update_columns?: Array<Store_Order_Item_Update_Column>;
  where?: InputMaybe<Store_Order_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "store_order_item". */
export type Store_Order_Item_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  orderId?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  productSku?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  taxAmount?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_order_item */
export type Store_Order_Item_Pk_Columns_Input = {
  orderId: Scalars['String'];
  productSku: Scalars['String'];
};

/** select columns of table "store_order_item" */
export enum Store_Order_Item_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Price = 'price',
  /** column name */
  ProductSku = 'productSku',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  TaxAmount = 'taxAmount',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "store_order_item" */
export type Store_Order_Item_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  orderId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  productSku?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  taxAmount?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_Order_Item_Stddev_Fields = {
  __typename?: 'store_order_item_stddev_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Order_Item_Stddev_Pop_Fields = {
  __typename?: 'store_order_item_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Order_Item_Stddev_Samp_Fields = {
  __typename?: 'store_order_item_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Store_Order_Item_Sum_Fields = {
  __typename?: 'store_order_item_sum_fields';
  price?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['numeric']>;
};

/** update columns of table "store_order_item" */
export enum Store_Order_Item_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  OrderId = 'orderId',
  /** column name */
  Price = 'price',
  /** column name */
  ProductSku = 'productSku',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  TaxAmount = 'taxAmount',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Store_Order_Item_Var_Pop_Fields = {
  __typename?: 'store_order_item_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Order_Item_Var_Samp_Fields = {
  __typename?: 'store_order_item_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Order_Item_Variance_Fields = {
  __typename?: 'store_order_item_variance_fields';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Store_Order_Max_Fields = {
  __typename?: 'store_order_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyCode?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerFirstName?: Maybe<Scalars['String']>;
  customerLastName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shippingAmount?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['numeric']>;
  taxAmount?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Store_Order_Min_Fields = {
  __typename?: 'store_order_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currencyCode?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerFirstName?: Maybe<Scalars['String']>;
  customerLastName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shippingAmount?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['numeric']>;
  taxAmount?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "store_order" */
export type Store_Order_Mutation_Response = {
  __typename?: 'store_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Order>;
};

/** on_conflict condition type for table "store_order" */
export type Store_Order_On_Conflict = {
  constraint: Store_Order_Constraint;
  update_columns?: Array<Store_Order_Update_Column>;
  where?: InputMaybe<Store_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "store_order". */
export type Store_Order_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  currencyCode?: InputMaybe<Order_By>;
  customerEmail?: InputMaybe<Order_By>;
  customerFirstName?: InputMaybe<Order_By>;
  customerLastName?: InputMaybe<Order_By>;
  emailSent?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shippingAmount?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subtotal?: InputMaybe<Order_By>;
  taxAmount?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_order */
export type Store_Order_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "store_order" */
export enum Store_Order_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  CustomerEmail = 'customerEmail',
  /** column name */
  CustomerFirstName = 'customerFirstName',
  /** column name */
  CustomerLastName = 'customerLastName',
  /** column name */
  EmailSent = 'emailSent',
  /** column name */
  Id = 'id',
  /** column name */
  ShippingAmount = 'shippingAmount',
  /** column name */
  Status = 'status',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  TaxAmount = 'taxAmount',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "store_order" */
export type Store_Order_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  customerEmail?: InputMaybe<Scalars['String']>;
  customerFirstName?: InputMaybe<Scalars['String']>;
  customerLastName?: InputMaybe<Scalars['String']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  shippingAmount?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  subtotal?: InputMaybe<Scalars['numeric']>;
  taxAmount?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Store_Order_Stddev_Fields = {
  __typename?: 'store_order_stddev_fields';
  shippingAmount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Order_Stddev_Pop_Fields = {
  __typename?: 'store_order_stddev_pop_fields';
  shippingAmount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Order_Stddev_Samp_Fields = {
  __typename?: 'store_order_stddev_samp_fields';
  shippingAmount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Store_Order_Sum_Fields = {
  __typename?: 'store_order_sum_fields';
  shippingAmount?: Maybe<Scalars['numeric']>;
  subtotal?: Maybe<Scalars['numeric']>;
  taxAmount?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** update columns of table "store_order" */
export enum Store_Order_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrencyCode = 'currencyCode',
  /** column name */
  CustomerEmail = 'customerEmail',
  /** column name */
  CustomerFirstName = 'customerFirstName',
  /** column name */
  CustomerLastName = 'customerLastName',
  /** column name */
  EmailSent = 'emailSent',
  /** column name */
  Id = 'id',
  /** column name */
  ShippingAmount = 'shippingAmount',
  /** column name */
  Status = 'status',
  /** column name */
  Subtotal = 'subtotal',
  /** column name */
  TaxAmount = 'taxAmount',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Store_Order_Var_Pop_Fields = {
  __typename?: 'store_order_var_pop_fields';
  shippingAmount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Order_Var_Samp_Fields = {
  __typename?: 'store_order_var_samp_fields';
  shippingAmount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Order_Variance_Fields = {
  __typename?: 'store_order_variance_fields';
  shippingAmount?: Maybe<Scalars['Float']>;
  subtotal?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "store_product" */
export type Store_Product = {
  __typename?: 'store_product';
  /** An array relationship */
  attributes: Array<Store_Product_Attribute>;
  /** An aggregate relationship */
  attributes_aggregate: Store_Product_Attribute_Aggregate;
  companyId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  media: Array<Store_Product_Media>;
  /** An aggregate relationship */
  media_aggregate: Store_Product_Media_Aggregate;
  name: Scalars['String'];
  price: Scalars['numeric'];
  sku: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "store_product" */
export type Store_ProductAttributesArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Attribute_Order_By>>;
  where?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
};


/** columns and relationships of "store_product" */
export type Store_ProductAttributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Attribute_Order_By>>;
  where?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
};


/** columns and relationships of "store_product" */
export type Store_ProductMediaArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Media_Order_By>>;
  where?: InputMaybe<Store_Product_Media_Bool_Exp>;
};


/** columns and relationships of "store_product" */
export type Store_ProductMedia_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Media_Order_By>>;
  where?: InputMaybe<Store_Product_Media_Bool_Exp>;
};

/** aggregated selection of "store_product" */
export type Store_Product_Aggregate = {
  __typename?: 'store_product_aggregate';
  aggregate?: Maybe<Store_Product_Aggregate_Fields>;
  nodes: Array<Store_Product>;
};

/** aggregate fields of "store_product" */
export type Store_Product_Aggregate_Fields = {
  __typename?: 'store_product_aggregate_fields';
  avg?: Maybe<Store_Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Product_Max_Fields>;
  min?: Maybe<Store_Product_Min_Fields>;
  stddev?: Maybe<Store_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Product_Sum_Fields>;
  var_pop?: Maybe<Store_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Product_Var_Samp_Fields>;
  variance?: Maybe<Store_Product_Variance_Fields>;
};


/** aggregate fields of "store_product" */
export type Store_Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "store_product_attribute" */
export type Store_Product_Attribute = {
  __typename?: 'store_product_attribute';
  createdAt: Scalars['timestamptz'];
  entityName: Entity_Enum;
  name: Scalars['String'];
  sku: Scalars['String'];
  /** An object relationship */
  type?: Maybe<Entity_Attribute_Type>;
  updatedAt: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "store_product_attribute" */
export type Store_Product_Attribute_Aggregate = {
  __typename?: 'store_product_attribute_aggregate';
  aggregate?: Maybe<Store_Product_Attribute_Aggregate_Fields>;
  nodes: Array<Store_Product_Attribute>;
};

/** aggregate fields of "store_product_attribute" */
export type Store_Product_Attribute_Aggregate_Fields = {
  __typename?: 'store_product_attribute_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Product_Attribute_Max_Fields>;
  min?: Maybe<Store_Product_Attribute_Min_Fields>;
};


/** aggregate fields of "store_product_attribute" */
export type Store_Product_Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Product_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_product_attribute" */
export type Store_Product_Attribute_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Store_Product_Attribute_Max_Order_By>;
  min?: InputMaybe<Store_Product_Attribute_Min_Order_By>;
};

/** input type for inserting array relation for remote table "store_product_attribute" */
export type Store_Product_Attribute_Arr_Rel_Insert_Input = {
  data: Array<Store_Product_Attribute_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Store_Product_Attribute_On_Conflict>;
};

/** Boolean expression to filter rows from the table "store_product_attribute". All fields are combined with a logical 'AND'. */
export type Store_Product_Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Product_Attribute_Bool_Exp>>;
  _not?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Product_Attribute_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  entityName?: InputMaybe<Entity_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_product_attribute" */
export enum Store_Product_Attribute_Constraint {
  /** unique or primary key constraint */
  StoreProductAttributePkey = 'store_product_attribute_pkey'
}

/** input type for inserting data into table "store_product_attribute" */
export type Store_Product_Attribute_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityName?: InputMaybe<Entity_Enum>;
  name?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Entity_Attribute_Type_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Product_Attribute_Max_Fields = {
  __typename?: 'store_product_attribute_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "store_product_attribute" */
export type Store_Product_Attribute_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Store_Product_Attribute_Min_Fields = {
  __typename?: 'store_product_attribute_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "store_product_attribute" */
export type Store_Product_Attribute_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "store_product_attribute" */
export type Store_Product_Attribute_Mutation_Response = {
  __typename?: 'store_product_attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Product_Attribute>;
};

/** on_conflict condition type for table "store_product_attribute" */
export type Store_Product_Attribute_On_Conflict = {
  constraint: Store_Product_Attribute_Constraint;
  update_columns?: Array<Store_Product_Attribute_Update_Column>;
  where?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product_attribute". */
export type Store_Product_Attribute_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  entityName?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  type?: InputMaybe<Entity_Attribute_Type_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product_attribute */
export type Store_Product_Attribute_Pk_Columns_Input = {
  name: Scalars['String'];
  sku: Scalars['String'];
};

/** select columns of table "store_product_attribute" */
export enum Store_Product_Attribute_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Name = 'name',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "store_product_attribute" */
export type Store_Product_Attribute_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityName?: InputMaybe<Entity_Enum>;
  name?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "store_product_attribute" */
export enum Store_Product_Attribute_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  Name = 'name',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Value = 'value'
}

/** aggregate avg on columns */
export type Store_Product_Avg_Fields = {
  __typename?: 'store_product_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "store_product". All fields are combined with a logical 'AND'. */
export type Store_Product_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Product_Bool_Exp>>;
  _not?: InputMaybe<Store_Product_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Product_Bool_Exp>>;
  attributes?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
  companyId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  media?: InputMaybe<Store_Product_Media_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "store_product_category" */
export type Store_Product_Category = {
  __typename?: 'store_product_category';
  createdAt: Scalars['timestamptz'];
  sku: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  urlPath: Scalars['String'];
};

/** aggregated selection of "store_product_category" */
export type Store_Product_Category_Aggregate = {
  __typename?: 'store_product_category_aggregate';
  aggregate?: Maybe<Store_Product_Category_Aggregate_Fields>;
  nodes: Array<Store_Product_Category>;
};

/** aggregate fields of "store_product_category" */
export type Store_Product_Category_Aggregate_Fields = {
  __typename?: 'store_product_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Product_Category_Max_Fields>;
  min?: Maybe<Store_Product_Category_Min_Fields>;
};


/** aggregate fields of "store_product_category" */
export type Store_Product_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Product_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_product_category". All fields are combined with a logical 'AND'. */
export type Store_Product_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Product_Category_Bool_Exp>>;
  _not?: InputMaybe<Store_Product_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Product_Category_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  urlPath?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_product_category" */
export enum Store_Product_Category_Constraint {
  /** unique or primary key constraint */
  StoreProductCategoryPkey = 'store_product_category_pkey'
}

/** input type for inserting data into table "store_product_category" */
export type Store_Product_Category_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  urlPath?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Product_Category_Max_Fields = {
  __typename?: 'store_product_category_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  urlPath?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Product_Category_Min_Fields = {
  __typename?: 'store_product_category_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  urlPath?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_product_category" */
export type Store_Product_Category_Mutation_Response = {
  __typename?: 'store_product_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Product_Category>;
};

/** on_conflict condition type for table "store_product_category" */
export type Store_Product_Category_On_Conflict = {
  constraint: Store_Product_Category_Constraint;
  update_columns?: Array<Store_Product_Category_Update_Column>;
  where?: InputMaybe<Store_Product_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product_category". */
export type Store_Product_Category_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  urlPath?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product_category */
export type Store_Product_Category_Pk_Columns_Input = {
  sku: Scalars['String'];
  urlPath: Scalars['String'];
};

/** select columns of table "store_product_category" */
export enum Store_Product_Category_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UrlPath = 'urlPath'
}

/** input type for updating data in table "store_product_category" */
export type Store_Product_Category_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  urlPath?: InputMaybe<Scalars['String']>;
};

/** update columns of table "store_product_category" */
export enum Store_Product_Category_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UrlPath = 'urlPath'
}

/** unique or primary key constraints on table "store_product" */
export enum Store_Product_Constraint {
  /** unique or primary key constraint */
  StoreProductPkey = 'store_product_pkey'
}

/** input type for incrementing numeric columns in table "store_product" */
export type Store_Product_Inc_Input = {
  price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "store_product" */
export type Store_Product_Insert_Input = {
  attributes?: InputMaybe<Store_Product_Attribute_Arr_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  media?: InputMaybe<Store_Product_Media_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** To store the relationships of linked products */
export type Store_Product_Link = {
  __typename?: 'store_product_link';
  createdAt: Scalars['timestamptz'];
  linkType: Store_Product_Link_Type_Enum;
  linkedSku: Scalars['String'];
  sku: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "store_product_link" */
export type Store_Product_Link_Aggregate = {
  __typename?: 'store_product_link_aggregate';
  aggregate?: Maybe<Store_Product_Link_Aggregate_Fields>;
  nodes: Array<Store_Product_Link>;
};

/** aggregate fields of "store_product_link" */
export type Store_Product_Link_Aggregate_Fields = {
  __typename?: 'store_product_link_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Product_Link_Max_Fields>;
  min?: Maybe<Store_Product_Link_Min_Fields>;
};


/** aggregate fields of "store_product_link" */
export type Store_Product_Link_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Product_Link_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_product_link". All fields are combined with a logical 'AND'. */
export type Store_Product_Link_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Product_Link_Bool_Exp>>;
  _not?: InputMaybe<Store_Product_Link_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Product_Link_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  linkType?: InputMaybe<Store_Product_Link_Type_Enum_Comparison_Exp>;
  linkedSku?: InputMaybe<String_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_product_link" */
export enum Store_Product_Link_Constraint {
  /** unique or primary key constraint */
  StoreProductLinkedPkey = 'store_product_linked_pkey'
}

/** input type for inserting data into table "store_product_link" */
export type Store_Product_Link_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  linkType?: InputMaybe<Store_Product_Link_Type_Enum>;
  linkedSku?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_Product_Link_Max_Fields = {
  __typename?: 'store_product_link_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  linkedSku?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Store_Product_Link_Min_Fields = {
  __typename?: 'store_product_link_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  linkedSku?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "store_product_link" */
export type Store_Product_Link_Mutation_Response = {
  __typename?: 'store_product_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Product_Link>;
};

/** on_conflict condition type for table "store_product_link" */
export type Store_Product_Link_On_Conflict = {
  constraint: Store_Product_Link_Constraint;
  update_columns?: Array<Store_Product_Link_Update_Column>;
  where?: InputMaybe<Store_Product_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product_link". */
export type Store_Product_Link_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  linkType?: InputMaybe<Order_By>;
  linkedSku?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product_link */
export type Store_Product_Link_Pk_Columns_Input = {
  linkType: Store_Product_Link_Type_Enum;
  linkedSku: Scalars['String'];
  sku: Scalars['String'];
};

/** select columns of table "store_product_link" */
export enum Store_Product_Link_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  LinkType = 'linkType',
  /** column name */
  LinkedSku = 'linkedSku',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "store_product_link" */
export type Store_Product_Link_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  linkType?: InputMaybe<Store_Product_Link_Type_Enum>;
  linkedSku?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** Link types for linked products */
export type Store_Product_Link_Type = {
  __typename?: 'store_product_link_type';
  link_type: Scalars['String'];
};

/** aggregated selection of "store_product_link_type" */
export type Store_Product_Link_Type_Aggregate = {
  __typename?: 'store_product_link_type_aggregate';
  aggregate?: Maybe<Store_Product_Link_Type_Aggregate_Fields>;
  nodes: Array<Store_Product_Link_Type>;
};

/** aggregate fields of "store_product_link_type" */
export type Store_Product_Link_Type_Aggregate_Fields = {
  __typename?: 'store_product_link_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Store_Product_Link_Type_Max_Fields>;
  min?: Maybe<Store_Product_Link_Type_Min_Fields>;
};


/** aggregate fields of "store_product_link_type" */
export type Store_Product_Link_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Product_Link_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "store_product_link_type". All fields are combined with a logical 'AND'. */
export type Store_Product_Link_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Product_Link_Type_Bool_Exp>>;
  _not?: InputMaybe<Store_Product_Link_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Product_Link_Type_Bool_Exp>>;
  link_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_product_link_type" */
export enum Store_Product_Link_Type_Constraint {
  /** unique or primary key constraint */
  LinkTypePkey = 'link_type_pkey'
}

export enum Store_Product_Link_Type_Enum {
  Crosssell = 'crosssell',
  Related = 'related',
  Upsell = 'upsell'
}

/** Boolean expression to compare columns of type "store_product_link_type_enum". All fields are combined with logical 'AND'. */
export type Store_Product_Link_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Store_Product_Link_Type_Enum>;
  _in?: InputMaybe<Array<Store_Product_Link_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Store_Product_Link_Type_Enum>;
  _nin?: InputMaybe<Array<Store_Product_Link_Type_Enum>>;
};

/** input type for inserting data into table "store_product_link_type" */
export type Store_Product_Link_Type_Insert_Input = {
  link_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Store_Product_Link_Type_Max_Fields = {
  __typename?: 'store_product_link_type_max_fields';
  link_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Store_Product_Link_Type_Min_Fields = {
  __typename?: 'store_product_link_type_min_fields';
  link_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "store_product_link_type" */
export type Store_Product_Link_Type_Mutation_Response = {
  __typename?: 'store_product_link_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Product_Link_Type>;
};

/** on_conflict condition type for table "store_product_link_type" */
export type Store_Product_Link_Type_On_Conflict = {
  constraint: Store_Product_Link_Type_Constraint;
  update_columns?: Array<Store_Product_Link_Type_Update_Column>;
  where?: InputMaybe<Store_Product_Link_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product_link_type". */
export type Store_Product_Link_Type_Order_By = {
  link_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product_link_type */
export type Store_Product_Link_Type_Pk_Columns_Input = {
  link_type: Scalars['String'];
};

/** select columns of table "store_product_link_type" */
export enum Store_Product_Link_Type_Select_Column {
  /** column name */
  LinkType = 'link_type'
}

/** input type for updating data in table "store_product_link_type" */
export type Store_Product_Link_Type_Set_Input = {
  link_type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "store_product_link_type" */
export enum Store_Product_Link_Type_Update_Column {
  /** column name */
  LinkType = 'link_type'
}

/** update columns of table "store_product_link" */
export enum Store_Product_Link_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  LinkType = 'linkType',
  /** column name */
  LinkedSku = 'linkedSku',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate max on columns */
export type Store_Product_Max_Fields = {
  __typename?: 'store_product_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "store_product_media" */
export type Store_Product_Media = {
  __typename?: 'store_product_media';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  file?: Maybe<File>;
  fileId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  mediaId?: Maybe<Scalars['String']>;
  order: Scalars['float8'];
  sku: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "store_product_media" */
export type Store_Product_Media_Aggregate = {
  __typename?: 'store_product_media_aggregate';
  aggregate?: Maybe<Store_Product_Media_Aggregate_Fields>;
  nodes: Array<Store_Product_Media>;
};

/** aggregate fields of "store_product_media" */
export type Store_Product_Media_Aggregate_Fields = {
  __typename?: 'store_product_media_aggregate_fields';
  avg?: Maybe<Store_Product_Media_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Store_Product_Media_Max_Fields>;
  min?: Maybe<Store_Product_Media_Min_Fields>;
  stddev?: Maybe<Store_Product_Media_Stddev_Fields>;
  stddev_pop?: Maybe<Store_Product_Media_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Store_Product_Media_Stddev_Samp_Fields>;
  sum?: Maybe<Store_Product_Media_Sum_Fields>;
  var_pop?: Maybe<Store_Product_Media_Var_Pop_Fields>;
  var_samp?: Maybe<Store_Product_Media_Var_Samp_Fields>;
  variance?: Maybe<Store_Product_Media_Variance_Fields>;
};


/** aggregate fields of "store_product_media" */
export type Store_Product_Media_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Store_Product_Media_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "store_product_media" */
export type Store_Product_Media_Aggregate_Order_By = {
  avg?: InputMaybe<Store_Product_Media_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Store_Product_Media_Max_Order_By>;
  min?: InputMaybe<Store_Product_Media_Min_Order_By>;
  stddev?: InputMaybe<Store_Product_Media_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Store_Product_Media_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Store_Product_Media_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Store_Product_Media_Sum_Order_By>;
  var_pop?: InputMaybe<Store_Product_Media_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Store_Product_Media_Var_Samp_Order_By>;
  variance?: InputMaybe<Store_Product_Media_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "store_product_media" */
export type Store_Product_Media_Arr_Rel_Insert_Input = {
  data: Array<Store_Product_Media_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Store_Product_Media_On_Conflict>;
};

/** aggregate avg on columns */
export type Store_Product_Media_Avg_Fields = {
  __typename?: 'store_product_media_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "store_product_media" */
export type Store_Product_Media_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "store_product_media". All fields are combined with a logical 'AND'. */
export type Store_Product_Media_Bool_Exp = {
  _and?: InputMaybe<Array<Store_Product_Media_Bool_Exp>>;
  _not?: InputMaybe<Store_Product_Media_Bool_Exp>;
  _or?: InputMaybe<Array<Store_Product_Media_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<File_Bool_Exp>;
  fileId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mediaId?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Float8_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "store_product_media" */
export enum Store_Product_Media_Constraint {
  /** unique or primary key constraint */
  StoreProductMediaPkey = 'store_product_media_pkey'
}

/** input type for incrementing numeric columns in table "store_product_media" */
export type Store_Product_Media_Inc_Input = {
  order?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "store_product_media" */
export type Store_Product_Media_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<File_Obj_Rel_Insert_Input>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  mediaId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  sku?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Store_Product_Media_Max_Fields = {
  __typename?: 'store_product_media_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mediaId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "store_product_media" */
export type Store_Product_Media_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Store_Product_Media_Min_Fields = {
  __typename?: 'store_product_media_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  fileId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mediaId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['float8']>;
  sku?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "store_product_media" */
export type Store_Product_Media_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "store_product_media" */
export type Store_Product_Media_Mutation_Response = {
  __typename?: 'store_product_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Product_Media>;
};

/** on_conflict condition type for table "store_product_media" */
export type Store_Product_Media_On_Conflict = {
  constraint: Store_Product_Media_Constraint;
  update_columns?: Array<Store_Product_Media_Update_Column>;
  where?: InputMaybe<Store_Product_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product_media". */
export type Store_Product_Media_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  file?: InputMaybe<File_Order_By>;
  fileId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mediaId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product_media */
export type Store_Product_Media_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "store_product_media" */
export enum Store_Product_Media_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  MediaId = 'mediaId',
  /** column name */
  Order = 'order',
  /** column name */
  Sku = 'sku',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "store_product_media" */
export type Store_Product_Media_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fileId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  mediaId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['float8']>;
  sku?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_Product_Media_Stddev_Fields = {
  __typename?: 'store_product_media_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "store_product_media" */
export type Store_Product_Media_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Store_Product_Media_Stddev_Pop_Fields = {
  __typename?: 'store_product_media_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "store_product_media" */
export type Store_Product_Media_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Store_Product_Media_Stddev_Samp_Fields = {
  __typename?: 'store_product_media_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "store_product_media" */
export type Store_Product_Media_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Store_Product_Media_Sum_Fields = {
  __typename?: 'store_product_media_sum_fields';
  order?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "store_product_media" */
export type Store_Product_Media_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "store_product_media" */
export enum Store_Product_Media_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  MediaId = 'mediaId',
  /** column name */
  Order = 'order',
  /** column name */
  Sku = 'sku',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Store_Product_Media_Var_Pop_Fields = {
  __typename?: 'store_product_media_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "store_product_media" */
export type Store_Product_Media_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Store_Product_Media_Var_Samp_Fields = {
  __typename?: 'store_product_media_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "store_product_media" */
export type Store_Product_Media_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Store_Product_Media_Variance_Fields = {
  __typename?: 'store_product_media_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "store_product_media" */
export type Store_Product_Media_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Store_Product_Min_Fields = {
  __typename?: 'store_product_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  sku?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "store_product" */
export type Store_Product_Mutation_Response = {
  __typename?: 'store_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Store_Product>;
};

/** on_conflict condition type for table "store_product" */
export type Store_Product_On_Conflict = {
  constraint: Store_Product_Constraint;
  update_columns?: Array<Store_Product_Update_Column>;
  where?: InputMaybe<Store_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "store_product". */
export type Store_Product_Order_By = {
  attributes_aggregate?: InputMaybe<Store_Product_Attribute_Aggregate_Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  media_aggregate?: InputMaybe<Store_Product_Media_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: store_product */
export type Store_Product_Pk_Columns_Input = {
  sku: Scalars['String'];
};

/** select columns of table "store_product" */
export enum Store_Product_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "store_product" */
export type Store_Product_Set_Input = {
  companyId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  sku?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Store_Product_Stddev_Fields = {
  __typename?: 'store_product_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Store_Product_Stddev_Pop_Fields = {
  __typename?: 'store_product_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Store_Product_Stddev_Samp_Fields = {
  __typename?: 'store_product_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Store_Product_Sum_Fields = {
  __typename?: 'store_product_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** update columns of table "store_product" */
export enum Store_Product_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  Sku = 'sku',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** aggregate var_pop on columns */
export type Store_Product_Var_Pop_Fields = {
  __typename?: 'store_product_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Store_Product_Var_Samp_Fields = {
  __typename?: 'store_product_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Store_Product_Variance_Fields = {
  __typename?: 'store_product_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** Subscription intervals for digital products */
export type Subscription_Interval = {
  __typename?: 'subscription_interval';
  value: Scalars['String'];
};

/** aggregated selection of "subscription_interval" */
export type Subscription_Interval_Aggregate = {
  __typename?: 'subscription_interval_aggregate';
  aggregate?: Maybe<Subscription_Interval_Aggregate_Fields>;
  nodes: Array<Subscription_Interval>;
};

/** aggregate fields of "subscription_interval" */
export type Subscription_Interval_Aggregate_Fields = {
  __typename?: 'subscription_interval_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Subscription_Interval_Max_Fields>;
  min?: Maybe<Subscription_Interval_Min_Fields>;
};


/** aggregate fields of "subscription_interval" */
export type Subscription_Interval_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Interval_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "subscription_interval". All fields are combined with a logical 'AND'. */
export type Subscription_Interval_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Interval_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Interval_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Interval_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_interval" */
export enum Subscription_Interval_Constraint {
  /** unique or primary key constraint */
  SubscriptionIntervalPkey = 'subscription_interval_pkey'
}

/** input type for inserting data into table "subscription_interval" */
export type Subscription_Interval_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Subscription_Interval_Max_Fields = {
  __typename?: 'subscription_interval_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Subscription_Interval_Min_Fields = {
  __typename?: 'subscription_interval_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "subscription_interval" */
export type Subscription_Interval_Mutation_Response = {
  __typename?: 'subscription_interval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Interval>;
};

/** on_conflict condition type for table "subscription_interval" */
export type Subscription_Interval_On_Conflict = {
  constraint: Subscription_Interval_Constraint;
  update_columns?: Array<Subscription_Interval_Update_Column>;
  where?: InputMaybe<Subscription_Interval_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_interval". */
export type Subscription_Interval_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_interval */
export type Subscription_Interval_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "subscription_interval" */
export enum Subscription_Interval_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "subscription_interval" */
export type Subscription_Interval_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "subscription_interval" */
export enum Subscription_Interval_Update_Column {
  /** column name */
  Value = 'value'
}

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "address_country" */
  address_country: Array<Address_Country>;
  /** fetch aggregated fields from the table: "address_country" */
  address_country_aggregate: Address_Country_Aggregate;
  /** fetch data from the table: "address_country" using primary key columns */
  address_country_by_pk?: Maybe<Address_Country>;
  /** fetch data from the table: "address_state" */
  address_state: Array<Address_State>;
  /** fetch aggregated fields from the table: "address_state" */
  address_state_aggregate: Address_State_Aggregate;
  /** fetch data from the table: "address_state" using primary key columns */
  address_state_by_pk?: Maybe<Address_State>;
  /** fetch data from the table: "app" */
  app: Array<App>;
  /** fetch aggregated fields from the table: "app" */
  app_aggregate: App_Aggregate;
  /** fetch data from the table: "app" using primary key columns */
  app_by_pk?: Maybe<App>;
  /** fetch data from the table: "cache" */
  cache: Array<Cache>;
  /** fetch aggregated fields from the table: "cache" */
  cache_aggregate: Cache_Aggregate;
  /** fetch data from the table: "cache" using primary key columns */
  cache_by_pk?: Maybe<Cache>;
  /** fetch data from the table: "category" */
  category: Array<Category>;
  /** fetch aggregated fields from the table: "category" */
  category_aggregate: Category_Aggregate;
  /** fetch data from the table: "category" using primary key columns */
  category_by_pk?: Maybe<Category>;
  /** fetch data from the table: "category_namespace" */
  category_namespace: Array<Category_Namespace>;
  /** fetch aggregated fields from the table: "category_namespace" */
  category_namespace_aggregate: Category_Namespace_Aggregate;
  /** fetch data from the table: "category_namespace" using primary key columns */
  category_namespace_by_pk?: Maybe<Category_Namespace>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "company_address" */
  company_address: Array<Company_Address>;
  /** fetch aggregated fields from the table: "company_address" */
  company_address_aggregate: Company_Address_Aggregate;
  /** fetch data from the table: "company_address" using primary key columns */
  company_address_by_pk?: Maybe<Company_Address>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company_attribute" */
  company_attribute: Array<Company_Attribute>;
  /** fetch aggregated fields from the table: "company_attribute" */
  company_attribute_aggregate: Company_Attribute_Aggregate;
  /** fetch data from the table: "company_attribute" using primary key columns */
  company_attribute_by_pk?: Maybe<Company_Attribute>;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_category" */
  company_category: Array<Company_Category>;
  /** fetch aggregated fields from the table: "company_category" */
  company_category_aggregate: Company_Category_Aggregate;
  /** fetch data from the table: "company_category" using primary key columns */
  company_category_by_pk?: Maybe<Company_Category>;
  /** fetch data from the table: "company_email" */
  company_email: Array<Company_Email>;
  /** fetch aggregated fields from the table: "company_email" */
  company_email_aggregate: Company_Email_Aggregate;
  /** fetch data from the table: "company_email" using primary key columns */
  company_email_by_pk?: Maybe<Company_Email>;
  /** fetch data from the table: "company_form_response" */
  company_form_response: Array<Company_Form_Response>;
  /** fetch aggregated fields from the table: "company_form_response" */
  company_form_response_aggregate: Company_Form_Response_Aggregate;
  /** fetch data from the table: "company_form_response" using primary key columns */
  company_form_response_by_pk?: Maybe<Company_Form_Response>;
  /** fetch data from the table: "company_phone_number" */
  company_phone_number: Array<Company_Phone_Number>;
  /** fetch aggregated fields from the table: "company_phone_number" */
  company_phone_number_aggregate: Company_Phone_Number_Aggregate;
  /** fetch data from the table: "company_phone_number" using primary key columns */
  company_phone_number_by_pk?: Maybe<Company_Phone_Number>;
  /** fetch data from the table: "company_user" */
  company_user: Array<Company_User>;
  /** fetch aggregated fields from the table: "company_user" */
  company_user_aggregate: Company_User_Aggregate;
  /** fetch data from the table: "company_user" using primary key columns */
  company_user_by_pk?: Maybe<Company_User>;
  /** fetch data from the table: "compliance_account" */
  compliance_account: Array<Compliance_Account>;
  /** fetch aggregated fields from the table: "compliance_account" */
  compliance_account_aggregate: Compliance_Account_Aggregate;
  /** fetch data from the table: "compliance_account" using primary key columns */
  compliance_account_by_pk?: Maybe<Compliance_Account>;
  /** fetch data from the table: "compliance_account_user" */
  compliance_account_user: Array<Compliance_Account_User>;
  /** fetch aggregated fields from the table: "compliance_account_user" */
  compliance_account_user_aggregate: Compliance_Account_User_Aggregate;
  /** fetch data from the table: "compliance_account_user" using primary key columns */
  compliance_account_user_by_pk?: Maybe<Compliance_Account_User>;
  /** fetch data from the table: "compliance_check" */
  compliance_check: Array<Compliance_Check>;
  /** fetch aggregated fields from the table: "compliance_check" */
  compliance_check_aggregate: Compliance_Check_Aggregate;
  /** fetch data from the table: "compliance_check" using primary key columns */
  compliance_check_by_pk?: Maybe<Compliance_Check>;
  /** fetch data from the table: "compliance_check_type" */
  compliance_check_type: Array<Compliance_Check_Type>;
  /** fetch aggregated fields from the table: "compliance_check_type" */
  compliance_check_type_aggregate: Compliance_Check_Type_Aggregate;
  /** fetch data from the table: "compliance_check_type" using primary key columns */
  compliance_check_type_by_pk?: Maybe<Compliance_Check_Type>;
  /** fetch data from the table: "compliance_dashboard" */
  compliance_dashboard: Array<Compliance_Dashboard>;
  /** fetch aggregated fields from the table: "compliance_dashboard" */
  compliance_dashboard_aggregate: Compliance_Dashboard_Aggregate;
  /** fetch data from the table: "compliance_dashboard" using primary key columns */
  compliance_dashboard_by_pk?: Maybe<Compliance_Dashboard>;
  /** fetch data from the table: "compliance_dashboard_check_type" */
  compliance_dashboard_check_type: Array<Compliance_Dashboard_Check_Type>;
  /** fetch aggregated fields from the table: "compliance_dashboard_check_type" */
  compliance_dashboard_check_type_aggregate: Compliance_Dashboard_Check_Type_Aggregate;
  /** fetch data from the table: "compliance_dashboard_check_type" using primary key columns */
  compliance_dashboard_check_type_by_pk?: Maybe<Compliance_Dashboard_Check_Type>;
  /** fetch data from the table: "compliance_dashboard_user" */
  compliance_dashboard_user: Array<Compliance_Dashboard_User>;
  /** fetch aggregated fields from the table: "compliance_dashboard_user" */
  compliance_dashboard_user_aggregate: Compliance_Dashboard_User_Aggregate;
  /** fetch data from the table: "compliance_dashboard_user" using primary key columns */
  compliance_dashboard_user_by_pk?: Maybe<Compliance_Dashboard_User>;
  /** fetch data from the table: "compliance_entity_company" */
  compliance_entity_company: Array<Compliance_Entity_Company>;
  /** fetch data from the table: "compliance_entity_company_address" */
  compliance_entity_company_address: Array<Compliance_Entity_Company_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_company_address" */
  compliance_entity_company_address_aggregate: Compliance_Entity_Company_Address_Aggregate;
  /** fetch data from the table: "compliance_entity_company_address" using primary key columns */
  compliance_entity_company_address_by_pk?: Maybe<Compliance_Entity_Company_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_company" */
  compliance_entity_company_aggregate: Compliance_Entity_Company_Aggregate;
  /** fetch data from the table: "compliance_entity_company" using primary key columns */
  compliance_entity_company_by_pk?: Maybe<Compliance_Entity_Company>;
  /** fetch data from the table: "compliance_entity_person" */
  compliance_entity_person: Array<Compliance_Entity_Person>;
  /** fetch data from the table: "compliance_entity_person_address" */
  compliance_entity_person_address: Array<Compliance_Entity_Person_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_person_address" */
  compliance_entity_person_address_aggregate: Compliance_Entity_Person_Address_Aggregate;
  /** fetch data from the table: "compliance_entity_person_address" using primary key columns */
  compliance_entity_person_address_by_pk?: Maybe<Compliance_Entity_Person_Address>;
  /** fetch aggregated fields from the table: "compliance_entity_person" */
  compliance_entity_person_aggregate: Compliance_Entity_Person_Aggregate;
  /** fetch data from the table: "compliance_entity_person" using primary key columns */
  compliance_entity_person_by_pk?: Maybe<Compliance_Entity_Person>;
  /** fetch data from the table: "compliance_entity_person_national_id" */
  compliance_entity_person_national_id: Array<Compliance_Entity_Person_National_Id>;
  /** fetch aggregated fields from the table: "compliance_entity_person_national_id" */
  compliance_entity_person_national_id_aggregate: Compliance_Entity_Person_National_Id_Aggregate;
  /** fetch data from the table: "compliance_entity_person_national_id" using primary key columns */
  compliance_entity_person_national_id_by_pk?: Maybe<Compliance_Entity_Person_National_Id>;
  /** fetch data from the table: "entity" */
  entity: Array<Entity>;
  /** fetch aggregated fields from the table: "entity" */
  entity_aggregate: Entity_Aggregate;
  /** fetch data from the table: "entity_attribute_group" */
  entity_attribute_group: Array<Entity_Attribute_Group>;
  /** fetch aggregated fields from the table: "entity_attribute_group" */
  entity_attribute_group_aggregate: Entity_Attribute_Group_Aggregate;
  /** fetch data from the table: "entity_attribute_group" using primary key columns */
  entity_attribute_group_by_pk?: Maybe<Entity_Attribute_Group>;
  /** fetch data from the table: "entity_attribute_type" */
  entity_attribute_type: Array<Entity_Attribute_Type>;
  /** fetch aggregated fields from the table: "entity_attribute_type" */
  entity_attribute_type_aggregate: Entity_Attribute_Type_Aggregate;
  /** fetch data from the table: "entity_attribute_type" using primary key columns */
  entity_attribute_type_by_pk?: Maybe<Entity_Attribute_Type>;
  /** fetch data from the table: "entity" using primary key columns */
  entity_by_pk?: Maybe<Entity>;
  /** fetch data from the table: "file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "file" */
  file_aggregate: File_Aggregate;
  /** fetch data from the table: "file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "form" */
  form: Array<Form>;
  /** fetch aggregated fields from the table: "form" */
  form_aggregate: Form_Aggregate;
  /** fetch data from the table: "form" using primary key columns */
  form_by_pk?: Maybe<Form>;
  /** fetch data from the table: "form_group" */
  form_group: Array<Form_Group>;
  /** fetch aggregated fields from the table: "form_group" */
  form_group_aggregate: Form_Group_Aggregate;
  /** fetch data from the table: "form_group" using primary key columns */
  form_group_by_pk?: Maybe<Form_Group>;
  /** fetch data from the table: "form_item" */
  form_item: Array<Form_Item>;
  /** fetch aggregated fields from the table: "form_item" */
  form_item_aggregate: Form_Item_Aggregate;
  /** fetch data from the table: "form_item" using primary key columns */
  form_item_by_pk?: Maybe<Form_Item>;
  /** fetch data from the table: "form_item_response" */
  form_item_response: Array<Form_Item_Response>;
  /** fetch aggregated fields from the table: "form_item_response" */
  form_item_response_aggregate: Form_Item_Response_Aggregate;
  /** fetch data from the table: "form_item_response" using primary key columns */
  form_item_response_by_pk?: Maybe<Form_Item_Response>;
  /** fetch data from the table: "form_namespace" */
  form_namespace: Array<Form_Namespace>;
  /** fetch aggregated fields from the table: "form_namespace" */
  form_namespace_aggregate: Form_Namespace_Aggregate;
  /** fetch data from the table: "form_namespace" using primary key columns */
  form_namespace_by_pk?: Maybe<Form_Namespace>;
  /** fetch data from the table: "form_response" */
  form_response: Array<Form_Response>;
  /** fetch aggregated fields from the table: "form_response" */
  form_response_aggregate: Form_Response_Aggregate;
  /** fetch data from the table: "form_response" using primary key columns */
  form_response_by_pk?: Maybe<Form_Response>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "job_run" */
  job_run: Array<Job_Run>;
  /** fetch aggregated fields from the table: "job_run" */
  job_run_aggregate: Job_Run_Aggregate;
  /** fetch data from the table: "job_run" using primary key columns */
  job_run_by_pk?: Maybe<Job_Run>;
  /** fetch data from the table: "log" */
  log: Array<Log>;
  /** fetch aggregated fields from the table: "log" */
  log_aggregate: Log_Aggregate;
  /** fetch data from the table: "log" using primary key columns */
  log_by_pk?: Maybe<Log>;
  /** fetch data from the table: "national_id_type" */
  national_id_type: Array<National_Id_Type>;
  /** fetch aggregated fields from the table: "national_id_type" */
  national_id_type_aggregate: National_Id_Type_Aggregate;
  /** fetch data from the table: "national_id_type" using primary key columns */
  national_id_type_by_pk?: Maybe<National_Id_Type>;
  /** fetch data from the table: "public_configuration" */
  public_configuration: Array<Public_Configuration>;
  /** fetch aggregated fields from the table: "public_configuration" */
  public_configuration_aggregate: Public_Configuration_Aggregate;
  /** fetch data from the table: "public_configuration" using primary key columns */
  public_configuration_by_pk?: Maybe<Public_Configuration>;
  /** fetch data from the table: "public_configuration_value" */
  public_configuration_value: Array<Public_Configuration_Value>;
  /** fetch aggregated fields from the table: "public_configuration_value" */
  public_configuration_value_aggregate: Public_Configuration_Value_Aggregate;
  /** fetch data from the table: "public_configuration_value" using primary key columns */
  public_configuration_value_by_pk?: Maybe<Public_Configuration_Value>;
  /** fetch data from the table: "store_order" */
  store_order: Array<Store_Order>;
  /** fetch data from the table: "store_order_address" */
  store_order_address: Array<Store_Order_Address>;
  /** fetch aggregated fields from the table: "store_order_address" */
  store_order_address_aggregate: Store_Order_Address_Aggregate;
  /** fetch data from the table: "store_order_address" using primary key columns */
  store_order_address_by_pk?: Maybe<Store_Order_Address>;
  /** fetch data from the table: "store_order_address_type" */
  store_order_address_type: Array<Store_Order_Address_Type>;
  /** fetch aggregated fields from the table: "store_order_address_type" */
  store_order_address_type_aggregate: Store_Order_Address_Type_Aggregate;
  /** fetch data from the table: "store_order_address_type" using primary key columns */
  store_order_address_type_by_pk?: Maybe<Store_Order_Address_Type>;
  /** fetch aggregated fields from the table: "store_order" */
  store_order_aggregate: Store_Order_Aggregate;
  /** fetch data from the table: "store_order" using primary key columns */
  store_order_by_pk?: Maybe<Store_Order>;
  /** fetch data from the table: "store_order_item" */
  store_order_item: Array<Store_Order_Item>;
  /** fetch aggregated fields from the table: "store_order_item" */
  store_order_item_aggregate: Store_Order_Item_Aggregate;
  /** fetch data from the table: "store_order_item" using primary key columns */
  store_order_item_by_pk?: Maybe<Store_Order_Item>;
  /** fetch data from the table: "store_product" */
  store_product: Array<Store_Product>;
  /** fetch aggregated fields from the table: "store_product" */
  store_product_aggregate: Store_Product_Aggregate;
  /** fetch data from the table: "store_product_attribute" */
  store_product_attribute: Array<Store_Product_Attribute>;
  /** fetch aggregated fields from the table: "store_product_attribute" */
  store_product_attribute_aggregate: Store_Product_Attribute_Aggregate;
  /** fetch data from the table: "store_product_attribute" using primary key columns */
  store_product_attribute_by_pk?: Maybe<Store_Product_Attribute>;
  /** fetch data from the table: "store_product" using primary key columns */
  store_product_by_pk?: Maybe<Store_Product>;
  /** fetch data from the table: "store_product_category" */
  store_product_category: Array<Store_Product_Category>;
  /** fetch aggregated fields from the table: "store_product_category" */
  store_product_category_aggregate: Store_Product_Category_Aggregate;
  /** fetch data from the table: "store_product_category" using primary key columns */
  store_product_category_by_pk?: Maybe<Store_Product_Category>;
  /** fetch data from the table: "store_product_link" */
  store_product_link: Array<Store_Product_Link>;
  /** fetch aggregated fields from the table: "store_product_link" */
  store_product_link_aggregate: Store_Product_Link_Aggregate;
  /** fetch data from the table: "store_product_link" using primary key columns */
  store_product_link_by_pk?: Maybe<Store_Product_Link>;
  /** fetch data from the table: "store_product_link_type" */
  store_product_link_type: Array<Store_Product_Link_Type>;
  /** fetch aggregated fields from the table: "store_product_link_type" */
  store_product_link_type_aggregate: Store_Product_Link_Type_Aggregate;
  /** fetch data from the table: "store_product_link_type" using primary key columns */
  store_product_link_type_by_pk?: Maybe<Store_Product_Link_Type>;
  /** fetch data from the table: "store_product_media" */
  store_product_media: Array<Store_Product_Media>;
  /** fetch aggregated fields from the table: "store_product_media" */
  store_product_media_aggregate: Store_Product_Media_Aggregate;
  /** fetch data from the table: "store_product_media" using primary key columns */
  store_product_media_by_pk?: Maybe<Store_Product_Media>;
  /** fetch data from the table: "subscription_interval" */
  subscription_interval: Array<Subscription_Interval>;
  /** fetch aggregated fields from the table: "subscription_interval" */
  subscription_interval_aggregate: Subscription_Interval_Aggregate;
  /** fetch data from the table: "subscription_interval" using primary key columns */
  subscription_interval_by_pk?: Maybe<Subscription_Interval>;
  /** fetch data from the table: "tracking_event" */
  tracking_event: Array<Tracking_Event>;
  /** fetch aggregated fields from the table: "tracking_event" */
  tracking_event_aggregate: Tracking_Event_Aggregate;
  /** fetch data from the table: "tracking_event" using primary key columns */
  tracking_event_by_pk?: Maybe<Tracking_Event>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user_address" */
  user_address: Array<User_Address>;
  /** fetch aggregated fields from the table: "user_address" */
  user_address_aggregate: User_Address_Aggregate;
  /** fetch data from the table: "user_address" using primary key columns */
  user_address_by_pk?: Maybe<User_Address>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user_attribute" */
  user_attribute: Array<User_Attribute>;
  /** fetch aggregated fields from the table: "user_attribute" */
  user_attribute_aggregate: User_Attribute_Aggregate;
  /** fetch data from the table: "user_attribute" using primary key columns */
  user_attribute_by_pk?: Maybe<User_Attribute>;
  /** fetch data from the table: "user_auth" */
  user_auth: Array<User_Auth>;
  /** fetch aggregated fields from the table: "user_auth" */
  user_auth_aggregate: User_Auth_Aggregate;
  /** fetch data from the table: "user_auth" using primary key columns */
  user_auth_by_pk?: Maybe<User_Auth>;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_compliance_check" */
  user_compliance_check: Array<User_Compliance_Check>;
  /** fetch aggregated fields from the table: "user_compliance_check" */
  user_compliance_check_aggregate: User_Compliance_Check_Aggregate;
  /** fetch data from the table: "user_compliance_check" using primary key columns */
  user_compliance_check_by_pk?: Maybe<User_Compliance_Check>;
  /** fetch data from the table: "user_compliance_check_status" */
  user_compliance_check_status: Array<User_Compliance_Check_Status>;
  /** fetch aggregated fields from the table: "user_compliance_check_status" */
  user_compliance_check_status_aggregate: User_Compliance_Check_Status_Aggregate;
  /** fetch data from the table: "user_compliance_check_status" using primary key columns */
  user_compliance_check_status_by_pk?: Maybe<User_Compliance_Check_Status>;
  /** fetch data from the table: "user_email" */
  user_email: Array<User_Email>;
  /** fetch aggregated fields from the table: "user_email" */
  user_email_aggregate: User_Email_Aggregate;
  /** fetch data from the table: "user_email" using primary key columns */
  user_email_by_pk?: Maybe<User_Email>;
  /** fetch data from the table: "user_national_id" */
  user_national_id: Array<User_National_Id>;
  /** fetch aggregated fields from the table: "user_national_id" */
  user_national_id_aggregate: User_National_Id_Aggregate;
  /** fetch data from the table: "user_national_id" using primary key columns */
  user_national_id_by_pk?: Maybe<User_National_Id>;
  /** fetch data from the table: "user_phone_number" */
  user_phone_number: Array<User_Phone_Number>;
  /** fetch aggregated fields from the table: "user_phone_number" */
  user_phone_number_aggregate: User_Phone_Number_Aggregate;
  /** fetch data from the table: "user_phone_number" using primary key columns */
  user_phone_number_by_pk?: Maybe<User_Phone_Number>;
  /** fetch data from the table: "value_type" */
  value_type: Array<Value_Type>;
  /** fetch aggregated fields from the table: "value_type" */
  value_type_aggregate: Value_Type_Aggregate;
  /** fetch data from the table: "value_type" using primary key columns */
  value_type_by_pk?: Maybe<Value_Type>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Order_By>>;
  where?: InputMaybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAddress_CountryArgs = {
  distinct_on?: InputMaybe<Array<Address_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Country_Order_By>>;
  where?: InputMaybe<Address_Country_Bool_Exp>;
};


export type Subscription_RootAddress_Country_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_Country_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_Country_Order_By>>;
  where?: InputMaybe<Address_Country_Bool_Exp>;
};


export type Subscription_RootAddress_Country_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootAddress_StateArgs = {
  distinct_on?: InputMaybe<Array<Address_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_State_Order_By>>;
  where?: InputMaybe<Address_State_Bool_Exp>;
};


export type Subscription_RootAddress_State_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Address_State_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Address_State_Order_By>>;
  where?: InputMaybe<Address_State_Bool_Exp>;
};


export type Subscription_RootAddress_State_By_PkArgs = {
  code: Scalars['String'];
  countryCode: Scalars['String'];
};


export type Subscription_RootAppArgs = {
  distinct_on?: InputMaybe<Array<App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Order_By>>;
  where?: InputMaybe<App_Bool_Exp>;
};


export type Subscription_RootApp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Order_By>>;
  where?: InputMaybe<App_Bool_Exp>;
};


export type Subscription_RootApp_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCacheArgs = {
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  where?: InputMaybe<Cache_Bool_Exp>;
};


export type Subscription_RootCache_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cache_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cache_Order_By>>;
  where?: InputMaybe<Cache_Bool_Exp>;
};


export type Subscription_RootCache_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootCategoryArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Subscription_RootCategory_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Order_By>>;
  where?: InputMaybe<Category_Bool_Exp>;
};


export type Subscription_RootCategory_By_PkArgs = {
  urlPath: Scalars['String'];
};


export type Subscription_RootCategory_NamespaceArgs = {
  distinct_on?: InputMaybe<Array<Category_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Namespace_Order_By>>;
  where?: InputMaybe<Category_Namespace_Bool_Exp>;
};


export type Subscription_RootCategory_Namespace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Category_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Category_Namespace_Order_By>>;
  where?: InputMaybe<Category_Namespace_Bool_Exp>;
};


export type Subscription_RootCategory_Namespace_By_PkArgs = {
  namespace: Scalars['String'];
};


export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AddressArgs = {
  distinct_on?: InputMaybe<Array<Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Address_Order_By>>;
  where?: InputMaybe<Company_Address_Bool_Exp>;
};


export type Subscription_RootCompany_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Address_Order_By>>;
  where?: InputMaybe<Company_Address_Bool_Exp>;
};


export type Subscription_RootCompany_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};


export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Company_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Attribute_Order_By>>;
  where?: InputMaybe<Company_Attribute_Bool_Exp>;
};


export type Subscription_RootCompany_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Attribute_Order_By>>;
  where?: InputMaybe<Company_Attribute_Bool_Exp>;
};


export type Subscription_RootCompany_Attribute_By_PkArgs = {
  companyId: Scalars['uuid'];
  name: Scalars['String'];
};


export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompany_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Company_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Category_Order_By>>;
  where?: InputMaybe<Company_Category_Bool_Exp>;
};


export type Subscription_RootCompany_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Category_Order_By>>;
  where?: InputMaybe<Company_Category_Bool_Exp>;
};


export type Subscription_RootCompany_Category_By_PkArgs = {
  categoryUrlPath: Scalars['String'];
  companyId: Scalars['uuid'];
};


export type Subscription_RootCompany_EmailArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Order_By>>;
  where?: InputMaybe<Company_Email_Bool_Exp>;
};


export type Subscription_RootCompany_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Email_Order_By>>;
  where?: InputMaybe<Company_Email_Bool_Exp>;
};


export type Subscription_RootCompany_Email_By_PkArgs = {
  companyId: Scalars['uuid'];
  email: Scalars['String'];
};


export type Subscription_RootCompany_Form_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


export type Subscription_RootCompany_Form_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Form_Response_Order_By>>;
  where?: InputMaybe<Company_Form_Response_Bool_Exp>;
};


export type Subscription_RootCompany_Form_Response_By_PkArgs = {
  companyId: Scalars['uuid'];
  formResponseId: Scalars['uuid'];
};


export type Subscription_RootCompany_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<Company_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Phone_Number_Order_By>>;
  where?: InputMaybe<Company_Phone_Number_Bool_Exp>;
};


export type Subscription_RootCompany_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Phone_Number_Order_By>>;
  where?: InputMaybe<Company_Phone_Number_Bool_Exp>;
};


export type Subscription_RootCompany_Phone_Number_By_PkArgs = {
  companyId: Scalars['uuid'];
  number: Scalars['String'];
};


export type Subscription_RootCompany_UserArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Subscription_RootCompany_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_User_Order_By>>;
  where?: InputMaybe<Company_User_Bool_Exp>;
};


export type Subscription_RootCompany_User_By_PkArgs = {
  companyId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Subscription_RootCompliance_AccountArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_Order_By>>;
  where?: InputMaybe<Compliance_Account_Bool_Exp>;
};


export type Subscription_RootCompliance_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_Order_By>>;
  where?: InputMaybe<Compliance_Account_Bool_Exp>;
};


export type Subscription_RootCompliance_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompliance_Account_UserArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_User_Order_By>>;
  where?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};


export type Subscription_RootCompliance_Account_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Account_User_Order_By>>;
  where?: InputMaybe<Compliance_Account_User_Bool_Exp>;
};


export type Subscription_RootCompliance_Account_User_By_PkArgs = {
  accountId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Subscription_RootCompliance_CheckArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Order_By>>;
  where?: InputMaybe<Compliance_Check_Bool_Exp>;
};


export type Subscription_RootCompliance_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Order_By>>;
  where?: InputMaybe<Compliance_Check_Bool_Exp>;
};


export type Subscription_RootCompliance_Check_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompliance_Check_TypeArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
};


export type Subscription_RootCompliance_Check_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
};


export type Subscription_RootCompliance_Check_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootCompliance_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
};


export type Subscription_RootCompliance_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Bool_Exp>;
};


export type Subscription_RootCompliance_Dashboard_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompliance_Dashboard_Check_TypeArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
};


export type Subscription_RootCompliance_Dashboard_Check_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_Check_Type_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_Check_Type_Bool_Exp>;
};


export type Subscription_RootCompliance_Dashboard_Check_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompliance_Dashboard_UserArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};


export type Subscription_RootCompliance_Dashboard_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Dashboard_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Dashboard_User_Order_By>>;
  where?: InputMaybe<Compliance_Dashboard_User_Bool_Exp>;
};


export type Subscription_RootCompliance_Dashboard_User_By_PkArgs = {
  dashboardId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Subscription_RootCompliance_Entity_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Company_AddressArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Company_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Address_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Company_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  companyId: Scalars['uuid'];
};


export type Subscription_RootCompliance_Entity_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Company_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Company_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompliance_Entity_PersonArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Person_AddressArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Person_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Address_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Address_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Person_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  personId: Scalars['uuid'];
};


export type Subscription_RootCompliance_Entity_Person_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Person_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompliance_Entity_Person_National_IdArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Person_National_Id_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compliance_Entity_Person_National_Id_Order_By>>;
  where?: InputMaybe<Compliance_Entity_Person_National_Id_Bool_Exp>;
};


export type Subscription_RootCompliance_Entity_Person_National_Id_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEntityArgs = {
  distinct_on?: InputMaybe<Array<Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Order_By>>;
  where?: InputMaybe<Entity_Bool_Exp>;
};


export type Subscription_RootEntity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Order_By>>;
  where?: InputMaybe<Entity_Bool_Exp>;
};


export type Subscription_RootEntity_Attribute_GroupArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Group_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
};


export type Subscription_RootEntity_Attribute_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Group_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Group_Bool_Exp>;
};


export type Subscription_RootEntity_Attribute_Group_By_PkArgs = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};


export type Subscription_RootEntity_Attribute_TypeArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};


export type Subscription_RootEntity_Attribute_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Entity_Attribute_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Entity_Attribute_Type_Order_By>>;
  where?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
};


export type Subscription_RootEntity_Attribute_Type_By_PkArgs = {
  entityName: Entity_Enum;
  name: Scalars['String'];
};


export type Subscription_RootEntity_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFormArgs = {
  distinct_on?: InputMaybe<Array<Form_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Order_By>>;
  where?: InputMaybe<Form_Bool_Exp>;
};


export type Subscription_RootForm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Order_By>>;
  where?: InputMaybe<Form_Bool_Exp>;
};


export type Subscription_RootForm_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootForm_GroupArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};


export type Subscription_RootForm_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Group_Order_By>>;
  where?: InputMaybe<Form_Group_Bool_Exp>;
};


export type Subscription_RootForm_Group_By_PkArgs = {
  childId: Scalars['uuid'];
  parentId: Scalars['uuid'];
};


export type Subscription_RootForm_ItemArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Order_By>>;
  where?: InputMaybe<Form_Item_Bool_Exp>;
};


export type Subscription_RootForm_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Order_By>>;
  where?: InputMaybe<Form_Item_Bool_Exp>;
};


export type Subscription_RootForm_Item_By_PkArgs = {
  formId: Scalars['uuid'];
  name: Scalars['String'];
};


export type Subscription_RootForm_Item_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};


export type Subscription_RootForm_Item_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Item_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Item_Response_Order_By>>;
  where?: InputMaybe<Form_Item_Response_Bool_Exp>;
};


export type Subscription_RootForm_Item_Response_By_PkArgs = {
  formItemName: Scalars['String'];
  formResponseId: Scalars['uuid'];
};


export type Subscription_RootForm_NamespaceArgs = {
  distinct_on?: InputMaybe<Array<Form_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Namespace_Order_By>>;
  where?: InputMaybe<Form_Namespace_Bool_Exp>;
};


export type Subscription_RootForm_Namespace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Namespace_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Namespace_Order_By>>;
  where?: InputMaybe<Form_Namespace_Bool_Exp>;
};


export type Subscription_RootForm_Namespace_By_PkArgs = {
  namespace: Scalars['String'];
};


export type Subscription_RootForm_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


export type Subscription_RootForm_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


export type Subscription_RootForm_Response_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJobArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJob_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Order_By>>;
  where?: InputMaybe<Job_Bool_Exp>;
};


export type Subscription_RootJob_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootJob_RunArgs = {
  distinct_on?: InputMaybe<Array<Job_Run_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Run_Order_By>>;
  where?: InputMaybe<Job_Run_Bool_Exp>;
};


export type Subscription_RootJob_Run_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Job_Run_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Job_Run_Order_By>>;
  where?: InputMaybe<Job_Run_Bool_Exp>;
};


export type Subscription_RootJob_Run_By_PkArgs = {
  createdAt: Scalars['timestamptz'];
  jobName: Scalars['String'];
};


export type Subscription_RootLogArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};


export type Subscription_RootLog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Log_Order_By>>;
  where?: InputMaybe<Log_Bool_Exp>;
};


export type Subscription_RootLog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNational_Id_TypeArgs = {
  distinct_on?: InputMaybe<Array<National_Id_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Id_Type_Order_By>>;
  where?: InputMaybe<National_Id_Type_Bool_Exp>;
};


export type Subscription_RootNational_Id_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<National_Id_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<National_Id_Type_Order_By>>;
  where?: InputMaybe<National_Id_Type_Bool_Exp>;
};


export type Subscription_RootNational_Id_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootPublic_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Order_By>>;
  where?: InputMaybe<Public_Configuration_Bool_Exp>;
};


export type Subscription_RootPublic_Configuration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Order_By>>;
  where?: InputMaybe<Public_Configuration_Bool_Exp>;
};


export type Subscription_RootPublic_Configuration_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootPublic_Configuration_ValueArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Value_Order_By>>;
  where?: InputMaybe<Public_Configuration_Value_Bool_Exp>;
};


export type Subscription_RootPublic_Configuration_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Public_Configuration_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Public_Configuration_Value_Order_By>>;
  where?: InputMaybe<Public_Configuration_Value_Bool_Exp>;
};


export type Subscription_RootPublic_Configuration_Value_By_PkArgs = {
  configurationName: Scalars['String'];
  name: Scalars['String'];
};


export type Subscription_RootStore_OrderArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Order_By>>;
  where?: InputMaybe<Store_Order_Bool_Exp>;
};


export type Subscription_RootStore_Order_AddressArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Bool_Exp>;
};


export type Subscription_RootStore_Order_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Bool_Exp>;
};


export type Subscription_RootStore_Order_Address_By_PkArgs = {
  addressType: Store_Order_Address_Type_Enum;
  orderId: Scalars['String'];
};


export type Subscription_RootStore_Order_Address_TypeArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Type_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Type_Bool_Exp>;
};


export type Subscription_RootStore_Order_Address_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Address_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Address_Type_Order_By>>;
  where?: InputMaybe<Store_Order_Address_Type_Bool_Exp>;
};


export type Subscription_RootStore_Order_Address_Type_By_PkArgs = {
  type: Scalars['String'];
};


export type Subscription_RootStore_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Order_By>>;
  where?: InputMaybe<Store_Order_Bool_Exp>;
};


export type Subscription_RootStore_Order_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootStore_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Item_Order_By>>;
  where?: InputMaybe<Store_Order_Item_Bool_Exp>;
};


export type Subscription_RootStore_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Order_Item_Order_By>>;
  where?: InputMaybe<Store_Order_Item_Bool_Exp>;
};


export type Subscription_RootStore_Order_Item_By_PkArgs = {
  orderId: Scalars['String'];
  productSku: Scalars['String'];
};


export type Subscription_RootStore_ProductArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Order_By>>;
  where?: InputMaybe<Store_Product_Bool_Exp>;
};


export type Subscription_RootStore_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Order_By>>;
  where?: InputMaybe<Store_Product_Bool_Exp>;
};


export type Subscription_RootStore_Product_AttributeArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Attribute_Order_By>>;
  where?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
};


export type Subscription_RootStore_Product_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Attribute_Order_By>>;
  where?: InputMaybe<Store_Product_Attribute_Bool_Exp>;
};


export type Subscription_RootStore_Product_Attribute_By_PkArgs = {
  name: Scalars['String'];
  sku: Scalars['String'];
};


export type Subscription_RootStore_Product_By_PkArgs = {
  sku: Scalars['String'];
};


export type Subscription_RootStore_Product_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Category_Order_By>>;
  where?: InputMaybe<Store_Product_Category_Bool_Exp>;
};


export type Subscription_RootStore_Product_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Category_Order_By>>;
  where?: InputMaybe<Store_Product_Category_Bool_Exp>;
};


export type Subscription_RootStore_Product_Category_By_PkArgs = {
  sku: Scalars['String'];
  urlPath: Scalars['String'];
};


export type Subscription_RootStore_Product_LinkArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Bool_Exp>;
};


export type Subscription_RootStore_Product_Link_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Bool_Exp>;
};


export type Subscription_RootStore_Product_Link_By_PkArgs = {
  linkType: Store_Product_Link_Type_Enum;
  linkedSku: Scalars['String'];
  sku: Scalars['String'];
};


export type Subscription_RootStore_Product_Link_TypeArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Type_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Type_Bool_Exp>;
};


export type Subscription_RootStore_Product_Link_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Link_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Link_Type_Order_By>>;
  where?: InputMaybe<Store_Product_Link_Type_Bool_Exp>;
};


export type Subscription_RootStore_Product_Link_Type_By_PkArgs = {
  link_type: Scalars['String'];
};


export type Subscription_RootStore_Product_MediaArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Media_Order_By>>;
  where?: InputMaybe<Store_Product_Media_Bool_Exp>;
};


export type Subscription_RootStore_Product_Media_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Store_Product_Media_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Store_Product_Media_Order_By>>;
  where?: InputMaybe<Store_Product_Media_Bool_Exp>;
};


export type Subscription_RootStore_Product_Media_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSubscription_IntervalArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Interval_Order_By>>;
  where?: InputMaybe<Subscription_Interval_Bool_Exp>;
};


export type Subscription_RootSubscription_Interval_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Interval_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Interval_Order_By>>;
  where?: InputMaybe<Subscription_Interval_Bool_Exp>;
};


export type Subscription_RootSubscription_Interval_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootTracking_EventArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Event_Order_By>>;
  where?: InputMaybe<Tracking_Event_Bool_Exp>;
};


export type Subscription_RootTracking_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tracking_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tracking_Event_Order_By>>;
  where?: InputMaybe<Tracking_Event_Bool_Exp>;
};


export type Subscription_RootTracking_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AddressArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};


export type Subscription_RootUser_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};


export type Subscription_RootUser_Address_By_PkArgs = {
  addressId: Scalars['uuid'];
  userId: Scalars['uuid'];
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AttributeArgs = {
  distinct_on?: InputMaybe<Array<User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Attribute_Order_By>>;
  where?: InputMaybe<User_Attribute_Bool_Exp>;
};


export type Subscription_RootUser_Attribute_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Attribute_Order_By>>;
  where?: InputMaybe<User_Attribute_Bool_Exp>;
};


export type Subscription_RootUser_Attribute_By_PkArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
  userId: Scalars['uuid'];
};


export type Subscription_RootUser_AuthArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Subscription_RootUser_Auth_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Auth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Auth_Order_By>>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};


export type Subscription_RootUser_Auth_By_PkArgs = {
  userId: Scalars['uuid'];
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Compliance_CheckArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};


export type Subscription_RootUser_Compliance_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};


export type Subscription_RootUser_Compliance_Check_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Compliance_Check_StatusArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Status_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Status_Bool_Exp>;
};


export type Subscription_RootUser_Compliance_Check_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Status_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Status_Bool_Exp>;
};


export type Subscription_RootUser_Compliance_Check_Status_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootUser_EmailArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Order_By>>;
  where?: InputMaybe<User_Email_Bool_Exp>;
};


export type Subscription_RootUser_Email_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Order_By>>;
  where?: InputMaybe<User_Email_Bool_Exp>;
};


export type Subscription_RootUser_Email_By_PkArgs = {
  email: Scalars['String'];
  userId: Scalars['uuid'];
};


export type Subscription_RootUser_National_IdArgs = {
  distinct_on?: InputMaybe<Array<User_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_National_Id_Order_By>>;
  where?: InputMaybe<User_National_Id_Bool_Exp>;
};


export type Subscription_RootUser_National_Id_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_National_Id_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_National_Id_Order_By>>;
  where?: InputMaybe<User_National_Id_Bool_Exp>;
};


export type Subscription_RootUser_National_Id_By_PkArgs = {
  type_name: Scalars['String'];
  user_id: Scalars['uuid'];
};


export type Subscription_RootUser_Phone_NumberArgs = {
  distinct_on?: InputMaybe<Array<User_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Phone_Number_Order_By>>;
  where?: InputMaybe<User_Phone_Number_Bool_Exp>;
};


export type Subscription_RootUser_Phone_Number_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Phone_Number_Order_By>>;
  where?: InputMaybe<User_Phone_Number_Bool_Exp>;
};


export type Subscription_RootUser_Phone_Number_By_PkArgs = {
  number: Scalars['String'];
  userId: Scalars['uuid'];
};


export type Subscription_RootValue_TypeArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootValue_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Value_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Value_Type_Order_By>>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};


export type Subscription_RootValue_Type_By_PkArgs = {
  type: Scalars['String'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "tracking_event" */
export type Tracking_Event = {
  __typename?: 'tracking_event';
  affiliate_code?: Maybe<Scalars['String']>;
  agent: Scalars['String'];
  created_at: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  domain: Scalars['String'];
  fingerprint?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  referrer?: Maybe<Scalars['String']>;
  source_ip: Scalars['String'];
  timestamp: Scalars['timestamptz'];
  tracking_id: Scalars['uuid'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "tracking_event" */
export type Tracking_EventDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "tracking_event" */
export type Tracking_Event_Aggregate = {
  __typename?: 'tracking_event_aggregate';
  aggregate?: Maybe<Tracking_Event_Aggregate_Fields>;
  nodes: Array<Tracking_Event>;
};

/** aggregate fields of "tracking_event" */
export type Tracking_Event_Aggregate_Fields = {
  __typename?: 'tracking_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tracking_Event_Max_Fields>;
  min?: Maybe<Tracking_Event_Min_Fields>;
};


/** aggregate fields of "tracking_event" */
export type Tracking_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tracking_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tracking_Event_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "tracking_event". All fields are combined with a logical 'AND'. */
export type Tracking_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Tracking_Event_Bool_Exp>>;
  _not?: InputMaybe<Tracking_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Tracking_Event_Bool_Exp>>;
  affiliate_code?: InputMaybe<String_Comparison_Exp>;
  agent?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  fingerprint?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  referrer?: InputMaybe<String_Comparison_Exp>;
  source_ip?: InputMaybe<String_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  tracking_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tracking_event" */
export enum Tracking_Event_Constraint {
  /** unique or primary key constraint */
  TrackingEventPkey = 'tracking_event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tracking_Event_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tracking_Event_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tracking_Event_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "tracking_event" */
export type Tracking_Event_Insert_Input = {
  affiliate_code?: InputMaybe<Scalars['String']>;
  agent?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  domain?: InputMaybe<Scalars['String']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  referrer?: InputMaybe<Scalars['String']>;
  source_ip?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  tracking_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tracking_Event_Max_Fields = {
  __typename?: 'tracking_event_max_fields';
  affiliate_code?: Maybe<Scalars['String']>;
  agent?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  referrer?: Maybe<Scalars['String']>;
  source_ip?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  tracking_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Tracking_Event_Min_Fields = {
  __typename?: 'tracking_event_min_fields';
  affiliate_code?: Maybe<Scalars['String']>;
  agent?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  referrer?: Maybe<Scalars['String']>;
  source_ip?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  tracking_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "tracking_event" */
export type Tracking_Event_Mutation_Response = {
  __typename?: 'tracking_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tracking_Event>;
};

/** on_conflict condition type for table "tracking_event" */
export type Tracking_Event_On_Conflict = {
  constraint: Tracking_Event_Constraint;
  update_columns?: Array<Tracking_Event_Update_Column>;
  where?: InputMaybe<Tracking_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "tracking_event". */
export type Tracking_Event_Order_By = {
  affiliate_code?: InputMaybe<Order_By>;
  agent?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  fingerprint?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  referrer?: InputMaybe<Order_By>;
  source_ip?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  tracking_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tracking_event */
export type Tracking_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tracking_Event_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "tracking_event" */
export enum Tracking_Event_Select_Column {
  /** column name */
  AffiliateCode = 'affiliate_code',
  /** column name */
  Agent = 'agent',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Domain = 'domain',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Id = 'id',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  SourceIp = 'source_ip',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TrackingId = 'tracking_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "tracking_event" */
export type Tracking_Event_Set_Input = {
  affiliate_code?: InputMaybe<Scalars['String']>;
  agent?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  domain?: InputMaybe<Scalars['String']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  referrer?: InputMaybe<Scalars['String']>;
  source_ip?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  tracking_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tracking_event" */
export enum Tracking_Event_Update_Column {
  /** column name */
  AffiliateCode = 'affiliate_code',
  /** column name */
  Agent = 'agent',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Domain = 'domain',
  /** column name */
  Fingerprint = 'fingerprint',
  /** column name */
  Id = 'id',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  SourceIp = 'source_ip',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TrackingId = 'tracking_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  /** An array relationship */
  attributes: Array<User_Attribute>;
  /** An aggregate relationship */
  attributes_aggregate: User_Attribute_Aggregate;
  /** An array relationship */
  complianceChecks: Array<User_Compliance_Check>;
  /** An aggregate relationship */
  complianceChecks_aggregate: User_Compliance_Check_Aggregate;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  emails: Array<User_Email>;
  /** An aggregate relationship */
  emails_aggregate: User_Email_Aggregate;
  /** An array relationship */
  formResponses: Array<Form_Response>;
  /** An aggregate relationship */
  formResponses_aggregate: Form_Response_Aggregate;
  handle?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  /** An array relationship */
  phoneNumbers: Array<User_Phone_Number>;
  /** An aggregate relationship */
  phoneNumbers_aggregate: User_Phone_Number_Aggregate;
  primaryEmail: Scalars['String'];
  referredBy?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userAddresses: Array<User_Address>;
  /** An aggregate relationship */
  userAddresses_aggregate: User_Address_Aggregate;
};


/** columns and relationships of "user" */
export type UserAttributesArgs = {
  distinct_on?: InputMaybe<Array<User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Attribute_Order_By>>;
  where?: InputMaybe<User_Attribute_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAttributes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Attribute_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Attribute_Order_By>>;
  where?: InputMaybe<User_Attribute_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserComplianceChecksArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserComplianceChecks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Compliance_Check_Order_By>>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEmailsArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Order_By>>;
  where?: InputMaybe<User_Email_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserEmails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Email_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Email_Order_By>>;
  where?: InputMaybe<User_Email_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserFormResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserFormResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Form_Response_Order_By>>;
  where?: InputMaybe<Form_Response_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPhoneNumbersArgs = {
  distinct_on?: InputMaybe<Array<User_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Phone_Number_Order_By>>;
  where?: InputMaybe<User_Phone_Number_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPhoneNumbers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Phone_Number_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Phone_Number_Order_By>>;
  where?: InputMaybe<User_Phone_Number_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUserAddressesArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUserAddresses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Address_Order_By>>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};

/** columns and relationships of "user_address" */
export type User_Address = {
  __typename?: 'user_address';
  /** An object relationship */
  address: Address;
  addressId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  isBilling: Scalars['Boolean'];
  isPrimary: Scalars['Boolean'];
  isShipping: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_address" */
export type User_Address_Aggregate = {
  __typename?: 'user_address_aggregate';
  aggregate?: Maybe<User_Address_Aggregate_Fields>;
  nodes: Array<User_Address>;
};

/** aggregate fields of "user_address" */
export type User_Address_Aggregate_Fields = {
  __typename?: 'user_address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Address_Max_Fields>;
  min?: Maybe<User_Address_Min_Fields>;
};


/** aggregate fields of "user_address" */
export type User_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_address" */
export type User_Address_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Address_Max_Order_By>;
  min?: InputMaybe<User_Address_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_address" */
export type User_Address_Arr_Rel_Insert_Input = {
  data: Array<User_Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Address_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_address". All fields are combined with a logical 'AND'. */
export type User_Address_Bool_Exp = {
  _and?: InputMaybe<Array<User_Address_Bool_Exp>>;
  _not?: InputMaybe<User_Address_Bool_Exp>;
  _or?: InputMaybe<Array<User_Address_Bool_Exp>>;
  address?: InputMaybe<Address_Bool_Exp>;
  addressId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isBilling?: InputMaybe<Boolean_Comparison_Exp>;
  isPrimary?: InputMaybe<Boolean_Comparison_Exp>;
  isShipping?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_address" */
export enum User_Address_Constraint {
  /** unique or primary key constraint */
  UserAddressPkey = 'user_address_pkey'
}

/** input type for inserting data into table "user_address" */
export type User_Address_Insert_Input = {
  address?: InputMaybe<Address_Obj_Rel_Insert_Input>;
  addressId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isBilling?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isShipping?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Address_Max_Fields = {
  __typename?: 'user_address_max_fields';
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_address" */
export type User_Address_Max_Order_By = {
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Address_Min_Fields = {
  __typename?: 'user_address_min_fields';
  addressId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_address" */
export type User_Address_Min_Order_By = {
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_address" */
export type User_Address_Mutation_Response = {
  __typename?: 'user_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Address>;
};

/** on_conflict condition type for table "user_address" */
export type User_Address_On_Conflict = {
  constraint: User_Address_Constraint;
  update_columns?: Array<User_Address_Update_Column>;
  where?: InputMaybe<User_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "user_address". */
export type User_Address_Order_By = {
  address?: InputMaybe<Address_Order_By>;
  addressId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  isBilling?: InputMaybe<Order_By>;
  isPrimary?: InputMaybe<Order_By>;
  isShipping?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_address */
export type User_Address_Pk_Columns_Input = {
  addressId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** select columns of table "user_address" */
export enum User_Address_Select_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsBilling = 'isBilling',
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  IsShipping = 'isShipping',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_address" */
export type User_Address_Set_Input = {
  addressId?: InputMaybe<Scalars['uuid']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isBilling?: InputMaybe<Scalars['Boolean']>;
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  isShipping?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_address" */
export enum User_Address_Update_Column {
  /** column name */
  AddressId = 'addressId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsBilling = 'isBilling',
  /** column name */
  IsPrimary = 'isPrimary',
  /** column name */
  IsShipping = 'isShipping',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "user_attribute" */
export type User_Attribute = {
  __typename?: 'user_attribute';
  createdAt: Scalars['timestamptz'];
  entityName: Entity_Enum;
  isPublic: Scalars['Boolean'];
  isSecureShareable: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['numeric'];
  /** An object relationship */
  type?: Maybe<Entity_Attribute_Type>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
  value: Scalars['String'];
};

/** aggregated selection of "user_attribute" */
export type User_Attribute_Aggregate = {
  __typename?: 'user_attribute_aggregate';
  aggregate?: Maybe<User_Attribute_Aggregate_Fields>;
  nodes: Array<User_Attribute>;
};

/** aggregate fields of "user_attribute" */
export type User_Attribute_Aggregate_Fields = {
  __typename?: 'user_attribute_aggregate_fields';
  avg?: Maybe<User_Attribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Attribute_Max_Fields>;
  min?: Maybe<User_Attribute_Min_Fields>;
  stddev?: Maybe<User_Attribute_Stddev_Fields>;
  stddev_pop?: Maybe<User_Attribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Attribute_Stddev_Samp_Fields>;
  sum?: Maybe<User_Attribute_Sum_Fields>;
  var_pop?: Maybe<User_Attribute_Var_Pop_Fields>;
  var_samp?: Maybe<User_Attribute_Var_Samp_Fields>;
  variance?: Maybe<User_Attribute_Variance_Fields>;
};


/** aggregate fields of "user_attribute" */
export type User_Attribute_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Attribute_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_attribute" */
export type User_Attribute_Aggregate_Order_By = {
  avg?: InputMaybe<User_Attribute_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Attribute_Max_Order_By>;
  min?: InputMaybe<User_Attribute_Min_Order_By>;
  stddev?: InputMaybe<User_Attribute_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Attribute_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Attribute_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Attribute_Sum_Order_By>;
  var_pop?: InputMaybe<User_Attribute_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Attribute_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Attribute_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_attribute" */
export type User_Attribute_Arr_Rel_Insert_Input = {
  data: Array<User_Attribute_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Attribute_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Attribute_Avg_Fields = {
  __typename?: 'user_attribute_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_attribute" */
export type User_Attribute_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_attribute". All fields are combined with a logical 'AND'. */
export type User_Attribute_Bool_Exp = {
  _and?: InputMaybe<Array<User_Attribute_Bool_Exp>>;
  _not?: InputMaybe<User_Attribute_Bool_Exp>;
  _or?: InputMaybe<Array<User_Attribute_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  entityName?: InputMaybe<Entity_Enum_Comparison_Exp>;
  isPublic?: InputMaybe<Boolean_Comparison_Exp>;
  isSecureShareable?: InputMaybe<Boolean_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<Entity_Attribute_Type_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_attribute" */
export enum User_Attribute_Constraint {
  /** unique or primary key constraint */
  UserAttributePkey = 'user_attribute_pkey'
}

/** input type for incrementing numeric columns in table "user_attribute" */
export type User_Attribute_Inc_Input = {
  order?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "user_attribute" */
export type User_Attribute_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityName?: InputMaybe<Entity_Enum>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isSecureShareable?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['numeric']>;
  type?: InputMaybe<Entity_Attribute_Type_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Attribute_Max_Fields = {
  __typename?: 'user_attribute_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_attribute" */
export type User_Attribute_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Attribute_Min_Fields = {
  __typename?: 'user_attribute_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['numeric']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_attribute" */
export type User_Attribute_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_attribute" */
export type User_Attribute_Mutation_Response = {
  __typename?: 'user_attribute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Attribute>;
};

/** on_conflict condition type for table "user_attribute" */
export type User_Attribute_On_Conflict = {
  constraint: User_Attribute_Constraint;
  update_columns?: Array<User_Attribute_Update_Column>;
  where?: InputMaybe<User_Attribute_Bool_Exp>;
};

/** Ordering options when selecting data from "user_attribute". */
export type User_Attribute_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  entityName?: InputMaybe<Order_By>;
  isPublic?: InputMaybe<Order_By>;
  isSecureShareable?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  type?: InputMaybe<Entity_Attribute_Type_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_attribute */
export type User_Attribute_Pk_Columns_Input = {
  key: Scalars['String'];
  name: Scalars['String'];
  userId: Scalars['uuid'];
};

/** select columns of table "user_attribute" */
export enum User_Attribute_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  IsSecureShareable = 'isSecureShareable',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_attribute" */
export type User_Attribute_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  entityName?: InputMaybe<Entity_Enum>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  isSecureShareable?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['numeric']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Attribute_Stddev_Fields = {
  __typename?: 'user_attribute_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_attribute" */
export type User_Attribute_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Attribute_Stddev_Pop_Fields = {
  __typename?: 'user_attribute_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_attribute" */
export type User_Attribute_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Attribute_Stddev_Samp_Fields = {
  __typename?: 'user_attribute_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_attribute" */
export type User_Attribute_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Attribute_Sum_Fields = {
  __typename?: 'user_attribute_sum_fields';
  order?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "user_attribute" */
export type User_Attribute_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "user_attribute" */
export enum User_Attribute_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EntityName = 'entityName',
  /** column name */
  IsPublic = 'isPublic',
  /** column name */
  IsSecureShareable = 'isSecureShareable',
  /** column name */
  Key = 'key',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type User_Attribute_Var_Pop_Fields = {
  __typename?: 'user_attribute_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_attribute" */
export type User_Attribute_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Attribute_Var_Samp_Fields = {
  __typename?: 'user_attribute_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_attribute" */
export type User_Attribute_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Attribute_Variance_Fields = {
  __typename?: 'user_attribute_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_attribute" */
export type User_Attribute_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_auth" */
export type User_Auth = {
  __typename?: 'user_auth';
  createdAt: Scalars['timestamptz'];
  hash: Scalars['String'];
  salt: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_auth" */
export type User_Auth_Aggregate = {
  __typename?: 'user_auth_aggregate';
  aggregate?: Maybe<User_Auth_Aggregate_Fields>;
  nodes: Array<User_Auth>;
};

/** aggregate fields of "user_auth" */
export type User_Auth_Aggregate_Fields = {
  __typename?: 'user_auth_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Auth_Max_Fields>;
  min?: Maybe<User_Auth_Min_Fields>;
};


/** aggregate fields of "user_auth" */
export type User_Auth_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Auth_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_auth". All fields are combined with a logical 'AND'. */
export type User_Auth_Bool_Exp = {
  _and?: InputMaybe<Array<User_Auth_Bool_Exp>>;
  _not?: InputMaybe<User_Auth_Bool_Exp>;
  _or?: InputMaybe<Array<User_Auth_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_auth" */
export enum User_Auth_Constraint {
  /** unique or primary key constraint */
  UserAuthPkey = 'user_auth_pkey'
}

/** input type for inserting data into table "user_auth" */
export type User_Auth_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hash?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Auth_Max_Fields = {
  __typename?: 'user_auth_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Auth_Min_Fields = {
  __typename?: 'user_auth_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_auth" */
export type User_Auth_Mutation_Response = {
  __typename?: 'user_auth_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Auth>;
};

/** on_conflict condition type for table "user_auth" */
export type User_Auth_On_Conflict = {
  constraint: User_Auth_Constraint;
  update_columns?: Array<User_Auth_Update_Column>;
  where?: InputMaybe<User_Auth_Bool_Exp>;
};

/** Ordering options when selecting data from "user_auth". */
export type User_Auth_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_auth */
export type User_Auth_Pk_Columns_Input = {
  userId: Scalars['uuid'];
};

/** select columns of table "user_auth" */
export enum User_Auth_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Hash = 'hash',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_auth" */
export type User_Auth_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  hash?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_auth" */
export enum User_Auth_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Hash = 'hash',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  attributes?: InputMaybe<User_Attribute_Bool_Exp>;
  complianceChecks?: InputMaybe<User_Compliance_Check_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  emails?: InputMaybe<User_Email_Bool_Exp>;
  formResponses?: InputMaybe<Form_Response_Bool_Exp>;
  handle?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  phoneNumbers?: InputMaybe<User_Phone_Number_Bool_Exp>;
  primaryEmail?: InputMaybe<String_Comparison_Exp>;
  referredBy?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userAddresses?: InputMaybe<User_Address_Bool_Exp>;
};

/** columns and relationships of "user_compliance_check" */
export type User_Compliance_Check = {
  __typename?: 'user_compliance_check';
  createdAt: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  id: Scalars['uuid'];
  isClean: Scalars['Boolean'];
  status: User_Compliance_Check_Status_Enum;
  /** An object relationship */
  type: Compliance_Check_Type;
  typeName: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  userId: Scalars['uuid'];
  vendorCheckId: Scalars['String'];
};


/** columns and relationships of "user_compliance_check" */
export type User_Compliance_CheckDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_compliance_check" */
export type User_Compliance_Check_Aggregate = {
  __typename?: 'user_compliance_check_aggregate';
  aggregate?: Maybe<User_Compliance_Check_Aggregate_Fields>;
  nodes: Array<User_Compliance_Check>;
};

/** aggregate fields of "user_compliance_check" */
export type User_Compliance_Check_Aggregate_Fields = {
  __typename?: 'user_compliance_check_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Compliance_Check_Max_Fields>;
  min?: Maybe<User_Compliance_Check_Min_Fields>;
};


/** aggregate fields of "user_compliance_check" */
export type User_Compliance_Check_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Compliance_Check_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_compliance_check" */
export type User_Compliance_Check_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Compliance_Check_Max_Order_By>;
  min?: InputMaybe<User_Compliance_Check_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Compliance_Check_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_compliance_check" */
export type User_Compliance_Check_Arr_Rel_Insert_Input = {
  data: Array<User_Compliance_Check_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Compliance_Check_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_compliance_check". All fields are combined with a logical 'AND'. */
export type User_Compliance_Check_Bool_Exp = {
  _and?: InputMaybe<Array<User_Compliance_Check_Bool_Exp>>;
  _not?: InputMaybe<User_Compliance_Check_Bool_Exp>;
  _or?: InputMaybe<Array<User_Compliance_Check_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isClean?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<User_Compliance_Check_Status_Enum_Comparison_Exp>;
  type?: InputMaybe<Compliance_Check_Type_Bool_Exp>;
  typeName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
  vendorCheckId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_compliance_check" */
export enum User_Compliance_Check_Constraint {
  /** unique or primary key constraint */
  UserComplianceCheckPkey1 = 'user_compliance_check_pkey1'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Compliance_Check_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Compliance_Check_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Compliance_Check_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_compliance_check" */
export type User_Compliance_Check_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  isClean?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<User_Compliance_Check_Status_Enum>;
  type?: InputMaybe<Compliance_Check_Type_Obj_Rel_Insert_Input>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  vendorCheckId?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Compliance_Check_Max_Fields = {
  __typename?: 'user_compliance_check_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  vendorCheckId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_compliance_check" */
export type User_Compliance_Check_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vendorCheckId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Compliance_Check_Min_Fields = {
  __typename?: 'user_compliance_check_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  typeName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
  vendorCheckId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_compliance_check" */
export type User_Compliance_Check_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vendorCheckId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_compliance_check" */
export type User_Compliance_Check_Mutation_Response = {
  __typename?: 'user_compliance_check_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Compliance_Check>;
};

/** on_conflict condition type for table "user_compliance_check" */
export type User_Compliance_Check_On_Conflict = {
  constraint: User_Compliance_Check_Constraint;
  update_columns?: Array<User_Compliance_Check_Update_Column>;
  where?: InputMaybe<User_Compliance_Check_Bool_Exp>;
};

/** Ordering options when selecting data from "user_compliance_check". */
export type User_Compliance_Check_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isClean?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Compliance_Check_Type_Order_By>;
  typeName?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  vendorCheckId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_compliance_check */
export type User_Compliance_Check_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Compliance_Check_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_compliance_check" */
export enum User_Compliance_Check_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsClean = 'isClean',
  /** column name */
  Status = 'status',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VendorCheckId = 'vendorCheckId'
}

/** input type for updating data in table "user_compliance_check" */
export type User_Compliance_Check_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  isClean?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<User_Compliance_Check_Status_Enum>;
  typeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
  vendorCheckId?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "user_compliance_check_status" */
export type User_Compliance_Check_Status = {
  __typename?: 'user_compliance_check_status';
  name: Scalars['String'];
};

/** aggregated selection of "user_compliance_check_status" */
export type User_Compliance_Check_Status_Aggregate = {
  __typename?: 'user_compliance_check_status_aggregate';
  aggregate?: Maybe<User_Compliance_Check_Status_Aggregate_Fields>;
  nodes: Array<User_Compliance_Check_Status>;
};

/** aggregate fields of "user_compliance_check_status" */
export type User_Compliance_Check_Status_Aggregate_Fields = {
  __typename?: 'user_compliance_check_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Compliance_Check_Status_Max_Fields>;
  min?: Maybe<User_Compliance_Check_Status_Min_Fields>;
};


/** aggregate fields of "user_compliance_check_status" */
export type User_Compliance_Check_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Compliance_Check_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_compliance_check_status". All fields are combined with a logical 'AND'. */
export type User_Compliance_Check_Status_Bool_Exp = {
  _and?: InputMaybe<Array<User_Compliance_Check_Status_Bool_Exp>>;
  _not?: InputMaybe<User_Compliance_Check_Status_Bool_Exp>;
  _or?: InputMaybe<Array<User_Compliance_Check_Status_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_compliance_check_status" */
export enum User_Compliance_Check_Status_Constraint {
  /** unique or primary key constraint */
  UserComplianceCheckStatusPkey = 'user_compliance_check_status_pkey'
}

export enum User_Compliance_Check_Status_Enum {
  Complete = 'complete',
  Pending = 'pending',
  Processing = 'processing'
}

/** Boolean expression to compare columns of type "user_compliance_check_status_enum". All fields are combined with logical 'AND'. */
export type User_Compliance_Check_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Compliance_Check_Status_Enum>;
  _in?: InputMaybe<Array<User_Compliance_Check_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Compliance_Check_Status_Enum>;
  _nin?: InputMaybe<Array<User_Compliance_Check_Status_Enum>>;
};

/** input type for inserting data into table "user_compliance_check_status" */
export type User_Compliance_Check_Status_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Compliance_Check_Status_Max_Fields = {
  __typename?: 'user_compliance_check_status_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Compliance_Check_Status_Min_Fields = {
  __typename?: 'user_compliance_check_status_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_compliance_check_status" */
export type User_Compliance_Check_Status_Mutation_Response = {
  __typename?: 'user_compliance_check_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Compliance_Check_Status>;
};

/** on_conflict condition type for table "user_compliance_check_status" */
export type User_Compliance_Check_Status_On_Conflict = {
  constraint: User_Compliance_Check_Status_Constraint;
  update_columns?: Array<User_Compliance_Check_Status_Update_Column>;
  where?: InputMaybe<User_Compliance_Check_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "user_compliance_check_status". */
export type User_Compliance_Check_Status_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_compliance_check_status */
export type User_Compliance_Check_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "user_compliance_check_status" */
export enum User_Compliance_Check_Status_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "user_compliance_check_status" */
export type User_Compliance_Check_Status_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_compliance_check_status" */
export enum User_Compliance_Check_Status_Update_Column {
  /** column name */
  Name = 'name'
}

/** update columns of table "user_compliance_check" */
export enum User_Compliance_Check_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsClean = 'isClean',
  /** column name */
  Status = 'status',
  /** column name */
  TypeName = 'typeName',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  VendorCheckId = 'vendorCheckId'
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserHandleKey = 'user_handle_key',
  /** unique or primary key constraint */
  UserPrimaryEmailKey = 'user_primary_email_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** columns and relationships of "user_email" */
export type User_Email = {
  __typename?: 'user_email';
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  isVerified: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_email" */
export type User_Email_Aggregate = {
  __typename?: 'user_email_aggregate';
  aggregate?: Maybe<User_Email_Aggregate_Fields>;
  nodes: Array<User_Email>;
};

/** aggregate fields of "user_email" */
export type User_Email_Aggregate_Fields = {
  __typename?: 'user_email_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Email_Max_Fields>;
  min?: Maybe<User_Email_Min_Fields>;
};


/** aggregate fields of "user_email" */
export type User_Email_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Email_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_email" */
export type User_Email_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Email_Max_Order_By>;
  min?: InputMaybe<User_Email_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_email" */
export type User_Email_Arr_Rel_Insert_Input = {
  data: Array<User_Email_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Email_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_email". All fields are combined with a logical 'AND'. */
export type User_Email_Bool_Exp = {
  _and?: InputMaybe<Array<User_Email_Bool_Exp>>;
  _not?: InputMaybe<User_Email_Bool_Exp>;
  _or?: InputMaybe<Array<User_Email_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  isVerified?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_email" */
export enum User_Email_Constraint {
  /** unique or primary key constraint */
  UserEmailEmailKey = 'user_email_email_key',
  /** unique or primary key constraint */
  UserEmailPkey = 'user_email_pkey'
}

/** input type for inserting data into table "user_email" */
export type User_Email_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Email_Max_Fields = {
  __typename?: 'user_email_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_email" */
export type User_Email_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Email_Min_Fields = {
  __typename?: 'user_email_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_email" */
export type User_Email_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_email" */
export type User_Email_Mutation_Response = {
  __typename?: 'user_email_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Email>;
};

/** on_conflict condition type for table "user_email" */
export type User_Email_On_Conflict = {
  constraint: User_Email_Constraint;
  update_columns?: Array<User_Email_Update_Column>;
  where?: InputMaybe<User_Email_Bool_Exp>;
};

/** Ordering options when selecting data from "user_email". */
export type User_Email_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  isVerified?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_email */
export type User_Email_Pk_Columns_Input = {
  email: Scalars['String'];
  userId: Scalars['uuid'];
};

/** select columns of table "user_email" */
export enum User_Email_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  IsVerified = 'isVerified',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_email" */
export type User_Email_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_email" */
export enum User_Email_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  IsVerified = 'isVerified',
  /** column name */
  Label = 'label',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  attributes?: InputMaybe<User_Attribute_Arr_Rel_Insert_Input>;
  complianceChecks?: InputMaybe<User_Compliance_Check_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  emails?: InputMaybe<User_Email_Arr_Rel_Insert_Input>;
  formResponses?: InputMaybe<Form_Response_Arr_Rel_Insert_Input>;
  handle?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  phoneNumbers?: InputMaybe<User_Phone_Number_Arr_Rel_Insert_Input>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  referredBy?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userAddresses?: InputMaybe<User_Address_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  primaryEmail?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  handle?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  primaryEmail?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** columns and relationships of "user_national_id" */
export type User_National_Id = {
  __typename?: 'user_national_id';
  created_at: Scalars['timestamptz'];
  file_id: Scalars['uuid'];
  type_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** aggregated selection of "user_national_id" */
export type User_National_Id_Aggregate = {
  __typename?: 'user_national_id_aggregate';
  aggregate?: Maybe<User_National_Id_Aggregate_Fields>;
  nodes: Array<User_National_Id>;
};

/** aggregate fields of "user_national_id" */
export type User_National_Id_Aggregate_Fields = {
  __typename?: 'user_national_id_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_National_Id_Max_Fields>;
  min?: Maybe<User_National_Id_Min_Fields>;
};


/** aggregate fields of "user_national_id" */
export type User_National_Id_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_National_Id_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_national_id". All fields are combined with a logical 'AND'. */
export type User_National_Id_Bool_Exp = {
  _and?: InputMaybe<Array<User_National_Id_Bool_Exp>>;
  _not?: InputMaybe<User_National_Id_Bool_Exp>;
  _or?: InputMaybe<Array<User_National_Id_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  type_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_national_id" */
export enum User_National_Id_Constraint {
  /** unique or primary key constraint */
  UserNationalIdPkey = 'user_national_id_pkey'
}

/** input type for inserting data into table "user_national_id" */
export type User_National_Id_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  type_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_National_Id_Max_Fields = {
  __typename?: 'user_national_id_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_National_Id_Min_Fields = {
  __typename?: 'user_national_id_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_national_id" */
export type User_National_Id_Mutation_Response = {
  __typename?: 'user_national_id_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_National_Id>;
};

/** on_conflict condition type for table "user_national_id" */
export type User_National_Id_On_Conflict = {
  constraint: User_National_Id_Constraint;
  update_columns?: Array<User_National_Id_Update_Column>;
  where?: InputMaybe<User_National_Id_Bool_Exp>;
};

/** Ordering options when selecting data from "user_national_id". */
export type User_National_Id_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  type_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_national_id */
export type User_National_Id_Pk_Columns_Input = {
  type_name: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** select columns of table "user_national_id" */
export enum User_National_Id_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  TypeName = 'type_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "user_national_id" */
export type User_National_Id_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  type_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_national_id" */
export enum User_National_Id_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  TypeName = 'type_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  attributes_aggregate?: InputMaybe<User_Attribute_Aggregate_Order_By>;
  complianceChecks_aggregate?: InputMaybe<User_Compliance_Check_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  emails_aggregate?: InputMaybe<User_Email_Aggregate_Order_By>;
  formResponses_aggregate?: InputMaybe<Form_Response_Aggregate_Order_By>;
  handle?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phoneNumbers_aggregate?: InputMaybe<User_Phone_Number_Aggregate_Order_By>;
  primaryEmail?: InputMaybe<Order_By>;
  referredBy?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userAddresses_aggregate?: InputMaybe<User_Address_Aggregate_Order_By>;
};

/** columns and relationships of "user_phone_number" */
export type User_Phone_Number = {
  __typename?: 'user_phone_number';
  countryCode: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  isVerified: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** aggregated selection of "user_phone_number" */
export type User_Phone_Number_Aggregate = {
  __typename?: 'user_phone_number_aggregate';
  aggregate?: Maybe<User_Phone_Number_Aggregate_Fields>;
  nodes: Array<User_Phone_Number>;
};

/** aggregate fields of "user_phone_number" */
export type User_Phone_Number_Aggregate_Fields = {
  __typename?: 'user_phone_number_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Phone_Number_Max_Fields>;
  min?: Maybe<User_Phone_Number_Min_Fields>;
};


/** aggregate fields of "user_phone_number" */
export type User_Phone_Number_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Phone_Number_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_phone_number" */
export type User_Phone_Number_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Phone_Number_Max_Order_By>;
  min?: InputMaybe<User_Phone_Number_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_phone_number" */
export type User_Phone_Number_Arr_Rel_Insert_Input = {
  data: Array<User_Phone_Number_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Phone_Number_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_phone_number". All fields are combined with a logical 'AND'. */
export type User_Phone_Number_Bool_Exp = {
  _and?: InputMaybe<Array<User_Phone_Number_Bool_Exp>>;
  _not?: InputMaybe<User_Phone_Number_Bool_Exp>;
  _or?: InputMaybe<Array<User_Phone_Number_Bool_Exp>>;
  countryCode?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  isVerified?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  number?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_phone_number" */
export enum User_Phone_Number_Constraint {
  /** unique or primary key constraint */
  UserPhoneNumberPkey = 'user_phone_number_pkey'
}

/** input type for inserting data into table "user_phone_number" */
export type User_Phone_Number_Insert_Input = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Phone_Number_Max_Fields = {
  __typename?: 'user_phone_number_max_fields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_phone_number" */
export type User_Phone_Number_Max_Order_By = {
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Phone_Number_Min_Fields = {
  __typename?: 'user_phone_number_min_fields';
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  label?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_phone_number" */
export type User_Phone_Number_Min_Order_By = {
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_phone_number" */
export type User_Phone_Number_Mutation_Response = {
  __typename?: 'user_phone_number_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Phone_Number>;
};

/** on_conflict condition type for table "user_phone_number" */
export type User_Phone_Number_On_Conflict = {
  constraint: User_Phone_Number_Constraint;
  update_columns?: Array<User_Phone_Number_Update_Column>;
  where?: InputMaybe<User_Phone_Number_Bool_Exp>;
};

/** Ordering options when selecting data from "user_phone_number". */
export type User_Phone_Number_Order_By = {
  countryCode?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  isVerified?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  number?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_phone_number */
export type User_Phone_Number_Pk_Columns_Input = {
  number: Scalars['String'];
  userId: Scalars['uuid'];
};

/** select columns of table "user_phone_number" */
export enum User_Phone_Number_Select_Column {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsVerified = 'isVerified',
  /** column name */
  Label = 'label',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_phone_number" */
export type User_Phone_Number_Set_Input = {
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_phone_number" */
export enum User_Phone_Number_Update_Column {
  /** column name */
  CountryCode = 'countryCode',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  IsVerified = 'isVerified',
  /** column name */
  Label = 'label',
  /** column name */
  Number = 'number',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  PrimaryEmail = 'primaryEmail',
  /** column name */
  ReferredBy = 'referredBy',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  handle?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  referredBy?: InputMaybe<Scalars['uuid']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Handle = 'handle',
  /** column name */
  Id = 'id',
  /** column name */
  PrimaryEmail = 'primaryEmail',
  /** column name */
  ReferredBy = 'referredBy',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "value_type" */
export type Value_Type = {
  __typename?: 'value_type';
  type: Scalars['String'];
};

/** aggregated selection of "value_type" */
export type Value_Type_Aggregate = {
  __typename?: 'value_type_aggregate';
  aggregate?: Maybe<Value_Type_Aggregate_Fields>;
  nodes: Array<Value_Type>;
};

/** aggregate fields of "value_type" */
export type Value_Type_Aggregate_Fields = {
  __typename?: 'value_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Value_Type_Max_Fields>;
  min?: Maybe<Value_Type_Min_Fields>;
};


/** aggregate fields of "value_type" */
export type Value_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Value_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "value_type". All fields are combined with a logical 'AND'. */
export type Value_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Value_Type_Bool_Exp>>;
  _not?: InputMaybe<Value_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Value_Type_Bool_Exp>>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "value_type" */
export enum Value_Type_Constraint {
  /** unique or primary key constraint */
  ValueTypesPkey = 'value_types_pkey'
}

export enum Value_Type_Enum {
  Boolean = 'Boolean',
  Date = 'Date',
  File = 'File',
  Image = 'Image',
  Json = 'JSON',
  Link = 'Link',
  Number = 'Number',
  RichText = 'RichText',
  Select = 'Select',
  Text = 'Text',
  Toggle = 'Toggle',
  Yaml = 'YAML'
}

/** Boolean expression to compare columns of type "value_type_enum". All fields are combined with logical 'AND'. */
export type Value_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Value_Type_Enum>;
  _in?: InputMaybe<Array<Value_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Value_Type_Enum>;
  _nin?: InputMaybe<Array<Value_Type_Enum>>;
};

/** input type for inserting data into table "value_type" */
export type Value_Type_Insert_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Value_Type_Max_Fields = {
  __typename?: 'value_type_max_fields';
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Value_Type_Min_Fields = {
  __typename?: 'value_type_min_fields';
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "value_type" */
export type Value_Type_Mutation_Response = {
  __typename?: 'value_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Value_Type>;
};

/** input type for inserting object relation for remote table "value_type" */
export type Value_Type_Obj_Rel_Insert_Input = {
  data: Value_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Value_Type_On_Conflict>;
};

/** on_conflict condition type for table "value_type" */
export type Value_Type_On_Conflict = {
  constraint: Value_Type_Constraint;
  update_columns?: Array<Value_Type_Update_Column>;
  where?: InputMaybe<Value_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "value_type". */
export type Value_Type_Order_By = {
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: value_type */
export type Value_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "value_type" */
export enum Value_Type_Select_Column {
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "value_type" */
export type Value_Type_Set_Input = {
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "value_type" */
export enum Value_Type_Update_Column {
  /** column name */
  Type = 'type'
}

export type SubmitFormMutationVariables = Exact<{
  formId: Scalars['uuid'];
  itemResponses: Array<Form_Item_Response_Insert_Input> | Form_Item_Response_Insert_Input;
}>;


export type SubmitFormMutation = { __typename?: 'mutation_root', submitForm?: { __typename?: 'form_response_mutation_response', affected_rows: number } | null };

export type UnitPurchaseRequestMutationVariables = Exact<{
  formData: Scalars['jsonb'];
}>;


export type UnitPurchaseRequestMutation = { __typename?: 'mutation_root', unitPurchaseRequest?: { __typename?: 'UnitPurchaseRequestOutput', error?: string | null } | null };

export type CreateExpiringContactCardCodeMutationVariables = Exact<{
  input?: InputMaybe<ExpiringCodeInput>;
}>;


export type CreateExpiringContactCardCodeMutation = { __typename?: 'mutation_root', createExpiringContactCardCode?: { __typename?: 'ExpiringCodeOutput', expiringCode: string } | null };

export type ContactCardFieldsFragment = { __typename?: 'ContactCardOutput', handle: string, codeStatus: ContactCardCodeStatus, attributes: Array<{ __typename?: 'ContactCardAttribute', name: string, value: string, order: any, type: { __typename?: 'ContactCardAttributeType', name: string, label?: string | null, description?: string | null, props?: any | null, validation?: any | null, order: any, valueType: string, group: { __typename?: 'ContactCardAttributeGroup', name: string, label?: string | null, description?: string | null, meta?: any | null, order: any } } }> };

export type ContactCardQueryVariables = Exact<{
  handle: Scalars['String'];
  expiringCode?: InputMaybe<Scalars['String']>;
}>;


export type ContactCardQuery = { __typename?: 'query_root', contactCard?: { __typename?: 'ContactCardOutput', handle: string, codeStatus: ContactCardCodeStatus, attributes: Array<{ __typename?: 'ContactCardAttribute', name: string, value: string, order: any, type: { __typename?: 'ContactCardAttributeType', name: string, label?: string | null, description?: string | null, props?: any | null, validation?: any | null, order: any, valueType: string, group: { __typename?: 'ContactCardAttributeGroup', name: string, label?: string | null, description?: string | null, meta?: any | null, order: any } } }> } | null };

export const ContactCardFieldsFragmentDoc = gql`
    fragment contactCardFields on ContactCardOutput {
  handle
  codeStatus
  attributes {
    name
    value
    order
    type {
      name
      label
      description
      props
      validation
      order
      valueType
      group {
        name
        label
        description
        meta
        order
      }
    }
  }
}
    `;
export const SubmitFormDocument = gql`
    mutation submitForm($formId: uuid!, $itemResponses: [form_item_response_insert_input!]!) {
  submitForm: insert_form_response(
    objects: {formId: $formId, formItemResponses: {data: $itemResponses}}
  ) {
    affected_rows
  }
}
    `;
export const UnitPurchaseRequestDocument = gql`
    mutation unitPurchaseRequest($formData: jsonb!) {
  unitPurchaseRequest(input: {formData: $formData}) {
    error
  }
}
    `;
export const CreateExpiringContactCardCodeDocument = gql`
    mutation createExpiringContactCardCode($input: ExpiringCodeInput) {
  createExpiringContactCardCode(input: $input) {
    expiringCode
  }
}
    `;
export const ContactCardDocument = gql`
    query contactCard($handle: String!, $expiringCode: String) {
  contactCard(input: {handle: $handle, expiringCode: $expiringCode}) {
    ...contactCardFields
  }
}
    ${ContactCardFieldsFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    submitForm(variables: SubmitFormMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SubmitFormMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SubmitFormMutation>(SubmitFormDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'submitForm', 'mutation');
    },
    unitPurchaseRequest(variables: UnitPurchaseRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UnitPurchaseRequestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UnitPurchaseRequestMutation>(UnitPurchaseRequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'unitPurchaseRequest', 'mutation');
    },
    createExpiringContactCardCode(variables?: CreateExpiringContactCardCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateExpiringContactCardCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateExpiringContactCardCodeMutation>(CreateExpiringContactCardCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createExpiringContactCardCode', 'mutation');
    },
    contactCard(variables: ContactCardQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactCardQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContactCardQuery>(ContactCardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'contactCard', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;