import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  position: relative;
  padding: 0px 0 80px;

  &:after {
    content: '';
    width: 140%;
    background: rgba(255, 255, 255, 0.01);
    height: 100%;
    transform: rotate(25deg);
    position: absolute;
    top: -60%;
    left: -17%;
    z-index: -1;
  }

  .heading2 {
    h3 {
      letter-spacing: 7px;
    }

    h2 {
      margin-top: 10px;
      font-size: 35px;
    }

    span {
      font-size: 27px;
      margin-top: 25px;
      display: block;
    }
  }

  .heading-items {
    span {
      font-size: 27px;
      margin-top: 25px;
      display: block;
    }
  }

  .p-image {
    display: block;
    position: relative;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .states {
    h3 {
      font-size: 50px;
      font-weight: 400;
      margin-right: 20px;
    }

    p {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .mail-us {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    a {
      -webkit-text-decoration: none;
      text-decoration: none;
      color: var(--text-color);
    }

    .text {
      text-align: right;
      padding-right: 20px;

      p {
        font-size: 12px;
        text-transform: uppercase;
      }

      h6 {
        font-size: 14px;
        margin-top: 5px;
      }
    }

    .icon-box {
      width: 60px;
      height: 60px;
      line-height: 65px;
      text-align: center;
      border-radius: 5px;
      background: -webkit-linear-gradient(323.53deg, #080718 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
      background: -o-linear-gradient(323.53deg, #080718 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
      background: linear-gradient(126.47deg, #080718 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
      font-size: 30px;
    }
  }

  .slick-slider {
    .arrow-next,
    .arrow-prev {
      height: 60px !important;
      width: 60px !important;
      background: #fff;
      z-index: 1;
      border-radius: 0 !important;
      position: absolute;
      top: calc(50% - 30px);
      transition: all 0.4s;
    }

    .arrow-next {
      right: -100px;
      opacity: 0;
    }

    .arrow-prev {
      left: -100px;
      opacity: 0;
    }

    &:hover {
      .arrow-next {
        right: -30px;
        opacity: 1;
      }

      .arrow-prev {
        left: -30px;
        opacity: 1;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

export const Aside = styled.aside`
  position: relative;
  border: 1px solid #ffffff05;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 5px;
  cursor: pointer;

  &:hover {
    .p-image {
      filter: blur(10px);
    }

    .overlay {
      display: flex;
    }
  }

  .p-image {
    display: block;
    padding-top: 90%;

    img {
      width: 100%;
      position: absolute;
      top: -40px;
    }
  }

  .overlay {
    display: none;
    flex-direction: column;
    justify-content: end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #0000007a;

    h6 {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 17px;
      font-weight: 500;
    }
  }
`;
