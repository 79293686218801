import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  position: relative;
  padding: 120px 0 80px;
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    width: 140%;
    margin-left: -20%;

    .slick-center {
      .item {
        transform: scale(1);
      }
    }

    .custom-slider {
      .slick-arrow {
        position: absolute;
        top: 50%;
        width: 50px;
        height: 50px;
        line-height: 55px;
        text-align: center;
        font-size: 30px;
        border: 1px solid #eee;
        border-radius: 50%;
        opacity: 1;
        transition: all 0.4s;
        color: #fff;

        &.slick-prev {
          left: 10%;
          z-index: 1;
        }

        &.slick-next {
          right: 10%;
        }

        &:hover {
          color: var(--primary-color);
          border-color: var(--primary-color);
        }

        &:before {
          display: none;
        }
      }

      &:hover {
        .slick-prev {
          left: 30%;
        }

        .slick-next {
          right: 30%;
        }
      }
    }
  }

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

export const Aside = styled.aside`
  text-align: left;
  padding: 80px 40px;
  transform: scale(0.8);
  background: linear-gradient(126.47deg, var(--surface-section) 6.69%, rgba(2, 2, 30, 0) 85.43%);
  transition: all 0.4s;
  display: flex;
  align-items: center;
  width: 100%;

  p {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    padding-left: 30px;
    margin-left: 30px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  .author {
    min-width: 120px;

    .p-image {
      img {
        width: 80px;
        height: 80px;
        border: 7px solid var(--surface-section);
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 20px;
      }
    }

    h6 {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 15px;
      // background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
      background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h5 {
      font-size: 13px;
      font-weight: 400;
    }
  }
`;
