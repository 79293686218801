import PreLoading from 'components/pre-loading';
import Banner from 'components/banner';
import Intro from 'components/intro';
import introData from './data/introData.json';
import VideoWrapper from 'components/video-wrapper';
import bannerData from './data/bannerData.json';
import Foundation from './foundation';
import Content from './content';
import contentData from './data/contentData.json';
import Vision from './vision';
import Cloud from './cloud';
import Technology from './technology';
import Towels from './towels';
import WhitepaperDownload from 'components/whitepaper-download';

const BlockchainPage = () => {
  return (
    <>
      <PreLoading label="BLOCKCHAIN" />
      <Banner {...bannerData} />
      <Foundation />
      <Vision />
      <Cloud light />
      <Technology />
      <Towels light />

      {contentData.map((item, i) => (
        <Content key={i} light={i % 2 === 0 ? false : true} {...item} classNames={!i ? 'text-center' : ''} />
      ))}

      <Intro {...introData} />
      <WhitepaperDownload />
      <VideoWrapper />
    </>
  );
};
export default BlockchainPage;
