import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  z-index: 1;
  position: relative;
  padding-top: 56%;
  width: 100%;

  .brightcove-react-player-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video-js {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 576px) {
  }
`;
