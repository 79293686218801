import { ParallaxBanner, Section } from './index.styled';

const JoinToday = ({ bannerImage, link, text }) => {
  return (
    <Section>
      <ParallaxBanner
        layers={[
          {
            image: bannerImage,
            speed: 0,
          },
        ]}
      >
        <div className="caption copy-cap">
          <h1>{text}</h1>
        </div>
        <div
          className="caption"
          onClick={() => {
            link && window.open(link, '_blank');
          }}
        >
          <h1 className="stroke">{text}</h1>
        </div>
      </ParallaxBanner>
    </Section>
  );
};

export default JoinToday;
