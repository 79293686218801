import { Section } from './index.styled';
import ReactPlayerLoader from '@brightcove/react-player-loader';

const Banner = () => {

  return (
    <Section light>
      <ReactPlayerLoader videoId="6313024343112" accountId="6264577224001" />
    </Section>
  );
};

export default Banner;
