import { Item, Section, SPAN } from './index.styled';
import { Container } from 'components/container';
import data from './data.json';
import { GradiantText } from 'components/text';
import { useNavigate } from 'react-router-dom';

const ItemTemplate = ({ img, label, path }) => {
  let navigate = useNavigate();

  return (
    <Item className="p-button-text p-button-plain p-button-outlined" onClick={() => navigate(path)}>
      <img src={img} />
      <h6>
        {/* {Array.from(url).map((c, i) => (
          <SPAN key={i} index={i}>
            {c}
          </SPAN>
        ))} */}
        {label}
      </h6>
    </Item>
  );
};

const Clients = () => {
  return (
    <Section>
      <Container>
        <div className="grid align-items-center">
          <div className="col-12 md:col-4 sec-head">
            <h6>TAKE A MOMENT. . .</h6>
            <GradiantText>LEARN MORE</GradiantText>
            <p>Click on the links to the right, learn about our team, and discover more about 10X WORLD.</p>
          </div>
          <div className="col-12 md:col-8">
            <div className="grid">
              {data.map((item, i) => (
                <div key={i} className="col-6 md:col-3">
                  <ItemTemplate {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Clients;
