import { Section as S } from 'components/Section';
import styled from 'styled-components';

interface Props {
  value: number;
}
export const Progress = styled.div<Props>`
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0px;
  }

  h6 {
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px !important;
    line-height: 1.4;
  }

  .p-progressbar {
    height: 7px;
    overflow: visible;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #151424;
      position: absolute;
      left: ${({ value }) => `${value}%`};
      bottom: 12px;
      transition: left 0.2s ease-in-out;
    }

    &:after {
      content: ${({ value }) => `'${value}%'`};
      position: absolute;
      left: ${({ value }) => `${value - 3}%`};
      top: -45px;
      padding: 7px 15px;
      border-radius: 5px;
      background: #151424;
      font-size: 12px;
      font-weight: 600;
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value-animate {
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value {
      // background: linear-gradient(to right, var(--info-color), var(--help-color), var(--danger-color));
      background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
    }
  }
`;

export const Section = styled(S)`
  padding: 120px 0;
  overflow: hidden;

  .heading2 {
    h2 {
      margin-top: 10px;
      font-size: 35px;
    }
  }

  @media (max-width: 576px) {
  }
`;

export const Aside = styled.aside`
  position: relative;
  border: 1px solid #ffffff05;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 5px 5px 5px;
  cursor: pointer;

  &:hover {
    .p-image {
      filter: blur(10px);
    }

    .overlay {
      display: flex;
    }
  }

  .p-image {
    display: block;
    padding-top: 90%;

    img {
      width: 100%;
      position: absolute;
      top: -40px;
    }
  }

  .overlay {
    display: none;
    flex-direction: column;
    justify-content: end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #0000007a;

    h6 {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 17px;
      font-weight: 500;
    }
  }
`;
