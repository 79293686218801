import { Container } from 'components/container';
import { Aside, Section } from './index.styled';
import data from './datas.json';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';

const Card = ({ icon, count, title }) => (
  <Aside>
    <span className={icon} />
    <CountUp end={count} duration={1}>
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start} delayedCall>
          <h6>
            <span ref={countUpRef} />
          </h6>
        </VisibilitySensor>
      )}
    </CountUp>
    <h5>{title}</h5>
  </Aside>
);

const Count = ({ light = false }) => {
  return (
    <Section light={light}>
      <Container>
        <div className="grid">
          {data.map((item, k) => (
            <div key={k} className="col-12 md:col-3">
              <Card {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};
export default Count;
