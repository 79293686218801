import { Section, Progress } from './index.styled';
import { Container } from 'components/container';
import data from './data.json';
import { ProgressBar } from 'primereact/progressbar';
import VisibilitySensor from 'react-visibility-sensor';
import { CountUp } from 'use-count-up';
import { Image } from 'primereact/image';
import { SectionHeader, SectionHeader2 } from 'components/Section';

const CustomProgressBar = ({ end = 0, label, className = '' }) => {
  return (
    <CountUp isCounting end={end}>
      {({ value, reset }) => (
        <VisibilitySensor onChange={reset}>
          <Progress value={value as number} className={className}>
            <h6>{label}</h6>
            <ProgressBar value={value as number} />
          </Progress>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

const Ecosystem = () => {
  return (
    <Section>
      <Container fluid>
        <div className="grid">
          <div className="col-12 md:col-6 p-0">
            <Image src="/assets/images/4.jpg" width="100%" />
          </div>
          <div className="col-12 md:col-5 col2">
            <SectionHeader2
              className="mb-7"
              label="A BETTER BLOCKCHAIN ECOSYSTEM"
              title="We put you at the center of it."
              text="Todays business models surround the deployment business ecosystems. The challenge with this model is the platform owner extracts all the value. In our model you extract the most value."
            />
            {data.progress.map((item, k) => (
              <CustomProgressBar key={k} end={parseInt(item.value)} label={item.title} />
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Ecosystem;
