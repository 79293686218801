import { Section } from './index.styled';
import Button from 'components/button';
import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';

const Details = () => {
  let navigate = useNavigate();

  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-5">
            <SectionHeader
              label="ABOUT"
              title="CROWDPOINT TECHNOLOGIES"
              text="<p>We are CrowdPoint Technologies:</p>
              <p>A trusted Agent in and untrusted world.</p><br>
              <p>DATA IS THE NEW OIL:</p>
              <p>In today's world, others refine your oil to power global commerce.</p><br>
              <p>JOIN OUR BLOCKCHAIN ECOSYSTEM:</p>
              <p>In crwdworld, you will take control of your data and reap rewards!</p><br>
              <p>WATCH THE VIDEO BELOW.</p>"
            />
            <Link className="p-button mt-6" to="testimonial-block" spy={true} smooth={true} offset={-80} duration={500}>
              Watch The Video
            </Link>
          </div>
          <div className="col-12 md:col-7 side-banner">
            <img
              onClick={() => navigate('/meet-team')}
              src="/assets/images/about/meet_team.png"
              alt=""
              className="cursor-pointer"
            />
            <div className="stauts cursor-pointer" onClick={() => window.open('https://app.crwd.world', '_blank')}>
              <div className="item">
                <h4>JOIN US</h4>
                {/* <h6>SATISFIED CUSTOMERS</h6> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Details;
