import Button from 'components/button';
import { Container } from 'components/container';
import { BGTitleText } from 'components/text';
import { Section, ParallaxBanner } from './index.styled';

const CallAction = () => {
  return (
    <Section light>
      <ParallaxBanner
        layers={[
          {
            className: 'layer1',
            image: '/assets/images/patrn.svg',
            speed: 50,
          },
        ]}
      >
        <Container className="container">
          <div className="grid m-0 align-items-center">
            <div className="col-12 md:col-9">
              <h5>Lets talk</h5>
              <label>
                About your
                <br />
                <BGTitleText>success</BGTitleText>
              </label>
            </div>

            <div className="col-12 md:col-3">
              <Button label="Contact us !" className="p-button-outlined light" />
            </div>
          </div>
        </Container>
      </ParallaxBanner>
    </Section>
  );
};

export default CallAction;
