import { Section } from './index.styled';
import { Container } from 'components/container';
import parse from 'html-react-parser';

interface Props {
  index: string;
  label: string;
  text: string;
  itemList?: Array<string>;
  light?: boolean;
}
const Intro = ({ index, label, text, itemList = [], light = false }: Props) => {
  return (
    <Section className={light && 'light'}>
      <Container>
        <div className="grid px-4">
          <div className="col-12 lg:col-3">
            <h6>
              {/* <span>{index}</span> */}
              {label}
            </h6>
          </div>
          <div className="col-12 lg:col-8 lg:col-offset-1">
            {parse(text)}
            {itemList && (
              <ul>
                {itemList.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Intro;
