export const HUB_SPOT_BLOG_TAG_IDS = {
  AMM: '53674613876',
  Materials: '49272182637',
  ConsumerDiscretionary: '49272182650',
  Financials: '49272182665',
  Industrials: '49272345349',
  ConsumerStaples: '49272345368',
  InformationTechnology: '49272345397',
  CommunicationServices: '49272345412',
  Utilities: '49272345422',
  RealEstate: '49272345426',
};
