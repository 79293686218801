import styled from 'styled-components';

export const ContractWrap = styled.div`
  position: relative;
  background: transparent;
  padding: 25px 2rem 15px;
  margin: 10px 10px 25px;
  height: 100%;

  .p-button-link {
    margin-bottom: -12px;
    @media (min-width: 960px) {
      margin-bottom: initial;
    }
  }
  .inner-box {
    padding: 0 15px 0 15px;

    * {
      z-index: 0;
    }
  }
  .contract-title {
    /* font-size: 24px; */
    margin-bottom: -10px;
    padding-bottom: 0px;
    color: #fff;
  }
  .contract-subtitle {
    font-size: 13px;
  }
  .icon-img-box {
    img {
      height: 80px;
      width: 80px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 15px;
    z-index: -1;
    background: rgba(255, 255, 255, 0.04);
  }

  .p-button-link {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: left;
    display: block;
    margin-bottom: 30px;
  }

  p {
    font-size: 15px;
    margin: 10px 0;
    text-align: justify;

    a {
      color: var(--primary-color);
    }
  }

  h4 {
    line-height: 1;
    margin-top: 30px;
    text-align: justify;
    font-size: 16px;
  }

  ul {
    li {
      color: var(--secondary-color);
      text-align: justify;
    }
  }
`;
