import { ButtonProps } from 'primereact/button';
import { BTN, BTN2 } from './index.styled';
import parse from 'html-react-parser';

const Button = (props: ButtonProps) => {
  let { label, ...rest } = props;
  return <BTN {...rest}>{parse(label)}</BTN>;
};

export default Button;

interface Props {
  label: any;
  onClick?: () => void;
}
const CustomBtn = ({ label, onClick }: Props) => {
  return <BTN2 label={label} className="p-button-link" onClick={() => onClick && onClick()} />;
};

export { CustomBtn };
