import { useEffect, useState } from 'react';
import useViewport from 'utils/useViewport';
import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Section } from './index.styled';
import data from './data.json';
import { SectionHeader } from 'components/Section';

const Foundation = () => {
  const { width } = useViewport();
  const isMobileWidth = width < 540;
  const [isMobile, setIsMobile] = useState(isMobileWidth);

  useEffect(() => {
    if (isMobileWidth !== isMobile) {
      setIsMobile(isMobileWidth);
    }
  }, [width, isMobile, isMobileWidth]);

  return (
    <>
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-6">
            <SectionHeader
              label="FIX THE FOUNDATION"
              title="Introducing the world's first decentralized cryptographic cloud platform"
              text="Purpose built to be a fully integrated, end-to-end solution for the market. Boundless scale, fault-tolerant, and enterprise-grade database speeds to give the world truly secure computing."
            />
            <ul className="mb-5 pr-4">
              {data.map((v, i) => (
                <li key={i}>
                  <p>{v}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 md:col-5 md:col-offset-1">
           <Image src="/assets/images/blockchain/Vogon_Distributed_Cloud.png" width="100%" />
          </div>
        </div>
        {isMobile ? (
          <Image
            src="/assets/images/team/Image_mobile_009.png"
            width="70%"
            className="w-full mx-auto flex align-items-center justify-content-center"
          />
        ) : (
          <Image src="/assets/images/team/Image_009.png" width="100%" />
        )}
      </Container>
    </Section>
    <img src="assets/images/blockchain/vogon_divider.png" className="w-full mt-6 flex" alt="" />
    </>
  );
};

export default Foundation;
