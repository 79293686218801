import PreLoading from 'components/pre-loading';
import Banner from 'components/banner';
import MeetTeam from './meet-team';
import bannerData from './data/bannerData.json';
import Members from './members';
import teams from './data/team.json';
import advisors from './data/advisors.json';
import Partners from './partners';
import boards from './data/board.json';
import WhitepaperDownload from 'components/whitepaper-download';

const TeamPage = () => {
  return (
    <>
      <PreLoading label="TEAM" />
      <Banner {...bannerData} />
      <MeetTeam />
      <Members users={teams} title="BIOS | SENIOR MANAGEMENT TEAM" light />
      <section>
        <img src="/assets/images/vogon_grid.png" alt="Vogan" className="flex w-full" />
      </section>
      <Members users={advisors} title="BIOS | EXECUTIVE ADVISORS" light />
      <Members users={boards} title="BIOS | ADVISORY BOARD" />
      <Partners />
      <WhitepaperDownload />
    </>
  );
};
export default TeamPage;
