import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  overflow: hidden;
  position: relative;
  padding: 100px 0;

  @media (max-width: 576px) {
  }
`;

export const Item = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px 10px 0px;
  padding: 80px 30px;
  background: var(--surface-card);
  border: 1px solid var(--surface-border);

  .icon {
    position: relative;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      // background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
      background: linear-gradient(to right, #dfdfdf, #f4f4f4, var(--primary-color));
      opacity: 0.05;
    }
  }

  .details {
    // padding-left: 30px;

    h6 {
      line-height: 1.8;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 2;
      margin: 0;
    }
  }
`;
