import { Item, Section } from './index.styled';
import { Container } from 'components/container';
import { classNames } from 'primereact/utils';
import { SectionHeader } from 'components/Section';
import { FC } from 'react';
import { Divider } from 'components/divider';

const ItemTemplate = ({ icon, label, text }) => {
  return (
    <Item>
      <span className={classNames('icon', icon)} />
      <div className="details">
        <h6>{label}</h6>
        <p>{text}</p>
      </div>
    </Item>
  );
};

interface Props {
  label: string;
  title: string;
  services: Array<{
    icon: string;
    label: string;
    text: string;
  }>;
}

const Services: FC<Props> = ({ label, title, services }) => {
  return (
    <Section>
      <Divider />
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader label={label} title={title} />
          </div>
        </div>
        <div className="grid m-0 mt-7">
          {services.map((item, i) => (
            <div key={i} className="col-12 md:col-4">
              <ItemTemplate {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Services;
