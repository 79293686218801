import { Breadcrumbs } from 'components/breadcrumbs';
import Button from 'components/button';
import { Container } from 'components/container';
import Input from 'components/input';
import PreLoading from 'components/pre-loading';
import { GradiantText } from 'components/text';
import { useForm } from 'react-hook-form';
import { Section } from './index.styled';
import { InputTextarea } from 'primereact/inputtextarea';
import SocialLinks from 'components/social';
import { GMap } from 'primereact/gmap';
import { loadGoogleMaps, removeGoogleMaps } from '../../utils/GoogleMaps';
import { useEffect, useState } from 'react';
import Footer from 'components/footer';
import { Divider } from 'components/divider';

declare var google: any;

const ContactPage = () => {
  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const { control, watch, handleSubmit, reset } = useForm();

  const options = {
    center: { lat: 34.0937458, lng: -118.3614978 },
    zoom: 12,
  };

  useEffect(() => {
    loadGoogleMaps(() => {
      setGoogleMapsReady(true);
    });

    return () => {
      removeGoogleMaps();
    };
  }, []);

  return (
    <>
      <Section>
        <PreLoading label="CONTACT" />
        <Breadcrumbs
          className="breadcrumbs"
          label="Let's talk <br /> about your project."
          text="Feel free to ask me any question or let’s do to talk about our future collaboration."
        />
        <div className="relative">
          <Divider right />
        </div>
        <div className="main">
          <Container>
            <div className="grid">
              <div className="col-12 md:col-6">
                <GradiantText>Get In Touch.</GradiantText>
                <Input name="name" control={control as any} label="Name" className="mb-4" />
                <Input name="email" control={control as any} label="Email" className="mb-4" />
                <span className="p-float-label mb-4">
                  <InputTextarea id="textarea" rows={5} name="message" />
                  <label htmlFor="textarea">Message</label>
                </span>
                <Button label="SEND MESSAGE" className="light" />
              </div>
              <div className="col-12 md:col-5 md:col-offset-1">
                <GradiantText>Contact Info.</GradiantText>
                <h5>Let'sTalk.</h5>
                <p>support@crwd.id</p>
                <p>+1.512.767.1773</p>

                <h5 className="second">VisitUs.</h5>
                <p>80 S.W. 8th Street, Suite 2000</p>
                <p>Miami, Florida USA</p>

                <SocialLinks className="social" />
              </div>
            </div>
          </Container>
        </div>
        <div className="map-wrapper">
          {googleMapsReady && (
            <GMap
              options={options}
              overlays={[
                new google.maps.Marker({
                  position: { lat: options.center.lat, lng: options.center.lng },
                  title: 'Konyaalti',
                }),
              ]}
            />
          )}
        </div>
      </Section>
      <Footer />
    </>
  );
};
export default ContactPage;
