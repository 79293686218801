import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  position: relative;
  padding: 120px 0;

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

export const Aside = styled.aside`
  text-align: center;
  padding: 60px;
  margin: 60px auto;
  background: #18191d;
  position: relative;

  &.xbottom {
    bottom: -60px;
  }

  &.xtop {
    top: -60px;
  }

  &.xcolor {
    background: #fff;
    color: #11141b;

    p {
      color: #333;
    }
  }

  .icon {
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color), var(--primary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    margin-bottom: 30px;
  }

  h6 {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 20px;
  }
`;
