import { Section as S } from 'components/Section';
import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';

export const Section = styled(S)`
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 100px;

    .grid {
      width: 100%;

      h3 {
        font-weight: 600;
        font-size: 77px;
        line-height: 1.2;
      }

      p {
        margin-top: 20px;
        letter-spacing: 1px;
        line-height: 24px;
        color: var(--text-color);
      }

      button {
        padding: 0;
        margin-top: 3rem !important;
      }

      .login-form {
        height: 525px;
        width: 322px;
      }
    }
  }

  @media (max-width: 576px) {
  }
`;

export const ParallaxBanner = styled(P)`
  .layer1 {
    &:before {
      content: '';
      /* position: absolute; */
      position: fixed;
      background: #0c0b28ba;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    video {
      position: absolute;
      z-index: -1;
      display: block;
      left: 50%;
      top: -28%;
      transform: translateX(-50%);
    }
  }

  .container {
    z-index: 1;
    position: relative;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--surface-ground);
    opacity: 0.5;
  }
`;
