import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { GraphQLClient } from 'graphql-request';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getSdk, Sdk } from 'graphql/generated/graphqlRequest';

const graphqlUrl = process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT + '/v1/graphql';
const queryClient = new QueryClient();

export interface ClientContextType {
  affiliateCode?: string;
  client: GraphQLClient;
  sdkClient: Sdk;
}

export const ClientContext = createContext<ClientContextType>({
  client: clientWithToken(),
  sdkClient: sdkClientWithToken(),
});

export const useClientContext = (): ClientContextType => useContext(ClientContext);

export function clientWithToken(token?: string): GraphQLClient {
  const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
  return new GraphQLClient(graphqlUrl, { headers });
}

export function sdkClientWithToken(token?: string): Sdk {
  return getSdk(clientWithToken(token))
}

export const ClientProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [affiliateCode, setAffiliateCode] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!affiliateCode) {
      setAffiliateCode(searchParams.get('afmc') || undefined)
    }
  }, [affiliateCode, searchParams])

  const contextValue: ClientContextType = {
    affiliateCode,
    client: clientWithToken(),
    sdkClient: sdkClientWithToken()
  };

  return (
    <ClientContext.Provider value={contextValue}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ClientContext.Provider>
  );
};
