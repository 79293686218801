import styled from 'styled-components';

export const Section = styled.section`
  overflow: hidden;

  img {
    width: 100%;

    &.big {
      // height: calc(100% - 10px);
    }
  }

  @media (max-width: 576px) {
    .grid {
      gap: 20px;
    }
  }
`;
