// import { ParallaxBanner as P } from 'react-scroll-parallax';
// import styled from 'styled-components';

// export const ParallaxBanner = styled(P)`
//   margin-top: -88px;
//   &.home-hero {
//     > div {
//       background-position: center bottom 40px !important;
//       background-repeat: no-repeat;
//       background-size: cover;
//       @media screen and (min-width: 960px) {
//         background-position: top 300px center !important;
//         background-size: contain;
//       }
//       .header-wrap {
//         padding: 15vh 0 30px;
//         @media screen and (min-width: 960px) {
//           padding: 40vh 0 50px;
//         }
//       }
//     }
//   }
//   > div {
//     background-repeat: no-repeat;
//     background-size: contain !important;
//   }
//   &:after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: 0.5;
//     background: #080718;
//   }

//   .container {
//     z-index: 1;
//     min-height: 65vh;
//     @media screen and (min-width: 960px) {
//       min-height: 85vh;
//     }
//     position: relative;

//     > * {
//       z-index: 1;
//     }

//     .header-wrap {
//       padding: 20vh 0 30px;
//       @media screen and (min-width: 960px) {
//         padding: 40vh 0 50px;
//       }

//       h5 {
//         line-height: 1.4;
//         font-weight: 500;
//         font-size: 18px;
//       }

//       h2 {
//         font-size: 40px;
//         line-height: 1.4;
//         font-weight: 500;
//       }
//     }

//     h6 {
//       font-size: 13px;
//       font-weight: 600;
//       letter-spacing: 4px;
//       text-transform: uppercase;
//       margin: 0;
//       line-height: 1.4;
//       margin-bottom: 10px;
//     }

//     button {
//       color: var(--secondary-color);
//       font-weight: 400;
//       text-transform: uppercase;
//       letter-spacing: 2px;
//       font-size: 12px;
//       line-height: 2;
//       margin: 0;
//       padding: 0;
//       text-align: left;
//       display: block;
//     }
//   }
// `;

import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';

export const ParallaxBanner = styled(P)`
  margin-top: -88px;

  &.home-hero {
    > div {
      background-position: center bottom 40px !important;
      background-repeat: no-repeat;
      background-size: cover;
      @media screen and (min-width: 960px) {
        background-position: top 300px center !important;
        background-size: contain;
      }
      .header-wrap {
        padding: 15vh 0 30px;
        @media screen and (min-width: 960px) {
          padding: 40vh 0 50px;
        }
      }
    }
  }

  div:first-child {
    // background-position: center 0px !important;
    background-repeat: no-repeat;
    background-size: contain !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #080718;
  }

  .container {
    z-index: 1;
    min-height: 85vh;
    position: relative;

    > * {
      z-index: 1;
    }

    .header-wrap {
      padding: 40vh 0 50px;

      h5 {
        line-height: 1.4;
        font-weight: 500;
        font-size: 18px;
      }

      h2 {
        font-size: 40px;
        line-height: 1.4;
        font-weight: 500;
      }
    }

    h6 {
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin: 0;
      line-height: 1.4;
      margin-bottom: 10px;
    }

    button {
      color: var(--secondary-color);
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 12px;
      line-height: 2;
      margin: 0;
      padding: 0;
      text-align: left;
      display: block;
    }
  }
`;
