import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;

  @media (max-width: 576px) {
    padding: 60px 0;
  }
`;
export const ParallaxBanner = styled(P)`
  div:first-child {
    background-repeat: no-repeat;
    height: 320px;
    width: 320px;
    margin: 0 auto;
    position: relative !important;
    border-radius: 50%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: #080718;
    }
  }

  .caption {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    height: 320px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    &.copy-cap {
      z-index: -1;
    }

    h1 {
      font-size: 55px;
      font-weight: 800;
      display: block;
      min-width: 700px;
      text-align: center;
      margin: auto;
      line-height: 1.4;
      cursor: pointer;

      &.stroke {
        color: transparent;
        -webkit-text-stroke: 1px #fff;
      }
    }
  }

  @media (max-width: 576px) {
    div:first-child {
      width: 50%;
      height: 200px;
    }

    .caption {
      height: 100%;
      width: 100%;

      h1 {
        font-size: 30px;
        min-width: 100%;
      }
    }
  }
`;
