import { Button } from 'primereact/button';
import { CustomBtn } from 'components/button';
import { Card } from './index.styled';
import { FC } from 'react';

interface Props {
  image: string;
  title: string;
  date: string;
  tag: string;
  onClick?: () => void;
}

const NewsCard: FC<Props> = ({ image, title, date, tag, onClick }) => {
  return (
    <Card className="news-card">
      <div className="figure">
        <Button className="p-button-text p-0">
          <img src={image} alt="" />
        </Button>
      </div>
      <div className="caption">
        <ul className="meta">
          <li>{date}</li>
          <li className="mx-2">/</li>
          <li className="tag">{tag}</li>
        </ul>
        <h4>{title}</h4>
        <div className="button-wrapper">
          <CustomBtn label="Read more" onClick={onClick && onClick} />
        </div>
      </div>
    </Card>
  );
};

export default NewsCard;
