import styled from 'styled-components';
import parse from 'html-react-parser';
import { GradiantText } from 'components/text';

export interface BreadCrumbProps {
  label: any;
  text?: any;
  className?: string;
}
const SectionStyled = styled.section`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 300px 0 50px;
  background: #18191d99;
  margin-top: -88px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -250px;
    top: 225px;
    right: auto;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #059dff, #36c6f0);
    opacity: 0.25;
    filter: blur(100px);
  }
  &:after {
    content: '';
    position: absolute;
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    // z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 70%;
    background-image: linear-gradient(45deg, #e80074, #c200db);
    opacity: 0.25;
    filter: blur(100px);
  }

  h3 {
    font-size: 60px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
  }

  p {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    text-align: center;
    margin: 0;
    margin-top: 30px;
  }

  @media (max-width: 576px) {
    padding: 200px 0 50px;

    h3 {
      font-size: 20px;
    }
  }
`;
export const Breadcrumbs = ({ label, text = '', className = '' }: BreadCrumbProps) => {
  return (
    <SectionStyled className={className}>
      <div className="grid m-0">
        <div className="col-12 md:col-10 md:col-offset-1">
          <GradiantText>{parse(label)}</GradiantText>
          <p>{parse(text)}</p>
        </div>
      </div>
    </SectionStyled>
  );
};
