import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 50px 0 0;
  background: var(--surface-card);

  h3 {
    display: inline-block;
  }

  .title {
    margin: 0;
    line-height: 1.4;
    font-weight: 500;
    margin-bottom: 30px;
    font-size: 35px;
  }

  .subtitle {
    margin: 0;
    line-height: 1.2;
    font-weight: 500;
    font-size: 18px;
    text-transform: initial;
    color: var(--primary-color);
  }

  .box {
    margin-top: 100px;
    background: var(--surface-900);

    .des {
      color: var(--surface-ground);
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      text-align: justify;
    }

    ul {
      padding-left: 22px;

      li {
        font-size: 20px;
        font-weight: 500;
        color: var(--surface-ground);
        line-height: 1.2;
      }
    }

    .inner-box {
      background: #fff;
      height: 100%;
      border-radius: 40px;
      padding: 40px 10px 40px;
      margin-top: -45px;
      box-shadow: 0px 4px 15px -6px rgb(0 0 0 / 75%);
      .card {
        margin: 0;
        align-items: center;
        margin-bottom: 20px;

        h5 {
          color: var(--surface-ground);
          font-size: 15px;
          font-weight: 600;
        }
        p {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 576px) {
  }
`;
