import PrimeReact from 'primereact/api';
// import "primereact/resources/themes/md-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ScrollToTop from './utils/ScrollToTop';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from 'pages/home';
import Header from 'components/header';
import { ClientProvider } from 'contexts/ClientContext';
import AboutPage from 'pages/about/about';
import ContactPage from 'pages/contact';
import crwdEcosystemData from './static/crwdEcosystemData.json';
import CompanyPage from 'pages/company';
import IFrame from 'components/iframe';
import TeamPage from 'pages/about/team';
import BigDataPage from 'pages/about/big-data';
import BlockchainPage from 'pages/about/blockchain';
import RoadmapPage from 'pages/about/roadmap';
import DigitalPlatformPage from 'pages/about/digital-platform';
import CrwdEcosystemPage from 'pages/ecosystem/crwd-ecosystem';
import UnitPage from 'pages/ecosystem/units';
import BlogListPage from 'pages/discover/blog-list';
import BlogDetailsPage from 'pages/discover/blog-details';
import NewsPage from 'pages/discover/news';
import { HashRouter } from 'react-router-dom';

function App() {
  // active ripple effect
  PrimeReact.ripple = true;

  return (
    <>
      <HashRouter>
        <ClientProvider>
          <ScrollToTop />
          <Header />
          <div className="wrap">
            <Routes>
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/company" element={<CompanyPage />} />

              <Route
                path="/quality-life"
                element={<IFrame src="/html/income.html" id="quality-life" className="quality-life" />}
              />
              <Route path="/meet-team" element={<TeamPage />} />
              <Route path="/big-data" element={<BigDataPage />} />
              <Route
                path="/business-ecosystem"
                element={<IFrame src="/html/index5.html" id="business-ecosystem" className="business-ecosystem" />}
              />
              <Route
                path="/blockchain-ecosystem"
                element={<IFrame src="/html/index4.html" id="blockchain-ecosystem" className="blockchain-ecosystem" />}
              />
              <Route path="/our-blockchain" element={<BlockchainPage />} />
              <Route path="/decenterlized-cloud" element={<RoadmapPage />} />
              <Route path="/digital-platform" element={<DigitalPlatformPage />} />

              <Route path="/crwdid" element={<CrwdEcosystemPage {...crwdEcosystemData.crwdid} />} />
              <Route path="/crwdmarket" element={<CrwdEcosystemPage {...crwdEcosystemData.crwdmarket} />} />
              <Route path="/crwdcapital" element={<CrwdEcosystemPage {...crwdEcosystemData.crwdcapital} />} />
              <Route path="/crwdfinance" element={<CrwdEcosystemPage {...crwdEcosystemData.crwdfinance} />} />
              <Route path="/crwdsystems" element={<CrwdEcosystemPage {...crwdEcosystemData.crwdsystems} />} />
              <Route path="/crwdworld" element={<CrwdEcosystemPage {...crwdEcosystemData.crwdworld} />} />
              <Route path="/crwdunit" element={<UnitPage />} />

              <Route path="/blog-list" element={<BlogListPage />} />
              <Route path="/blog-details" element={<BlogDetailsPage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/kb" element={<IFrame src="https://km.crowdpointtech.com/" id="kb" className="kb" />} />

              <Route path="" element={<HomePage />} />
              <Route path="*" element={<Navigate to="" />} />
            </Routes>
          </div>
          {/* <Footer /> */}
        </ClientProvider>
      </HashRouter>
    </>
  );
}

export default App;
