import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  background: #fff;
  padding: 80px 0 20px;

  h3 {
    display: inline-block;
  }

  .p-image {
    display: block;
    width: 100%;
    height: auto;
    padding: 0 10px;

    &.center {
      text-align: center;
    }
  }

  @media (max-width: 576px) {
  }
`;
