import { Section } from "./index.styled";

const CrwdworldSeparator = ({ topImg = '', bottomImg = '', details = [], light = false }) => {
  return (
    <Section className={light && 'light'}>
      <div className="grid">
        <div className="col-12 text-center">
          {topImg && <img className="small" src={topImg} alt="project top" />}
          {bottomImg && <img className="big" src={bottomImg} alt="project bottom" />}
        </div>
      </div>
    </Section>
  );
};

export default CrwdworldSeparator;
