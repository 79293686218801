import { Container } from 'components/container';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import Slider, { Settings } from 'react-slick';
import { Item, ParallaxBanner, Section, SkillItem } from './index.styled';
import { Knob } from 'primereact/knob';
import data from './data.json';
import skillData from './skillData.json';
import parse from 'html-react-parser';
import PlayerDialog from 'components/player-modal';
import { useState } from 'react';

interface Props {
  author: { img: string; name: string; detail: string };
  text: string;
}

const ItemTemplate = (props: Props) => (
  <Item>
    <p>{parse(props.text)}</p>
    <div className="info">
      <div className="img-box">
        <Avatar image={props.author.img} shape="circle" size="xlarge" />
      </div>
      <div className="author">
        <h6 className="author-name ">{props.author.name}</h6>
        <span className="author-details">{props.author.detail}</span>
      </div>
    </div>
  </Item>
);

const SkillItemTemplate = ({ label, text, count }) => (
  <SkillItem>
    <Knob value={count} valueTemplate={`${count}%`} strokeWidth={3} />
    <div className="cont">
      <span>{label}</span>
      <h6>{text}</h6>
    </div>
  </SkillItem>
);

const TestimonialBlock = ({ light = false }) => {
  const [show, setShow] = useState(false);

  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  } as Settings;

  return (
    <Section light={light}>
      <ParallaxBanner
        layers={[
          {
            id: 'testimonial-block',
            className: 'layer1',
            image: '/assets/images/crwdfinance_join.jpeg',
            speed: 50,
          },
        ]}
      >
        <Container className="container">
          <div className="vid-area">
            <span className="text">Watch Video</span>
            <Button
              icon="pi pi-play"
              className="p-button-rounded p-button-secondary p-button-outlined"
              onClick={() => setShow(true)}
            />
            <PlayerDialog show={show} onHide={() => setShow(false)} videoUrl="6306709017112" />
          </div>
        </Container>
      </ParallaxBanner>
      <div className="testim-box">
        <div className="head-box">
          <h6>OUR CRWD</h6>
          <h4>HEAR THEIR OWN WORDS</h4>
        </div>
        <Slider {...settings}>
          {data.map((item, i) => (
            <ItemTemplate key={i} {...item} />
          ))}
        </Slider>
      </div>
      <div className="skill-wrapper">
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-1">
            <div className="grid">
              {skillData.map((item, i) => (
                <div key={i} className="col-12 md:col-4">
                  <SkillItemTemplate {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default TestimonialBlock;
