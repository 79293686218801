import { Section } from './index.styled';
import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import { Divider } from 'components/divider';
import data from './data.json';
import { Button as Btn } from 'primereact/button';
import Button from 'components/button';

const GlobalMovement = () => {
  return (
    <Section>
      <Divider right />
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8 md:col-offset-2 text-center">
            <SectionHeader label="WANT TO JOIN A GLOBAL MOVEMENT?" title="CLICK ON THE LINK BELOW!" />
            <Button label="LEARN MORE" className="mt-7 light p-button-outlined" />
            <div className="grid mt-5 justify-content-center social">
              {data.map((s, i) => (
                <Btn key={i} className="p-button-rounded p-button-text" onClick={() => window.open(s.url, '_blank')}>
                  <img src={s.img} />
                </Btn>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default GlobalMovement;
