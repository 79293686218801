import Button from 'components/button';
import { Container } from 'components/container';
import { SectionHeader } from 'components/Section';
import { Image } from 'primereact/image';
import { Section } from './index.styled';

const SAFT = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-8">
            <SectionHeader
              label="SAFT"
              title="Simple Agreement for Future Tokens"
              text="<p>In Round 1, our SAFT is a security, and can be sold pursuant to exemptions (i.e. “accredited investors”) available under unregistered securities offering that follows a Regulation D 506c framework.</p>"
            />
          </div>
          <div className="col-12 md:col-4">
            <Image src="/assets/images/unit/safet_billet.png" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default SAFT;
