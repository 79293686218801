import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Loader } from './index.styled';

interface SpinnerProps {
  className?: string;
  text?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ text }) => (
  <Loader>
    <div className="loader--content">
      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" />
      {text && <span className="p-text-primary">{text}</span>}
    </div>
  </Loader>
);
