import { Container } from 'components/container';
import data from './data.json';
import { GradiantText } from 'components/text';
import { useNavigate } from 'react-router-dom';
import { FooterWrapper } from './index.styled';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import { Button as BTN } from 'primereact/button';
import parse from 'html-react-parser';
import Button from 'components/button';
import { Divider } from 'components/divider';

const Card = ({ img, title, date, path }) => {
  let navigate = useNavigate()

  return (
    <article onClick={() => navigate(path)}>
      <div className="img">
        <img src={img} alt="" />
      </div>
      <div className="post">
        <h3>{parse(title)}</h3>
        <p>{date}</p>
      </div>
    </article>
  );
}

const Footer = () => {
  const navigate = useNavigate();

  return (
    <FooterWrapper>
      <Divider />
      <Container>
        <div className="grid px-4">
          <div className="col-12 lg:col-6 xl:col-4 contact mb-4 md:mb-0">
            <h2>CONTACT US</h2>

            <ul>
              <li>
                <i className="pi pi-map-marker" />
                <div className="cont">
                  <h6>Global HQ</h6>
                  <p>
                    10815 Ranch Rd 2222,
                    <br />
                    Austin, TX 78730,
                    <br />
                    +1 844 972 1777
                  </p>
                </div>
              </li>
              <li>
                <i className="pi pi-envelope" />
                <div className="cont">
                  <h6>Email Us</h6>
                  <p>support@crwd.id</p>
                </div>
              </li>
              <li>
                <i className="pi pi-phone" />
                <div className="cont">
                  <h6>Call Us</h6>
                  <p>+1 512 767 1773</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-12 lg:col-6 xl:col-4 news">
            <h2>RECENT NEWS </h2>

            <ul>
              {data.news.map((item, k) => (
                <li key={k}>
                  <Card {...item} />
                </li>
              ))}

              <li>
                <div className="subscribe">
                  <InputText name="email" placeholder="Type Your Email" />
                  <BTN icon="pi pi-send" className="p-button-rounded" />
                </div>
              </li>
            </ul>
          </div>
          <div className="col-12 md:col-4 copy-right">
            <img src="/assets/images/logo-light.png" onClick={() => {}} alt="" className="logo-img" />
            <div className="grid">
              {data.images.map((img, k) => (
                <div key={k} className="col-6 md:col-4 p-0 text-center">
                  <Image src={img} className="block" />
                </div>
              ))}
            </div>
            <p>
              © 2022 | CROWDPOINT TECHNOLOGIES INC. MADE WITH PASSION BY
              <br />
              <Button label="<span className='crwd-font'>crwd<b>shop</b></span>" className="p-button-link p-0" />
              {/* <br />
              <Button
                label="Privacy Policy"
                className="p-button-link p-0 privacy"
                onClick={() => navigate('/privacy')}
              /> */}
            </p>
          </div>
        </div>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
