import { Container } from 'components/container';
import { Image } from 'primereact/image';
import { Aside, Section } from './index.styled';
import data from './data.json';
import Slider, { Settings } from 'react-slick';
import parse from 'html-react-parser';

const Card = ({ author, text }) => (
  <Aside className="item">
    <div className="author">
      <Image src={author.image} />
      <h6>{author.name}</h6>
      <h5>{author.details}</h5>
    </div>
    <p>{parse(text)}</p>
  </Aside>
);

const Testimonials = ({ light = false }) => {
  const NextArrow = props => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span className="pe-7s-angle-right"></span>
      </div>
    );
  };

  const PrevArrow = props => {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span className="pe-7s-angle-left"></span>
      </div>
    );
  };

  const settings: Settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Section bg="/assets/images/testim-img.jpg" light={light}>
      <Container fluid className="container">
        <Slider {...settings} className="custom-slider">
          {data.map((item, k) => (
            <Card key={k} {...item} />
          ))}
        </Slider>
      </Container>
    </Section>
  );
};
export default Testimonials;
