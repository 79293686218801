import styled, { keyframes } from 'styled-components';

const LoadingAnim = keyframes`
0% {
  stroke: #12c2e9b3 !important;
}
100% {
  stroke: #12c2e9b3 !important;
}
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.no-min-height) {
    min-height: 80vh;
  }

  .loader--content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    span {
      margin-top: 16px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }

  &.dialog-height {
    min-height: calc(100% - 2rem) !important;
  }

  .p-progress-spinner-circle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: #12c2e9b3 !important;
    animation: p-progress-spinner-dash 1.5s ease-in-out infinite, ${LoadingAnim} 6s ease-in-out infinite;
    stroke-linecap: round;
  }
`;
