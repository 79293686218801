import { Dialog as D } from 'primereact/dialog';
import styled from 'styled-components';

export const Dialog = styled(D)`
  max-height: 100%;
  width: 100%;
  height: 100%;

  .p-dialog-header {
    position: absolute;
    top: 0;
    width: 100%;
    background: transparent;

    .p-dialog-title {
      text-align: center;
    }
  }

  .p-dialog-content {
    padding-bottom: 0px;
    background: #000000d6;
  }

  @media (max-width: 576px) {
  }
`;

export const Item = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    position: absolute;
    top: 10px;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
  }

  @media (max-width: 576px) {
  }
`;
