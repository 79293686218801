import { Container } from 'components/container';
import { Section } from './index.styled';
import { GradiantText } from 'components/text';

interface Props {
  title: string;
  users: Array<{ name: string; designation: string; bios: Array<string> }>;
  light?: boolean;
}
const Members = ({ title, users, light }: Props) => {
  return (
    <Section className={light && 'light'}>
      <Container>
        <GradiantText>{title}</GradiantText>
        <ul className="list-none user-detail">
          {users.map((user, i) => (
            <li key={i}>
              <div className="grid px-4">
                <div className="col-12 md:col-3">
                  <h4>{user.name}</h4>
                  <p>{user.designation}</p>
                </div>
                <ul className="col-12 md:col-9">
                  {user.bios.map((bio, k) => (
                    <li key={k}>{bio}</li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  );
};

export default Members;
