import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  position: relative;
  padding: 90px 0 50px;

  @media (max-width: 576px) {
    padding: 10px 25px;
  }
`;

export const Grid = styled.div`
  margin-top: 90px;

  .col-f {
    position: relative;
    height: 228px;

    img {
      width: 100%;
      padding: 0 120px;
      position: absolute;
      top: -100px;
      left: 0;
    }
  }

  h3 {
    line-height: 60px;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 50px;
  }

  p {
    letter-spacing: 1px;
    line-height: 22px;
    color: var(--text-color);
    margin: 20px 0 40px;
  }
`;
