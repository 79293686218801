import { Section as S } from 'components/Section';
import styled from 'styled-components';

export const Section = styled(S)`
  padding: 100px 0 40px;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    width: 140%;
    background: rgba(255, 255, 255, 0.01);
    height: 100%;
    transform: rotate(25deg);
    position: absolute;
    top: -60%;
    left: -17%;
    z-index: -1;
}

  .heading2 {
    h3{
      letter-spacing:7px;
    }

    h2 {
      margin-top: 10px;
      font-size: 35px;
    }

    span {
      font-size: 27px;
      margin-top: 25px;
      display: block;
    }
  }

  .heading-items {
    span {
      font-size: 27px;
      margin-top: 25px;
      display: block;
    }
  }

  .p-image {
    display: block;
    position: relative;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    z-index: 2;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 50%;
    }

    // &:after {
    //   content: '';
    //   background: -webkit-gradient(linear, right top, left top, from(#00cfe5), color-stop(#c200db), to(#e80074));
    //   background: -webkit-linear-gradient(right, #00cfe5, #c200db, #e80074);
    //   background: -o-linear-gradient(right, #00cfe5, #c200db, #e80074);
    //   background: linear-gradient(to left, #00cfe5, #c200db, #e80074);
    //   position: absolute;
    //   top: -5px;
    //   left: -5px;
    //   right: -5px;
    //   bottom: -5px;
    //   border-radius: 50%;
    //   z-index: -1;
    // }
  }

  .states {
    h3 {
      font-size: 50px;
      font-weight: 400;
      margin-right: 20px;
    }

    p {
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .mail-us {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    a {
      -webkit-text-decoration: none;
      text-decoration: none;
      color: #fff;
    }

    .text {
      text-align: right;
      padding-right: 20px;

      p {
        font-size: 12px;
        text-transform: uppercase;
      }

      h6 {
        font-size: 14px;
        margin-top: 5px;
      }
    }

    .icon-box {
      width: 60px;
      height: 60px;
      line-height: 65px;
      text-align: center;
      border-radius: 5px;
      background: -webkit-linear-gradient(323.53deg, #080718 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
      background: -o-linear-gradient(323.53deg, #080718 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
      background: linear-gradient(126.47deg, #080718 6.69%, rgba(4, 4, 5, 0.1) 85.43%);
      font-size: 30px;
    }
  }

  @media (max-width: 576px) {
  }
`;
